import React from 'react';
import {
  message,
  Row,
  Col,
  Card,
  Button,
  Icon,
  Modal,
  Select,
  notification,
  Input,
} from 'antd';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import {Persistence, Request, Desktop, sessionDetails, uploadCache, VideoListSorter, userInfo, Utils, requestCache} from "../../utils";
import { VideoTable, VideoTableSorter } from "../../components";

const queryString = require('query-string');

class VideoList extends React.Component {
  constructor(props) {
    super(props);
    const filteredGroups = sessionDetails.getVideoListFilterProps();
    const tableSortProps = sessionDetails.getVideoListSortProps()
    sessionDetails.changeView("VideoList");

    this.state = {
      videos: [],
      groups: null,
      filteredGroups: filteredGroups ? filteredGroups : [],
      selectedVideoKeys: [],
      selectedCompletedVideoKeys: [],
      loading: false,
      refreshDate: "",
      deleteModalVisible: false,
      tableSortProps: tableSortProps ? tableSortProps : {
        column: "uploadDate",
        direction: "descend",
      },
      comparing: !sessionDetails.isMobile(), // on desktop always "comparing", not on mobile
      searchTerm: null,
    };
  }

  componentDidMount() {
    if (Persistence.isAdmin() && this.props.location.search) {
      let params = queryString.parse(this.props.location.search);
      if (params && params.targetUser) {
        this.setState({targetUser: params.targetUser}, this.refreshVideos);
      }
    } else {
      this.refreshInterval = setInterval(() => this.refreshVideosSilently(), 60000);
      this.refreshVideos(true);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  refreshVideos = (initial) => {
    const { t } = this.props;
    let startTime = new Date().getTime();
    this.setState({loading: true});

    const getList = () => {
      this.refreshGroups().then((groups) => {
        Request.listVideos(this.state.targetUser).then(data => {
          if (data) {
            userInfo.setCurrentlyProcessingVideos(data.currentlyProcessing);
            let videoList = data.videos;
            if (sessionDetails.isCordova()) { // Merge any offline uploads
              videoList = this.mergeCachedVideos(videoList);
            }
            this.setState( {
              videos: videoList,
              groups: groups,
              refreshDate: Date.now()
            });
            if (initial) {
              this.checkForProcessingNotification();
            }
            // Request is usually too fast for user to notice, so let the loading spinner spin at least a full rotation.
            // Allow total rotation time to at least be one full rotation (500 ms), including request time.
            let timeout = Math.max(0, startTime + 500 - new Date().getTime());
            setTimeout(() => {
              this.setState({loading: false});
            }, timeout);
          } else {
            message.warn(t('AnalysisView.noData'));
          }
        })
      });
    }

    if (sessionDetails.isCordova()) {
      if (Utils.hasConnection()) {
        uploadCache.uploadCachedAssessments()
      }
      requestCache.upload().then(getList)
    } else {
      getList();
    }

  };

  checkForProcessingNotification = () => {
    const { t } = this.props;
    const ONE_MONTH_IN_MILLISECONDS = 2.628e+9;
    // Close any previously opened notifications
    notification.destroy();

    userInfo.downloadUserInfo().then(() => {
      let processingCredits = userInfo.getProcessingCredits();
      let processingVideos = userInfo.getCurrentlyProcessingVideos();
      let lowCredit = (processingCredits != null && processingCredits - processingVideos <= 10);


      let expiration = userInfo.getExpiration();
      let oneMonthAway = Date.now() + ONE_MONTH_IN_MILLISECONDS;
      let expiresSoon = expiration ? expiration < oneMonthAway : false;

      let warningText = "";
      if (lowCredit) {
        warningText = t("VideoList.processingCreditWarning", {videos: processingCredits - processingVideos});
      }

      if (expiresSoon) {
        warningText += t("VideoList.expirationWarning", {date: new Date(expiration)});
      }

      let description = <div data-test={"processing-warning-notification"}>
        {warningText}
        {this.conditionalContactInfo(t)}
      </div>

      if (warningText) {
        notification["warning"]({
          message: t("VideoList.accountStatus"),
          description: description,
          duration: 0
        })
      }
    })
  }

  conditionalContactInfo = (t) => {
    let close = sessionDetails.isStaging() ? <a href={"/#"} data-test={"close-warning-notification"} onClick={notification.destroy}>Close</a> : null;
    if (sessionDetails.isCordova()) {
      return close;
    }
    return <div>
      <br/>
      {t("VideoList.contactInfoStart")}
      <a href="https://success.ehs.com/s/supportform">form</a>
      {t("VideoList.contactInfoEnd")}
      <div align={"right"}>
        {close}
      </div>
    </div>
  }


  refreshGroups = () => {
    return Request.listGroups().then((res) => {
      if (res) {
        return res.groups;
      }
    });
  }

  handleGroupNameChange = (id, newName) => {
    let { groups } = this.state;
    groups[id] = newName;
    this.setState({groups: groups});
  }

  refreshVideosSilently = () => {
    this.refreshGroups().then((groups) => {
      Request.listVideos(this.state.targetUser).then(data => {
        if (data) {
          let videoList = data.videos;
          userInfo.setCurrentlyProcessingVideos(data.currentlyProcessing);
          if (sessionDetails.isCordova()) {
            videoList = this.mergeCachedVideos(videoList);
          }
          this.setState( {
            videos: videoList,
            groups: groups,
            refreshDate: Date.now()
          });
        }
      })
    });
  };

  mergeCachedVideos = (videoList) => {
    return videoList.concat(uploadCache.getAnalyses());
  }

  onSelectVideo = (selectedVideoKeys, selectedRows) => {
    let completedVideoIds = [];

    selectedRows.forEach((row) => {
      if (row.status === "complete") {
        completedVideoIds.push(row.id);
      }
    });

    this.setState({
      selectedVideoKeys: selectedVideoKeys,
      selectedCompletedVideoKeys: completedVideoIds
    });
  };

  onFilterChange = (selectedGroups) => {
    this.setState({filteredGroups: selectedGroups});
    sessionDetails.setVideoListFilterProps(selectedGroups);
  }

  onPaginationChange = (page, pageSize) => {
    sessionDetails.setVideoListPaginationProps({
      page: page,
      pageSize: pageSize
    });
  }

  onComparingChange = () => {
    const { t } = this.props;
    const { comparing } = this.state;
    if (sessionDetails.isMobile() && !comparing) {
      message.info(t('VideoList.compareMessage'), 0);
    } else {
      message.destroy();
    }
    this.setState({ comparing: !sessionDetails.isMobile() || !comparing });
  };

  renderGroupSelector = () => {
    const { t } = this.props;
    let noneOption = <Select.Option data-test={"noGroup-filter"} key="None">{t('GroupSelector.none')}</Select.Option>

    let groups = [noneOption];
    if (this.state.groups) {
      for (let groupId of Object.keys(this.state.groups)) {
        groups.push(
          <Select.Option data-test={this.state.groups[groupId] + "-filter"} key={groupId}>
            {this.state.groups[groupId]}
          </Select.Option>
        );
      }
    }
    const cachedFilterProps = sessionDetails.getVideoListFilterProps();
    return (
      <Select
        id="video-list-group-menu"
        data-test="video-list-group-menu"
        mode="multiple"
        style={{ minWidth: '175px', maxWidth: sessionDetails.isCordova() ? '175px' : '400px', margin: '5px', marginLeft: '0px' }}
        placeholder={t('VideoList.groupFilterPrompt')}
        defaultValue={cachedFilterProps ? cachedFilterProps : []}
          // Dont show filtered group names before we have them in state as to not expose the groupIds
          // stored in sessionDetails while the main request is still resolving the names
        maxTagCount={sessionDetails.isCordova() || !this.state.groups ? 0 : 3}
        maxTagTextLength={8}
        showArrow={true}
        onChange={this.onFilterChange}
        disabled={!this.state.groups}
      >
        {groups}
      </Select>
    )
  }

  renderSearch = () => {
    const { t } = this.props;
    return (
        <Input
            allowClear
            placeholder={t('VideoList.search')}
            onChange={e => {
              this.setState({searchTerm: e.target.value})
            }}
            style={{ minWidth: '175px', maxWidth: sessionDetails.isCordova() ? '184px' : '230px', margin: '5px', marginLeft: '0px' }}
            data-test="search-input"
        />
    );
  }

  renderModals = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal 
          onCancel={() => this.setState({deleteModalVisible:false})}
          visible={this.state.deleteModalVisible}
          okText={t('VideoList.okButton')}
          cancelText={t('VideoList.cancel')}
          onOk={() => {
            this.setState({deleteModalVisible:false});
            Request.deleteVideos(this.state.selectedVideoKeys).then(() => {
              this.setState({selectedVideoKeys: []});
              this.refreshVideos();
            });
          }}
          okButtonProps={{'data-test': "delete-modal-ok-button"}}
        >
          <div>{ t('VideoList.deleteConfirmation') }</div>
          <div>{ t('VideoList.assessmentsDeleted') }</div>
        </Modal>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { comparing, selectedCompletedVideoKeys } = this.state;
    const sortedVideos = VideoListSorter.sortVideos(this.state.videos, this.state.groups, this.state.tableSortProps.column,
        this.state.tableSortProps.direction, this.state.filteredGroups, this.state.searchTerm);
    const videoCount = Utils.getRowCount(sortedVideos);
    return (
      <div>
        {this.renderModals()}
        <div className="queue">
          <Row type="flex" justify="center">
            <Col span={24} style={{maxWidth: '2560px'}}>
              <Card size='small' title={<span style={{width: '100%', fontSize: '20pt', textAlign: 'center'}}>{t('VideoList.title')}</span>} bodyStyle={{padding: '10px', paddingTop: '1px', paddingBottom: '1px'}}>
                <Desktop>
                  <Button data-test="delete-button" type="danger" icon="delete" onClick={() => this.setState({deleteModalVisible:true})} style={{margin: '5px', marginLeft: '0px'}} disabled={ this.state.selectedVideoKeys.length === 0 || sessionDetails.isMobile()}>
                    { t('VideoList.delete') }
                  </Button>
                </Desktop>
                <Button data-test = "refresh-button" onClick={this.refreshVideos} style={{margin: '5px', marginLeft: '0px', padding: "0px 8px 0px 8px"}}>
                  <Icon type="sync" spin={this.state.loading} style={{marginLeft: '5px'}}/>{ t('VideoList.refresh') }
                </Button>
                <VideoTableSorter persistentSort sortChange={(sortProps) => {this.setState({tableSortProps: sortProps})}} />
                <Button data-test="compare-button" type={comparing ? 'primary' : null} disabled={!sessionDetails.isMobile() && !selectedCompletedVideoKeys.length} onClick={this.onComparingChange} style={{margin: '5px', marginLeft: '0px'}}>
                  { comparing && selectedCompletedVideoKeys.length ?
                    <Link to={`/comparison?${queryString.stringify({v: selectedCompletedVideoKeys})}`}>{t('VideoList.compare')}</Link> :
                    t('VideoList.compare')
                  }
                </Button>
                {this.renderGroupSelector()}
                {this.renderSearch()}
                <VideoTable
                    refreshDate={this.state.refreshDate}
                    loading={this.state.loading}
                    noSelection={!this.state.comparing && sessionDetails.isMobile()}
                    rowSelectionChange={this.onSelectVideo}
                    videos={sortedVideos}
                    videoCount={videoCount}
                    targetUser={this.state.targetUser}
                    tableSortProps={this.state.tableSortProps}
                    onPaginationChange={this.onPaginationChange}
                    handleGroupNameChange={this.handleGroupNameChange}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VideoList);
