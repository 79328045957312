import React from 'react';
import { withTranslation } from "react-i18next";

import { Checkbox } from 'antd';

class FormCheckbox extends React.Component {

  onChange = (e) => {
    if (this.props.onChange){
      if (e.target.checked === true){
        this.props.onChange(this.props.data.key, 1);
      } else {
        this.props.onChange(this.props.data.key, 0);
      }
    }
  };

  render() {
    const { data } = this.props;
    return(
      <Checkbox onChange={this.onChange} defaultChecked={data.initVal ? data.initVal : false} disabled={this.props.disabled} style={{lineHeight: '20px', margin: '0px'}} data-test={this.props.dataTest} >
        {data.label}
      </Checkbox>
    );
  }
}

export default withTranslation()(FormCheckbox);