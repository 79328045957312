import React from 'react';
import { Line } from '@vx/shape';
import { Group } from '@vx/group';

class HoverLine extends React.Component {
  render() {
    const {
      pointLeft, 
      pointTop,
      height,
      lineColor,
      innerCircleColor,
      outerCircleColor,
    } = this.props;
    return (
      <Group>
        <Line
          from={{ x: pointLeft, y: 0 }}
          to={{ x: pointLeft, y: height }}
          stroke={lineColor || '#FFFFFF'}
          strokeWidth={2}
          style={{ pointerEvents: 'none' }}
          strokeDasharray="2,2"
        />
        <circle
          cx={pointLeft}
          cy={pointTop + 1}
          r={4}
          fill="black"
          fillOpacity={0.1}
          stroke="black"
          strokeOpacity={0.1}
          strokeWidth={2}
          style={{ pointerEvents: 'none' }}
        />
        <circle
          cx={pointLeft}
          cy={pointTop}
          r={4}
          fill={innerCircleColor || 'rgba(92, 119, 235, 1.000)'}
          stroke={outerCircleColor || 'white'}
          strokeWidth={2}
          style={{ pointerEvents: 'none' }}
        />
      </Group>
    );
  }
}

export default HoverLine;
