import React from 'react';
import { LinePath } from '@vx/shape';
import { Group } from '@vx/group';

class ThresholdedLinePath extends React.Component {

  getThreshold = (target) => {
    const { thresholds } = this.props;
    for (let i = 0; i < thresholds.length; i++) {
      if (target < 0 || (target >= thresholds[i].low && target < thresholds[i].high)) {
        return thresholds[i];
      }
    }
    console.error("Thresholds are mis-configured, out of range target: " + target, thresholds);
  };

  /**
    thresholds: {low: 0, high: 100, color: '#FFFFFF'}
  */
  render() {
    const {
      data,
      thresholds,
      valueAccessor,
      ...rest
    } = this.props;
    const paths = [];
    let currentThreshold = thresholds[0];
    let startIdx = 0;
    for(let i = 0;i < data.length;i++) {
      const val = valueAccessor(data[i]);
      const { high, color } = currentThreshold;
      currentThreshold = this.getThreshold(val);
      let start = startIdx;
      let end = i;
      startIdx = i;
      if (i > 0) {
        if (currentThreshold.low >= high) {
          // Next threshold is higher so change startIdx to be 1 less
          // This is to correctly connect the line paths without gaps and unneven coloring
          startIdx = i - 1;
        } else {
          // Next threshold is lower so change end of the current line path to 1 more
          end += 1;
        }
      }

      if (valueAccessor(data[start]) === -1 || valueAccessor(data[i]) === -1) {
        paths.push(
          <LinePath
            key={`linepath-threshold-${i}`}
            {...rest}
            data={data.slice(start, end)}
            defined={d => valueAccessor(d) !== -1}
            strokeLinecap={"round"}
            strokeWidth={4}
            stroke={color}
          />
        );
      } else {
        paths.push(
          <LinePath
            key={`linepath-threshold-${i}`}
            {...rest}
            data={data.slice(start, end)}
            stroke={color}
          />
        );
      }
    }

    return (
      <Group>
        {paths}
      </Group>
    );
  }
}

export default ThresholdedLinePath;
