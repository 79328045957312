import React from 'react';
import { Group } from '@vx/group';
import { Bar } from '@vx/shape';
import { scaleLinear, scaleOrdinal } from '@vx/scale';
import { ParentSize } from '@vx/responsive';
import { GradientOrangeRed } from '@vx/gradient';
import { AxisBottom, AxisLeft, AxisRight, AxisTop } from '@vx/axis';
import { withTooltip, Tooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { LegendOrdinal } from '@vx/legend';
import { bisector, min, max } from 'd3-array';

import HoverLine from './HoverLine';
import ThresholdedLinePath from './ThresholdedLinePath';
import { sessionDetails } from "../../utils";
import { KINETICA_DARK_BLUE } from '../../utils/constants';

const AXIS_COLOR = '#000000';
const HOVER_LINE_COLOR = KINETICA_DARK_BLUE;
const ACCENT_COLOR = KINETICA_DARK_BLUE;

class ThresholdGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.colorScale = scaleOrdinal({
      domain: this.props.thresholds.map(t => t.name),
      range: this.props.thresholds.map(t => t.color),
    });
  }

  componentDidMount() {
    const { xAccessor, data, onClick, onRange} = this.props;
    let videoLength = xAccessor(data[data.length - 1]);
    let range = {
      start: 0,
      end: videoLength
    };

    if (onRange) {
      this.setState({
        startIsSet: false,
        endIsSet: false
      });

      this.props.onRange(range);
      if (onClick) {
        onClick(0);
      }
    }

    this.setState({
      range: range
    });

    // Add a black border around graph legend boxes
    for (let box of document.getElementsByClassName("vx-legend-shape")) {
      box.style["border"] = "1px solid black";
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.hideSelectionLine !== this.props.hideSelectionLine) {
      this.setState({hideSelectionLine: this.props.hideSelectionLine});
    }
  }

  handleTooltip({ event, data, xAccessor, yAccessor, xScale, yScale }) {
    const { showTooltip, onHover } = this.props;
    const { x } = localPoint(event);
    const x0 = xScale.invert(x);
    const index = bisector(xAccessor).left(data, x0);
    const d = data[index];
    showTooltip({
      tooltipData: d,
      tooltipLeft: x,
      tooltipTop: yScale(yAccessor(d))
    });
    if (onHover) {
      onHover(xAccessor(d));
    }
  }

  handleTooltipHide({ event }) {
    const { hideTooltip, onHoverEnd } = this.props;
    hideTooltip();
    if (onHoverEnd) {
      onHoverEnd();
    }
  }

  onClick({event, xScale}) {
    const { onClick, type } = this.props;
    const { x } = localPoint(event);
    const x0 = xScale.invert(x);
    if (onClick) {
      if (type === "range") {
        this.adjustRange(x0);
      }
      onClick(x0);
    }
  }

  adjustRange(newPoint) {
    const { range, startIsSet, endIsSet } = this.state;
    const secondPointBuffer = 0;
    let newRange = range;
    let distanceToStart = Math.abs(newPoint - range.start);
    let distanceToEnd = Math.abs(newPoint - range.end);

    if (range) {
      if (!startIsSet) {  // Adjust start first no matter what
        newRange.start = newPoint
        this.setState({startIsSet: true});
      } else if (!endIsSet) { // Adjust end if we aren't super close to the start point
        if (distanceToStart < secondPointBuffer) {
          newRange.start = newPoint
        } else {
          newRange.end = newPoint
          this.setState({endIsSet: true});
        }
      } else { // Adjust which ever is closest after end and start have been set once
        if (distanceToStart < distanceToEnd) {
          newRange.start = newPoint;
        } else {
          newRange.end = newPoint;
        }
      }

      // swap start and end if start is after end
      if (newRange.start > newRange.end)
        [newRange.start, newRange.end] = [newRange.end, newRange.start];

      if (this.props.onRange) {
        this.props.onRange(newRange);
      }
      this.setState({range: newRange});
    }
    return newRange;
  }

  // Translate a data point to a location on the graph (default is current position in video)
  calculatePlayedOffsets({xScale, yScale}, givenTime=null) {
    const {
      xAccessor,
      yAccessor,
      playedTime,
      data,
    } = this.props;

    let time = givenTime ? givenTime : playedTime;
    let index = bisector(xAccessor).right(data, time) - 1; // gets the frame number for a specified time
    let d = data[index];

    let playedLeft = xScale(time);
    const playedTop = yScale(yAccessor(d));

    return {playedLeft, playedTop, d};
  }

  // Translate a data range to locations on the graph
  calculateRangeOffsets({xScale, yScale}) {
    const { range } = this.state;
    let start = this.calculatePlayedOffsets({xScale, yScale}, range.start);
    let end = this.calculatePlayedOffsets({xScale, yScale}, range.end);

    let points = {
      startLeft: start.playedLeft,
      startTop: start.playedTop,
      endLeft: end.playedLeft,
      endTop: end.playedTop
    };
    return points;
  }

  renderTooltips({parent, bottomMargin}) {
    const {
      xAccessor,
      yAccessor,
      tooltipData,
      tooltipLeft,
      tooltipTop,
      renderTooltips
    } = this.props;
    if (renderTooltips && tooltipData) {
      return (
        <div>
          <Tooltip
            top={tooltipTop - 12}
            left={tooltipLeft + 12}
            style={{
              backgroundColor: KINETICA_DARK_BLUE,
              color: 'white'
            }}
          >
            {`${yAccessor(tooltipData).toFixed(3)}${this.props.yTooltipUnits}`}
          </Tooltip>
          <Tooltip
            top={parent.height - bottomMargin}
            left={tooltipLeft}
            style={{
              transform: 'translateX(-50%)',
              backgroundColor: KINETICA_DARK_BLUE,
              color: 'white'
            }}
          >
            {`${xAccessor(tooltipData).toFixed(3)}s`}
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  }

  renderPlayedTooltips({parent, bottomMargin, xScale, yScale}) {
    const {
      xAccessor,
      yAccessor,
      renderPlayedTooltips,
      playedTime
    } = this.props;
    const { playedLeft, playedTop, d } = this.calculatePlayedOffsets({xScale, yScale});
    if (renderPlayedTooltips && playedTime >= 0) {
      return (
        <div>
          <Tooltip
            top={playedTop - 12}
            left={playedLeft + 12}
          >
            {`${yAccessor(d).toFixed(3)}${this.props.yTooltipUnits}`}
          </Tooltip>
          <Tooltip
            top={parent.height - bottomMargin}
            left={playedLeft}
            style={{
              transform: 'translateX(-50%)'
            }}
          >
            {`${xAccessor(d).toFixed(3)}s`}
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  }

  renderLegend({left, top, width}) {
    if (this.props.renderLegend && this.renderLegend) {
      return (
        <div
          style={{
            position: 'absolute',
            top: top,
            left: left,
            width: width,
            display: 'block',
            justifyContent: 'right',
            fontSize: '12px',
          }}
        >
          <LegendOrdinal scale={this.colorScale} direction="row" labelMargin="0 15px 0 0" />
        </div>
      );
    } else {
      return null;
    }
  }

  renderHoverLine = (height, pointLeft, pointTop) => {
    if (height && pointLeft && pointTop) {
      return (
        <HoverLine
          pointLeft={pointLeft}
          pointTop={pointTop}
          height={height}
          lineColor={AXIS_COLOR}
          innerCircleColor={ACCENT_COLOR}
          outerCircleColor={AXIS_COLOR}
        />
      );
    }
  }

  renderSelectionLine = ({xScale, yScale}, height) => {
    const { type, hideSelectionLine } = this.props;
    const { range } = this.state;

    if (hideSelectionLine) {
      return null;
    } else {
      if (type === "range") {
        if (range) {
          const { startLeft, startTop, endLeft, endTop } = this.calculateRangeOffsets({xScale, yScale});
          return (
            <Group>
              <HoverLine
                pointLeft={startLeft}
                pointTop={startTop}
                height={height}
                lineColor={HOVER_LINE_COLOR}
                innerCircleColor={ACCENT_COLOR}
                outerCircleColor={AXIS_COLOR}
              />
              <HoverLine
                pointLeft={endLeft}
                pointTop={endTop}
                height={height}
                lineColor={HOVER_LINE_COLOR}
                innerCircleColor={ACCENT_COLOR}
                outerCircleColor={AXIS_COLOR}
              />
            </Group>
          );
        }
      } else {
        const { playedLeft, playedTop } = this.calculatePlayedOffsets({xScale, yScale});
        return (
          <Group>
          <HoverLine
              pointLeft={playedLeft}
              pointTop={playedTop}
              height={height}
              lineColor={HOVER_LINE_COLOR}
              innerCircleColor={ACCENT_COLOR}
              outerCircleColor={AXIS_COLOR}
            /></Group>
        );
      }
    }
  }

  render() {
    const {
      xAccessor,
      yAccessor,
      data,
      tooltipLeft,
      tooltipTop,
      capturingGraph,
    } = this.props;
    return (
      <ParentSize>
        {parent => {
          const leftMargin = Math.max(parent.width * 0.1 + 20, 40);
          const bottomMargin = Math.max(parent.height * 0.3, 40);

          // These scales are used to translate data points to physical locations on the graph
          const xScale = scaleLinear({
            range: [leftMargin, parent.width * 0.95],
            domain: [0, max(data, xAccessor)]
          });
          const yScale = scaleLinear({
            range: [parent.height - bottomMargin, 0],
            domain: [Math.min(min(data, yAccessor), 0), max(data, yAccessor) * 1.1]
          });
          const strokeWidth = sessionDetails.isMobile() ? 2 : 3;

          // Sets font size based on if mobile or if capturing
          let tickLabelFontSize; let axisLabelFontSize;
          if (sessionDetails.isMobile()) {
            tickLabelFontSize = 10;
            axisLabelFontSize = 12;
          } else if (capturingGraph) {
            tickLabelFontSize = 12;
            axisLabelFontSize = 14;
          } else {
            tickLabelFontSize = 12;
            axisLabelFontSize = 14;
          }

          return (
          <React.Fragment>
            <svg width={parent.width} height={parent.height+8} data-test="threshold-graph">
              <Group top={10}>
                <GradientOrangeRed id="OrangeRed" />
                <ThresholdedLinePath
                  thresholds={this.props.thresholds}
                  valueAccessor={yAccessor}
                  data={data}
                  x={d => xScale(xAccessor(d))}
                  y={d => yScale(yAccessor(d))}
                  strokeWidth={strokeWidth}
                />
                <Bar
                  x={leftMargin}
                  y={0}
                  width={Math.max(parent.width * 0.95 - leftMargin, 0)}
                  height={Math.max(parent.height * 0.95 - bottomMargin, 0)}
                  fill="transparent"
                  rx={14}
                  data={data}
                  onTouchStart={event =>
                    this.handleTooltip({
                      event,
                      xAccessor,
                      yAccessor,
                      xScale,
                      yScale,
                      data: data
                    })
                  }
                  onTouchMove={event =>
                    this.handleTooltip({
                      event,
                      xAccessor,
                      yAccessor,
                      xScale,
                      yScale,
                      data: data
                    })
                  }
                  onMouseMove={event =>
                    this.handleTooltip({
                      event,
                      xAccessor,
                      yAccessor,
                      xScale,
                      yScale,
                      data: data
                    })
                  }
                  onMouseLeave={event =>  this.handleTooltipHide({event})}
                  onClick={event => this.onClick({event, xScale})}
                />
                <Group>
                  <AxisLeft
                    left={leftMargin}
                    scale={yScale}
                    label={this.props.yAxisLabel}
                    labelOffset={20}
                    labelProps={{
                      fill: {AXIS_COLOR},
                      textAnchor: 'middle',
                      fontSize: axisLabelFontSize,
                    }}
                    stroke={AXIS_COLOR}
                    tickStroke={AXIS_COLOR}
                    tickLabelProps={() => (this.props.hideYAxisUnits ?
                      {
                        fill: {AXIS_COLOR},
                        textAnchor: 'end',
                        fontSize: tickLabelFontSize,
                        dx: '-0.25em',
                        dy: '0.25em',
                        display: 'none',
                      } : {
                        fill: {AXIS_COLOR},
                        textAnchor: 'end',
                        fontSize: tickLabelFontSize,
                        dx: '-0.25em',
                        dy: '0.25em',
                      })
                    }
                  />
                  <AxisRight
                    left={parent.width * 0.95}
                    scale={yScale}
                    stroke={AXIS_COLOR}
                    tickStroke={AXIS_COLOR}
                    hideTicks={true}
                    tickLabelProps={() => ({
                        fill: {AXIS_COLOR},
                        textAnchor: 'end',
                        fontSize: 10,
                        dx: '-0.25em',
                        dy: '0.25em',
                        display: 'none',
                      })
                    }
                  />
                  <AxisTop
                    top={0}
                    scale={xScale}
                    stroke={AXIS_COLOR}
                    hideTicks={true}
                    tickLabelProps={(value, index) => ({
                      fill: {AXIS_COLOR},
                      textAnchor: 'middle',
                      fontSize: 10,
                      dy: '0.25em',
                      display: 'none'
                    })}
                  />
                  <AxisBottom
                    top={parent.height - bottomMargin}
                    scale={xScale}
                    label="Time (sec)"
                    labelProps={{
                      fill: {AXIS_COLOR},
                      textAnchor: 'middle',
                      fontSize: axisLabelFontSize,
                    }}
                    stroke={AXIS_COLOR}
                    tickStroke={AXIS_COLOR}
                    tickLabelProps={(value, index) => ({
                      fill: {AXIS_COLOR},
                      textAnchor: 'middle',
                      fontSize: tickLabelFontSize,
                      dy: '0.25em'
                    })}
                  />
                </Group>
                {this.renderHoverLine(parent.height - bottomMargin, tooltipLeft, tooltipTop)}
                {this.renderSelectionLine({xScale, yScale}, parent.height - bottomMargin)}
              </Group>
            </svg>
            {this.renderLegend({left: 0.1 * parent.width + 19, top: -12, width: parent.width * 0.15})}
            {this.renderPlayedTooltips({parent, bottomMargin, xScale, yScale})}
            {this.renderTooltips({parent, bottomMargin})}
          </React.Fragment>
          );
        }}
      </ParentSize>
    );
  }
}

export default withTooltip(ThresholdGraph);
