import React from 'react';
import ReactPlayer from 'react-player';

import { Request, uploadCache, Utils } from "../../utils/";

class VideoStream extends React.Component {
    constructor(props) {
        super(props);
        this.playerRef = React.createRef();
        this.state = {
            refreshTimer: "",
            url: null,
        };
               
    }

    componentDidMount() {
        let intervalId = setInterval(() => {return Request.renewAuthorization(this.props.authorization).then((response) => {
            if (!(Utils.responseIsSuccess(response))) {
                clearInterval(this.state.refreshTimer);
            }
        })}, 30000);
        this.setState({refreshTimer : intervalId});
    }

    componentDidUpdate() {
        let validVideo = this.props.videoId && this.props.authorization;
        let validCachedVideo = this.props.videoId && uploadCache.isCached(this.props.videoId);
        if ((validVideo || validCachedVideo) && !this.state.url) {
            Request.getVideoUrl(this.props.videoId, this.props.authorization, "", (this.props.videoStatus === "processing")).then((url) => {
                this.setState({url: url});
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshTimer);
    }

    seekTo = (time) => {
        if (this.playerRef.current) {
            this.playerRef.current.seekTo(time, 'seconds');
        }
    };

    handleReady = () => {
        // Disable picture in picture
        this.playerRef.current.getInternalPlayer().disablePictureInPicture = true;
        if (this.props.onReady) {
            this.props.onReady();
        }

        if (this.props.setVideoResolution) {
            let videoResolution = {
                height: this.playerRef.current.player.player.player.videoHeight,
                width: this.playerRef.current.player.player.player.videoWidth,
            };
            this.props.setVideoResolution(videoResolution);
        }
    };

    render () {
        const { authorization, videoId } = this.props;
        const { url } = this.state;
        if (((authorization && videoId) || (uploadCache.isCached(videoId))) && url ) {
            return (
                <ReactPlayer
                    playsinline={true}
                    muted={this.props.muted}
                    progressInterval={this.props.progressInterval}
                    playing={this.props.playing}
                    loop={this.props.loop}
                    url={this.state.url}
                    width={this.props.width}
                    height={this.props.height}
                    controls={this.props.controls}
                    onDuration={this.props.onDuration}
                    onProgress={this.props.onProgress}
                    onError={this.props.onError}
                    onReady={this.handleReady}
                    onPause={this.props.onPause}
                    onPlay={this.props.onPlay}
                    style={this.props.style}
                    ref={this.playerRef}
                    config={{ file: {
                        attributes: {
                            crossOrigin: 'true',
                            disableremoteplayback: 'true'
                        }
                    }}}
                />
        )
        } else {
            return null;
        }
    }
}

export default VideoStream;