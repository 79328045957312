import React from "react";
import { Badge, Button, Form, InputNumber, Modal, Table } from "antd";

import { Utils, Request } from "../../utils";

import { withTranslation } from "react-i18next";


class InstanceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startModalVisible: false,
            terminateModalVisible: false,
            modalLoading: false,
            modalGpu: null
        }
    }


    stopGpu = (instanceId) => {
        Request.stopInstance(instanceId).then(this.props.onGpuChange);
    };

    cancelProcessing() {
        console.log("this doesn't work yet");
    }

    startGpu = (instanceId) => {
        this.setState({startModalVisible: true, modalGpu: instanceId});
    };

    terminateGpu = (instanceId) => {
        this.setState({terminateModalVisible: true, modalGpu: instanceId});
    };

    onTerminate = (e) => {
        e.preventDefault();
        this.setState({modalLoading: true});

        Request.terminateInstance(this.state.modalGpu).then(() => {
            this.setState({modalLoading: false, terminateModalVisible: false});
            this.props.onGpuChange();
        });
    };

    extendGpu = (e) => {
        e.preventDefault();
        const { form } = this.props;
        this.setState({modalLoading: true});

        Request.startInstance(this.state.modalGpu, form.getFieldValue("uptime")).then(() => {
            this.setState({startModalVisible: false, modalLoading: false});
            this.props.onGpuChange();
        });
    };

    renderGpuButton(gpuState, gpuId) {
        const { t } = this.props;
        let label, action, extendButton;

        switch (gpuState) {
            case 'startup':
            case 'available':
            case 'error':
                label = t('InstanceTable.stop');
                action = () => this.stopGpu(gpuId);
                extendButton = <Button onClick={() => this.startGpu(gpuId)} size="small">{t('InstanceTable.extend')}</Button>;
                break;
            case 'processing':
                label = t('InstanceTable.cancel');
                action = () => this.cancelProcessing(gpuId);
                break;
            case 'off':
                label = t('InstanceTable.start');
                action = () => this.startGpu(gpuId);
                break;
            case 'terminated':
            default:
                return t('InstanceTable.terminated');
        }


        return (
            <span>
                {extendButton}
                <Button onClick={action} type={gpuState === 'processing' ? 'danger' : 'primary'} size="small">{label}</Button>
                <Button onClick={() => this.terminateGpu(gpuId)} disabled={gpuState === 'processing'} type="danger" size="small">{ t('InstanceTable.terminate')}</Button>
            </span>
        );
    }

    renderState(text, record) {
        let gpuState =
            text === 'pending' ? 'startup' :
            text === 'terminated' || text === "shutting-down" ? 'terminated' :
            text === 'running' ?
                record.status === 'available' ? 'available' :
                record.status === 'processing' ? 'processing' :
                record.status === 'initializing' ? 'startup' : 'error' :
            'off';
        return <span><Badge status={
            gpuState === 'startup' ? 'warning' :
            gpuState === 'processing' ? 'processing' :
            gpuState === 'available' ? 'success' :
            gpuState === 'error' ? 'error' :
            'default'
        } />{this.renderGpuButton(gpuState, record.id)}</span>
    }

    render() {
        const {t} = this.props;
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: t('InstanceTable.columns.id'),
                dataIndex: 'id',
                sorter: (a, b) => {return (a.id ?? a.id.localeCompare(b.id) : 0)}
            },
            {
                title: t('InstanceTable.columns.name'),
                dataIndex: 'name',
                sorter: (a, b) => {
                    if (a.name === null || b.name === null) {
                        return 0;
                    }
                    a.name.localeCompare(b.name)
                }
            },
            {
                title: t('InstanceTable.columns.url'),
                dataIndex: 'publicDns'
            },
            {
                title: t('InstanceTable.columns.date'),
                dataIndex: 'processStartDate',
                render: (text) => <span>{t('Formats.longDateTime', {date: new Date(text)})}</span>,
                sorter: (a, b) => Utils.compareDates(a.processStartDate, b.processStartDate),
                defaultSortOrder: "descend"
            },
            {
                title: t('InstanceTable.columns.expiration'),
                dataIndex: 'expiration',
                render: (text) => <span>{t('Formats.longDateTime', {date: new Date(text)})}</span>,
                sorter: (a, b) => Utils.compareDates(a.expiration, b.expiration)
            },
            {
                title: t('InstanceTable.columns.state'),
                dataIndex: 'state',
                render: (record, state) => this.renderState(record, state, this.props),
                sorter: (a, b) => a.status ? a.status.localeCompare(b.status) : 0
            }
        ];
        return (
            <div>
                <Modal onOk={this.extendGpu} onCancel={() => this.setState({startModalVisible:false})} visible={this.state.startModalVisible} confirmLoading={this.state.modalLoading}>
                    <Form layout="horizontal" labelCol={{span: 4}} wrapperCol={{span: 6}}>
                        <Form.Item label={t('InstanceTable.startupMinutes')}>
                            {getFieldDecorator('uptime', {initialValue: 60})(
                                <InputNumber min={0} max={8*60} precision={0} step={60}/>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal onOk={this.onTerminate} onCancel={() => this.setState({terminateModalVisible:false})} visible={this.state.terminateModalVisible} confirmLoading={this.state.modalLoading}>
                    {t('InstanceTable.confirmTerminate', {instanceId: this.state.modalGpu})}
                </Modal>
                <Table loading={this.props.loading} columns={columns} rowKey={'id'} dataSource={this.props.instances}/>
            </div>
        );
    }
}

export default withTranslation()(Form.create()(InstanceTable));