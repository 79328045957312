import React from 'react';
import { BarStack } from '@vx/shape';
import { scaleLinear, scaleBand, scaleOrdinal } from '@vx/scale';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { Group } from '@vx/group';
import { Grid } from '@vx/grid';
import { withParentSize } from '@vx/responsive';
import { LegendOrdinal } from '@vx/legend';
import {sessionDetails, Utils} from '../../utils';

const DEFAULT_AXIS_COLOR = 'black';
const DEFAULT_AXIS_FONT_SIZE = 10;
const DEFAULT_MARGINS = {
  top: 10,
  bottom: 40,
  left: 60,
  right: 40,
};
const DEFAULT_LEGEND_HEIGHT = 45;

class ComparisonGraph extends React.Component {

  componentDidMount() {
    // Add a black border around graph legend boxes
    for (let box of document.getElementsByClassName("vx-legend-shape")) {
      box.style["border"] = "1px solid black";
    }
  }

  // BarStacks uses the raw keys for the bars as labels so we need to add
  // spaces to differentiate Analyses with the same name (therefore same key)
  differentiateIdenticalNames = (data) => {
    let names = {}
    for (const analysis of data) {
      let newName = Utils.truncateString(analysis.name, 18)
      if (names[newName]) {
        //Append spaces to identical names
        names[newName] += 1
        newName += " ".repeat(names[newName])
      } else {
        names[newName] = 1
      }
      analysis.name = newName
    }
    return data
  }

  renderGrid = ({ margins, graphSize, xScale, yScale }) => {
    // noinspection RequiredAttributes,JSUnresolvedFunction
    return (
      <Grid
        top={margins.top}
        left={margins.left}
        xScale={xScale}
        yScale={yScale}
        width={graphSize.width}
        height={graphSize.height}
        stroke="black"
        strokeOpacity={0.1}
        xOffset={xScale.bandwidth() / 2 - margins.left}
        yOffset={-margins.top}
      />
    );
  }

  renderAxes = ({ margins, graphSize, xScale, yScale, axisColor, axisFontSize, axisLabel }) => {
    // noinspection JSUnusedLocalSymbols
    return (
      <Group>
        <AxisLeft
          left={margins.left}
          scale={yScale}
          label={axisLabel}
          labelOffset={30}
          labelProps={{
            fill: { axisColor },
            textAnchor: 'middle',
            fontSize: 12,
          }}
          stroke={axisColor}
          tickStroke={axisColor}
          tickFormat={(v) => v + '%'}
          tickLabelProps={() =>
            ({
              fill: { axisColor },
              textAnchor: 'end',
              fontSize: axisFontSize,
              dx: '-0.25em',
              dy: '0.25em',
            })
          }
        />
        <AxisBottom
          top={graphSize.height + margins.top}
          scale={xScale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={(value, index) => ({
            fill: { axisColor },
            textAnchor: 'middle',
            fontSize: axisFontSize,
            angle: sessionDetails.isMobile() ? 300 : 0,
            dy: sessionDetails.isMobile() ? '2em' : '.25em',
          })}
        />
      </Group>
    );
  }

  renderBarStacks = (barStacks) => {
    // Calculate borders to draw around empty data bars
    let borderStack = barStacks[0].bars.map( (bar, i) => {
      let minY = Infinity;
      let totalHeight = 0;
      for(let j=0; j<barStacks.length; ++j) {
        let currBar = barStacks[j].bars[i];
        if (currBar.y && !isNaN(currBar.y))
          minY = Math.min(minY, currBar.y);
        if (currBar.height && !isNaN(currBar.height))
          totalHeight += currBar.height
      }
      return (
        <rect
          key={`bar-stack-border-${bar.index}`}
          x={bar.x}
          y={minY}
          height={totalHeight}
          width={bar.width}
          fill={"none"}
          stroke={"black"}
          strokeWidth={1} />
      );
    });

    let mainStack = barStacks.map((barStack) => {
      return barStack.bars.map((bar) => {
        return (
          <rect
            key={`bar-stack-${barStack.index}-${bar.index}`}
            x={bar.x}
            y={bar.y}
            height={isNaN(bar.height) ? 0 : bar.height}
            width={bar.width}
            fill={bar.color} />
        );
      });
    });

    return mainStack.concat(borderStack);
  }

  renderBars = ({ data, keys, xScale, yScale, colorScale }) => {
    return (
      <Group>
        <BarStack
          data={data}
          keys={keys}
          x={(a) => a.name}
          xScale={xScale}
          yScale={yScale}
          color={colorScale}
        >
          {this.renderBarStacks}
        </BarStack>
      </Group>
    );
  }

  // noinspection JSUnusedLocalSymbols
  renderLegend = ({ colorScale, margins, graphSize }) => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '12px',
        }}
      >
        <LegendOrdinal scale={colorScale} direction="row" labelMargin="0 10px 0 0" />
      </div>
    );
  }

  render() {
    const { parentWidth, parentHeight, 
      data = [], 
      keys = [], 
      keyColors = [],
      margins = {},
      axisColor = DEFAULT_AXIS_COLOR,
      axisFontSize = DEFAULT_AXIS_FONT_SIZE,
      axisLabel = '',
      legendHeight = DEFAULT_LEGEND_HEIGHT,
    } = this.props;
    // Margins for the graph content (axis/labels/title go in margins)

    // Truncate and differentiate keys (analysis names)
    let chartReadyData = this.differentiateIdenticalNames(data)

    // Calculate margins and size
    let viewMargins = Object.assign({}, DEFAULT_MARGINS, margins);
    const maxWidth = 100 * data.length;
    const contentWidth = parentWidth - viewMargins.left - viewMargins.right;
    if (contentWidth > maxWidth) {
      // If we hit maxWidth then we need to adjust viewMargins to center graph
      viewMargins.left = (parentWidth - maxWidth) / 2
      viewMargins.right = (parentWidth - maxWidth) / 2
    }
    const graphHeight = parentHeight - viewMargins.top - viewMargins.bottom - legendHeight
    const graphSize = {
      width: Math.min(contentWidth, maxWidth),
      height: sessionDetails.isMobile() ? graphHeight - 36 : graphHeight
    };

    // Calculate Scales
    const colorScale = scaleOrdinal({
      domain: keys,
      range: keyColors,
    });
    const xScale = scaleBand({
      range: [viewMargins.left, viewMargins.left + graphSize.width],
      domain: chartReadyData.map((d) => d.name),
      padding: 0.2,
    });
    const yScale = scaleLinear({
      range: [viewMargins.top + graphSize.height, viewMargins.top],
      domain: [0, 100]
    });
    const chartHeight = parentHeight - legendHeight;
    return (
      <React.Fragment>
        <svg width={parentWidth} height={chartHeight} style={{ margin: 0 }}>
          {this.renderGrid({ margins: viewMargins, graphSize, xScale, yScale })}
          {this.renderBars({ data: chartReadyData, keys, xScale, yScale, colorScale })}
          {this.renderAxes({ margins: viewMargins, graphSize, xScale, yScale, axisColor, axisFontSize, axisLabel })}
        </svg>
        {this.renderLegend({ colorScale, margins: viewMargins, graphSize })}
      </React.Fragment>
    );
  }
}

export default withParentSize(ComparisonGraph);
