import React from 'react';
import {Row, Col, Divider, Layout, Icon, Button} from 'antd';

import { Logo } from '../../components';

import { withTranslation } from "react-i18next";
import {
  FileCache,
  firebaseClient,
  Persistence,
  Request,
  sessionDetails,
  uploadCache,
} from '../../utils';

const { Footer } = Layout;

class AcceptTermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  acceptTOS = () => {
    Request.acceptTOS().then(() => {
      Persistence.acceptTOS();
      this.props.onAccept();
    });
  };

  logout = () => {
    // noinspection JSIgnoredPromiseFromCall
    Request.logout();
    Persistence.logout();
    // noinspection JSIgnoredPromiseFromCall
    firebaseClient.logout();
    if (sessionDetails.isCordova()) {
      // noinspection JSIgnoredPromiseFromCall
      FileCache.clearCache();
      uploadCache.clearCache();
    }
    this.props.onLogout();
  };

  render() {
    const { t } = this.props;
    return (
        <div style={{height: "100vh"}}>
          <Row type="flex" justify="center" align="middle" style={{height: '85vh'}}>
            <Col span={24} style={{maxWidth: '300px'}}>
              <Row type="flex" justify="center" align="middle">
                <Col  style={{display: "flex", alignItems: "center", flexDirection: "column", width: '100%'}}>
                  <Logo />
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle" style={{height: "2px"}}>
                <Col  style={{width: '100%'}}>
                  <Divider style={{margin: 0, height: "2px"}}/>
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle">
                <Col  style={{width: '100%', marginTop: "8px"}}>
                  <span style={{margin: "auto", textAlign: "center"}}>
                    <div>{t('AcceptTermsOfService.instructions')}</div>
                    <span style={{marginTop: "12px"}}>
                      <a href={"https://www.ehs.com/privacy"} target="_blank" style={{display: "inline-block", margin: "0px 8px 0px 0px"}} rel="noreferrer">
                        <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.privacyPolicy')}
                      </a>
                      {t('AcceptTermsOfService.and')}
                      <a href={"https://legal.ehs.com/wp-content/uploads/VelocityEHS_Master_Subscription_Services_Agreement.pdf"} target="_blank" style={{display: "inline-block", margin: "0px 0px 0px 8px"}} rel="noreferrer">
                        <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.termsAndConditions')}
                      </a>
                    </span>
                  </span>
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle">
                <Col style={{width: '100%', textAlign: "center", marginTop: "16px"}}>
                  <Button type="primary" onClick={this.acceptTOS} style={{width: "80px"}} data-test="accept-tos-button">
                    {t('AcceptTermsOfService.accept')}
                  </Button>
                  <Button type="primary" onClick={this.logout} style={{width: "80px", marginLeft: '32px'}} data-test="logout-button">
                    {t('AcceptTermsOfService.logout')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer style={{backgroundColor: "#ffffff", textAlign: "center"}}>
            <span>{t('AppLayout.copyright')}</span>
          </Footer>
        </div>
    );
  }
}

export default withTranslation()(AcceptTermsOfService);
