import 'whatwg-fetch';
import axios from 'axios';
import { Utils, FileUtil, CordovaUtils, Persistence, sessionDetails, FileCache, uploadCache } from './';


const SUFFIX_ACCEPT_TERMS_OF_SERVICE = "/api/acceptTOS";
const SUFFIX_CANCEL_VIDEO = "/api/analysis/cancel";
const SUFFIX_CHANGE_PASSWORD = "/api/changePassword";
const SUFFIX_CHECK_ADMIN = "/api/checkAdmin";
const SUFFIX_CLEAR_ASSESSMENT = "/api/assessment/clear";
const SUFFIX_CLEAR_VIDEO = "/api/analysis/clear";
const SUFFIX_CREATE_DEV_ENVIRONMENT = "/dev/env/create";
const SUFFIX_CREATE_GPU = "/dev/gpu/provision";
const SUFFIX_CREATE_GROUP = "/api/groups/create"
const SUFFIX_CREATE_ORG = "/api/admin/createOrg";
const SUFFIX_CREATE_ORG_ORG_ADMIN = "/api/orgAdmin/createOrg";
const SUFFIX_CREATE_USER = "/api/admin/createUser";
const SUFFIX_CREATE_USER_ORG_ADMIN = "/api/orgAdmin/createUser";
const SUFFIX_DEACTIVATE_USER = "/api/admin/deactivateUser";
const SUFFIX_DEACTIVATE_USER_ORG_ADMIN = "/api/orgAdmin/deactivateUser";
const SUFFIX_DELETE_DEV_ENVIRONMENT = "/dev/env/delete";
const SUFFIX_DELETE_USER = "/api/admin/deleteUser";
const SUFFIX_DELETE_USER_ORG_ADMIN = "/api/orgAdmin/deleteUser";
const SUFFIX_DEPLOY_DEV_ENVIRONMENT = "/dev/env/deploy";
const SUFFIX_UPLOAD_SETTINGS = "/api/uploadSettings";
const SUFFIX_DOWNLOAD_JSON = "/api/analysis/data/";
const SUFFIX_DOWNLOAD_THUMBNAIL = "/api/analysis/thumbnail/";
const SUFFIX_DOWNLOAD_VIDEO = "/api/analysis/video/";
const SUFFIX_EDIT_ANALYSIS = "/api/analysis/edit";
const SUFFIX_EDIT_GROUP = "/api/groups/edit"
const SUFFIX_EDIT_ORG = "/api/admin/editOrg";
const SUFFIX_EDIT_USER = "/api/admin/editUser";
const SUFFIX_EDIT_USER_ORG_ADMIN = "/api/orgAdmin/editUser";
const SUFFIX_FIND_ORGANIZATION = "/api/admin/getOrg";
const SUFFIX_FIND_ORGANIZATION_ORG_ADMIN = "/api/orgAdmin/getOrg";
const SUFFIX_FIND_USER = "/api/admin/getUser";
const SUFFIX_FIND_USER_ORG_ADMIN = "/api/orgAdmin/getUser";
const SUFFIX_GET_ASSESSMENT = "/api/assessment";
const SUFFIX_GET_INSTANCES = "/api/gpu/list";
const SUFFIX_LIST_GROUPS = "/api/groups/list";
const SUFFIX_LIST_DEV_ENVIRONMENTS = "/dev/env/list";
const SUFFIX_LIST_ORGANIZATIONS = "/api/admin/listOrgs";
const SUFFIX_LIST_ORGANIZATIONS_ORG_ADMIN = "/api/orgAdmin/listOrgs";
const SUFFIX_LIST_USERS = "/api/admin/listUsers";
const SUFFIX_LIST_USERS_ORG_ADMIN = "/api/orgAdmin/listUsers";
const SUFFIX_LIST_USERS_FOR_ORGANIZATION = "/api/admin/listUsersForOrg";
const SUFFIX_LIST_USERS_FOR_ORGANIZATION_ORG_ADMIN = "/api/orgAdmin/listUsersForOrg";
const SUFFIX_LIST_VIDEOS = "/api/listVideos";
const SUFFIX_LIST_ASSESSMENTS = "/api/assessment/list";
const SUFFIX_LOGIN = "/api/login";
const SUFFIX_LOGOUT = "/api/logout";
const SUFFIX_NEW_PASSWORD = "/api/newPassword";
const SUFFIX_PROCESS_VIDEO = "/api/analysis/start";
const SUFFIX_REAUTHORIZE = "/api/analysis/reauthorize";
const SUFFIX_RENEW_AUTHORIZATION = "/api/analysis/renewAuthorization";
const SUFFIX_REQUEST_PASSWORD_RESET = "/api/requestPasswordReset";
const SUFFIX_STOP_DEV_ENVIRONMENT = "/dev/env/stop"
const SUFFIX_START_DEV_ENVIRONMENT = "/dev/env/start"
const SUFFIX_START_INSTANCE = "/api/gpu/start";
const SUFFIX_STOP_INSTANCE = "/api/gpu/stop";
const SUFFIX_TERMINATE_INSTANCE = "/api/gpu/terminate";
const SUFFIX_UPLOAD_VIDEO = "/api/analysis/upload";
const SUFFIX_UPLOAD_ASSESSMENT = "/api/assessment/upload";
const SUFFIX_USER_INFO = "/api/userInfo";
const SUFFIX_VIDEO_DATA = "/api/analysis";

export default class Request {
    static setApiUrl(customUrl) {
        let origin = document.location.origin;
        if (origin === "https://app.kineticalabs.com") {
            sessionDetails.setApiUrl("https://api.kineticalabs.com")
            sessionDetails.setStaging(false);
            return;
        }

        let apiUrl;
        let isStaging = true;
        if (customUrl) {
            apiUrl = customUrl;
            console.log("Set API Url: " + customUrl)
        }  else // noinspection JSUnresolvedVariable
            if (window.cordova) {
            apiUrl = "https://api.kineticalabs.com";
            isStaging = false;
        } else {
            apiUrl = "https://staging.api.kineticalabs.com";
        }

        sessionDetails.setApiUrl(apiUrl);
        sessionDetails.setStaging(isStaging);
        sessionDetails.setDev(apiUrl.startsWith("http://ec2-"));
    }

    static getApiUrl() {
        return sessionDetails.getApiUrl();
    }

    /**
     * Used to retrieve a thumbnail for an analysis or assessment
     * @param analysisId the id of the analysis
     * @param filename the current thumbnail file uuid on master server.  (wont respond with thumbnail if this hasn't changed)
     * @param assessmentId optional id of assessment (if we are getting an assessment thumbnail)
     * @returns {Promise<any>} a promise containing the updated thumbnail if it has been updated
     */
    static getThumbnailUrl(analysisId, filename, assessmentId=null) {
        let body = {
            filename: filename,
            analysisId: analysisId
        };
        if (assessmentId) {
            body.assessmentId = assessmentId;
        }

        if (sessionDetails.isCordova()) {
            let thumbnailId = analysisId;
            if (assessmentId) {
                if (uploadCache.isCached(assessmentId)) {
                    thumbnailId = assessmentId;
                } else {
                    thumbnailId = analysisId + "-" + assessmentId
                }
            }
            return FileCache.getThumbnailUrl(thumbnailId).then((responseData) => { // Get cached thumbnail
                if (responseData) { // Return the cached thumbnail
                    return Promise.resolve(responseData);
                } else { // No cached thumbnail :(  Get one from remote URL instead and save it to the offline cache
                    return Request.credentialedRequest(SUFFIX_DOWNLOAD_THUMBNAIL + analysisId, body).then((data) => {
                        if (data.responseData && data.responseData.thumbnail){
                            FileUtil.urlToBlob(data.responseData.thumbnail).then((blob) => {
                                // noinspection JSIgnoredPromiseFromCall
                                CordovaUtils.saveFile(blob, FileCache.getCacheDirectory(), "thumbnails", thumbnailId + ".png");
                            });
                            return Promise.resolve(data);
                        } else {
                            return Promise.resolve(null);
                        }
                    });
                }
            });
        } else {
            return Request.credentialedRequest(SUFFIX_DOWNLOAD_THUMBNAIL + analysisId, body);
        }
    }

    static getVideoUrl(id, auth, requestParam="", forceRemote=false, display = true) {
        let remoteUrl = Request.getRemoteVideoURL(id, auth, requestParam)
        if (sessionDetails.isCordova() && !forceRemote) {
            return FileCache.getVideoUrl(id, remoteUrl, display);
        } else {
            return Promise.resolve(remoteUrl);
        }
    }

    static getRemoteVideoURL(id, auth, requestParam) {
        let remoteUrl = Request.getApiUrl() + SUFFIX_DOWNLOAD_VIDEO + id + "?auth=" + auth;
        if (requestParam !== "") {
            remoteUrl = Request.getApiUrl() + SUFFIX_DOWNLOAD_VIDEO + id + "?auth=" + auth + "&" + requestParam + "=true";
        }

        return remoteUrl;
    }

    static renewAuthorization(authorization) {
        return Request.credentialedRequest(SUFFIX_RENEW_AUTHORIZATION, {authorization: authorization});
    }

    // Retrieve data from remote and update cached data, otherwise use cached data
    static getAnalysisJson(id) {
        if (sessionDetails.isCordova()) {
            return Request._getAnalysisJson(id).then((data) => {
                return FileCache.getAnalysisJson(id, data);
            });
        } else {
            return Request._getAnalysisJson(id);
        }
    }

    static _getAnalysisJson(id) {
        return Request.credentialedRequest(SUFFIX_DOWNLOAD_JSON + id);
    }

    static listUsers() {
        return Request.credentialedRequest(SUFFIX_LIST_USERS);
    }

    static listUsersOrgAdmin() {
        return Request.credentialedRequest(SUFFIX_LIST_USERS_ORG_ADMIN);
    }

    static listOrgUsersAdmin(orgName) {
        return this.credentialedRequest(SUFFIX_LIST_USERS_FOR_ORGANIZATION, {
            targetOrg: orgName
        });
    }

    static listOrgUsersOrgAdmin(orgName) {
        return this.credentialedRequest(SUFFIX_LIST_USERS_FOR_ORGANIZATION_ORG_ADMIN, {
            targetOrg: orgName
        });
    }

    /**
     * Used to reauthorize an analysis request (e.g. to reload the video)
     * @param id the id of the analysis
     * @returns {Promise<any>} a promise containing the authorization code, or empty string if it failed
     */
    static getNewAuthorization(id) {
        return Request.credentialedRequest(SUFFIX_REAUTHORIZE, {id: id}).then(response => {
            if (Utils.responseIsSuccess(response)) {
                return Promise.resolve(response.responseData.authorization);
            } else {
                return Promise.reject("");
            }
        });
    }

    /**
     * Check if the currently logged in user is an administrator
     * @returns {Promise<any>}
     */
    static checkAdmin() {
        return Request.credentialedRequest(SUFFIX_CHECK_ADMIN, {}).then(response => {
            return Utils.responseIsSuccess(response);
        });
    }

    static getInstances() {
        return Request.credentialedRequest(SUFFIX_GET_INSTANCES, {});
    }

    static startInstance(instanceId, minutes) {
        return Request.credentialedRequest(SUFFIX_START_INSTANCE, {
            instanceId: instanceId,
            uptime: minutes
        });
    }

    static stopInstance(instanceId) {
        return Request.credentialedRequest(SUFFIX_STOP_INSTANCE, {instanceId: instanceId});
    }

    static terminateInstance(instanceId) {
        return Request.credentialedRequest(SUFFIX_TERMINATE_INSTANCE, {instanceId: instanceId});
    }

    static createGPU(parameters) {
        return Request.credentialedRequest(SUFFIX_CREATE_GPU, parameters);
    }

    static listDevEnvironments() {
        return Request.credentialedRequest(SUFFIX_LIST_DEV_ENVIRONMENTS, {})
    }

    static createDevEnvironment(parameters) {
        return Request.credentialedRequest(SUFFIX_CREATE_DEV_ENVIRONMENT, parameters)
    }

    static deployDevEnvironment(deployTarget, targetEnv) {
        return Request.credentialedRequest(SUFFIX_DEPLOY_DEV_ENVIRONMENT, {
            deployTarget: deployTarget === "webapp" ? "webapp" : "masterServer",
            targetEnv: targetEnv
        });
    }

    static stopDevEnvironment(targetEnv) {
        return Request.credentialedRequest(SUFFIX_STOP_DEV_ENVIRONMENT, {targetEnv: targetEnv})
    }

    static startDevEnvironment(targetEnv) {
        return Request.credentialedRequest(SUFFIX_START_DEV_ENVIRONMENT, {targetEnv: targetEnv})
    }

    static deleteDevEnvironment(targetEnv) {
        return Request.credentialedRequest(SUFFIX_DELETE_DEV_ENVIRONMENT, {targetEnv: targetEnv})
    }

    static createUser(userData) {
        return Request.userAction(SUFFIX_CREATE_USER, userData);
    }

    static createUserOrgAdmin(userData) {
        return Request.userActionOrgAdmin(SUFFIX_CREATE_USER_ORG_ADMIN, userData);
    }

    static editUser(userData) {
        return Request.userAction(SUFFIX_EDIT_USER, userData);
    }

    static editUserOrgAdmin(userData) {
        return Request.userActionOrgAdmin(SUFFIX_EDIT_USER_ORG_ADMIN, userData);
    }

    static userAction(suffix, userData) {
        return Request.credentialedRequest(suffix, {
            targetUser: userData.username,
            newPassword: userData.password,
            emailAddress: userData.emailAddress,
            displayName: userData.displayName,
            uses: userData.uses === null || userData.uses === undefined ? "" : "" + userData.uses,
            expiration: userData.expiration ? userData.expiration.format("YYYYMMDD") : "",
            userType: userData.userType,
            organization: userData.organization,
            productAccess: userData.productAccess.join(',')
        });
    }

    static userActionOrgAdmin(suffix, userData) {
        return Request.credentialedRequest(suffix, {
            targetUser: userData.username,
            newPassword: userData.password,
            emailAddress: userData.emailAddress,
            displayName: userData.displayName,
            userType: userData.userType,
            organization: userData.organization,
        });
    }

    static listOrgs() {
        return this.credentialedRequest(SUFFIX_LIST_ORGANIZATIONS, {});
    }

    static listOrgsOrgAdmin() {
        return this.credentialedRequest(SUFFIX_LIST_ORGANIZATIONS_ORG_ADMIN, {});
    }

    static findOrg(orgName) {
        return this.credentialedRequest(SUFFIX_FIND_ORGANIZATION, {
            targetOrg: orgName
        });
    }

    static findOrgOrgAdmin(orgName) {
        return this.credentialedRequest(SUFFIX_FIND_ORGANIZATION_ORG_ADMIN, {
            targetOrg: orgName
        });
    }

    static createOrg(orgData) {
        return this.orgAction(SUFFIX_CREATE_ORG, orgData);
    }

    static createOrgOrgAdmin(orgData) {
        return this.orgAction(SUFFIX_CREATE_ORG_ORG_ADMIN, orgData);
    }

    static editOrg(orgData) {
        return this.orgAction(SUFFIX_EDIT_ORG, orgData);
    }

    static orgAction(suffix, orgData) {
        return this.credentialedRequest(suffix, {
            targetOrg: orgData.orgName,
            superOrg: orgData.superOrg,
            uses: orgData.uses === null || orgData.uses === undefined ? "" : "" + orgData.uses,
            expiration: orgData.expiration ? orgData.expiration.format("YYYYMMDD"): ""
        });
    }

    static findUser(username) {
        return Request.credentialedRequest(SUFFIX_FIND_USER, {
            targetUser: username
        });
    }

    static findUserOrgAdmin(username) {
        return Request.credentialedRequest(SUFFIX_FIND_USER_ORG_ADMIN, {
            targetUser: username
        });
    }

    static deleteUser(username) {
        return Request.credentialedRequest(SUFFIX_DELETE_USER, {
            targetUser: username
        });
    }

    static deleteUserOrgAdmin(username) {
        return Request.credentialedRequest(SUFFIX_DELETE_USER_ORG_ADMIN, {
            targetUser: username
        });
    }

    static deactivateUser(username) {
        return Request.credentialedRequest(SUFFIX_DEACTIVATE_USER, {
            targetUser: username
        });
    }

    static deactivateUserOrgAdmin(username) {
        return Request.credentialedRequest(SUFFIX_DEACTIVATE_USER_ORG_ADMIN, {
            targetUser: username
        });
    }

    static getUserInfo() {
        return Request.credentialedRequest(SUFFIX_USER_INFO, {});
    }

    static uploadSettings(settings) {
        return Request.credentialedRequest(SUFFIX_UPLOAD_SETTINGS, {
            settings: settings
        });
    }

    /**
     * Send login request to backend
     * @param username Plaintext username to log in
     * @param password Plaintext password for user
     * @returns {Promise<any>} data object containing login information
     */
    static login(username, password) {
        return Request.postRequest(SUFFIX_LOGIN, {
            "username": username,
            "password": password,
            "product": "kinetica",
        });
    }

    /**
     * Send logout request to backend
     * @returns {Promise<any>} data object containing session information
     */
    static logout() {
        return Request.credentialedRequest(SUFFIX_LOGOUT, {});
    }

    static requestPasswordReset(usernameOrEmail) {
        return Request.postRequest(SUFFIX_REQUEST_PASSWORD_RESET, {
            usernameOrEmail: usernameOrEmail
        });
    }

    /**
     * Send logout change password request to backend
     * @param password Plaintext current password for user
     * @param newPassword Plaintext new password for user
     * @returns {Promise<any>} data object containing password information
     */
    static changePassword(password, newPassword) {
        return Request.credentialedRequest(SUFFIX_CHANGE_PASSWORD, {"password": password, "newPassword": newPassword});
    }

    /**
     * Send new password request to backend
     * @param newPassword Plaintext new password for user
     * @param passwordResetToken reset token provided in the reset link to validate the request
     * @returns {Promise<any>} data object containing password information
     */
    static newPassword(newPassword, passwordResetToken) {
        return Request.postRequest(SUFFIX_NEW_PASSWORD, {
            "newPassword": newPassword,
            "passwordResetToken": passwordResetToken
        });
    }

    /**
     * Get list of videos submitted by user
     *
     * @returns {Promise<any>}
     */
    static listVideos(targetUser) {
        if (sessionDetails.isCordova()) {
            return Request._listVideos(targetUser).then((data) => {
                return FileCache.listVideos(data);
            });
        }
        else {
            return Request._listVideos(targetUser);
        }
    }
    
    static _listVideos(targetUser) {
        return Request.credentialedRequest(SUFFIX_LIST_VIDEOS, {targetUser: targetUser}).then((data => {
            if (data.videos) {
                // Count up the number of videos that are currently processing
                let currentlyProcessing = 0;
                data.videos.forEach(video => {
                    if (video.status === "processing") {
                        currentlyProcessing += 1;
                    }
                })
                data.currentlyProcessing = currentlyProcessing;
                return data;
            }
        }));
    }

    /**
     * Get a list of Assessments of a specific type for an Analysis
     * @returns {Promise<any>}
     */
    static listAssessments(analysisId, assessmentType=null) {
        if (sessionDetails.isCordova()) {
            if (Utils.hasConnection()) {
                uploadCache.uploadCachedAssessments()
            }
            return Request._listAssessments(analysisId, assessmentType).then((data) => {
                return FileCache.listAssessments(analysisId, assessmentType, data);
            });
        } else {
            return Request._listAssessments(analysisId, assessmentType);
        }
    }

    static _listAssessments(analysisId, assessmentType){
        return Request.credentialedRequest(SUFFIX_LIST_ASSESSMENTS, {
            analysisId: analysisId,
            assessmentType: assessmentType
        });
    }

    /**
     * Request metadata about an analysis
     * @param id the ID of the analysis
     * @returns {Promise<any>} response from server as a json object
     */
    static getVideoData(id) {
        if (sessionDetails.isCordova()) {
            if (uploadCache.isCached(id)) {
                return Promise.resolve(uploadCache.get(id));
            } else {
                return Request._getVideoData(id).then((data) => {
                    return FileCache.getVideoData(id, data);
                });
            }
        } else {
            return Request._getVideoData(id);
        }
    }

    static _getVideoData(id) {
        return Request.credentialedRequest(SUFFIX_VIDEO_DATA, { id: id, authorize: "y"});
    }

    static getAssessment(assessmentId, analysisId) {
        if (sessionDetails.isCordova()) {
            return Request._getAssessment(assessmentId, analysisId).then((data) => {
                return FileCache.getAssessment(assessmentId, data);
            });
        } else {
            return Request._getAssessment(assessmentId, analysisId);
        }
    }

    static _getAssessment(assessmentId, analysisId) {
        return Request.credentialedRequest(SUFFIX_GET_ASSESSMENT, {
            assessmentId: assessmentId,
            analysisId: analysisId
        });
    }

    /**
     * Upload a video to the backend for analysis
     *
     * @param videoName user provided name for the video
     * @param video the video file itself
     * @param thumbnail the thumbnail file for the video
     * @param devEnv the environment to set on the GPU
     * @param runOptions list of selected run options
     * @param trimStart start position for trimming in seconds
     * @param trimEnd end position for trimming in seconds
     * @param blurLevel amount of blur (0-3)
     * @param deviceId for receiving processing notifications
     * @param groups optional list of groupIDs
     * @param onProgressFunc function to pass percent completion during upload
     * @returns {Promise<any>} response from the server as a json object.
     */
    static uploadVideo(videoName, video, thumbnail, devEnv, runOptions, trimStart, trimEnd, blurLevel, deviceId=null, groups=null, onProgressFunc=null) {
        let formData = new FormData();

        formData.append("username", Persistence.getUsername());
        formData.append("sessionId", Persistence.getSessionId());
        formData.append("video", video);
        formData.append("thumbnail", thumbnail);
        formData.append("videoName", videoName);
        if (groups != null) {
            formData.append("groupIds", groups.join(","));
        }
        if (blurLevel !== 0) {
            runOptions.push(String("blurLevel:" + blurLevel));
        }
        if (trimStart !== 0) {
            runOptions.push(String("trimStart:" + trimStart));
        }
        if (trimEnd !== 0) {
            runOptions.push(String("duration:" + Number((trimEnd - trimStart).toFixed(1))));
        }
        let finalRunOptions = runOptions.join(",");
        if (finalRunOptions !== "") {
            formData.append("runOptions", finalRunOptions);
        }
        if (devEnv && sessionDetails.isStaging()) {
            formData.append("devEnv", devEnv);
        }
        if (deviceId) {
            formData.append("device_id", deviceId)
        }

        if (sessionDetails.isCordova() && !Utils.hasConnection()) {
            return Promise.reject(null);
        }

        const axiosOptions = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total );
                if (onProgressFunc) (
                    onProgressFunc(percent)
                );

            }
        }
        return axios.put(Request.getApiUrl() + SUFFIX_UPLOAD_VIDEO, formData, axiosOptions).catch((error) => {
            if (error.response) {
                return Promise.reject(error.response)
            } else {
                return Promise.reject(null)
            }
        });
    }

    /**
     * Upload an assessment to the backend
     * If assessmentData contains the property 'assessmentId', it indicates the assessment
     * already exists in the backend and will perform an update to that Id rather than 
     * create a new entry
     * 
     * @param analysisId Id of the analysis
     * @param assessmentType the assessment model used
     * @param thumbnail serialized frame from the video where the assessment is taken
     * @param assessmentData Object containing all of the joint and assessment data
     * @param score Object containing all of the score data
     */
    static uploadAssessment(analysisId, assessmentType, thumbnail, assessmentData, score) {
        let formData = new FormData();
        let dataFile = new Blob([JSON.stringify(assessmentData)],{type:'application/json'});
        let serializedScore = JSON.stringify(score)

        formData.append("username", Persistence.getUsername());
        formData.append("sessionId", Persistence.getSessionId());
        formData.append("analysisId", analysisId);
        formData.append("assessmentType", assessmentType);
        formData.append("assessmentName", assessmentData.name);
        formData.append("score", serializedScore);
        formData.append("assessmentData", dataFile);

        //Determines if this is an update to an existing record or an entirely new one
        if (assessmentData.assessmentId && !uploadCache.isCached(assessmentData.assessmentId)) {
            formData.append("assessmentId", assessmentData.assessmentId);
        } else {
            formData.append("thumbnail", thumbnail);
        }

        let options = {
            method: "PUT",
            mode: "cors",
            body: formData
        };

        return window.fetch(Request.getApiUrl() + SUFFIX_UPLOAD_ASSESSMENT, options)
            .then(response => {
                response.json()
            }).then(data => {
                return Request.checkCredentialedResponse(data)
            }).catch((error) => {
                if (error && error.response) {
                    return Promise.reject(error.response)
                } else {
                    return Promise.reject(null)
                }
            });
    }

    static editAnalysis(analysisId, analysisData) {
        let body = { analysisId: analysisId }
        if (analysisData.name) {
            body.name = analysisData.name;
        }

        if (analysisData.groupIds) {
            body.groupIds = analysisData.groupIds.join(",");
        }

        return this.credentialedRequest(SUFFIX_EDIT_ANALYSIS, body);
    }

    static processVideo(videoId) {
        if (uploadCache.isCached(videoId)) {
            return uploadCache.uploadCachedVideo(videoId);
        } else {
            return Request.credentialedRequest(SUFFIX_PROCESS_VIDEO, {
                videoId: videoId
            });
        }
    }

    static deleteVideos(idList) {
        let url = SUFFIX_CLEAR_VIDEO + "?";
        for (let id of idList) {
            if (uploadCache.isCached(id)) {
                uploadCache.remove(id);
            } else {
                url = url + "videoIdList=" + id + "&";
            }
        }
        if (url.length > SUFFIX_CLEAR_VIDEO.length + 1) {
            return Request.credentialedRequest(url, {});
        } else {
            return Promise.resolve(null);
        }
    }

    static deleteAssessment(assessmentId, analysisId) {
        return Request.credentialedRequest(SUFFIX_CLEAR_ASSESSMENT, {
            assessmentId: assessmentId,
            analysisId: analysisId
        });
    }

    static cancelVideos(idList) {
        let url = SUFFIX_CANCEL_VIDEO + "?";
        for (let id of idList){
            url = url + "videoIdList=" + id + "&";
        }
        url = url.substring(0, url.length - 1);
        return Request.credentialedRequest(url, {});
    }

    static createGroup(groupName) {
        return Request.credentialedRequest(SUFFIX_CREATE_GROUP, {
            groupName: groupName
        });
    }

    static listGroups() {
        if (sessionDetails.isCordova()) {
            return Request.credentialedRequest(SUFFIX_LIST_GROUPS, {}).then((data) => {
                return FileCache.listGroups(data);
            });
        }
        else {
            return Request.credentialedRequest(SUFFIX_LIST_GROUPS, {});
        }
    }

    static editGroup(targetGroup, groupName) {
        return this.credentialedRequest(SUFFIX_EDIT_GROUP, {
            targetGroup: targetGroup,
            groupName: groupName
        });
    }

    static acceptTOS() {
        return this.credentialedRequest(SUFFIX_ACCEPT_TERMS_OF_SERVICE, {});
    }

    /**
     * Send a request using the logged in user's credentials. If the credentials are invalid, the user will be logged out.
     *
     * @param suffix the URL suffix to send the request to
     * @param body object with request parameters
     * @returns {Promise<any>} Promise returning data response from backend
     */
    static credentialedRequest(suffix, body) {
        return Request.postRequest(suffix, Object.assign({
            "username": Persistence.getUsername(),
            "sessionId": Persistence.getSessionId()
        }, body))
            .then(data => Request.checkCredentialedResponse(data));
    }

    /**
     * Check the response to a credentialed request. If the credentials were invalid, logout the user
     * @param response
     * @returns {{context}|*} the response as a json object
     */
    static checkCredentialedResponse(response) {
        // noinspection JSUnresolvedVariable
        if (response && response.context && response.context.sessionInvalid) {
            Persistence.invalidateSession();
            window.location.reload();
        }
        return response;
    }

    /**
     * Send a request to the backend
     *
     * @param suffix the pathname to append to the backend URL
     * @param body object with request parameters
     * @returns {Promise<any>} Promise returning data response from backend or {} if no connection is available
     */
    static postRequest(suffix, body) {
        if (sessionDetails.isCordova() && !Utils.hasConnection()) {
            return Promise.resolve({});
        }
        return window.fetch(Request.getApiUrl() + suffix, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json()
        });
    }
}
