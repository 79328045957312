import React from "react";
import { Button, DatePicker, Form, Input, InputNumber } from "antd";

import { Request, Utils } from "../../utils";
import { OrganizationSelector } from "../index";
import { withTranslation } from "react-i18next";
import moment from "moment";

class OrganizationDataForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    handleSubmit = (e, isEdit) => {
        e.preventDefault();
        const { form, onSubmitSuccess, onSubmitFail } = this.props;
        form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({loading: true});
                let finishFn = (response) => {
                    this.setState({loading: false});
                    if (Utils.responseIsSuccess(response)) {
                        if (onSubmitSuccess) onSubmitSuccess(values);
                    } else {
                        if (onSubmitFail) onSubmitFail(response);
                    }
                };
                if (isEdit) {
                    if (this.props.isAdmin){
                        Request.editOrg(values).then(finishFn);
                    }
                } else {
                    if (this.props.isAdmin){
                        Request.createOrg(values).then(finishFn);
                    } else {
                        Request.createOrgOrgAdmin(values).then(finishFn);
                    }
                }
            }
        });
    };

    renderButtons = (isEdit) => {
        const { t, isAdmin, onCancel, onSubmitSuccess, getUsers } = this.props;
        const { getFieldsError } = this.props.form;
        let buttons = [];
        let hideAll = (isEdit && !isAdmin)

        if (!hideAll) {
            if (onSubmitSuccess) {
                buttons.push(
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      disabled={!isEdit && Utils.formHasErrors(getFieldsError())}
                      style={{margin: "4px"}}
                      data-test="org-data-form-submit-button"
                    >
                        {isEdit ? t('OrganizationDataForm.update') :t('OrganizationDataForm.submit')}
                    </Button>
                );
            }
            if (onCancel) {
                buttons.push(<Button onClick={this.props.onCancel} style={{margin: "4px"}} data-test="org-data-form-cancel-button">{t('OrganizationDataForm.cancel')}</Button>);
            }
            if (getUsers) {
                buttons.push(<Button onClick={this.props.getUsers} style={{margin: "4px"}} data-test="get-users-button">{t('OrganizationDataForm.showUsers')}</Button>);
            }
        }

        return buttons;
    };

    renderAdminFields = (initialValues, isEdit, isChild) => {
        const { t, isAdmin } = this.props;
        const { getFieldDecorator } = this.props.form;

        if (isAdmin || isEdit) {
            return (
                <span>
                    <Form.Item label={t('OrganizationDataForm.uses')}>
                        {getFieldDecorator('uses', {initialValue: initialValues.uses})(
                            <InputNumber min={0} precision={0} step={25} disabled={!isAdmin || isChild} data-test="org-uses-input"/>
                        )}
                    </Form.Item>
                    <Form.Item label={t('OrganizationDataForm.expiration')}>
                        {getFieldDecorator('expiration', initialValues.expiration ? {initialValue: initialValues.expiration} : {})(
                            <DatePicker format="YYYYMMDD" disabled={!isAdmin || isChild} data-test="org-expiration-date-picker"/>
                        )}
                    </Form.Item>
                </span>
            );
        } else {
            return null;
        }
    }

    render() {
        const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
        const { t, dataSource, isAdmin } = this.props;


        let orgData = {};
        let isEdit = false;
        if (dataSource && dataSource.orgName) {
            orgData = dataSource;
            isEdit = true;
        }

        const initialValues = {
            orgName: orgData.orgName ? orgData.orgName : "",
            uses: (orgData.uses === null || orgData.uses === undefined) ? 25 : Number(orgData.uses),
            expiration: orgData.expiration ? moment(orgData.expiration.substring(0,10)) : "",   //Cuts off time from date string to avoid time zone issues
            superOrg: orgData.superOrg ? orgData.superOrg : null,
        };

        let isChild = orgData.superOrg !== null;
        const orgNameError = isFieldTouched('orgName') && getFieldError('orgName');

        return (
            <Form layout="horizontal" labelCol={{span: 4}} wrapperCol={{span: 6}} onSubmit={e => this.handleSubmit(e, isEdit)}>
                <Form.Item validateStatus={!isEdit && orgNameError ? 'error' : ''} help={orgNameError || ''} label={t('OrganizationDataForm.orgName')} >
                    {getFieldDecorator('orgName', isEdit ? {initialValue: initialValues.orgName} : { // This is a workaround to what I believe is a bug in antd Forms
                        rules: [{ required: true, message: t('OrganizationDataForm.hints.orgNameRequired')}],
                        initialValue: initialValues.orgName
                    })(
                        <Input autoComplete="off" disabled={isEdit} data-test="org-name-input"/>
                    )}
                </Form.Item>
                <Form.Item label={t('OrganizationDataForm.parentOrg')}>
                    {getFieldDecorator('superOrg', {initialValue: initialValues.superOrg})(
                        <OrganizationSelector previousValue={initialValues.superOrg} onChange={value => this.setState({selectedOrg: value})} isAdmin={this.props.isAdmin} disabled={!isAdmin && isEdit}/>
                    )}
                </Form.Item>
                {this.renderAdminFields(initialValues, isEdit, isChild)}
                <Form.Item labelCol={{}}>
                    {this.renderButtons(isEdit)}
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(Form.create()(OrganizationDataForm));