import React from "react";
import { Button, Form, Select, } from "antd";

import {userInfo} from "../../utils";
import { withTranslation } from "react-i18next";


const { Option } = Select;

class SettingsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloading: false,
            loading: false,
            cacheLoading: false,
            update: false
        }
    }

    componentDidMount() {
        this.setState({downloading: true})
        userInfo.downloadUserInfo().then(() => {this.setState({downloading: false})});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
                if (err) {
                    console.log(err);
                } else {
                    this.setState({loading: true});
                    userInfo.saveSettings(JSON.stringify(values));
                    userInfo.uploadSettings().then(() => {
                            this.setState({loading: false, update: false});
                        }
                    );
                }
            }
        );
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {t} = this.props;
        const MEASURE = userInfo.getParam_Measure();
        const MEASURE_IMPERIAL = userInfo.getParam_MeasureImperial();
        const MEASURE_METRIC = userInfo.getParam_MeasureMetric();

        return (
            <Form layout='horizontal'  labelCol={{span: 6}} wrapperCol={{span: 6}} style={{marginTop: '6px'}}
                  onSubmit={e => this.handleSubmit(e)}>
                <Form.Item label={t('Settings.measure.label')} labelAlign='left' style={{width: '600px'}}>
                    {getFieldDecorator(MEASURE, {initialValue: userInfo.getMeasure()})(
                        <Select onChange={() => {this.setState({update: true})}} style={{width: '125px'}} data-test="measurement-system-setting-menu">
                            <Option value={MEASURE_IMPERIAL} data-test="imperial-option">{t('Settings.measure.imperial')}</Option>
                            <Option value={MEASURE_METRIC} data-test="metric-option">{t('Settings.measure.metric')}</Option>
                        </Select>)}
                </Form.Item>
                <Form.Item>
                    <Button type='primary' disabled={!this.state.update} loading={this.state.loading} htmlType='submit' data-test="settings-submit-button">{t('Settings.submit')}</Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(Form.create()(SettingsForm));