import React from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col, Divider, Layout } from 'antd';

import { Logo } from '../../components';

import { withTranslation } from "react-i18next";

const { Footer } = Layout;

class NewPasswordEV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/videos' />
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{height: "100vh"}}>
        <Row type="flex" justify="center" align="middle" style={{height: '95vh'}}>
          <Col span={24} style={{maxWidth: '300px'}}>
            <Row type="flex" justify="center" align="middle">
              <Col  style={{display: "flex", alignItems: "center", flexDirection: "column", width: '100%'}}>
                <Logo />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{height: "2px"}}>
              <Col  style={{width: '100%'}}>
                <Divider style={{margin: 0, height: "2px"}}/>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col  style={{width: '100%'}}>
                Please access this link from a device with the Liberty Mutual ErgoValuator app installed.
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer style={{backgroundColor: "#ffffff", textAlign: "center"}}>
          <span>{t('AppLayout.copyright')}</span>
        </Footer>
        {this.renderRedirect()}
      </div>
    );
  }
}

export default withTranslation()(NewPasswordEV);
