import React from 'react';
import { Link } from 'react-router-dom';

import { Utils } from '../../utils';

const Logo = (props) => {
  let content = (
    <img src={props.collapsed ? Utils.getResource("/images/logo_small.svg") : Utils.getResource("/images/VelocityEHS.png")} alt="logo" className="logo-image" data-test="header-logo"/>
  );
  if (!props.nolink) {
    content = <Link to='/videos'>{content}</Link>
  }
  return (
    <div className="logo noselect">
      {content}
    </div>
  );
};

export default Logo;
