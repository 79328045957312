import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './styles/css/kinetica-theme.css';
import HashRouter from './HashRouter';
import * as serviceWorker from './serviceWorker';
import './i18n'
import initI18next from "./i18n";
import {
  requestCache,
  uploadCache,
  userInfo,
  Request,
  firebaseClient,
} from './utils';

const queryString = require('query-string');


function initApp() {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();

  let params = queryString.parse(document.location.search)
  let url;
  if (params && params.apiUrl) {
    url = decodeURIComponent(params.apiUrl);
  }
  if (window.location.hostname.startsWith("ec2")) {
    url = window.location.protocol + "//" + window.location.hostname + ":8080";
  }

  Request.setApiUrl(url);
  // Uncomment the following line to force app to connect to staging on mobile
  // Request.setApiUrl("https://staging.api.kineticalabs.com")
  window['setApiUrl'] = Request.setApiUrl;
  window['getApiUrl'] = Request.getApiUrl;
  requestCache.initializeRequestCache();
  uploadCache.initializeUploadCache();
  userInfo.initializeUserSettings();
  firebaseClient.initialize();

  initI18next().then(() => {
    ReactDOM.render(<HashRouter />, document.getElementById('root'));
  });
}

// If we are in a native app, give cordova a chance to load
if (document.location.protocol !== "http:" && document.location.protocol !== "https:") {
  // Init app on device ready
  document.addEventListener("deviceready", () => {
    window.open = window.cordova.InAppBrowser.open;
    initApp();
  }, false);
} else {
  // Init app immediately
  initApp();
}
