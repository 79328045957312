import React from 'react';
import { Table, Badge, Descriptions, Typography, Tag, message, Divider, Pagination} from 'antd';
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Thumbnail } from '..';
import {Request, sessionDetails, uploadCache, Utils} from '../../utils';
import AssessmentNumber from "./AssessmentNumber";

const { Paragraph } = Typography;

class VideoTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: sessionDetails.getVideoListPaginationProps() ? sessionDetails.getVideoListPaginationProps().pageSize : 25,
            pageNumber: sessionDetails.getVideoListPaginationProps() ? sessionDetails.getVideoListPaginationProps().page : 1
        }
    }

    getVideoLink = (id) => {
        return '/videos/' + id;
    };

    renderProcessingStatus = (status) => {
        const { t } = this.props;
        return (
            <span><Badge status={ status === 'complete' ? 'success' :
                status === 'failed' || status === 'cleared' ? 'error' :
                    status === 'cancelled' || status === 'unprocessed' || status === 'staged' ? 'default':
                        status === 'processing' ? 'processing' : 'warning'}/>
                {t('VideoTable.status.' + status)}
      </span>
        );
    };

    onGroupNameChange = (groupId, oldName, newName) => {
        const { t } = this.props;
        if (newName !== oldName) {
            Request.editGroup(groupId, newName).then((response) => {
                if (Utils.responseIsSuccess(response)) {
                    this.props.handleGroupNameChange(groupId, newName);
                } else {
                    message.error(t("VideoTable.groupNotUnique"))
                }
            });
        }
    }

    renderThumbnail = (id) => {
        if (id >= 0 || uploadCache.isCached(id)) {
            return (
                <Thumbnail analysisId={id} refreshDate={this.props.refreshDate} bordered='false' style={{maxHeight: '3em', fontSize: '12pt'}}/>
            );
        } else {
            return null;
        }
    }

    renderHeaderRow = (record) => {
        const isAndroid11 = sessionDetails.cordovaPlatform() === "android" && sessionDetails.platformVersion() === "11"
        return (
            <Tag
                closable={false}
                style={{position: 'relative', right: sessionDetails.isMobile() ? '104px' : '140px'}}
                data-test={record.groupName + "-header"}
            >
                <Paragraph
                    ellipsis={!isAndroid11}
                    editable={(record.groupName !== "Miscellaneous" && record.groupName !== "Not Yet Uploaded") ? { onChange: (newName) => this.onGroupNameChange(record.groupId, record.groupName, newName) } : null}
                    style={{
                        display: "inline-block",
                        margin: '0px',
                        fontSize: '12pt',
                        fontWeight: 600,
                        maxWidth: sessionDetails.isMobile() ? 'calc(100vw - 190px)' : 'calc(100vw - 300px)',
                    }}
                >
                    {isAndroid11 ? record.groupName.substring(0,20) : record.groupName}
                </Paragraph>
            </Tag>
        )
    }

    renderNormalRow = (record) => {
        const { t } = this.props;
        let searchString = this.props.targetUser ? "?targetUser=" + this.props.targetUser : "";
        let linkObj = {
            pathname: this.getVideoLink(record.id),
            search: searchString,
            state: {
                activeView: "data"
            }
        };
        return (
            <Descriptions size='small' layout='vertical' bordered>
                <Descriptions.Item
                    style={{ margin: '0px'}}
                    label={
                        <div>
                            <Paragraph
                                style={{float: "left", width: '100%', maxWidth: sessionDetails.isMobile() ? 'calc(100vw - 210px)' : 'calc(100vw - 350px)', minWidth: '110px', margin: '0px'}}
                                ellipsis={true} strong={false}
                            >
                                <Link to={linkObj} style={{fontWeight: 500, fontSize: '12pt'}}>
                                    {record.name}
                                </Link>
                            </Paragraph>
                            {this.handleAssessmentNumberLoc(record.id, record.name, true)}
                        </div>
                    }>
                    <div style={{float: "left"}}>
                        {this.renderProcessingStatus(record.status)}
                        <Divider type={"vertical"}/>
                        {this.handleAssessmentNumberLoc(record.id, record.name, false)}
                    </div>
                    <span style={{float: sessionDetails.isMobile() ? "left" : "right", marginRight: sessionDetails.isMobile() ? "-10px": "0px"}}>{t('Formats.shortDateTime', {date: new Date(record.uploadDate)})}</span>
                </Descriptions.Item>
            </Descriptions>
        )
    }

    renderRow = (ignored, record) => {
        if (record.id >= 0 || uploadCache.isCached(record.id)) {
            return this.renderNormalRow(record);
        } else if (record.groupName) {
            return this.renderHeaderRow(record);
        } else {
            return null;
        }
    }

    handleAssessmentNumberLoc = (assessmentId, assessmentName, nextToTitle) => {
        // Returns null if it's the wrong place based on isMobile()
        if ((sessionDetails.isMobile() && !nextToTitle) ||
            (!sessionDetails.isMobile() && nextToTitle)) {
            return null;
        }

        return (<AssessmentNumber
          assessmentId={assessmentId}
          assessmentName={assessmentName}
          targetUser={this.props.targetUser}
          getVideoLink={this.getVideoLink}
        />);
    }

    getCurrentPage = (dataSource, pageNumber, pageSize) => {
        // list of videos and group headers in current page
        let currPage = [];
        // number of videos so far
        let rowCount = 0
        // used to display the group header when a group overflows into the next page
        let lastLabel = null;
        for(let i = 0; i < dataSource.length; ++i) {
            // stop iterating when at the end of the page
            if (rowCount >= pageSize * pageNumber) {
                break;
            }
            // find last group header on previous page
            else if (rowCount < pageSize * (pageNumber - 1)) {
                if (dataSource[i].groupName)
                    lastLabel = dataSource[i];
            }
            // push all videos and headers for current page
            else {
                currPage.push(dataSource[i]);
            }
            // increment row counter only when not a group header
            if (!dataSource[i].groupName) {
                rowCount++;
            }
        }
        // if first element of page is not a group header, insert the last group header from the previous page at the top
        if (currPage[0] && !currPage[0].groupName && lastLabel !== null) {
            currPage.unshift(lastLabel);
        }
        return currPage;
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                dataIndex: 'id',
                key: 'id',
                width: 90,
                render: this.renderThumbnail
            },
            {
                dataIndex: 'name',
                key: 'name',
                render: this.renderRow
            },
        ];
        // noinspection JSUnusedGlobalSymbols
        const pagination = {
            showTotal: (total) => {
                if (this.props.videoCount) {
                    return t('VideoTable.videoCount', {videoCount: this.props.videoCount})
                } else {
                    return t('VideoTable.videoCount', {videoCount: total})
                }
            },
            // workaround to make sure page number is initially highlighted in pagination
            total: Math.max(this.props.videoCount, 1),
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            defaultPageSize: this.state.pageSize,
            defaultCurrent: this.state.pageNumber,
            size: 'small',
            onChange: (page, pageSize) => {
                if (this.props.onPaginationChange) {
                    this.props.onPaginationChange(page, pageSize);
                }
                this.setState({
                    pageNumber: page,
                    pageSize: pageSize
                });
            },
            onShowSizeChange: (page, pageSize) => {
                if (this.props.onPaginationChange) {
                    this.props.onPaginationChange(page, pageSize);
                }
                this.setState({
                    pageNumber: page,
                    pageSize: pageSize
                });
            }
        };
        const noSelection = this.props.noSelection === null ? sessionDetails.isMobile() : this.props.noSelection;
        const rowSelection = {
            onChange: this.props.rowSelectionChange,
            columnWidth: '15px',
            getCheckboxProps: record => ({
                'data-test': "video-selection-checkbox",
                disabled: record.id < 0,
                name: record.name,
                style: {display: record.id < 0 ? "none" : "block"}
            }),
        };
        let currPage = this.getCurrentPage(this.props.videos, pagination.defaultCurrent, pagination.defaultPageSize);
        return (
            <div>
                <Table loading={this.props.loading}
                       key={this.props.tableSortProps}
                       rowSelection={noSelection ? null : rowSelection}
                       rowKey={'id'}
                       columns={columns}
                       dataSource={currPage}
                       size='small'
                       showHeader={!sessionDetails.isMobile()}
                       style={{padding: '0px', marginTop: '20px'}}
                       pagination={false}/>
                <div align={"right"} style={{margin: "16px 0px"}}>
                    <Pagination {...pagination}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(VideoTable);