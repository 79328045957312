import React from 'react';
import { Dropdown, Layout, Icon, Divider, Popover, Menu, Drawer } from 'antd';
import {Route} from 'react-router-dom';
import { withTranslation} from "react-i18next";

import SideMenu from './SideMenu';
import { Logo } from '../../components';
import { LoginView, RecoverPassword, ContentContainer, AcceptTermsOfService } from '../../views'
import {Persistence, Desktop, Mobile, Request, sessionDetails, FileCache, uploadCache, firebaseClient} from '../../utils/'

const { Header, Sider, Content, Footer } = Layout;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      collapsed: true,
      drawerVisible: false,
      requestingPasswordReset: false,
      hasAcceptedTOS: false,
    };
  }

  componentDidMount() {
      this.checkLoggedIn();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      drawerVisible: !this.state.drawerVisible,
    });
  };

  onDrawerClose = () => {
      this.setState({
          collapsed: true,
          drawerVisible: false,
      });
  };

  onPasswordResetRequest = () => {
    this.setState({requestingPasswordReset: false});
  };

  onClickPasswordReset = () => {
    this.setState({requestingPasswordReset: true});
  };

  checkLoggedIn = () => {
    this.setState( {
      loggedIn: Persistence.isLoggedIn(),
      hasAcceptedTOS: Persistence.hasAcceptedTOS()
    });
  };

  render() {
    if (this.state.requestingPasswordReset) {
      return <RecoverPassword onPasswordResetRequest={this.onPasswordResetRequest}/>
    } else if (!this.state.loggedIn) {
      return <LoginView onLogin={ this.checkLoggedIn } onClickPasswordReset={this.onClickPasswordReset} />
    } else if (!this.state.hasAcceptedTOS) {
      return <AcceptTermsOfService onAccept={ this.checkLoggedIn } onLogout={ this.checkLoggedIn } />
    } else {
      const { t } = this.props;
      return (
        <Layout style={{height: '100vh', minWidth: '320px'}}>
          <Header
              style={{
                  left: '0px',
                  zIndex: 1,
                  padding: 0,
                  height: 68,
                  backgroundColor: '#FFFFFF',
                  transition: 'all 0.2s ease',
                  textAlign: 'center',
                  width: '100vw',
              }}
          >
            <Desktop>
              <div style={{float: 'left', height: '68px', width: '80px', paddingLeft: '0.75em', paddingRight: '0.75em', paddingTop: '0.75em', color: '#a7a9ac'}}>
                <Icon
                    data-test={"side-menu-toggle"}
                    style={{cursor: 'pointer', fontSize: '32px'}}
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                />
              </div>
              <Divider type="vertical" style={{float: 'left', height: 68, margin: 0}}/>
              <div style={{float: 'middle', display: 'inline-block',}}>
                <Logo collapsed={false}/>
              </div>
            </Desktop>
            <Mobile>
              <div style={{float: 'left', height: '68px', paddingLeft: '0.75em', paddingRight: '0.75em', paddingTop: '0.70em', color: '#a7a9ac'}}>
                <Icon
                    data-test={"side-menu-toggle"}
                    style={{cursor: 'pointer', fontSize: '32px'}}
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                />
              </div>
              <Divider type="vertical" style={{float: 'left', height: 68, margin: 0}}/>
              <div style={{float: 'left', display: 'inline-block', paddingLeft: '0.5em', fontSize: '16pt', color: '#a7a9ac'}}>
                  <Logo collapsed={false}/>
              </div>
            </Mobile>
            <Dropdown overlay={
              <Menu>
                <Menu.Item
                    key="0"
                    onClick={ () => {
                      // noinspection JSIgnoredPromiseFromCall
                      Request.logout();
                      Persistence.logout();
                      // noinspection JSIgnoredPromiseFromCall
                      firebaseClient.logout();
                      this.checkLoggedIn();
                      if (sessionDetails.isCordova()) {
                        // noinspection JSIgnoredPromiseFromCall
                        FileCache.clearCache();
                        uploadCache.clearCache();
                      }
                    }}
                    data-test="logout-option"
                > { t('AppLayout.logout') }
                </Menu.Item>
              </Menu>} trigger={['click']}>
              <div
                  style={{
                      float: 'right',
                      paddingRight: '0.5em',
                      fontSize: '16pt',
                      color: '#a7a9ac',
                      cursor: 'pointer',
                      height: '48px'
                  }}
                  data-test="header-dropdown">
                <Icon
                    type="user"
                    style={{padding: '6px', fontSize: '16pt'}}
                />
                <Desktop>
                  <span style={{fontSize: '12pt', fontWeight: 500}}>{ Persistence.getDisplayName() }</span>
                </Desktop>
              </div>
            </Dropdown>
            <Divider type="horizontal" style={{margin: 0, width: '100%'}} />
          </Header>
          <Layout>
            <Desktop>
            <Sider
                theme="light"
                collapsible
                collapsed={this.state.collapsed}
                trigger={null}
                width={250}
            >
              <Divider type="horizontal" style={{margin: 0}}/>
              <Route path="/" component={SideMenu} />
              <Footer
                  style={{
                      backgroundColor: 'transparent',
                      textAlign: "center",
                      bottom: '0px',
                      height: '40px',
                      position: 'absolute',
                      width: '100%',
                      padding: '0px',
                      fontWeight: 500,
                      fontSize: '12pt'
                  }}
              >
                <span style={{width: '60px', margin: 'auto'}}>
                  <Popover
                      placement="top"
                      content={t('AppLayout.copyright')}
                  >
                    <Icon type="copyright" style={{display: 'inline', marginRight: '8px'}} />
                  </Popover>
                  <Popover
                      placement="top"
                      content={
                        <a href={"https://www.ehs.com/privacy"} target="_blank" rel="noreferrer">
                          <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.privacyPolicy')}
                        </a>
                      }
                  >
                    <Icon type="lock" style={{display: 'inline', marginRight: '8px'}} />
                  </Popover>
                  <Popover
                      placement="top"
                      content={
                        <a href={"https://legal.ehs.com/wp-content/uploads/VelocityEHS_Master_Subscription_Services_Agreement.pdf"} target="_blank" rel="noreferrer">
                          <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.termsAndConditions')}
                        </a>
                      }
                  >
                    <Icon type="solution" style={{display: 'inline'}} />
                  </Popover>
                </span>
              </Footer>
            </Sider>
            </Desktop>
            <Mobile><Drawer
                title="Navigation"
                placement="left"
                closable={true}
                onClose={this.onDrawerClose}
                visible={this.state.drawerVisible}
                width={300}
                bodyStyle={{
                    padding: '0px'
                }}
            >
              <Route
                  path="/"
                  render={(props) => <SideMenu {...props} onClick={this.onDrawerClose} />}
              />
            </Drawer></Mobile>
            <Content style={{padding: '1vw', paddingTop: '1vw', minWidth: '320px'}}>
              <ContentContainer />
            </Content>
          </Layout>
        </Layout>
      );
    }
  }
}

export default withTranslation()(AppLayout);