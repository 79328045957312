import React from 'react';
import {
  Button,
  Card,
  Icon,
  Radio,
  Menu,
  Dropdown,
  Modal,
  Badge,
  Tabs,
  Typography,
  Affix,
  Spin,
  Select,
  Tooltip,
  message
} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { json2csv } from 'json-2-csv';
import html2canvas from 'html2canvas';

import {
  VideoStream,
  TrimmedVideoPlayer,
  ThresholdGraph,
  AssessmentForm,
  AssessmentTable,
  GroupSelector
} from "../../components";
import {
  Persistence,
  Utils,
  Request,
  sessionDetails,
  uploadCache,
  CordovaUtils,
  FileUtil,
  requestCache,
} from "../../utils";
import { Reba, Rula, Niosh, LM, PT } from "../../utils/AssessmentCalculators";
import {MultiJointChart} from '../MultiJointChart';

const { Meta } = Card;
const queryString = require('query-string');
const blackThresholds = [{low: 0, high:180, color: "#000000"}]

class AnalysisView extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.analysisData = {
      joints: {},
      headDirections: [],
    };
    this.optionList = [];
    this.state = {
      videoLoaded: false,
      playedSeconds: 0,
      paused: false,
      hovering: false,
      graphClicked: false,
      freezeVideo: false,
      videoData: {},
      assessmentList: [],
      assessmentEstimateData: [],
      assessmentFrame: null,
      assessmentFrameTimestamp: null,
      assessmentType: null,
      jsonUrl: null,
      videoUrl: null,
      unprocessedVideoUrl: null,
      jointNames: [],
      currentJoint: null,
      currentJointData: null,
      deleteModalVisible:false,
      cancelModalVisible:false,
      returnToList: false,
      activeView: "data",
      assessmentView: "list",
      videoResolution: null,
      optionList: [],
      loadingAssessment: false,
      loadingModalVisible: false,
      groups: null,
      ptMode: false,
      choosingNewAssessment: false,
    };
  }

  componentDidMount() {
    if (Persistence.isAdmin() && this.props.location.search) {
      let params = queryString.parse(this.props.location.search);
      if (params && params.targetUser) {
        this.setState({targetUser: params.targetUser});
      }
    }
    this.updateVideoData();
  }

  updateVideoData = () => {
    const { match, t } = this.props;

    Request.getVideoData(match.params.id).then(data => {
      if (data) {
        let cachedName = requestCache.getChangedName(match.params.id)
        if (cachedName) {
          data.name = cachedName;
        }
        this.refreshGroups();
        this.setState({videoData: data});
        if (data.status === "complete") {
          Request.getAnalysisJson(data.id).then(jsonResponse => {
            if (jsonResponse) {
              let jointNames = [];
              const colors = Utils.convertColors(jsonResponse.processedData.colors);
              let initialJoint = null;
              jsonResponse.processedData.joints.forEach((joint) => {
                let jointName = joint.name;
                let jointFps = joint.fps;
                let timeIncrement = 1 / jointFps;
                let jointAngles = [];
                let dangerAngle = joint.thresholds.dangerous[1];
                let inDanger = false;
                let dangerDuration = 0.0;
                let dangerTimes = [];
                let dangerFrequency = 0;
                let maxAngle = 0.0;
                let maxAngleTime = 0.0;

                let isEmpty = joint.angles.every((angle) => {return angle === -1});
                if (initialJoint === null && !isEmpty)
                  initialJoint = jointName;

                joint.angles.forEach((angle, index) => {
                  let currentTime = index / jointFps;
                  jointAngles.push({x: currentTime, y: angle});
                  if (angle > maxAngle) {
                    maxAngle = angle;
                    maxAngleTime = currentTime;
                  }
                  if (angle > dangerAngle) {
                    dangerDuration += timeIncrement;
                    if (!inDanger) {
                      inDanger = true;
                      dangerTimes.push(currentTime);
                      dangerFrequency += 1;
                    }
                  } else if (inDanger) {
                    inDanger = false;
                  }
                });
                this.analysisData.joints[jointName] = {
                  isEmpty: isEmpty,
                  data: jointAngles,
                  stats: {
                    dangerDuration: Utils.secondsToDuration(dangerDuration),
                    dangerTimes: dangerTimes,
                    dangerFrequency: dangerFrequency,
                    maxAngle: maxAngle,
                    maxAngleTime: maxAngleTime
                  },
                  fps: jointFps,

                  thresholds: this.convertThresholds(joint, colors),
                  thresholdConfig: joint.thresholdConfig,
                  angles: joint.angles,
                };
                jointNames.push(jointName);
              });
              if (initialJoint === null)
                initialJoint = jointNames[0];
              this.analysisData.headDirections = jsonResponse.processedData.headDirections;
              this.analysisData.hands = jsonResponse.processedData.hands;
              this.analysisData.colors = jsonResponse.processedData.colors;
              this.setState({
                jointNames: jointNames,
                currentJoint: initialJoint,
                currentJointData: this.analysisData.joints[initialJoint],
                assessmentType: "rula",
              });
            } else {
              message.warn(t('AnalysisView.noData'));
            }

          });
        } else if (data.status === "processing") {
          this.initializeUnprocessedVideo(data);
        }
      } else {
        message.warn(t('AnalysisView.noData'));
      }
      this.initializeVideoOptions(data);
    });

    if (this.props.location.state && this.props.location.state.activeView === "assessments") {
      this.onChangeView("assessments")
    }
  };

  /**
   * This function converts the joint color thresholds passed in from the backend to be usable by the graph
   *
   * @param jointData single joint's JSON data
   * @param colors the definitions of the colors used
   * @returns {[]} thresholds in a format readable by the graph
   */
  convertThresholds = (jointData, colors) => {
    const { t } = this.props;
    let thresholds = []
    if (!jointData || !jointData.thresholdConfig || !jointData.thresholdConfig.ranges) {
      // Not supporting conditional ranges at this time
      return Utils.getDefaultThresholdData(jointData.thresholds);
    }

    let defaultColor = "#00ff00"
    colors.forEach(colorObj => {
      const name = colorObj.name;
      const color = colorObj.color;

      if (name === "default") {
        // Set this as the default color to be injected later
        defaultColor = color;
      }

      const range = jointData.thresholdConfig.ranges[name];
      if (!range) {
        return;
      }

      thresholds.push({
        low: range[0],
        high: range[1],
        color: color,
        name: t("AnalysisView.thresholds." + name)
      })
    });

    thresholds.sort((a, b) => {
      return a.low - b.low;
    });

    let minAngle = 0;
    let finalThresholds = [];
    // Fill in gaps left by the default color scheme
    thresholds.forEach(threshold => {
      if (minAngle < threshold.low) {
        finalThresholds.push({
          low: minAngle,
          high: threshold.low,
          color: defaultColor,
          name: t("AnalysisView.thresholds.default")
        });
      }

      minAngle = threshold.high;
      finalThresholds.push(threshold);
    });

    if (minAngle < 180) {
      finalThresholds.push({
        low: minAngle,
        high: 180,
        color: defaultColor,
        name: t("AnalysisView.thresholds.default")
      });
    }
    return finalThresholds;
  };


  initializeUnprocessedVideo(videoData) {
    const { t } = this.props;
    let duration = 0;
    let trimStart = 0;
    if (videoData.runOptions) {
      for (let runOption of videoData.runOptions.split(",")) {
        if (runOption.includes(":")) {
          let optArg = runOption.split(":")
          if (optArg[0] === "trimStart") {
            trimStart = parseFloat(optArg[1]);
          } else if (optArg[0] === "duration") {
            duration = parseFloat(optArg[1]);
          }
        }
      }
    }
    Request.getVideoUrl(videoData.id, videoData.authorization, "", true).then((url) => {
      if (url) {
        this.setState({
          unprocessedVideoUrl: url,
          trimStart: trimStart,
          duration: duration
        });
      } else {
        message.warn(t('AnalysisView.noData'));
      }
    });
  }

  initializeVideoOptions = (videoData) => {
    const { t } = this.props;
    let optionList = [
      {
        key: "delete",
        onClick: () => {
          this.setState( {deleteModalVisible:true} );
        },
        icon: <Icon type="delete" style={{padding: '6px', fontSize: '12pt'}}/>,
        text: t('AnalysisView.options.deleteVideo'),
        dataTest: "delete-analysis-option"
      }
    ];
    if (videoData.status !== "complete" && videoData.status !== "cleared" &&
        videoData.status !== "processing" && videoData.status !== "failed") {
      optionList.push(
        {
          key: "process",
          onClick: () => {
            Request.processVideo(videoData.id).then(() => this.setState({returnToList: true}));
          },
          icon: <Icon type="upload" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.processVideo'),
          dataTest: "process-video-option"
        }
      );
    }
    if (videoData.status === "processing") {
      optionList.push(
        {
          key: "cancel",
          onClick: () => {
            this.setState( {cancelModalVisible:true} );
          },
          icon: <Icon type="stop" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.cancelProcessing'),
          dataTest: "cancel-analysis-option"
        }
      );
    }
    if (videoData.status === "complete") {
      optionList = optionList.concat(
        [{
          key: "downloadVideo",
          onClick: () => {
            FileUtil.exportVideo(videoData.id, videoData.authorization, "attachment", FileUtil.prepareFilename(videoData.name + "_ProcessedVideo.mp4"));
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadVideo'),
          dataTest: "download-video-option"
        },
        {
          key: "shareVideo",
          onClick: () => {
            return CordovaUtils.shareVideo(videoData.id, videoData.auth, FileUtil.prepareFilename(videoData.name + "_ProcessedVideo.mp4"));
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareVideo'),
          dataTest: "share-video-option"
        }],
        [{
          key: "downloadCSV",
          onClick: () => {
            let reformattedData = this.formatVideoData(this.analysisData.joints);
            json2csv(reformattedData, (err, csvData) => {
              let blob = new Blob(["\ufeff" + csvData], { type: "text/csv" });
              return FileUtil.saveBlobToDevice(blob, FileUtil.prepareFilename(videoData.name + "_BodyJointData.csv"));
            });
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadCsv'),
          dataTest: "download-analysis-data-option"
        },
        {
          key: "shareCSV",
          onClick: () => {
            let reformattedData = this.formatVideoData(this.analysisData.joints);
            json2csv(reformattedData, (err, csvData) => {
              let blob = new Blob(["\ufeff" + csvData], { type: "text/csv" });
              return CordovaUtils.shareBlob(blob, FileUtil.prepareFilename(videoData.name + "_BodyJointData.csv"));
            });
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareCsv'),
          dataTest: "share-analysis-data-option"
        },
        {
          key: "downloadGraph",
          onClick: () => {
            this.captureAndExportGraph();
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadGraph'),
          dataTest: "download-graph-option"
        },
        {
          key: "shareGraph",
          onClick: () => {
            this.captureAndExportGraph(true);
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareGraph'),
          dataTest: "share-graph-option"
        }]
      );
      if (!this.state.ptMode) {
        optionList = optionList.concat([
            {
              key: "downloadJointChart",
              onClick: () => {
                MultiJointChart.captureAndExportStackedBarGraph(videoData, false)
              },
              icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
              text: t('AnalysisView.options.downloadChart'),
              dataTest: "download-chart-option"
            },
            {
              key: "shareJointChart",
              onClick: () => {
                MultiJointChart.captureAndExportStackedBarGraph(videoData, true)
              },
              icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
              text: t('AnalysisView.options.shareChart'),
              dataTest: "share-chart-option"
            }]);
      }
    }
    this.optionList = optionList;
    this.setState({optionList: optionList});
  };

  addOptions = (options) => {
    let newOptionList = this.optionList;
    for (let option of options) {
      newOptionList = newOptionList.filter(existingOption => existingOption.key !== option.key);
      newOptionList.push(option);
    }
    this.optionList = newOptionList;
    this.setState({ optionList: this.optionList });
  };

  removeOptions = (keys) => {
    let newOptionList = this.optionList;
    for (let key of keys) {
      newOptionList = newOptionList.filter(existingOption => existingOption.key !== key);
    }
    this.optionList = newOptionList;
    this.setState({ optionList: this.optionList });
  };

  refreshGroups = () => {
    Request.listGroups().then((res) => {
      if (res && res.groups) {
        this.setState({groups: res.groups});
      }
    });
  }

  enableGraphCapture = () => {
    const { t } = this.props;
    this.addOptions(
      [{
        key: "downloadGraph",
        onClick: () => {
          this.captureAndExportGraph(false);
        },
        icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
        text: t('AnalysisView.options.downloadGraph'),
        dataTest: "download-graph-option"
      },
      {
        key: "shareGraph",
        onClick: () => {
          this.captureAndExportGraph(true);
        },
        icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
        text: t('AnalysisView.options.shareGraph'),
        dataTest: "share-graph-option"
      }]);
      if (!this.state.ptMode) {
        this.addOptions(
          [{
          key: "downloadJointChart",
          onClick: () => {
            MultiJointChart.captureAndExportStackedBarGraph(this.state.videoData, false)
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadChart'),
          dataTest: "download-chart-option"
        },
        {
          key: "shareJointChart",
          onClick: () => {
            MultiJointChart.captureAndExportStackedBarGraph(this.state.videoData, true)
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareChart'),
          dataTest: "share-chart-option"
        }]
      );
    }
  }

  onVideoDuration = () => {
    this.setState({videoLoaded: true});
  };

  onVideoProgress = ({ignored, playedSeconds}) => {
    const { paused, freezeVideo, hovering, assessmentRangeTimestamp } = this.state;
    if (!paused && !freezeVideo) {
      if (assessmentRangeTimestamp && Utils.exists(assessmentRangeTimestamp.end) && Utils.exists(assessmentRangeTimestamp.start)) {
        if (playedSeconds >= assessmentRangeTimestamp.end || playedSeconds <= assessmentRangeTimestamp.start) {
          this.playerRef.current.seekTo(assessmentRangeTimestamp.start);
        }
      } else {
        this.setState({playedSeconds: playedSeconds});
      }
    } else if (!hovering) {
      this.playerRef.current.seekTo(this.state.playedSeconds);
    }
  };

  onUpdateRange = (range) => {
    if (range && Utils.exists(range.start) && Utils.exists(range.end)) {
      let frameRange = {
        start: Math.floor(range.start * this.analysisData.joints.Neck.fps),
        end: Math.floor(range.end * this.analysisData.joints.Neck.fps)
      }
      this.setState({
        assessmentFrameRange: frameRange,
        assessmentRangeTimestamp: range
      });
    }
  }

  onClickGraph = (time) => {
    if (this.state.paused) {
      this.playerRef.current.seekTo(time);
    }
    this.setState({
      playedSeconds:time,
      paused: true,
      graphClicked: true,
    });
  };

  onHoverGraph = (time) => {
    if (!sessionDetails.isMobile()) {
      this.setState({hovering: true});
      this.playerRef.current.seekTo(time, 'seconds');
    }
  };

  onHoverEndGraph = () => {
    if (!sessionDetails.isMobile()) {
      const { playedSeconds } = this.state;
      this.playerRef.current.seekTo(playedSeconds, 'seconds');
      this.setState({
        hovering: false,
        paused: this.state.graphClicked
      });
    }
  };

  onVideoError = () => {
    if (this.state.videoLoaded) {
      let id = this.state.videoData.id;
      this.setState({videoLoaded: false});
      Request.getNewAuthorization(id)
      .then((authorization) => {
        Request.getVideoUrl(id, authorization)
        .then((url) => {
          this.setState({videoUrl: url})
        });
      });
    }
  };

  onJointSelect = (joint) => {
    this.setState({
      currentJoint: joint,
      currentJointData: this.analysisData.joints[joint]
    });
  };

  onAnalysisNameChange = (name) => {
    let { videoData } = this.state;

    videoData.name = name;
    this.setState({videoData: videoData});

    Request.editAnalysis(videoData.id, {name: name}).then((res) => {
      if (!res.context) {
        requestCache.addNameChangeRequest(this.state.videoData.id, name);
      } else {
        this.updateVideoData();
      }
    });
  }

  onGroupChange = (newGroup) => {
    Request.editAnalysis(this.state.videoData.id, {groupIds: [newGroup]}).then((ignored) => {
      this.updateVideoData();
    });
  }

  onChangeView = (view) => {
    const { t } = this.props;
    if (view === "assessments") {
      Request.listAssessments(this.props.match.params.id).then(data => {
        if (data) {
          let mergedAssessmentList = sessionDetails.isCordova() ? data["assessments"].concat(uploadCache.getAssessments(this.props.match.params.id)) : data["assessments"]
          this.setState({
            activeView: view,
            freezeVideo: false,
            assessmentView: "list",
            assessmentFrame: null,
            assessmentFrameRange: null,
            assessmentFrameTimestamp: null,
            assessmentList: mergedAssessmentList,
            loadingAssessment: false,
            savedAssessment: null,
            assessmentId: null,
          });
        } else {
          message.warn(t('AnalysisView.noData'));
        }
      });
      this.removeOptions(["downloadGraph", "shareGraph", "downloadJointChart", "shareJointChart"]);
    } else {
      this.setState({
        activeView: view,
        freezeVideo: false,
        assessmentView: "list",
        assessmentFrame: null,
        assessmentFrameRange: null,
        assessmentFrameTimestamp: null,
        assessmentRangeTimestamp: null,
        savedAssessment: null,
        assessmentId: null,
        choosingNewAssessment: false,
      });
      this.enableGraphCapture();
    }
    this.removeOptions(["deleteAssessment", "downloadFrame", "shareFrame", "downloadAssessment", "shareAssessment"]);
  };

  onLeaveAssessment = () => {
    const { t } = this.props;
    Request.listAssessments(this.state.videoData.id).then(data => {
      if (data) {
        let mergedAssessmentList = sessionDetails.isCordova() ? data["assessments"].concat(uploadCache.getAssessments(this.props.match.params.id)) : data["assessments"]
        this.setState({
          activeView: "assessments",
          freezeVideo: false,
          assessmentView: "list",
          assessmentFrame: null,
          assessmentFrameRange: null,
          assessmentFrameTimestamp: null,
          assessmentRangeTimestamp: null,
          assessmentList: mergedAssessmentList,
          savedAssessment: null,
          assessmentId: null,
          deleteAssessmentModalVisible: false
        });
      } else {
        message.warn(t('AnalysisView.noData'));
      }
    });
    this.removeOptions(["deleteAssessment", "downloadFrame", "shareFrame", "downloadAssessment", "shareAssessment"]);
  };

  onNewAssessment = () => {
    this.setState({
      choosingNewAssessment: true,
    });
  };

  onNewAssessmentType = (assessmentType) => {
    const { estimates, complete } = this.getEstimate(assessmentType, this.analysisData);
    this.setState({
      assessmentFrame: null,
      assessmentEstimateData: estimates,
      assessmentEstimateDataComplete: complete,
      assessmentView: "single",
      assessmentId: null,
      assessmentType: assessmentType,
      freezeVideo: true,
      choosingNewAssessment: false,
    });
    if (assessmentType === "pt")  // skip frame selection view
      this.setState({assessmentFrame: 0});
  };

  onOpenAssessment = (assessmentListEntry) => {
    const { id, date, type } = assessmentListEntry;
    const { t } = this.props;
    this.setState({loadingAssessment: true});
    if (sessionDetails.isCordova() && uploadCache.isCached(id)) {
      let cachedAssessment = uploadCache.get(id)
      let data = cachedAssessment.assessmentData
      let timestamp = data.frameTimestamp ? data.frameTimestamp :
          data.rangeTimestamp && data.rangeTimestamp.start ? data.rangeTimestamp.start : 0;

      this.setState({
        playedSeconds: timestamp,
        assessmentFrameRange: data.rangeTimestamp,
        assessmentRangeTimestamp: data.rangeTimestamp ? data.rangeTimestamp : null,
        savedAssessment: data,
        assessmentId: cachedAssessment.id,
        assessmentType: cachedAssessment.type,
        assessmentDate: t('AssessmentForm.offline'),
        assessmentView: "single",
        loadingAssessment: false,
        choosingNewAssessment: false,
      });

      if (cachedAssessment.type !== "niosh" && cachedAssessment.type !== "lm") {
        this.freezeFrame();
      }
    } else {
      Request.getAssessment(id, this.props.match.params.id).then(data => {
        if (data) {
          let timestamp = data.frameTimestamp ? data.frameTimestamp :
              data.rangeTimestamp && data.rangeTimestamp.start ? data.rangeTimestamp.start : 0;

          this.setState({
            playedSeconds: timestamp,
            assessmentFrameRange: data.rangeTimestamp,
            assessmentRangeTimestamp: data.rangeTimestamp ? data.rangeTimestamp : null,
            savedAssessment: data,
            assessmentId: id,
            assessmentType: type,
            assessmentDate: date,
            assessmentView: "single",
            loadingAssessment: false,
            choosingNewAssessment: false,
          });

          if (this.state.assessmentType !== "niosh" && this.state.assessmentType !== "lm") {
            this.freezeFrame();
          }
        } else {
          message.warn(t('AnalysisView.noData'));
        }
      });
    }
  };

  onDeleteAssessment = () => {
    this.setState({
      deleteAssessmentModalVisible: true
    });
  }

  setVideoResolution = (resolution) => {
    this.setState({
      videoResolution: resolution
    });
  };

  handlePause = () => {
    this.setState({
      paused: true
    })
  };

  handlePlay = () => {
    this.setState({
      paused: false,
      graphClicked: false
    })
  };

  translateJoint = (joint) => {
    const { t } = this.props;
    switch (joint) {
      case "Back Bending":
        return t('AnalysisView.joints.backBending');
      case "Neck":
        return t('AnalysisView.joints.neck');
      case "Right Arm":
        return t('AnalysisView.joints.rightArm');
      case "Left Arm":
        return t('AnalysisView.joints.leftArm');
      case "Right Knee":
        return t('AnalysisView.joints.rightKnee');
      case "Left Knee":
        return t('AnalysisView.joints.leftKnee');
      case "Right Elbow":
        return t('AnalysisView.joints.rightElbow');
      case "Left Elbow":
        return t('AnalysisView.joints.leftElbow');
      case "Right Hip":
        return t('AnalysisView.joints.rightHip');
      case "Left Hip":
        return t('AnalysisView.joints.leftHip');
      default:
        return t('AnalysisView.joints.unknown');
    }
  };

  getVideoLink = () => {
    if (this.state.targetUser) {
      return "/videos?targetUser=" + this.state.targetUser;
    } else {
      return "/videos";
    }
  };

  getEstimate(assessmentType, analysisData) {
    switch(assessmentType) {
      case "rula":
        return Rula.calculateRULAEstimate(analysisData);
      case "reba":
        return Reba.calculateREBAEstimate(analysisData);
      case "niosh":
        return Niosh.calculateNIOSHEstimate(analysisData);
      case "lm":
        return LM.calculateLMEstimate(analysisData);
      case "pt":
        return PT.calculatePTEstimate(analysisData);
      default:
        return null;
    }
  }

  captureAndExportGraph = (share) => {
    this.setState({capturingGraph: true}, () => {
      const yLabel = document.getElementsByClassName("vx-axis-label")[0];
      const graphBox = document.getElementsByClassName("vx-bar")[0];
      const cropStart = yLabel.getBoundingClientRect().x - 10;
      const cropWidth = graphBox.getBoundingClientRect().right - cropStart + 20;

      const graphArea = document.getElementById("joint-graph");
      window.scrollTo(0,0);
      html2canvas(graphArea, {scrollY: -window.scrollY, x: cropStart, width: cropWidth, height: graphArea.offsetHeight+10}).then(canvas => {
        const dataURL = canvas.toDataURL();
        fetch(dataURL)
        .then(res => res.blob())
        .then(blob => {
          this.setState({capturingGraph: false});
          let filename = FileUtil.prepareFilename(this.state.videoData.name + "_Graph_" + this.state.currentJoint + ".png");
          if (share) {
            return CordovaUtils.shareBlob(blob, filename);
          } else {
            return FileUtil.saveBlobToDevice(blob, filename);
          }
        });
      });
    });
  }

  captureFrame = () => {
    let canvas = document.createElement('canvas');
    canvas.width = 1920;
    canvas.height = 1080;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(this.playerRef.current.playerRef.current.getInternalPlayer(), 0, 0, 1920, 1080);

    // Convert thumbnail to correct data type
    let url = canvas.toDataURL('image/png');

    return fetch(url)
    .then(res => res.blob())
    .then(blob => {
        return blob;
    });
  };

  freezeFrame = () => {
    const { t } = this.props;
    const assessmentName = this.state.savedAssessment ? this.state.savedAssessment.name : "Unsaved_Assessment"
    let frameFilename = FileUtil.prepareFilename(this.state.videoData.name + "_" + assessmentName + "_" +
        t("AnalysisView." + this.state.assessmentType) + "_FrameImage.png");
    this.addOptions(
      [{
        key: "downloadFrame",
        onClick: () => {
          this.captureFrame().then((frameImg) => {
            return FileUtil.saveBlobToDevice(frameImg, FileUtil.prepareFilename(frameFilename))
          })
        },
        icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
        text: t('AnalysisView.options.downloadFrame'),
        dataTest: "download-frame-option"
      },
      {
        key: "shareFrame",
        onClick: () => {
          this.captureFrame().then((frameImg) => {
            return CordovaUtils.shareBlob(frameImg, FileUtil.prepareFilename(this.state.videoData.name + "_FrameImage.png"));
          })
        },
        icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
        text: t('AnalysisView.options.shareFrame'),
        dataTest: "share-frame-option"
      }]
    );

    this.playerRef.current.seekTo(this.state.playedSeconds, 'seconds');
    this.setState({
      freezeVideo: true,
      assessmentFrame: Math.floor(this.state.playedSeconds * this.analysisData.joints.Neck.fps),
      assessmentFrameTimestamp: this.state.playedSeconds,
    });
  };

  formatVideoData = (jsonData) => {
    let csvData = [];
    let i = 0;
    for (let key in jsonData) {
      // noinspection JSUnfilteredForInLoop
      for (let frame in jsonData[key]["data"]) {
        if (i === 0) {
          // noinspection JSUnfilteredForInLoop,JSNonASCIINames,NonAsciiCharacters
          csvData.push({Frame: frame,
            'Timestamp (s)': jsonData[key]["data"][frame]["x"].toFixed(2),
          });
        }
        let angle = "NO DATA";
        if (jsonData.hasOwnProperty(key)) {
          let translatedKey = this.translateJoint(key) + " (°)";
          if (jsonData[key]["data"].hasOwnProperty(frame)) {
            angle = (jsonData[key]["data"][frame]["y"] === -1) ? "NO DATA" :
                jsonData[key]["data"][frame]["y"].toFixed(2);
            csvData[frame][translatedKey] = angle;
          }
        }
      }
      i += 1
    }
    return csvData;
  };

  deleteAssessment = () => {
    if (sessionDetails.isCordova() && uploadCache.isCached(this.state.assessmentId)) {
      uploadCache.remove(this.state.assessmentId)
      this.onLeaveAssessment();
      this.setState({deleteAssessmentModalVisible: false});
    }
    Request.deleteAssessment(this.state.assessmentId, this.props.match.params.id)
      .then(() => {
        this.onLeaveAssessment();
        this.setState({deleteAssessmentModalVisible: false});
      });
  }

  conditionalNioshInfo = () => {
    const { t } = this.props;

    const handHeightInfo = <div>{t('AnalysisView.handHeightInfo1')} <br/><br/> {t('AnalysisView.handHeightInfo2')}</div>
    const handHeightTooltip = <Tooltip title={handHeightInfo} trigger={sessionDetails.isCordova() ? "click" : "hover"}>
      {t('AnalysisView.handHeightEstimate')} <Icon type="info-circle"/>
    </Tooltip>;

    const incompleteDataTooltip = this.state.assessmentEstimateDataComplete ? null : <Tooltip title={t('AnalysisView.handHeightIncomplete')} trigger={sessionDetails.isCordova() ? "click" : "hover"}>
      <Icon style={{color: "red", marginLeft: "6px"}} type="exclamation-circle"/>
    </Tooltip>;

    if (this.state.assessmentType === "niosh" || this.state.assessmentType === "lm") {
      return(
        <div style={{fontSize: '12pt'}}>
          {handHeightTooltip}
          {incompleteDataTooltip}
        </div>
      );
    } else {
      return null;
    }
  }

  renderModals = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal
          onCancel={() => this.setState({deleteModalVisible:false})}
          visible={this.state.deleteModalVisible}
          okText={t('AnalysisView.okButton')}
          cancelText={t('AnalysisView.cancelButton')}
          onOk={() => {
            this.setState( {deleteModalVisible: false} );
            Request.deleteVideos([this.state.videoData.id]).then(() => this.setState({returnToList: true}));
          }}
          okButtonProps={{'data-test': "modal-yes-button"}}
        >
          <div>{ t('AnalysisView.deleteConfirmation') }</div>
          <div>{ t('AnalysisView.assessmentsDeleted') }</div>
        </Modal>
        <Modal
          onCancel={() => this.setState({cancelModalVisible:false})}
          visible={this.state.cancelModalVisible}
          okText={t('AnalysisView.okButton')}
          cancelText={t('AnalysisView.cancelButton')}
          onOk={() => {
            this.setState( {cancelModalVisible: false} );
            Request.cancelVideos([this.state.videoData.id]).then(() => this.setState({returnToList: true}));
          }}
          okButtonProps={{'data-test': "modal-yes-button"}}
        >
          { t('AnalysisView.cancelConfirmation') }
        </Modal>
        <Modal
          onCancel={() => this.setState({deleteAssessmentModalVisible: false})}
          visible={this.state.deleteAssessmentModalVisible}
          okText={t('AnalysisView.okButton')}
          cancelText={t('AnalysisView.cancelButton')}
          onOk={this.deleteAssessment}
          okButtonProps={{'data-test': "modal-yes-button"}}
        >
          {t('AnalysisView.deleteAssessmentConfirmation')}
        </Modal>
      </div>
    );
  };

  renderOptionsMenu = () => {
    const { t } = this.props;
    const { optionList } = this.state;
    let shareOptions = [];
    let downloadOptions = [];
    let options = [];

    optionList.forEach(option => {
      if (option.key.startsWith("download")) {
        downloadOptions.push(this.renderOption(option));
      } else if (option.key.startsWith("share")) {
        shareOptions.push(this.renderOption(option));
      } else {
        options.push(this.renderOption(option));
      }
    });
    return (
      <Dropdown overlay={
        <Menu>
          {this.renderConditionalDownload(downloadOptions)}
          {this.renderConditionalShare(shareOptions)}
          {options}
        </Menu>} trigger={['click']}>
          <Button type="secondary" style={{marginRight: '4px'}} data-test="action-menu">
            <Icon type="menu-unfold" />{t('AnalysisView.actions')}
          </Button>
      </Dropdown>
    );
  };

  renderOption = (option) => {
    return (
      <Menu.Item key={option.key} onClick={option.onClick} style={{paddingTop: '8px', paddingBottom: '8px'}} data-test={option.dataTest}>
        {option.icon}
        <span>{option.text}</span>
      </Menu.Item>
    );
  }

  renderGroupSelector = () => {
    let group = null;
    if (this.state.videoData.groupMembership) {
      const assignedGroups = Utils.getGroupNames(this.state.videoData.groupMembership, this.state.groups);
      group = (assignedGroups.length >= 0) ? assignedGroups[0] : null;
    }

    const isCached = uploadCache.isCached(this.state.videoData.id)
    return (
      <span>
        <GroupSelector onChange={this.onGroupChange} initialGroup={group} disabled={isCached}/>
      </span>
    );
  }


  renderPTSelector = () => {
    if (sessionDetails.isStaging() && Persistence.isAdmin()) {
      const {t} = this.props;
      return (
          <Radio.Group style={{margin: '10px', width: '100%', textAlign: 'center', display: 'inline'}}
                       defaultValue={false} onChange={(e) => {
                           this.setState({
                             ptMode: e.target.value,
                             assessmentView: "list"});
                           this.updateVideoData();
          }}>
            <Radio value={false}>{t('AnalysisView.options.ergoMode')}</Radio>
            <Radio value={true}>{t('AnalysisView.options.ptMode')}</Radio>
          </Radio.Group>
      );
    }
    else {
      return null;
    }
  }


  renderConditionalShare = (options) => {
    const { t } = this.props;
    if (sessionDetails.isCordova()) {
      return (
        <Menu.SubMenu
            key="share"
            data-test="share-submenu"
            title={
              <span>
                <Icon type="share-alt" style={{padding: '6px', paddingTop: '8px', paddingBottom: '8px', marginRight: '8px', fontSize: '12pt'}}/>
                <span style={{marginRight: "12px"}}>{t('AnalysisView.share')}</span>
              </span>
            }
        >
          {options}
        </Menu.SubMenu>
      )
    }
  };

  renderConditionalDownload = (options) => {
    const { t } = this.props;
    if (!sessionDetails.isCordova() || sessionDetails.canSaveFiles()) {
      return (
        <Menu.SubMenu
          key="download"
          data-test="download-submenu"
          title={
            <span>
              <Icon type="download" style={{padding: '6px', paddingTop: '8px', paddingBottom: '8px', marginRight: '8px', fontSize: '12pt'}}/>
              <span style={{marginRight: "12px"}}>{t('AnalysisView.download')}</span>
            </span>
          }
        >
          {options}
        </Menu.SubMenu>
      );
    }
  }

  renderVideoTitle = () => {
    const { videoData } = this.state;
    const { Paragraph } = Typography;
    return (
      <Paragraph style={{minWidth: '260px', margin: '0px', fontSize: '18pt'}}>
        <Paragraph
            ellipsis
            editable={{ onChange: this.onAnalysisNameChange }}
            style={{maxWidth: 'calc(100vw - 24px)', margin: '0px', paddingLeft: '16px'}}
            data-test="analysis-name"
        >
          {videoData.name}
        </Paragraph>
        <span style={{float: "right", fontSize: '10pt'}}>{this.renderProcessingStatus()}</span>
      </Paragraph>
    );
  };

  renderThresholdGraph = () => {
    const { t } = this.props;
    const { playedSeconds, currentJointData } = this.state;
    const graphHeight = sessionDetails.isMobile() ? '200px' : '350px'

    if (!currentJointData.isEmpty) {
      return (
          <div id="joint-graph">
          <span style={{position: 'relative', left: 'calc(2.25vw + 10px)', display: this.state.capturingGraph ? 'block' : 'none'}}>
            <div style={{fontSize: '16pt', fontWeight: 'bold'}}>{t('AnalysisView.riskProfile')}</div>
            <div style={{fontSize: '12pt'}}>({this.state.currentJoint})</div>
          </span>
            <div style={{height: graphHeight, marginBottom: "8px", paddingTop: this.state.ptMode ? "0px" : "20px"}}>
              <ThresholdGraph
                  data={currentJointData.data}
                  thresholds={this.state.ptMode ? blackThresholds : currentJointData.thresholds}
                  xAccessor={d => d.x}
                  yAccessor={d => d.y}
                  playedTime={playedSeconds}
                  onClick={this.onClickGraph}
                  onHover={this.onHoverGraph}
                  onHoverEnd={this.onHoverEndGraph}
                  renderTooltips={true}
                  renderLegend={!this.state.ptMode}
                  yTooltipUnits={"º"}
                  yAxisLabel={t('AnalysisView.angleLabel')}
                  yMinRange={0}
                  hideSelectionLine={this.state.capturingGraph || this.state.hovering}
                  capturingGraph={this.state.capturingGraph}
              />
            </div>
          </div>
      );
    }
    return null;
  };

  renderMultiJointChart = () => {
    const { t } = this.props;
    if (!this.state.ptMode) {
      return (
          <div id="joint-chart" style={{height: '300px', paddingBottom: sessionDetails.isMobile() ? '45px' : '20px', paddingTop: '5px'}}>
            <span  style={{position: 'relative'}}>
              <div style={{fontSize: '16pt', fontWeight: 'bold'}}>{t('AnalysisView.jointGraphTitle')}</div>
            </span>
            <MultiJointChart data={this.analysisData}/>
          </div>
      );
    }
    return null;
  }

  renderJointGraph = () => {
    const { currentJointData } = this.state;
    const { t } = this.props;

    if (currentJointData != null) {
      return (
        <div>
          <Card hoverable style={{padding: '0px'}} bordered={false} bodyStyle={{padding: '0px'}}>
            {this.renderJointSelector()}
            <div style={{paddingTop: "10px"}}>
              {this.renderThresholdGraph()}
              {this.renderMultiJointChart()}
              <div
                style={{
                  position: 'relative',
                  left: 'calc(2.25vw + 10px)',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  margin: '12px',
                  display: this.state.capturingGraph ? 'block' : 'none'
                }}
              >{t('AnalysisView.graphInfo')}</div>
            </div>
          </Card>
          {
            /*
            // The following is being hidden until product decides what they want it to be.
          <Descriptions bordered style={{paddingTop: '1vh'}} size="small">
            <Descriptions.Item label={ t('AnalysisView.stats.frequency') }>{ currentJointData.stats.dangerFrequency }</Descriptions.Item>
            <Descriptions.Item label={ t('AnalysisView.stats.duration') }>{ t('Formats.elapsedTime', currentJointData.stats.dangerDuration) }</Descriptions.Item>
            <Descriptions.Item label={ t('AnalysisView.stats.maxAngle') }>{ currentJointData.stats.maxAngle.toFixed(2) + "°" }</Descriptions.Item>
          </Descriptions>
          */
          }
        </div>
      );
    }
    return null;
  };

  renderAssessmentGraph = () => {
    const { playedSeconds } = this.state;
    const { t } = this.props;
    let assessmentThresholds = [
			{low: 0, high: Number.MAX_VALUE, color: 'grey', name: 'all'},
    ];
    if (this.analysisData != null) {
      return (
        <span style={{ padding: '0px', height: '300px'}}>
          <ThresholdGraph
              type={this.state.assessmentType === "niosh" || this.state.assessmentType === "lm" ? "range" : "point"}
              data={this.state.assessmentEstimateData}
              thresholds={assessmentThresholds}
              xAccessor={d => d.x}
              yAccessor={d => d.y}
              playedTime={playedSeconds}
              onClick={this.onClickGraph}
              onHover={this.onHoverGraph}
              onHoverEnd={this.onHoverEndGraph}
              onRange={this.onUpdateRange}
              renderTooltips={false}
              renderLegend={false}
              yAxisLabel={t('AnalysisView.estimateLabel')}
              hideYAxisUnits={true}
          />
        </span>
      );
    }
    return null;
  };

  renderAssessment = () => {
    const { t } = this.props;
    if (this.state.assessmentView === "list") {
      if (this.state.loadingAssessment) {
        return (
          <div><Spin size="large" style={{margin: '20px'}}/></div>
        );
      } else {
        return (
          <span>
            <AssessmentTable
                assessmentList={this.state.assessmentList}
                analysisId={this.props.match.params.id}
                onClickAssessment={this.onOpenAssessment}
                onNewAssessment={this.onNewAssessment}
                onSelectNewAssessmentType={this.onNewAssessmentType}
                choosingNewAssessment={this.state.choosingNewAssessment}
                style={{padding: '0px', marginTop: '12px'}}
                ptMode={this.state.ptMode}/>
          </span>
        );
      }
    }
    if (this.state.assessmentFrame === null && this.state.savedAssessment == null) {
      let useFrameText = (this.state.assessmentType === "niosh" || this.state.assessmentType === "lm") ? t('AnalysisView.useRange') : t('AnalysisView.useFrame');
      return (
        <div>
          <Button
              type="primary"
              icon="picture"
              size="large"
              disabled={!this.state.paused}
              onClick={
                this.state.assessmentType === "niosh" ? () => this.setState({
                  assessmentFrame: Math.floor(this.state.playedSeconds * this.analysisData.joints.Neck.fps),
                  assessmentFrameTimestamp: this.state.playedSeconds,
                  freezeVideo: false,
                  paused: false
                }) : this.freezeFrame
              }
              style={{marginTop: '16px', marginBottom: '12px', fontSize: '10pt'}}
              data-test="use-frame-button"
          >
            {useFrameText}
          </Button>
          {this.conditionalNioshInfo()}
          {this.renderAssessmentGraph()}
        </div>
      );
    }
    else {
      return (
        <AssessmentForm
            frame={this.state.assessmentFrame}
            frameRange={this.state.assessmentFrameRange}
            frameTimestamp={this.state.assessmentFrameTimestamp}
            rangeTimestamp={this.state.assessmentRangeTimestamp}
            analysisData={this.analysisData}
            assessmentType={this.state.assessmentType}
            existingAssessmentData={this.state.savedAssessment}
            analysisId={this.props.match.params.id}
            assessmentId={this.state.assessmentId}
            assessmentDate={this.state.assessmentDate}
            onLeave={this.onLeaveAssessment}
            onDelete={this.onDeleteAssessment}
            addOptions={this.addOptions}
            removeOptions={this.removeOptions}
            captureThumbnail={this.captureFrame}
            videoName={this.state.videoData.name}
            videoData={this.state.videoData}
            groups={this.state.groups}
        />
      );
    }
  };

  renderInfo = () => {
    const { t } = this.props;
    const { activeView } = this.state;

    const selectorWidth = sessionDetails.isMobile() ? "90vw": "70vw";
    const buttonWidth = sessionDetails.isMobile() ? "45vw": "35vw";



    return (
      <span style={{width: "100%", textAlign: "center", display: 'inline-block'}} data-test="view-selector" id="view-selector">
        <Radio.Group defaultValue={activeView} buttonStyle="solid" size="large" onChange={(e) => this.onChangeView(e.target.value)} style={{width: selectorWidth, marginTop: "20px",}}>
          <Radio.Button value="data" style={{width: buttonWidth}}>
            {t('AnalysisView.videoAnalysis')}
          </Radio.Button>
          <Radio.Button value="assessments" style={{width: buttonWidth}}>
            {this.renderConditionalAssessmentBackButton()}
            {t('AnalysisView.assessments')}
          </Radio.Button>
        </Radio.Group>
        {this.state.activeView === "data" ? this.renderJointGraph() : this.renderAssessment()}
      </span>
    );
  };

  renderConditionalAssessmentBackButton = () => {
    const showAssessmentBackButton = (this.state.activeView === "assessments" &&
        this.state.assessmentView === "single" && !this.state.choosingNewAssessment);
    if (showAssessmentBackButton) {
      // don't show new assessment buttons if back button is pressed from new or saved assessment form
      let showAssessmentButtons = this.state.assessmentFrame == null && this.state.savedAssessment == null;
      return (
          <Button
              icon="rollback"
              onClick={() => this.setState({
                choosingNewAssessment: showAssessmentButtons,
                assessmentFrame: null,
                assessmentFrameRange: null,
                assessmentFrameTimestamp: null,
                assessmentRangeTimestamp: null,
                freezeVideo: false,
                assessmentView: "list",
                savedAssessment: null,
              })}
              style={{marginRight: "6px", display: showAssessmentBackButton ? "inline-block" : "none"}}
              data-test="assessment-back-button"
          />
      );
    } else {
      return null;
    }
  }

  renderVideoStream = (style) => {
    const { hovering, paused, freezeVideo, videoData } = this.state;
    if (videoData.status === "processing") {
      return (
        <TrimmedVideoPlayer
          muted
          isExistingAnalysis={true}
          trimStart={this.state.trimStart}
          duration={this.state.duration}
          url={this.state.unprocessedVideoUrl}
          width="100%"
          height="100%"
          controls={true}
          style={{maxHeight: "40vh"}}
          ref={this.playerRef}
        />
      );
    }
    return (
      <VideoStream
        muted={true}
        progressInterval={this.state.currentJointData ? 1000 / this.state.currentJointData.fps : 66}
        playing={!hovering && !paused && !freezeVideo}
        loop={true}
        authorization={videoData.authorization}
        videoId={videoData.id}
        key={videoData.id}
        videoStatus={videoData.status}
        width={"100%"}
        height={style.height}
        controls={!freezeVideo}
        onDuration={this.onVideoDuration}
        onProgress={this.onVideoProgress}
        onError={this.onVideoError}
        onPause={this.handlePause}
        onPlay={this.handlePlay}
        style={style}
        ref={this.playerRef}
        setVideoResolution={this.setVideoResolution}
      />
    );
  };

  renderVideo = () => {
    const { videoResolution, videoData } = this.state;

    let aspectPercentageString = "62.5%";
    if (videoResolution) {
      let aspectPercentage = ((videoResolution.height / videoResolution.width) * 100).toFixed(3);
      aspectPercentageString = aspectPercentage.toString() + "%";
    }

    let videoStyle = {backgroundColor: 'black', height: '40vh'};
    let affixStyle = {backgroundColor: 'transparent', position: "absolute", top: 0, left: 0, height: "none", right: 0};

    let videoComponent;
    if (sessionDetails.isVertical() && videoData.status !== "processing") {
      videoComponent = (
        <div style={{paddingTop: aspectPercentageString, backgroundColor: 'black'}}>
          {this.renderVideoStream(affixStyle)}
        </div>
      );
    } else {
      videoComponent = this.renderVideoStream(videoStyle);
    }

    if (this.state.freezeVideo && sessionDetails.isMobile()) {
      videoComponent = <Affix offsetTop={0} >{videoComponent}</Affix>;
    }

    return (
      <Card
        bordered={false}
        cover={videoComponent}
        bodyStyle={{padding: '0px'}}>
        <Meta
          description={(this.state.videoData.status === "complete") ? this.renderInfo() : null} />
      </Card>
    );
  };

  renderJointSelector = () => {
    const { t } = this.props;
    const { currentJoint, jointNames } = this.state;
    if (sessionDetails.isMobile()) {

      const joints = jointNames.map((joint) => {
        let disableJoint = this.analysisData.joints[joint].isEmpty;
        return (
            <Select.Option key={joint} disabled={disableJoint}>
              <Tooltip title={t('AnalysisView.noJointData')} placement="left" trigger={disableJoint ? "click" : ""}>
                {Utils.translateJoint(joint)}
              </Tooltip>
            </Select.Option>
        );
      });
      return (
        <Select defaultValue={currentJoint} onChange={this.onJointSelect} size="large" style={{margin: '6px', marginTop: '12px', width: '150px'}}>
          {joints}
        </Select>
      );

    } else {
      const buttons = jointNames.map((joint) => {
        let disableJoint = this.analysisData.joints[joint].isEmpty;
        return (
            <Tabs.TabPane key={joint} disabled={disableJoint} tab={
              <Tooltip title={t('AnalysisView.noJointData')} trigger={disableJoint ? "hover" : ""}>
                {this.translateJoint(joint)}
              </Tooltip>
            }/>
        );
      });
      return (
          <div style={{textAlign: 'center', marginTop: '8px'}}>
            <Tabs onChange={this.onJointSelect} activeKey={currentJoint} animated={false} size="large">
              {buttons}
            </Tabs>
          </div>
      );
    }
  };

  renderProcessingStatus = () => {
    const { t } = this.props;
    let displayDate;
    if (this.state.videoData.completionDate) {
      displayDate = t('AnalysisView.processed') + " " + t('Formats.longDateTime', {date: new Date(this.state.videoData.completionDate)});
    } else if (this.state.videoData.uploadDate) {
      if (uploadCache.isCached(this.state.videoData.id)) {
        displayDate = t('AnalysisView.saved') + " " + t('Formats.longDateTime', {date: new Date(this.state.videoData.uploadDate)});
      } else {
        displayDate = t('AnalysisView.uploaded') + " " + t('Formats.longDateTime', {date: new Date(this.state.videoData.uploadDate)});
      }
    }
    return(
      <div style={{marginRight: '6px', marginLeft: '6px'}}><Badge status={
        this.state.videoData.status === 'processing' ? 'processing' :
        this.state.videoData.status === 'failed' || this.state.videoData.status === 'cleared' ? 'error' :
        this.state.videoData.status === 'cancelled' || this.state.videoData.status === 'unprocessed' ? 'default':
        this.state.videoData.status === 'complete' ? 'success' : 'cancelled'}/>
        <span data-test="processed-date">{displayDate}</span>
      </div>
    );
  };

  render() {
    if (this.state.returnToList) {
      return (<Redirect to='/videos' />);
    }
    return (
      <div className="analysisView">
        {this.renderModals()}
        <Card title={this.renderVideoTitle()}
            size='small'
            bordered={false}
            bodyStyle={{padding: '0px', margin: 'calc(6px + 1vw)'}}
            headStyle={{padding: '0px'}}>
          <Link to={this.getVideoLink()}>
            <Button type="secondary" style={{margin: '4px', marginLeft: '0px'}}>
              <Icon type="left" />Videos
            </Button>
          </Link>
          {this.renderOptionsMenu()}
          {this.renderGroupSelector()}
          {this.renderPTSelector()}
          {this.renderVideo()}
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AnalysisView);
