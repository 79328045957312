import React from 'react';
import { withTranslation } from "react-i18next";

import {InputNumber, Tooltip, Icon, Form} from 'antd';
import {sessionDetails} from "../../../utils";

class FormInputNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.data.initVal){
      this.setState({
        value: this.props.data.initVal,
        initVal: this.props.data.initVal
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // This allows the form value to be updated through the initVal prop
    if (!nextProps.data.invalid && nextProps.data.initVal !== prevState.initVal) {
      return {
        value: nextProps.data.initVal,
        initVal: nextProps.data.initVal
      };
    } else {
      return null;
    }
  }
  
  onChange = (value) => {
    if (this.props.onChange){
      this.props.onChange(this.props.data.key, value);
    }
    this.setState({value: value});
  };

  render() {
    const { data, tooltip, t } = this.props;

    let invalidTooltip = null;
    if (data.invalid && this.state.value == null) {
      invalidTooltip =
        <span style={{marginRight:"2px"}}>
          <Tooltip title={t('AssessmentForm.invalidHand')} trigger={sessionDetails.isCordova() ? "click" : "hover"}>
            <Icon style={{color: "red"}} type="exclamation-circle" data-test={data.key + "-invalid-tooltip"}/>
          </Tooltip>
        </span>
    }

    let formText;
    let style={marginRight: '4px', display:'inline-block', lineHeight: 'normal'}
    if (tooltip) {
      formText = <span style={style}>{invalidTooltip}<Tooltip title={data.description}><Icon type="info-circle-o" style={{marginRight: "4px"}} data-test={data.key + "-tooltip"}/></Tooltip><span>{data.label}:</span></span>
    } else {
      formText = <span style={style}>{invalidTooltip}{data.label}:</span>
    }

    return(
      <div>
        <Form.Item style={{margin: '0px'}} label={formText}>
          <InputNumber
            onChange={this.onChange}
            defaultValue={!data.invalid && data.initVal ? data.initVal : null}
            disabled={this.props.disabled ? this.props.disabled : false}
            precision={this.props.precision ? this.props.precision : 1}
            step={this.props.step ? this.props.step : 0.1}
            min={this.props.min ? this.props.min : 0}
            type={"number"}
            size={"large"}
            value={this.state.value}
            style={this.props.style}
            data-test={this.props.dataTest}
          />
        </Form.Item>
      </div>
    );
  }
}

FormInputNumber.defaultProps = {
  tooltip: false
}
export default withTranslation()(FormInputNumber);