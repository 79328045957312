import {Utils, uploadCache, sessionDetails} from '..';

const validAssessmentTypes = ["rula", "reba", "niosh", "lm", "pt"];

export default class AssessmentListSorter {
  static sortAssessments = (assessments, sortColumn, sortDirection, filteredAssessmentTypes) => {
    if (!assessments || assessments.length === 0) {
      return assessments;
    }

    let assessmentGroups = {}

    // Split up assessments by group
    assessments.forEach((assessment) => {
      // Check for a valid group membership
      let assignedAssessmentType;
      // noinspection JSUnresolvedVariable
      if (assessment.type) {
        assignedAssessmentType = assessment.type;
      }

      let list = assessmentGroups[assignedAssessmentType];
      if (validAssessmentTypes.includes(assignedAssessmentType) && (filteredAssessmentTypes.length === 0 || filteredAssessmentTypes.includes(assignedAssessmentType))) {
        if (list) {
          list.push(assessment);
        } else {
          assessmentGroups[assignedAssessmentType] = [assessment];
        }
      }
    });

    // Sort the assessments within each group by the sort column
    let rowId = -1;
    let listOfSortedGroups = [];
    Object.keys(assessmentGroups).forEach((assessmentType) => {
      let sortedGroup = assessmentGroups[assessmentType].sort((videoA, videoB) => {
        if (sortDirection === "ascend") {
          return AssessmentListSorter.compareAssessments(videoA, videoB, sortColumn);
        } else {
          return AssessmentListSorter.compareAssessments(videoB, videoA, sortColumn);
        }
      });

      // Add mixed assessmentType entry to the front of each group to act as the group header
      sortedGroup.unshift({id: rowId, type: assessmentType});
      listOfSortedGroups.push(sortedGroup);
      rowId -= 1;
    });

    // Determine order of groups
    let sortedListOfSortedGroups = listOfSortedGroups.sort((groupA, groupB) => {
      // Compare groups by their highest/lowest assessment based on the sort column (ignoring header rows)
      let groupSortColumn = (sortColumn === "score") ? "date" : "sortColumn";
      if (sortDirection === "ascend") {
        return this.compareAssessments(groupA[1], groupB[1], groupSortColumn);
      } else {
        return this.compareAssessments(groupB[1], groupA[1], groupSortColumn);
      }
    });

    // Combine list of groups into a single assessment list
    return sortedListOfSortedGroups.flat(1);
  }

  // Sort function to compare assessments based on the sort column
  static compareAssessments = (assessmentA, assessmentB, sortColumn) => {
    if (sortColumn === "name" || sortColumn === "score") {
      return assessmentA[sortColumn].localeCompare(assessmentB[sortColumn]);
    } else { // Date
      if (sessionDetails.isCordova()) { // Manually sort for offline uploads
        if (uploadCache.isCachedAssessmentId(assessmentA.id)) {
          return -1;
        } else if (uploadCache.isCachedAssessmentId(assessmentB.id)) {
          return 1;
        }
      }
      return Utils.compareDates(assessmentA.date, assessmentB.date);
    }
  }
}