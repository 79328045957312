import React from 'react';
import {Button, Form, Icon, Input, InputNumber, Modal, Select} from "antd";
import { withTranslation } from "react-i18next";
import InstanceTable from "./InstanceTable";
import { Request, sessionDetails } from "../../utils";

const { Option } = Select;

class InstanceView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            instances: null,
            loading: false,
            newGpuModal: false,
            newGpuLoading: false,
            gpuTypeStaging: true
        }
    }

    componentDidMount() {
        this.onGpuChange();
    }

    onGpuChange = () => {
        this.setState({loading: true});
        Request.getInstances().then(result => this.setState({
            instances: result.savedInstances,
            loading: false
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        this.setState({newGpuLoading: true});

        Request.createGPU(form.getFieldsValue()).then(() => {
            this.setState({newGpuModal: false, newGpuLoading: false});
            this.onGpuChange();
        });
    };

    handleTypeChange = (value) => {
        this.setState({gpuTypeStaging: value === "STAGING"});
    };

    renderNewGpuModal = () => {
        const { t } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="horizontal" labelCol={{span: 4}} wrapperCol={{span: 6}}>
                <Form.Item label={t('InstanceTable.gpuType')}>
                    {getFieldDecorator('instanceType', {initialValue: "STAGING"})(
                        <Select onChange={this.handleTypeChange}>
                            <Option value="STAGING">{t('InstanceTable.gpuTypes.STAGING')}</Option>
                            <Option value="DEV">{t('InstanceTable.gpuTypes.DEV')}</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('InstanceTable.devEnv')}>
                    {getFieldDecorator('devEnv', {initialValue: "staging"})(
                        <Input disabled={!this.state.gpuTypeStaging}/>
                    )}
                </Form.Item>
                <Form.Item label={t('InstanceTable.startupMinutes')}>
                    {getFieldDecorator('uptime', {initialValue: 60})(
                        <InputNumber min={0} max={8*60} precision={0} step={60}/>
                    )}
                </Form.Item>
                <Form.Item label={t('InstanceTable.expirationDays')}>
                    {getFieldDecorator('expirationDays', {initialValue: 3})(
                        <InputNumber min={1} max={30} precision={0} step={1}/>
                    )}
                </Form.Item>
            </Form>
        );
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <Button disabled={!sessionDetails.isStaging()} onClick={() => this.setState({newGpuModal: true})} type="primary">{t('InstanceTable.newGpu')}</Button>
                <Button onClick={this.onGpuChange}><Icon type="sync" spin={this.state.loading}/>{t('InstanceTable.refresh')}</Button>
                <Modal onOk={this.handleSubmit} onCancel={() => this.setState({newGpuModal: false})} visible={this.state.newGpuModal} confirmLoading={this.state.newGpuLoading}>{this.renderNewGpuModal()}</Modal>
                <InstanceTable loading={this.state.loading} instances={this.state.instances} onGpuChange={this.onGpuChange}/>
            </div>
            );
    }
}

export default withTranslation()(Form.create()(InstanceView));