import React from 'react';
import {Button, Card, Modal} from 'antd';

import { UserDataForm, UserLookup, OrgLookup, OrganizationDataForm} from "../../components";
import { withTranslation } from "react-i18next";
import {Request, sessionDetails, Utils} from '../../utils';

class OrgAdminView extends React.Component {
  constructor(props) {
    super(props);
    sessionDetails.changeView("OrgAdminView");
    this.state = {
      key: 'newUserTab',
      isOrgAdmin: false,
      userData: {},
      errorModalVisible: false,
      errorMessage: props.t('Errors.unknownError')
    }
  }

  handleNewUser = (e) => {
    this.goToUser(e.username);
  };

  handleNewOrg = (e) => {
    this.setState({newOrg: e.orgName, key: 'viewOrgTab'})
  };

  goToUser = (username) => {
    Request.findUserOrgAdmin(username).then(userData => this.setState({userData: userData, key: 'editUserTab'}));
  };

  handleSubmissionFailure = (response) => {
    const { t } = this.props;
    let errorMessage = t('Errors.unknownError');
    if (Utils.responseHasErrors(response)) {
      errorMessage = response.context.errors;
    }
    this.setState({
      errorModalVisible: true,
      errorMessage: errorMessage
    });
  };


  renderModals = () => {
    const { t } = this.props;
    const { errorMessage, errorModalVisible } = this.state;

    return(
      <Modal
        closable={false}
        title={t('NewVideo.uploadFailed')}
        visible={errorModalVisible}
        footer={[
          <Button type={'primary'} key="ok" onClick={() => this.setState({errorModalVisible:false})}>
            { t('TrimVideo.okButton') }
          </Button>
        ]}
      >
        {errorMessage}
      </Modal>
    );
  }

  renderContent = () => {
    switch(this.state.key) {
      case 'viewOrgTab':
        return(<OrgLookup initialOrg={this.state.newOrg} onClickUser={this.goToUser} isAdmin={false}/>);
      case 'editUserTab':
        return (<UserLookup dataSource={this.state.userData} onSubmitSuccess={e => console.log(e)} onSubmitFail={this.handleSubmissionFailure} isAdmin={false}/>);
      case 'newOrgTab':
        return (<OrganizationDataForm onSubmitSuccess={this.handleNewOrg} onSubmitFail={this.handleSubmissionFailure} isAdmin={false}/>);
      case 'newUserTab':
      default:
        return (<UserDataForm onSubmitSuccess={this.handleNewUser} onSubmitFail={this.handleSubmissionFailure} isAdmin={false}/>);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Card size='small' title={<span style={{width: '100%', fontSize: '20pt', padding: '6px'}}>{t('OrgAdmin.title')}</span>}
          tabList={[
            {
              key: 'newUserTab',
              tab: <span data-test="create-user-tab">{t('Admin.newUser')}</span>
            },
            {
              key: 'editUserTab',
              tab: <span data-test="edit-user-tab">{t('Admin.editUser')}</span>
            },
            {
              key: 'newOrgTab',
              tab: <span data-test="create-org-tab">{t('Admin.newOrg')}</span>
            },
            {
              key: 'viewOrgTab',
              tab: <span data-test="view-org-tab">{t('OrgAdmin.viewOrg')}</span>
            },
          ]}
          activeTabKey={this.state.key}
          onTabChange={key => this.setState({key: key, newOrg: null, userData: null})}
          data-test="org-admin-tab-list"
        >
          {this.renderContent()}
          {this.renderModals()}
        </Card>
      </div>
    );
  }
}

export default withTranslation()(OrgAdminView);
