import React from 'react';
import { withTranslation } from "react-i18next";
import { Icon } from 'antd';

import { REBAForm, RULAForm, NIOSHForm, LMForm, PTForm } from '.';
import {Request, sessionDetails, uploadCache} from '../../utils';

class AssessmentForm extends React.Component {

  componentDidMount() {
    const { t } = this.props;
    let optionList = [];
    if (this.props.existingAssessmentData && this.props.addOptions !== undefined) {
      if (this.props.assessmentId) {
        optionList.push(
          {
            key: "deleteAssessment",
            onClick: this.props.onDelete,
            icon: <Icon type="delete" style={{padding: '6px', fontSize: '12pt'}}/>,
            text: t('AnalysisView.options.deleteAssessment'),
            dataTest: "delete-assessment-option"
          }
        );
      }
      this.props.addOptions(optionList);
    }
  }

  handleSubmit = (assessmentState, score) => {
    const isCached = sessionDetails.isCordova() && uploadCache.isCached(this.props.assessmentId)
    this.props.captureThumbnail().then((thumbnail) => {
      Request.uploadAssessment(this.props.analysisId, this.props.assessmentType, thumbnail, assessmentState, score)
      .then(() => { // on success
        if (isCached) {
          uploadCache.remove(this.props.assessmentId).then(this.props.onLeave)
        } else {
          this.props.onLeave()
        }
      }, () => { // on failure
        if (sessionDetails.isCordova()) {
          if (uploadCache.isCached(this.props.assessmentId)) {
            uploadCache.updateAssessment(this.props.assessmentId, assessmentState, score).then(this.props.onLeave)
          } else {
            uploadCache.addAssessment(this.props.analysisId,this.props.assessmentType, thumbnail, assessmentState, score).then(this.props.onLeave)
          }
        } else {
          this.props.onLeave()
        }
      });
    });
  };

  renderRULA = () => {
    return (
      <RULAForm
          handleSubmit={this.handleSubmit}
          frame={this.props.frame}
          frameTimestamp={this.props.frameTimestamp}
          analysisData={this.props.analysisData}
          assessmentType={this.props.assessmentType}
          existingAssessmentData={this.props.existingAssessmentData}
          analysisId={this.props.analysisId}
          assessmentId={this.props.assessmentId}
          assessmentDate={this.props.assessmentDate}
          onLeave={this.props.onLeave}
          addOptions={this.props.addOptions}
          removeOptions={this.props.removeOptions}
          captureThumbnail={this.props.captureThumbnail}
          videoName={this.props.videoName}
          videoData={this.props.videoData}
          groups={this.props.groups}
      />
    );
  };

  renderREBA = () => {
    return (
      <REBAForm
          handleSubmit={this.handleSubmit}
          frame={this.props.frame}
          frameTimestamp={this.props.frameTimestamp}
          analysisData={this.props.analysisData}
          assessmentType={this.props.assessmentType}
          existingAssessmentData={this.props.existingAssessmentData}
          analysisId={this.props.analysisId}
          assessmentId={this.props.assessmentId}
          assessmentDate={this.props.assessmentDate}
          onLeave={this.props.onLeave}
          addOptions={this.props.addOptions}
          removeOptions={this.props.removeOptions}
          captureThumbnail={this.props.captureThumbnail}
          videoName={this.props.videoName}
          videoData={this.props.videoData}
          groups={this.props.groups}
      />
    );
  };

  renderNIOSH = () => {
    return (
      <NIOSHForm
          handleSubmit={this.handleSubmit}
          frameRange={this.props.frameRange}
          rangeTimestamp={this.props.rangeTimestamp}
          analysisData={this.props.analysisData}
          assessmentType={this.props.assessmentType}
          existingAssessmentData={this.props.existingAssessmentData}
          analysisId={this.props.analysisId}
          assessmentId={this.props.assessmentId}
          assessmentDate={this.props.assessmentDate}
          onLeave={this.props.onLeave}
          addOptions={this.props.addOptions}
          removeOptions={this.props.removeOptions}
          captureThumbnail={this.props.captureThumbnail}
          videoName={this.props.videoName}
          videoData={this.props.videoData}
          groups={this.props.groups}
      />
    );
  };

  renderLM = () => {
    return (
      <LMForm
        handleSubmit={this.handleSubmit}
        frameRange={this.props.frameRange}
        rangeTimestamp={this.props.rangeTimestamp}
        analysisData={this.props.analysisData}
        assessmentType={this.props.assessmentType}
        existingAssessmentData={this.props.existingAssessmentData}
        analysisId={this.props.analysisId}
        assessmentId={this.props.assessmentId}
        assessmentDate={this.props.assessmentDate}
        onLeave={this.props.onLeave}
        addOptions={this.props.addOptions}
        removeOptions={this.props.removeOptions}
        captureThumbnail={this.props.captureThumbnail}
        videoName={this.props.videoName}
        videoData={this.props.videoData}
        groups={this.props.groups}
      />
    );
  };

  renderPT = () => {
    return (
        <PTForm
            handleSubmit={this.handleSubmit}
            frameRange={this.props.frameRange}
            rangeTimestamp={this.props.rangeTimestamp}
            analysisData={this.props.analysisData}
            assessmentType={this.props.assessmentType}
            existingAssessmentData={this.props.existingAssessmentData}
            analysisId={this.props.analysisId}
            assessmentId={this.props.assessmentId}
            assessmentDate={this.props.assessmentDate}
            onLeave={this.props.onLeave}
            addOptions={this.props.addOptions}
            removeOptions={this.props.removeOptions}
            captureThumbnail={this.props.captureThumbnail}
            videoName={this.props.videoName}
            videoData={this.props.videoData}
            groups={this.props.groups}
        />
    );
  };

  renderAssessment = () => {
    // noinspection JSRedundantSwitchStatement
    switch (this.props.assessmentType) {
      case "rula":
        return this.renderRULA();
      case "reba":
        return this.renderREBA();
      case "niosh":
        return this.renderNIOSH();
      case "lm":
        return this.renderLM();
      case "pt":
        return this.renderPT();
      default:
        return null;
    }
  }

  render() {
    return (
      <span>
        {this.renderAssessment()}
      </span>
    );
  }
}

export default withTranslation()(AssessmentForm);