import React from 'react';
import { withTranslation } from "react-i18next";
import {Form, Button, Affix, Input, Icon, Select, Divider, Tooltip, Popover, Card} from 'antd';

import {CordovaUtils, userInfo, Utils, FileUtil, sessionDetails } from '../../utils';
import { CM_TO_IN } from '../../utils/constants';
import { LM } from '../../utils/AssessmentCalculators';
import { FormInputNumber, FormRadio } from './FormItems';


class LMForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      actionType: null,
    };
  }

  // noinspection DuplicatedCode
  componentDidMount() {
    const { t } = this.props;
    this.props.form.validateFields();

    let optionList = [];
    if (this.props.addOptions && this.prepareAssessmentReport) {
      optionList = [
        {
          key: "downloadAssessment",
          onClick: () => {
            this.prepareAssessmentReport();
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadAssessment')
        },
        {
          key: "shareAssessment",
          onClick: () => {
            this.prepareAssessmentReport(true);
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareAssessment')
        }
      ];
    }
    this.props.addOptions(optionList);
    this.setState(this.initializeFormValuesLM());
  }

  // noinspection DuplicatedCode
  initializeFormValuesLM() {
    let { t, frameRange, analysisData } = this.props;
    let hands = analysisData.hands;
    let initialValues;
    let units = userInfo.getMeasure();
    let massUnit = (units === "metric") ? "(" + t('Units.kilogram.abbrev') + ")" : "(" + t('Units.pound.abbrev') + ")";
    let distanceUnit = (units === "metric") ? "(" + t('Units.centimeter.abbrev') + ")" : "(" + t('Units.inch.abbrev') + ")";
    let distanceUnitLong = (units === "metric") ? "(" + t('Units.meter.abbrev') + ")" : "(" + t('Units.foot.abbrev') + ")";
    let initActionType = "None"

    // noinspection DuplicatedCode
    if (this.props.existingAssessmentData) {
      initialValues = this.props.existingAssessmentData;
      initialValues.assessmentId = this.props.assessmentId;
      massUnit = (this.props.existingAssessmentData.units === "metric") ? "(" + t('Units.kilogram.abbrev') + ")" : "(" + t('Units.pound.abbrev') + ")";
      distanceUnit = (this.props.existingAssessmentData.units === "metric") ? "(" + t('Units.centimeter.abbrev') + ")" : "(" + t('Units.inch.abbrev') + ")";
      distanceUnitLong = (this.props.existingAssessmentData.units === "metric") ? "(" + t('Units.meter.abbrev') + ")" : "(" + t('Units.foot.abbrev') + ")";
      initActionType = this.props.existingAssessmentData.actionType
    } else {
      let handsVertical = hands && hands.vertical ? hands["vertical"].slice(frameRange.start, frameRange.end + 1) : [0];
      let handsHorizontal = hands && hands.horizontal ? hands["horizontal"].slice(frameRange.start, frameRange.end + 1) : [0];
      let initialData = {
        startHandHeight: handsVertical[0],
        endHandHeight: handsVertical[handsVertical.length - 1],
        // Get Max value over frame range for hand distance
        handDistance: Math.max(...handsHorizontal),
        // Get Average value of frame range for hand height
        handHeight: handsVertical.reduce((a, b) => a + b) / handsVertical.length,
      };
      initialValues = {
        name: null,
        actionType: "None",
        units: units ? units : "imperial",
        initialData: initialData,
        rangeTimestamp: this.props.rangeTimestamp,
        gender: 0,
        frequency: 0,
        handCoupling: 0,
        score: "",
        naFields: []
      };
    }

    let inputFields = {
      gender: {
        key: "gender",
        initVal: initialValues["gender"],
        options: [
          {label: t('AssessmentForm.lm.gender.option1'), score: 0},
          {label: t('AssessmentForm.lm.gender.option2'), score: 1},
        ],
      },
      height: {
        key: "height",
        label: t('AssessmentForm.lm.height') + " " + distanceUnit,
        initVal: initialValues["height"],
      },
      startHandHeight: {
        key: "startHandHeight",
        label: t('AssessmentForm.lm.startHandHeight') + " " + distanceUnit,
        initVal: initialValues["startHandHeight"],
        invalid: initialValues.initialData["startHandHeight"] === -1
      },
      endHandHeight: {
        key: "endHandHeight",
        label: t('AssessmentForm.lm.endHandHeight') + " " + distanceUnit,
        initVal: initialValues["endHandHeight"],
        invalid: initialValues.initialData["endHandHeight"] === -1
      },
      handDistance: {
        key: "handDistance",
        label: t('AssessmentForm.lm.handDistance') + " " + distanceUnit,
        initVal: initialValues["handDistance"],
        invalid: initialValues.initialData["handDistance"] === -1
      },
      handHeight: {
        key: "handHeight",
        label: t('AssessmentForm.lm.handHeight') + " " + distanceUnit,
        initVal: initialValues["handHeight"],
        invalid: initialValues.initialData["handHeight"] === -1
      },
      initialForce: {
        key: "initialForce",
        label: t('AssessmentForm.lm.initialForce') + " " + massUnit,
        initVal: initialValues["initialForce"],
      },
      sustainedForce: {
        key: "sustainedForce",
        label: t('AssessmentForm.lm.sustainedForce') + " " + massUnit,
        initVal: initialValues["sustainedForce"],
      },
      distance: {
        key: "distance",
        label: t('AssessmentForm.lm.distance') + " " + distanceUnitLong,
        initVal: initialValues["distance"],
      },
      objectWeight: {
        key: "objectWeight",
        label: t('AssessmentForm.lm.objectWeight') + " " + massUnit,
        initVal: initialValues["objectWeight"],
      },
      handCoupling: {
        key: "handCoupling",
        label: t('AssessmentForm.lm.handCoupling.label'),
        initVal: initialValues["handCoupling"] === -1 ? 0 : initialValues["handCoupling"],
        options: [
          {label: t('AssessmentForm.lm.handCoupling.option1'), score: 0},
          {label: t('AssessmentForm.lm.handCoupling.option2'), score: 1},
          {label: t('AssessmentForm.lm.handCoupling.option3'), score: 2},
        ],
      },
      frequencyShort: {
        key: "frequency",
        label: t('AssessmentForm.lm.frequency.label'),
        initVal: initialValues["frequency"] === -1 ? 0 : initialValues["frequency"],
        options: [
          {label: t('AssessmentForm.lm.frequency.option1'), score: 0},
          {label: t('AssessmentForm.lm.frequency.option2'), score: 1},
          {label: t('AssessmentForm.lm.frequency.option3'), score: 2},
          {label: t('AssessmentForm.lm.frequency.option4'), score: 3},
          {label: t('AssessmentForm.lm.frequency.option6'), score: 4},
        ],
      },
      frequencyLong: {
        key: "frequency",
        label: t('AssessmentForm.lm.frequency.label'),
        initVal: initialValues["frequency"] === -1 ? 0 : initialValues["frequency"],
        options: [
          {label: t('AssessmentForm.lm.frequency.option2'), score: 0},
          {label: t('AssessmentForm.lm.frequency.option3'), score: 1},
          {label: t('AssessmentForm.lm.frequency.option4'), score: 2},
          {label: t('AssessmentForm.lm.frequency.option5'), score: 3},
          {label: t('AssessmentForm.lm.frequency.option6'), score: 4},
        ],
      },
    };

    const { score, naFields } = this.getScore(initialValues, initActionType)
    initialValues["naFields"] = naFields

    return ({
      assessmentState: initialValues,
      inputFields: inputFields,
      actionType: initActionType,
      score: score
    });
  }

  /**
   * Renders fields that are N/A as list elements for Popover element
   * @param {Array<string>} naFields
   * @return {JSX.Element}
   */
  renderNAFields = (naFields) => {
    const fieldList = naFields.map((field, index) =>
      <li key={index}>{field}</li>
    );
    return (<div>{fieldList}</div>)
  }

  handleChange = (key, value) => {
    let newAssessmentState = this.state.assessmentState;
    newAssessmentState[key] = value;

    const { score, naFields } = this.getScore(newAssessmentState, this.state.actionType)
    newAssessmentState["naFields"] = naFields;

    if (key === "height") {
      this.setHandLocationForHeight(value);
    }
    this.setState({
      assessmentState: newAssessmentState,
      score: score
    });
  };

  getScore = (assessmentState, actionType) => {
    if (this.isComplete(assessmentState, actionType)) {
      return LM.calculateLM(assessmentState);
    } else {
      switch (actionType) {
        case "push":
        case "pull":
          return {
            "score": {
              "populationPercentageInitialForceLMTables": "N/A",
              "populationPercentageSustainedForceLMTables": "N/A",
              "populationPercentageInitialForceLMEquations": "N/A",
              "populationPercentageSustainedForceLMEquations": "N/A"
            },
            "naFields": []
          };
        default:
          return {
            "score": {
              "populationPercentageLMTables": "N/A",
              "populationPercentageLMEquations": "N/A"
            },
            "naFields": []
          };
      }
    }
  }

  handleActionTypeChange = (actionType) => {
    const { assessmentState, inputFields } = this.state;

    let newInputFields = inputFields;
    newInputFields.height.initVal = assessmentState.height;

    // Force clear inputs on actionType change if a value hasn't been previously entered
    for (let key in inputFields) {
      if (inputFields.hasOwnProperty(key)) {
        if (assessmentState[key]) {
          newInputFields[key].initVal = assessmentState[key];
        } else {
          newInputFields[key].initVal = null;
        }
      }
    }

    this.setState({
      actionType: actionType,
      inputFields, newInputFields
    }, () => {
      this.handleChange("actionType", actionType)
    });
  }

  // noinspection DuplicatedCode
  setHandLocationForHeight = (height) => {
    const { assessmentState, inputFields } = this.state;
    let newAssessmentState = assessmentState;
    let newInputFields = inputFields;

    // Subtract 'radius' of torso for LM tables calculation
    let handDistanceAdjustment = 20  // 20cm
    if (assessmentState.units === "imperial") {
      handDistanceAdjustment = handDistanceAdjustment * CM_TO_IN;
    }

    const initialData = assessmentState.initialData;

    if (initialData.startHandHeight >= 0) {
      newInputFields.startHandHeight.initVal = assessmentState.initialData.startHandHeight * height;
      newAssessmentState.startHandHeight = assessmentState.initialData.startHandHeight * height;
    }
    if (initialData.endHandHeight >= 0) {
      newInputFields.endHandHeight.initVal = assessmentState.initialData.endHandHeight * height;
      newAssessmentState.endHandHeight = assessmentState.initialData.endHandHeight * height;
    }
    if (initialData.handDistance >= 0) {
      newInputFields.handDistance.initVal = assessmentState.initialData.handDistance * height - handDistanceAdjustment;
      newAssessmentState.handDistance = assessmentState.initialData.handDistance * height - handDistanceAdjustment;
    }
    if (initialData.handHeight >= 0) {
      newInputFields.handHeight.initVal = assessmentState.initialData.handHeight * height;
      newAssessmentState.handHeight = newAssessmentState.initialData.handHeight * height;
    }

    this.setState({
      assessmentState: newAssessmentState,
      inputFields: newInputFields
    });
  }

  prepareAssessmentReport = (share=false) => {
    const { t, videoName, videoData, assessmentDate, groups } = this.props;
    // noinspection JSUnresolvedVariable
    let groupNames = Utils.getGroupNames(videoData.groupMembership, groups)
    let reportData = LM.generateAndDownloadLMReport(this.state.assessmentState, this.state.score, videoName, t('Formats.calendarDateTime', {date: new Date(assessmentDate)}), groupNames);
    const assessmentName = (this.state.assessmentState && this.state.assessmentState.name) ? this.state.assessmentState.name : "Unsaved_Assessment";
    let filename = FileUtil.prepareFilename(this.props.videoName + "_" + assessmentName + "_LMTables_Report.csv");

    if (share) {
      return CordovaUtils.shareBlob(reportData, filename);
    } else {
      return FileUtil.saveBlobToDevice(reportData, filename);
    }
  };

  isComplete = (assessmentState, actionType) => {
    switch (actionType) {
      case "lift":
      case "lower":
        return ("gender" in assessmentState && "height" in assessmentState && "startHandHeight" in assessmentState
          && "endHandHeight" in assessmentState && "handDistance" in assessmentState && "objectWeight" in assessmentState
          && "handCoupling" in assessmentState && "frequency" in assessmentState);
      case "push":
      case "pull":
        return ("gender" in assessmentState && "height" in assessmentState && "handHeight" in assessmentState
          && "initialForce" in assessmentState && "sustainedForce" in assessmentState && "distance" in assessmentState
          && "frequency" in assessmentState);
      case "carry":
        return ("gender" in assessmentState && "height" in assessmentState && "handHeight" in assessmentState
          && "objectWeight" in assessmentState && "distance" in assessmentState && "frequency" in assessmentState);
      default:
        return false
    }
  }

  onSubmit = () => {
    let currentAssessmentData = this.state.assessmentState;
    let finalAssessmentData = {};

    switch (this.state.actionType) {
      case "lift":
      case "lower":
        finalAssessmentData = {
          startHandHeight: currentAssessmentData.startHandHeight,
          endHandHeight: currentAssessmentData.endHandHeight,
          handDistance: currentAssessmentData.handDistance,
          objectWeight: currentAssessmentData.objectWeight,
          handCoupling: currentAssessmentData.handCoupling,
          populationPercentageLMTables: currentAssessmentData.populationPercentageLMTables,
          populationPercentageLMEquations: currentAssessmentData.populationPercentageLMEquations
        };
        break;
      case "push":
      case "pull":
        finalAssessmentData = {
          handHeight: currentAssessmentData.handHeight,
          initialForce: currentAssessmentData.initialForce,
          sustainedForce: currentAssessmentData.sustainedForce,
          distance: currentAssessmentData.distance,
        }

        break;
      case "carry":
        finalAssessmentData = {
          handHeight: currentAssessmentData.handHeight,
          objectWeight: currentAssessmentData.objectWeight,
          distance: currentAssessmentData.distance,
          populationPercentageLMTables: currentAssessmentData.populationPercentageLMTables,
          populationPercentageLMEquations: currentAssessmentData.populationPercentageLMEquations
        };
        break;
      default:
    }


    finalAssessmentData.gender = currentAssessmentData.gender;
    finalAssessmentData.height = currentAssessmentData.height;
    finalAssessmentData.name = currentAssessmentData.name;
    finalAssessmentData.initialData = currentAssessmentData.initialData;
    finalAssessmentData.actionType = currentAssessmentData.actionType;
    finalAssessmentData.units = currentAssessmentData.units;
    finalAssessmentData.rangeTimestamp = currentAssessmentData.rangeTimestamp;
    finalAssessmentData.frequency = currentAssessmentData.frequency;
    finalAssessmentData.score = currentAssessmentData.score;
    finalAssessmentData.naFields = currentAssessmentData.naFields;

    if (currentAssessmentData.assessmentId) {
      finalAssessmentData.assessmentId = currentAssessmentData.assessmentId;
    }

    this.props.handleSubmit(finalAssessmentData, this.state.score)
  }

  // noinspection DuplicatedCode
  renderFormSubmissionBar = () => {
    const { t } = this.props;
    const { assessmentState, actionType } = this.state;
    const isMobile = sessionDetails.isMobile();
    let tooltip;
    let scoreDisplay;

    // Handles error message if PP is N/A
    if (assessmentState.naFields && assessmentState.naFields.length > 0) {
      tooltip = <Popover
        title={t('AssessmentForm.lm.NAErrorMessage')}
        content={this.renderNAFields(assessmentState.naFields)}>
        <Icon type="exclamation-circle" style={{color: 'red', margin: "0px 0px 0px 6px"}} data-test={"NA-popover-icon"}/>

      </Popover>;
    } else {
      tooltip = <Tooltip title={
        <span><div>{t('AssessmentForm.lm.scoreTooltip1')}</div>
          <div>{t('AssessmentForm.lm.scoreTooltip2')}</div></span>
      }>
        <Icon type="info-circle-o" data-test={"score-tooltip"} style={{margin: "0px 0px 0px 6px"}}/>
      </Tooltip>
    }

    const lmTablesPPScoreDisplay = <span>
      {t('AssessmentForm.lm.populationPercentageLMTablesAbbrev') + ": "}
      <span data-test="population-percentage-tables">
        <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageLMTables)}%</b>
      </span>
    </span>;

    const lmEquationsPPScoreDisplay  = <span>
      {t('AssessmentForm.lm.populationPercentageLMEquationsAbbrev') + ": "}
      <span data-test="population-percentage-equations">
          <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageLMEquations)}%</b>
        </span>
    </span>;

    if (isMobile) {
      scoreDisplay = <h3 style={{margin: "0px", float: "center"}}>
        <span style={{marginRight: "6px"}}>{lmTablesPPScoreDisplay }</span>
        {lmEquationsPPScoreDisplay }
        {tooltip}
      </h3>;
    } else {
      scoreDisplay = <h3 style={{margin: "0px"}}>
        <div style={{float: "left"}}>
          {lmTablesPPScoreDisplay }
          {tooltip}
        </div>
        <div style={{float: "left"}}>
          {lmEquationsPPScoreDisplay }
        </div>
      </h3>;
    }

    if (actionType === "push" || actionType=== "pull") {
      scoreDisplay = <h3 style={{margin: "0px"}}>
        <div style={{float: isMobile ? "center": "left", height: "18px"}}>
          {t('AssessmentForm.lm.populationPercentageInitialForceLMTablesAbbrev') + ": "}
          <span data-test="population-percentage-initial-force-tables">
            <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageInitialForceLMTables)}%</b>
          </span>
          <span style={{marginLeft: "6px"}}>
            {t('AssessmentForm.lm.populationPercentageSustainedForceLMTablesAbbrev') + ": "}
            <span data-test="population-percentage-sustained-force-tables">
              <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageSustainedForceLMTables)}%</b>
            </span>
          </span>
          {tooltip}
        </div>
        <div style={{float: isMobile ? "center": "left", height: "18px", paddingRight:"12px"}}>
          {t('AssessmentForm.lm.populationPercentageInitialForceLMEquationsAbbrev') + ": "}
          <span data-test="population-percentage-initial-force-equations">
            <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageInitialForceLMEquations)}%</b>
          </span>
          <span style={{marginLeft: "6px"}}>
            {t('AssessmentForm.lm.populationPercentageSustainedForceLMEquationsAbbrev') + ": "}
            <span data-test="population-percentage-sustained-force-equations">
              <b>{Utils.interpretPopulationPercentage(this.state.score.populationPercentageSustainedForceLMEquations)}%</b>
            </span>
          </span>
        </div>
      </h3>
    }

    let conditionalScoreDisplay = () => {
      if (isMobile) {
        return scoreDisplay
      }
      return null;
    }

    return (
      <Affix offsetBottom={-2}>
        <span>
          <Card size="small" bodyStyle={{padding: "0px"}} style={{height: ["push","pull"].includes(actionType) ? "44px" : "26px", paddingBottom: "4px", top: "2px", display: isMobile ? "block" : "none"}}>
            {conditionalScoreDisplay()}
          </Card>
          <Input
              size="large"
              placeholder={ t('AssessmentForm.assessmentName') }
              onChange={e => this.handleChange("name", e.target.value)}
              defaultValue={assessmentState.name}
              style={{fontWeight: 'bold'}}
              data-test="assessment-name-input"
              addonBefore={isMobile ? null : scoreDisplay}
              addonAfter={
            <span>
              <Button
                  type="primary"
                  onClick={() => {
                    this.setState({uploading: true}, this.onSubmit);
                  }}
                  disabled={!assessmentState.name || assessmentState.name.length <= 0 || !this.isComplete(assessmentState, actionType)}
                  loading={this.state.uploading}
                  htmlType="submit"
                  data-test="assessment-submit-button"
              >
                {t('AssessmentForm.save')}
              </Button>
              <Button type="default" onClick={this.props.onLeave} style={{marginLeft: '6px'}} data-test="assessment-cancel-button">
                {t('AssessmentForm.cancel')}
              </Button>
            </span>
              }
          />
        </span>
      </Affix>
    );
  };

  renderLiftLowerFormItems = () => {
    const { form } = this.props;
    const { inputFields, assessmentState } = this.state;

    return(
      <span>
        <Form.Item wrapperCol={{}} style={{marginBottom: "0px"}}>
          <FormRadio buttonStyle data={inputFields.gender} onChange={this.handleChange} form={form} dataTest="gender-radio"/>
        </Form.Item>
        <FormInputNumber data={inputFields.height} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="height-input"/>
        <FormInputNumber data={inputFields.startHandHeight} onChange={this.handleChange} disabled={!assessmentState.height} style={{margin: "4px"}} form={form} dataTest="start-hand-height-input"/>
        <FormInputNumber data={inputFields.endHandHeight} onChange={this.handleChange} disabled={!assessmentState.height} style={{margin: "4px"}} form={form} dataTest="end-hand-height-input"/>
        <FormInputNumber data={inputFields.handDistance} onChange={this.handleChange} disabled={!assessmentState.height} style={{margin: "4px"}} form={form} dataTest="hand-distance-input"/>
        <FormInputNumber data={inputFields.objectWeight} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="object-weight-input"/>
        <Form.Item wrapperCol={{}} style={{marginTop: "12px", marginBottom: "0px"}}>
          <FormRadio data={inputFields.handCoupling} onChange={this.handleChange} form={form} dataTest="hand-coupling-radio"/>
          <FormRadio data={inputFields.frequencyShort} onChange={this.handleChange} form={form} dataTest="frequency-radio"/>
        </Form.Item>
      </span>
    )
  }

  renderPushPullFormItems = () => {
    const { form } = this.props;
    const { inputFields, assessmentState } = this.state;

    // The superfluous spans are to combat a bug where numbers are transferred between different form inputs when switching action type
    return (
      <span><span>
        <Form.Item wrapperCol={{}} style={{marginBottom: "0px"}}>
          <FormRadio buttonStyle data={inputFields.gender} onChange={this.handleChange} form={form} dataTest="gender-radio"/>
        </Form.Item>
        <FormInputNumber data={inputFields.height} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="height-input"/>
        <FormInputNumber data={inputFields.handHeight} onChange={this.handleChange} disabled={!assessmentState.height} style={{margin: "4px"}} form={form} dataTest="hand-height-input"/>
        <FormInputNumber data={inputFields.initialForce} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="initial-force-input"/>
        <FormInputNumber data={inputFields.sustainedForce} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="sustained-force-input"/>
        <FormInputNumber data={inputFields.distance} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="distance-input"/>
        <Form.Item wrapperCol={{}} style={{marginTop: "12px", marginBottom: "0px"}}>
          <FormRadio data={inputFields.frequencyLong} onChange={this.handleChange} form={form} dataTest="frequency-radio"/>
        </Form.Item>
      </span></span>
    );
  }

  renderCarryFormItems = () => {
    const { form } = this.props;
    const { inputFields, assessmentState } = this.state;

    // The superfluous spans are to combat a bug where numbers are transferred between different form inputs when switching action type
    return(
      <span><span><span>
        <Form.Item wrapperCol={{}} style={{marginBottom: "2px"}}>
          <FormRadio buttonStyle data={inputFields.gender} onChange={this.handleChange} form={form} dataTest="gender-radio"/>
        </Form.Item>
        <FormInputNumber data={inputFields.height} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="height-input"/>
        <FormInputNumber data={inputFields.handHeight} onChange={this.handleChange} disabled={!assessmentState.height} style={{margin: "4px"}} form={form} dataTest="hand-height-input"/>
        <FormInputNumber data={inputFields.objectWeight} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="object-weight-input"/>
        <FormInputNumber data={inputFields.distance} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="distance-input"/>
        <Form.Item wrapperCol={{}} style={{marginTop: "12px", marginBottom: "0px"}}>
          <FormRadio data={inputFields.frequencyShort} onChange={this.handleChange} form={form} dataTest="frequency-radio"/>
        </Form.Item>
      </span></span></span>
    )
  }

  renderFormItems = () => {
    // const { t } = this.props;
    switch (this.state.actionType) {
      case "lift":
      case "lower":
        return this.renderLiftLowerFormItems();
      case "push":
      case "pull":
        return this.renderPushPullFormItems();
      case "carry":
        return this.renderCarryFormItems();
      case "None":
        return;
      default:
        return <div style={{fontSize: "16px"}}>{this.state.actionType + " not implemented"}</div>;
    }
  }

  render() {
    const { t } = this.props;
    const { inputFields } = this.state;
    if (inputFields) {
      return (
        <Form colon={false} layout="horizontal" onSubmit={this.handleSubmit} style={{marginTop: '16px'}} labelCol={{span: 12}} wrapperCol={{span: 1}}>
          <span style={{marginRight: '12px', fontSize: "12pt"}}><b>{t('AssessmentForm.lm.actionType')}:</b></span>
          <Select defaultValue={this.state.actionType} onChange={this.handleActionTypeChange} style={{ width: 80 }} data-test="action-type-dropdown">
            <Select.Option value="lift" data-test="lift-option">{t('AssessmentForm.lm.lift')}</Select.Option>
            <Select.Option value="lower" data-test="lower-option">{t('AssessmentForm.lm.lower')}</Select.Option>
            <Select.Option value="push" data-test="push-option">{t('AssessmentForm.lm.push')}</Select.Option>
            <Select.Option value="pull" data-test="pull-option">{t('AssessmentForm.lm.pull')}</Select.Option>
            <Select.Option value="carry" data-test="carry-option">{t('AssessmentForm.lm.carry')}</Select.Option>
          </Select>
          <Divider style={{maxHeight: '20px', width: '80vw', marginBottom: '8px', marginTop: '12px'}}/>
          {this.renderFormItems()}
          <Form.Item style={{margin: '0px'}} wrapperCol={{}}>
            {this.renderFormSubmissionBar()}
          </Form.Item>
        </Form>
      );
    }
    else {
      return null;
    }
  };
}

export default withTranslation()(Form.create()(LMForm));