import React from "react";
import {Select, Divider, Icon, Button, Modal, Input} from "antd";
import { withTranslation } from "react-i18next";
import {Request, Utils} from '../../utils';

class GroupSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "None",
      initialGroup: null,
      loading: true,
      groups: {},
      newGroupModalVisible: false,
      newGroupErrorModalVisible: false,
      newGroupLoading: false,
      newGroupName: null,
      newGroupErrorMessage: props.t('Error.unknownError'),
    }
  }

  componentDidMount() {
    Request.listGroups().then(response => {
      this.initialize(response);
    });
  }

  initialize = (response) => {
    if (response && response.groups) {
      this.setState({loading: false, groups: response.groups});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // This allows the form value to be updated through the initVal prop
    if (nextProps.initialGroup !== prevState.initialGroup) {
      return {
        selected: nextProps.initialGroup === null ? "None" : nextProps.initialGroup,
        initialGroup: nextProps.initialGroup,
      };
    } else {
      return null;
    }
  }

  renderOptions = () => {
    const { t } = this.props;
    const { groups } = this.state;

    let noneOption = <Select.Option key="None" value="None" data-test="none-option">{t('GroupSelector.none')}</Select.Option>;
    return [noneOption].concat(
      Object.entries(groups).map((entry) => {
        let groupId = entry[0];
        let groupName = entry[1];
        return <Select.Option key={groupId} value={groupId} data-test={groupName + "-option"}>{groupName}</Select.Option>
      })
    );
  };

  handleChange = (value) => {
    let newSelectedGroup = value;
    if (value === "None") {
      newSelectedGroup = null;
    }

    this.setState({selected: value});
    if (this.props.onChange) {
      this.props.onChange([newSelectedGroup]);
    }
  }

  createGroup = () => {
    const { t } = this.props;
    const { newGroupName } = this.state;
    this.setState({newGroupLoading: true}, () => {
      // Create the new group
      Request.createGroup(newGroupName).then((res) => {
        if (res && res.responseData && res.responseData.groupId) {
          let groupId = res.responseData.groupId;
          let newGroups = this.state.groups;
          newGroups[groupId] = newGroupName;
          this.setState({
            groups: newGroups,
            selected: groupId,
            newGroupLoading: false,
            newGroupModalVisible: false,
          });
          this.props.onChange([groupId]);
        } else {
          let errorMsg = t('Error.unknownError');
          if (Utils.responseHasErrors(res)) {
            errorMsg = res.context.errors[0];
          }
          this.setState({
            newGroupLoading: false,
            newGroupModalVisible: false,
            newGroupErrorModalVisible: true,
            newGroupErrorMessage: errorMsg,
          });
        }
      })
    });
  }

  renderModals = () => {
    const { t } = this.props;
    const { newGroupModalVisible, newGroupErrorModalVisible,  newGroupLoading, newGroupErrorMessage } = this.state;
    return (
      <span>
        <Modal
          closable={false}
          title={t('GroupSelector.createNewGroup')}
          visible={newGroupModalVisible}
          footer={[
            <Button type={'secondary'} key="cancel" onClick={() => this.setState({newGroupModalVisible: false})}>
              { t('GroupSelector.cancelButton') }
            </Button>,
            <Button type={'primary'} key="create" onClick={this.createGroup} loading={newGroupLoading} data-test="new-group-modal-create-button">
              { t('GroupSelector.create') }
            </Button>
          ]}
        >
         <Input
           maxLength={50}
           onChange={(e) => {this.setState({newGroupName: e.target.value})}}
           data-test="new-group-input"
         />
        </Modal>
        <Modal
          closable={false}
          title={t('GroupSelector.newGroupError')}
          visible={newGroupErrorModalVisible}
          footer={[
            <Button type={'primary'} key="ok" onClick={() => this.setState({newGroupErrorModalVisible: false, errorMessage: t('Error.unknownError')})} loading={newGroupLoading}>
              { t('GroupSelector.okButton') }
            </Button>
          ]}
        >
         {newGroupErrorMessage}
        </Modal>
    </span>
    )
  }

  render() {
    const { t, disabled, initialGroup } = this.props;
    return (
      <span>
        <Select showSearch allowClear
          value={this.state.selected}
          loading={this.state.loading}
          onChange={this.handleChange}
          placeholder={t('GroupSelector.select')}
          style={{ minWidth: 200, maxWidth: 400, marginBottom: "6px"}}
          disabled={disabled}
          defaultValue={initialGroup ? initialGroup : "None"}
          data-test="group-selector"
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={() => this.setState({newGroupModalVisible: true})}
                data-test="new-group-option"
              >
                <Icon type="plus" />{t('GroupSelector.newGroup')}
              </div>
            </div>
          )}
        >
          {this.renderOptions()}
        </Select>
        {this.renderModals()}
      </span>
    );
  }
}

export default withTranslation()(GroupSelector);