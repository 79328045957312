import React from "react";
import {Icon, Popover, Tag, Row, Col} from 'antd';
import { withTranslation } from "react-i18next";
import { Utils, sessionDetails } from "../../utils";

class ColorScorePopover extends React.Component {

  renderRULAPopover = () => {
    const { t } = this.props;
    const colors = Utils.assessmentColors;

    return (
        <div style={{width: '320px'}} data-test="rula-score-popover-content">
          <Row style={{marginBottom: '4px'}}>
            <div>
              <Col span={3}>
                <Tag color={colors.NO_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                  <b>{t('ColorScorePopover.rula.noRisk')}</b>
                </Tag>
              </Col>
              <Col span={21}> {t('ColorScorePopover.noRisk')}</Col>
            </div>
          </Row><Row style={{marginBottom: '4px'}}>
          <div>
            <Col span={3}>
              <Tag color={colors.LOW_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.rula.lowRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.lowRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}}>
          <div>
            <Col span={3}>
              <Tag color={colors.MEDIUM_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.rula.mediumRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.mediumRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}}>
          <div>
            <Col span={3}>
              <Tag color={colors.VERY_HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.rula.veryHighRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.veryHighRisk')}</Col>
          </div>
        </Row>
        </div>
    );
  }

  renderREBAPopover = () => {
    const { t } = this.props;
    const colors = Utils.assessmentColors;
    const gutterSize = 47;

    return (
      <div style={{width: '330px'}} data-test="reba-score-popover-content">
        <Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={3}>
              <Tag color={colors.NO_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.reba.noRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.noRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={3}>
              <Tag color={colors.LOW_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.reba.lowRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.lowRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={3}>
              <Tag color={colors.MEDIUM_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.reba.mediumRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.mediumRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={3}>
              <Tag color={colors.HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.reba.highRisk')}</b>
              </Tag>
            </Col>
          <Col span={21}> {t('ColorScorePopover.highRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={3}>
              <Tag color={colors.VERY_HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.reba.veryHighRisk')}</b>
              </Tag>
            </Col>
            <Col span={21}> {t('ColorScorePopover.veryHighRisk')}</Col>
          </div>
        </Row>
      </div>
    );
  }

  renderNIOSHPopover = () => {
    const { t } = this.props;
    const colors = Utils.assessmentColors;
    const gutterSize = sessionDetails.isMobile() ? 90 : 64;

    return (
      <div style={{width: '380px', maxWidth: sessionDetails.isMobile() ? '95vw': '100vw'}}  data-test="niosh-score-popover-content">
        <Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.NO_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.niosh.noRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.noRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.LOW_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.niosh.lowRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.lowRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.MEDIUM_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.niosh.mediumRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.mediumRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.niosh.highRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.highRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.VERY_HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.niosh.veryHighRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.veryHighRisk')}</Col>
          </div>
      </Row>
      </div>
    );
  }

  renderLMPopover = () => {
    const { t } = this.props;
    const colors = Utils.assessmentColors;
    const gutterSize = sessionDetails.isMobile() ? 64 : 32;

    return (
      <div style={{width: '400px', maxWidth: sessionDetails.isMobile() ? '95vw': '100vw'}} data-test="lm-score-popover-content">
        <Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.NO_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.lm.noRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.noRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.MEDIUM_RISK} style={{fontWeight: '500', fontSize: "14px", color: "black"}}>
                <b>{t('ColorScorePopover.lm.mediumRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.mediumRisk')}</Col>
          </div>
        </Row><Row style={{marginBottom: '4px'}} gutter={gutterSize}>
          <div>
            <Col span={5}>
              <Tag color={colors.VERY_HIGH_RISK} style={{fontWeight: '500', fontSize: "14px", color: "white"}}>
                <b>{t('ColorScorePopover.lm.veryHighRisk')}</b>
              </Tag>
            </Col>
            <Col span={19}> {t('ColorScorePopover.veryHighRisk')}</Col>
          </div>
        </Row>
      </div>
    );
  }

  render = () => {
    const { t, assessmentType } = this.props;
    let popoverContent;
    switch (assessmentType) {
      case "rula":
        popoverContent = this.renderRULAPopover();
        break;
      case "reba":
        popoverContent = this.renderREBAPopover();
        break;
      case "niosh":
        popoverContent = this.renderNIOSHPopover();
        break;
      case "lm":
        popoverContent = this.renderLMPopover();
        break;
      default:
        popoverContent = null;
    }

    return (
      <Popover
          title={t('ColorScorePopover.title')}
          content={popoverContent}>
        <Icon type="info-circle" data-test={`${assessmentType}-score-color-popover-icon`}/>
      </Popover>
    );
  }
}

export default withTranslation()(ColorScorePopover);