import {CordovaUtils, sessionDetails, firebaseClient, Request, FileCache, Utils} from './';

const CACHED_ASSESSMENT_ID_PREFIX = 'a'
const CACHED_ANALYSIS_ID_PREFIX = 'u'

class UploadCache {
  constructor() {
      this.uploads = {};
  }

  getSubdirectory() {
    return "staged_uploads";
  }

  initializeUploadCache = () =>  {
    if (sessionDetails.isCordova()) {
      CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-uploads.json", CordovaUtils.readJSONFileEntry).then((savedUploads) => {
        if (savedUploads) {
          this.uploads = savedUploads;
        }
      });
    }
  }

  get = (uid) =>  {
    return this.uploads[uid];
  }

  getAnalyses = () =>  {
    let uploadList = []
    for (let key in this.uploads) {
      if (this.uploads.hasOwnProperty(key) && this.isCachedAnalysisId(key)) {
        uploadList.push(this.uploads[key]);
      }
    }
    return uploadList;
  }

  getAssessments = (analysisId) =>  {
    let uploadList = []
    for (let key in this.uploads) {
      if (this.uploads.hasOwnProperty(key) && this.isCachedAssessmentId(key) && this.uploads[key].analysisId === analysisId) {
        uploadList.push(this.uploads[key]);
      }
    }
    return uploadList;
  }

  addAnalysis = (video, thumbnail, data) =>  {
    let newEntry = data
    let uid = this.getNewAnalysisId();

    newEntry.id = uid;
    newEntry.status = "unprocessed";
    newEntry.uploadDate = new Date().toISOString();

    this.uploads[uid] = newEntry;

    let videoPromise = CordovaUtils.saveFile(video, FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".mp4");

    let thumbnailAndDataPromise = CordovaUtils.saveFile(CordovaUtils.blobify(this.uploads), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-uploads.json").then(() => {
      if (thumbnail && thumbnail.name) { // File constructor actually sets the blob here, so thumbnail.name[0] = blob
        return CordovaUtils.saveFile(thumbnail.name[0], FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".png");
      }
    });

    return Promise.all([videoPromise, thumbnailAndDataPromise]);
  }

  addAssessment = (analysisId, assessmentType, thumbnail, assessmentData, score) => {
    let newEntry = {}
    newEntry.analysisId = analysisId
    newEntry.type = assessmentType
    newEntry.assessmentData = assessmentData
    newEntry.name = assessmentData.name
    newEntry.score = score
    let uid = this.getNewAssessmentId();

    newEntry.id = uid;
    newEntry.uploadDate = new Date().toISOString();

    this.uploads[uid] = newEntry;

    return CordovaUtils.saveFile(CordovaUtils.blobify(this.uploads), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-uploads.json").then(() => {
      if (thumbnail) {
        return CordovaUtils.saveFile(thumbnail, FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".png");
      }
    });
  }

  updateAssessment = (uid, assessmentData, score) => {
    if (this.uploads[uid]) {
      this.uploads[uid].assessmentData = assessmentData
      this.uploads[uid].name = assessmentData.name
      this.uploads[uid].score = score

      return CordovaUtils.saveFile(CordovaUtils.blobify(this.uploads), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-uploads.json");
    }
  }

  remove = (uid) => {
    delete this.uploads[uid];
    let requests = [
      CordovaUtils.saveFile(CordovaUtils.blobify(this.uploads), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-uploads.json"),
      CordovaUtils.deleteFile(FileCache.getCacheDirectory(), this.getSubdirectory(), uid, ".png"),
      CordovaUtils.deleteFile(FileCache.getCacheDirectory(), this.getSubdirectory(), uid, ".mp4"),
    ];

    return Utils.allSettled(requests)
  }

  clearCache = () =>  {
    let requests = []
    for (let uid in this.uploads) {
      requests.push(this.remove(uid));
    }

    return Utils.allSettled(requests).then(() => {
      // noinspection JSUnresolvedVariable
      return CordovaUtils.deleteFolder(window.cordova.file.externalDataDirectory, this.getSubdirectory());
    });
  }

  uploadCachedVideo = (uid) =>  {
    let videoPromise = CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".mp4", CordovaUtils.readFileEntryAsBlob);
    let thumbnailPromise = CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".png", CordovaUtils.readFileEntryAsBlob);
    let uploadData = this.get(uid)

    return Promise.all([videoPromise, thumbnailPromise]).then((blobs) => {
      return firebaseClient.getToken().then((token) => {
        let deviceId = token ? token : null;
        let video = blobs[0];
        let thumbnail = blobs[1];
        return Request.uploadVideo(
            uploadData.name,
            video,
            thumbnail,
            uploadData.devEnv,
            uploadData.runOptions,
            uploadData.trimStart,
            uploadData.trimEnd,
            uploadData.blurLevel,
            deviceId,
            uploadData.groups).then((response) => {
            if (response && response.data && response.data.responseData && response.data.responseData.analysisId) {
              this.remove(uid);
            }
            return response;
          });
      });
    });
  }

  uploadCachedAssessments = () => {
    let requests = [];
    for (let key in this.uploads) {
      if (this.uploads.hasOwnProperty(key) && this.isCachedAssessmentId(key)) {
        requests.push(this.uploadCachedAssessment(key))
      }
    }

    return Utils.allSettled(requests)
  }

  uploadCachedAssessment = (uid) => {
    let uploadData = this.get(uid)

    return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), this.getSubdirectory(), uid + ".png", CordovaUtils.readFileEntryAsBlob).then((thumbnail) => {
        return Request.uploadAssessment(
            uploadData.analysisId,
            uploadData.type,
            thumbnail,
            uploadData.assessmentData,
            uploadData.score,
        ).then(() => {
          return this.remove(uid);
        }, () => {
          return Promise.resolve();
        });
    });
  }

  getNewAnalysisId = () =>  {
    let i = 0
    while(true) {
      let uid = CACHED_ANALYSIS_ID_PREFIX + i;
      if (!(uid in this.uploads)) {
        return uid;
      }
      i++;
    }
  }

  getNewAssessmentId = () =>  {
    let i = 0
    while(true) {
      let uid = CACHED_ASSESSMENT_ID_PREFIX + i;
      if (!(uid in this.uploads)) {
        return uid;
      }
      i++;
    }
  }

  isCachedAnalysisId = (id) => {
    return id.toString().startsWith(CACHED_ANALYSIS_ID_PREFIX)
  }

  isCachedAssessmentId = (id) => {
    return id.toString().startsWith(CACHED_ASSESSMENT_ID_PREFIX)
  }

  isCached = (uid) =>  {
    return uid in this.uploads;
  }
}

export let uploadCache = new UploadCache();