import React from 'react';
import {Table, Descriptions, Typography, Button, Select, Tag, Pagination} from 'antd';
import { withTranslation } from "react-i18next";

import {
  Utils,
  sessionDetails,
  AssessmentListSorter,
  uploadCache,
} from '../../utils';
import { Thumbnail, ColorScore, ColorScorePopover } from '..';
import { KINETICA_DARK_BLUE } from '../../utils/constants';

const { Paragraph } = Typography;

const sortDirections = {
  "MODIFIED_DATE_DESCENDING": "Modified Date Desc",
  "MODIFIED_DATE_ASCENDING": "Modified Date Asc",
  "NAME_ASCENDING": "Name Asc",
  "NAME_DESCENDING": "Name Desc",
  "SCORE_ASCENDING": "Score Asc",
  "SCORE_DESCENDING": "Score Desc",
}

class AssessmentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedAssessmentList: [],
      sortColumn: "date",
      sortDirection: "descend",
      filteredAssessmentTypes: [],
      choosingNewAssessment: false,
      pageSize: 10,
      pageNumber: 1
    }
  }

  componentDidMount() {
    this.setState({
      sortedAssessmentList: AssessmentListSorter.sortAssessments(this.props.assessmentList,
          this.state.sortColumn, this.state.sortDirection, this.state.filteredAssessmentTypes)
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Allow AnalysisView to override table state
    if (nextProps.choosingNewAssessment !== prevState.choosingNewAssessment) {
      return {
        choosingNewAssessment: nextProps.choosingNewAssessment,
      };
    } else {
      return null;
    }
  }

  isSerializedJsonScore = (string) => {
    return String(string).includes("{") && String(string).includes("}");
  }

  onSortChange = (value) => {
    let column = "Date";
    let direction = "descend";
    switch (value) {
      case sortDirections.MODIFIED_DATE_DESCENDING:
        column = "date";
        direction = "descend";
        break;
      case sortDirections.MODIFIED_DATE_ASCENDING:
        column = "date";
        direction = "ascend";
        break;
      case sortDirections.NAME_DESCENDING:
        column = "name";
        direction = "descend";
        break;
      case sortDirections.NAME_ASCENDING:
        column = "name";
        direction = "ascend";
        break;
      case sortDirections.SCORE_DESCENDING:
        column = "score";
        direction = "descend";
        break;
      case sortDirections.SCORE_ASCENDING:
        column = "score";
        direction = "ascend";
        break;
      default:
    }

    this.setState({
      sortColumn: column,
      sortDirection: direction,
      sortedAssessmentList: AssessmentListSorter.sortAssessments(this.props.assessmentList,
          column, direction, this.state.filteredAssessmentTypes)
    });
  };

  onFilterChange = (filteredTypes) => {
    this.setState({
      filteredAssessmentTypes: filteredTypes,
      sortedAssessmentList: AssessmentListSorter.sortAssessments(this.props.assessmentList,
          this.state.sortColumn, this.state.sortDirection, filteredTypes)
    });
  }

  onCreateNewAssessment = () => {
    this.props.onNewAssessment()
    this.setState({choosingNewAssessment: true})
  }

  onSelectNewAssessmentType = (assessmentType) => {
    this.props.onSelectNewAssessmentType(assessmentType);
    this.setState({choosingNewAssessment: false});
  }

  renderScore = (rawScore, assessmentType) => {
    const { t } = this.props;
    if (this.isSerializedJsonScore(rawScore)) { // Score is a JSON Object
      const score = JSON.parse(rawScore);
      return <ColorScore assessmentType={assessmentType} score={score}/>
    } else if (rawScore.constructor === Object) { // Check if score is a dictionary
      return <ColorScore assessmentType={assessmentType} score={rawScore}/>
    } else { // Legacy Score
      if (isNaN(rawScore)) {
        return <div style={{float: "left", marginRight: '6px'}}><b>{rawScore}</b></div>
      } else {
        return <div style={{float: "left", marginRight: '6px'}}>{t('AssessmentTable.score')}: <b>{rawScore}</b></div>
      }
    }
  }

  renderPTButton = () => {
    const { t } = this.props;
    if (this.props.ptMode)
      return <Button type="primary" icon="plus" onClick={() => this.props.onSelectNewAssessmentType("pt")} style={{margin: '8px'}}>{t('AssessmentTable.pt')}</Button>
    return null;
  }

  renderNewAssessmentSelector = () => {
    const { t } = this.props;
    if (this.state.choosingNewAssessment) {
      return (
        <div style={{marginTop: '6px'}}>
          <Button type="primary" icon="plus" onClick={() => this.props.onSelectNewAssessmentType("rula")} style={{margin: '8px'}} data-test="new-rula-button">{t('AssessmentTable.rula')}</Button>
          <Button type="primary" icon="plus" onClick={() => this.props.onSelectNewAssessmentType("reba")} style={{margin: '8px'}} data-test="new-reba-button">{t('AssessmentTable.reba')}</Button>
          <Button type="primary" icon="plus" onClick={() => this.props.onSelectNewAssessmentType("niosh")} style={{margin: '8px'}} data-test="new-niosh-button">{t('AssessmentTable.niosh')}</Button>
          <Button type="primary" icon="plus" onClick={() => this.props.onSelectNewAssessmentType("lm")} style={{margin: '8px'}} data-test="new-lm-button">{t('AssessmentTable.lm')}</Button>
          {this.renderPTButton()}
        </div>
      )
    } else {
      return (
        <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={this.onCreateNewAssessment}
            style={{marginBottom: '4px', marginTop: '10px'}}
            data-test="new-assessment-button"
        >
          {t('AnalysisView.newAssessment')}
        </Button>
      )
    }
  }

  renderSorter = () => {
    const { t } = this.props;
    return(
        <Select data-test="assessment-list-sort-menu" defaultValue={t('AssessmentTable.sort.instruction')} onChange={this.onSortChange} style={{margin: '5px', marginLeft: '0px', width: '160px'}}>
          <Select.Option data-test="sort-date-desc" value={sortDirections.MODIFIED_DATE_DESCENDING}>{t('AssessmentTable.sort.dateDescending')}</Select.Option>
          <Select.Option data-test="sort-date-asc" value={sortDirections.MODIFIED_DATE_ASCENDING}>{t('AssessmentTable.sort.dateAscending')}</Select.Option>
          <Select.Option data-test="sort-name-desc" value={sortDirections.NAME_DESCENDING}>{t('AssessmentTable.sort.nameAscending')}</Select.Option>
          <Select.Option data-test="sort-name-asc" value={sortDirections.NAME_ASCENDING}>{t('AssessmentTable.sort.nameDescending')}</Select.Option>
        </Select>
    );
    // Todo: Add these back in when re-enabling sorting by score
    // <Select.Option data-test="sort-score-desc" value={sortDirections.SCORE_DESCENDING}>{t('AssessmentTable.sort.scoreDescending')}</Select.Option>
    // <Select.Option data-test="sort-score-asc" value={sortDirections.SCORE_ASCENDING}>{t('AssessmentTable.sort.scoreAscending')}</Select.Option>
  };

  renderAssessmentTypeSelector = () => {
    const { t } = this.props;
    return (
        <Select
            mode="multiple"
            style={{ minWidth: '160px', maxWidth: sessionDetails.isMobile() ? '160px' : '450px', margin: '5px', marginLeft: '0px' }}
            placeholder={t('AssessmentTable.filterByType')}
            defaultValue={[]}
            maxTagCount={sessionDetails.isMobile() ? 1 : 4}
            onChange={this.onFilterChange}
            data-test="assessment-type-selector"
        >
          <Select.Option key="rula" data-test="filter-rula-option">{t('AssessmentTable.rula')}</Select.Option>
          <Select.Option key="reba" data-test="filter-reba-option">{t('AssessmentTable.reba')}</Select.Option>
          <Select.Option key="niosh" data-test="filter-niosh-option">{t('AssessmentTable.niosh')}</Select.Option>
          <Select.Option key="lm" data-test="filter-lm-option">{t('AssessmentTable.lm')}</Select.Option>
        </Select>
    );
  };

  renderThumbnail= (id) => {
    if (id >= 0 || uploadCache.isCached(id)) {
      return <Thumbnail
          analysisId={this.props.analysisId}
          assessmentId={id}
          refreshDate={this.props.refreshDate}
          bordered='false'
          style={{maxHeight: '3em', fontSize: '12pt'}}
      />
    } else {
      return null;
    }
  }

  renderHeaderRow = (record) => {
    const { t } = this.props;
    return (
      <span style={{position: 'relative', right: '104px', top: '3px'}}>
        <Tag
            closable={false}
            data-test={record.type + "-header"}
        >
          <Paragraph
              ellipsis={true}
              style={{margin: '0px', fontSize: '12pt', fontWeight: 600, width: "100%"}}
          >
          {t(`AssessmentTable.${record.type}`)}
          </Paragraph>
        </Tag>
        <span style={{position: 'relative', bottom:'4px'}}>
          <ColorScorePopover assessmentType={record.type} style={{position: 'relative', marginBottom:'20px'}}/>
        </span>
      </span>
    )
  }

  renderNormalRow = (record) => {
    const { t } = this.props;
    return (
      <Descriptions size='small' layout='vertical' bordered>
        <Descriptions.Item
            span={2}
            style={{ margin: '0px'}}
            label={
          <Paragraph
              style={{width: '100%', maxWidth: sessionDetails.isMobile() ? 'calc(100vw - 210px)' : 'calc(100vw - 350px)', minWidth: '124px', margin: '0px'}}
              ellipsis={true}
          >
            <button
                type="button"
                onClick={() => this.props.onClickAssessment(record)}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  texDecoration: 'underline',
                  display: 'inline',
                  margin: 0,
                  padding: 0,
                  color: KINETICA_DARK_BLUE,
                  fontSize: '16px',
                  fontWeight: '500'
                }}
                data-test="assessment-link"
            >
              {record.name}
            </button>
          </Paragraph>}>
          {this.renderScore(record.score, record.type)}
          <span style={{float: "right"}}>{t('Formats.shortDateTime', {date: new Date(record.date)})}</span>
        </Descriptions.Item>
      </Descriptions>
    )
  }

  renderOfflineRow = (record) => {
    const { t } = this.props;
    return (
        <Descriptions size='small' layout='vertical' bordered>
          <Descriptions.Item
              span={2}
              style={{ margin: '0px'}}
              label={
                <Paragraph
                    style={{width: '100%', maxWidth: sessionDetails.isMobile() ? 'calc(100vw - 210px)' : 'calc(100vw - 350px)', minWidth: '124px', margin: '0px'}}
                    ellipsis={true}
                >
                  <button
                      type="button"
                      onClick={() => this.props.onClickAssessment(record)}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        texDecoration: 'underline',
                        display: 'inline',
                        margin: 0,
                        padding: 0,
                        color: KINETICA_DARK_BLUE,
                        fontSize: '16px',
                        fontWeight: '500'
                      }}
                      data-test="assessment-link"
                  >
                      {record.assessmentData.name}
                  </button>
                </Paragraph>}>
            {this.renderScore(record.score, record.type)}
            <span style={{float: "right"}}>{t('AssessmentForm.offline')}</span>
          </Descriptions.Item>
        </Descriptions>
    )
  }

  renderRow = (ignored, record) => {
    if (sessionDetails.isCordova() && uploadCache.isCached(record.id)) {
      return this.renderOfflineRow(record)
    } else if (record.id >= 0) {
      return this.renderNormalRow(record);
    } else if (record.type) {
      return this.renderHeaderRow(record);
    } else {
      return null;
    }
  }

  getCurrentPage = (dataSource, pageNumber, pageSize) => {
    // list of assessments and group headers in current page
    let currPage = [];
    // number of assessments so far
    let rowCount = 0
    // used to display the group header when a group overflows into the next page
    let lastLabel = null;
    for(let i = 0; i < dataSource.length; ++i) {
      // stop iterating when at the end of the page
      if (rowCount >= pageSize * pageNumber) {
        break;
      }
      // find last group header on previous page
      else if (rowCount < pageSize * (pageNumber - 1)) {
        if (!dataSource[i].name)
          lastLabel = dataSource[i];
      }
      // push all assessments and headers for current page
      else {
        currPage.push(dataSource[i]);
      }
      // increment row counter only when not a group header
      if (dataSource[i].name) {
        rowCount++;
      }
    }
    // if first element of page is not a group header, insert the last group header from the previous page at the top
    if (currPage[0] && currPage[0].name && lastLabel !== null) {
      currPage.unshift(lastLabel);
    }
    return currPage;
  }

  render() {
    const { t } = this.props;
    const assessmentCount = Utils.getRowCount(this.state.sortedAssessmentList)
    const columns = [
      {
        dataIndex: 'id',
        key: 'id',
        width: 90,
        render: this.renderThumbnail
      },
      {
        dataIndex: 'name',
        key: 'name',
        render: this.renderRow
      },
    ];
    // noinspection JSUnusedGlobalSymbols
    const pagination = {
      showTotal: (ignored) => {
        return t('AssessmentTable.assessmentCount', {assessmentCount: assessmentCount})
      },
      // workaround to make sure page number is initially highlighted in pagination
      total: Math.max(assessmentCount, 1),
      showSizeChanger: true,
      pageSizeOptions: ['10', '25', '50'],
      defaultPageSize: 10,
      defaultPageNumber: 1,
      size: 'small',
      onChange: (page, pageSize) => {
        this.setState({
          pageNumber: page,
          pageSize: pageSize
        });
      },
      onShowSizeChange: (page, pageSize) => {
        this.setState({
          pageNumber: page,
          pageSize: pageSize
        });
      },
    };
    if (this.props.assessmentList && this.props.assessmentList.length > 0) {
      let currPage = this.getCurrentPage(this.state.sortedAssessmentList, this.state.pageNumber, this.state.pageSize);
      return (
        <div>
          {this.renderNewAssessmentSelector()}
          <div>
            {this.renderSorter()}
            {this.renderAssessmentTypeSelector()}
          </div>
          <Table
              pagination={false}
              rowKey={'timestamp'}
              columns={columns}
              dataSource={currPage}
              size='small'
              showHeader={false}
              style={this.props.style}
          />
          <div align={"right"} style={{margin: "16px 0px"}}>
            <Pagination {...pagination}/>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.renderNewAssessmentSelector()}
        </div>);
    }
    
  }
}

export default withTranslation()(AssessmentTable);