import React from 'react';
import { Row, Col, Card } from 'antd';
import { withTranslation } from "react-i18next";
import {sessionDetails} from "../../utils";

const AboutView = (props) => {
    sessionDetails.changeView("AboutView");
    const title = <span style={{width: '100%', fontSize: '24pt', textAlign: 'center'}}>About Kinetica Labs</span>;
    const { t } = props;
    return (
        <div className="dashboard" style={{textAlign: 'center'}}>
            <Row>
                <Col offset={0} span={24}>
                    <Card hoverable title={title} style={{fontSize: '12pt', maxWidth: '600px', display: 'inline-block', textAlign: 'left'}}>
                        <p>{ t('About.para1') } </p>
                        <p>{ t('About.para2') } </p>
                        <p>{ t('About.para3') } </p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(AboutView);
