import React from 'react';
import {sessionDetails} from "../../utils";

const AccountView = (props) => {
  sessionDetails.changeView("AccountView");
  return (
    <div className="dashboard">
        <span>
            Account
        </span>
    </div>
  );
};

export default AccountView;
