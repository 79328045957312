const MAX_MOBILE_WIDTH = 1200;

const DETAIL_IS_STAGING = "IS_STAGING";
const DETAIL_IS_DEV = "IS_DEV";
const DETAIL_VIDEO_LIST_SORT_PROPS = "VIDEO_TABLE_SORT_PROPS";
const DETAIL_VIDEO_LIST_PAGINATION_PROPS = "VIDEO_TABLE_PAGINATION_PROPS";
const DETAIL_VIDEO_LIST_FILTER_PROPS = "VIDEO_LIST_FILTER_PROPS"

class SessionDetails {
    constructor() {
        this.details = {};
        this.updateIsMobile();
        this.updateIsVertical();
        this.updateIsCordova();
        this.updateCordovaPlatform();
        this.updatePlatformVersion();
        window.onresize = () => {
            this.updateIsMobile();
            this.updateIsVertical();
        };
    }

    get(detail) {
        return this.details[detail];
    }

    set(detail, value) {
        this.details[detail] = value;
    }

    delete(detail) {
        delete this.details[detail];
    }

    setApiUrl(url) {
        this.apiUrl = url;
    }

    setStaging(isStaging) {
        this.set(DETAIL_IS_STAGING, isStaging);
    }

    setDev(isDev) {
        this.set(DETAIL_IS_DEV, isDev);
    }

    getApiUrl() {
        return this.apiUrl;
    }

    isMobile() {
        return this.details.isMobile;
    }

    isVertical() {
        return this.details.isVertical;
    }

    isCordova() {
        return this.details.isCordova;
    }

    cordovaPlatform() { // IOS/Android
        return this.details.cordovaPlatform;
    }

    platformVersion() { //eg. 11 for Android 11
        return this.details.platformVersion
    }

    isStaging() {
        return this.get(DETAIL_IS_STAGING);
    }

    isDev() {
        return this.get(DETAIL_IS_DEV);
    }

    canSaveFiles() {
        // noinspection RedundantIfStatementJS,JSUnresolvedVariable
        if (window.cordova.file.externalRootDirectory) {
            return true;
        } else {
            return false;
        }
    }

    updateIsMobile() {
        this.details.isMobile = window.innerWidth < MAX_MOBILE_WIDTH;
    }

    updateIsVertical() {
        this.details.isVertical = window.innerWidth < window.innerHeight;
    }

    updateIsCordova() {
        // noinspection RedundantConditionalExpressionJS,JSUnresolvedVariable
        this.details.isCordova = window.cordova ? true : false;
    }

    updateCordovaPlatform() {
        this.details.cordovaPlatform = window.device ? window.device.platform.toLowerCase() : null
    }

    updatePlatformVersion() {
        this.details.platformVersion = window.device ? window.device.version.toLowerCase() : null
    }

    getVideoListSortProps() {
        return this.get(DETAIL_VIDEO_LIST_SORT_PROPS);
    }

    getVideoListPaginationProps() {
        return this.get(DETAIL_VIDEO_LIST_PAGINATION_PROPS);
    }

    getVideoListFilterProps() {
        return this.get(DETAIL_VIDEO_LIST_FILTER_PROPS);
    }

    setVideoListSortProps(sortProps) {
        this.set(DETAIL_VIDEO_LIST_SORT_PROPS, sortProps);
    }

    setVideoListPaginationProps(paginationProps) {
        this.set(DETAIL_VIDEO_LIST_PAGINATION_PROPS, paginationProps);
    }

    setVideoListFilterProps(filterProps) {
        this.set(DETAIL_VIDEO_LIST_FILTER_PROPS, filterProps);
    }

    changeView(newView) {
        if (newView !== "AnalysisView" && newView !== "VideoList") {
            this.delete(DETAIL_VIDEO_LIST_SORT_PROPS);
            this.delete(DETAIL_VIDEO_LIST_PAGINATION_PROPS);
            this.delete(DETAIL_VIDEO_LIST_FILTER_PROPS);
        }
    }
}

export let sessionDetails = new SessionDetails();