import Request from './request'
import Persistence from "./persistence";

const MEASURE = 'measure';
const MEASURE_IMPERIAL = 'imperial';
const MEASURE_METRIC = 'metric';


class UserInfo {
    constructor() {
        // contains all default values; must be updated for new settings
        this.settings = {[MEASURE]: MEASURE_IMPERIAL};
        this.processingCredits = null;
        this.expiration = null;
        this.currentlyProcessingVideos = 0;
    }

    initializeUserSettings = () => {
        if (Persistence.isLoggedIn()) {
            this.downloadUserInfo().then(() => {});
        }
    }

    saveSettings(json_str) {
        try {
            Object.assign(this.settings, JSON.parse(json_str));
        }
        catch(err) {
            console.log(err);
        }
    }

    downloadUserInfo() {
        return Request.getUserInfo().then(data => {
                this.saveSettings(data.settings);
                this.processingCredits = data.uses;
                // noinspection JSUnresolvedVariable
                this.expiration = data.expirationUnix;
            },
            (err) => console.log(err));
    }

    uploadSettings() {
        return Request.uploadSettings(JSON.stringify(this.settings));
    }

    getExpiration() {
        return this.expiration;
    }

    getProcessingCredits() {
        return this.processingCredits;
    }

    setCurrentlyProcessingVideos(numVideos) {
        this.currentlyProcessingVideos = numVideos;
    }

    getCurrentlyProcessingVideos() {
        return this.currentlyProcessingVideos;
    }

    getMeasure() {
        return this.getSetting(MEASURE);
    }

    getSetting(key) {
        return this.settings[key];
    }

    setSetting(key, val) {
        this.settings[key] = val;
    }

    getParam_Measure() {
        return MEASURE;
    }

    getParam_MeasureImperial() {
        return MEASURE_IMPERIAL;
    }

    getParam_MeasureMetric() {
        return MEASURE_METRIC;
    }
}

export let userInfo = new UserInfo();