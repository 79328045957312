import React from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";
import { Request, Utils } from "../../utils";

const { Option } = Select;

class OrganizationSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orgList: []
        }
    }

    componentDidMount() {
        if (this.props.isAdmin) {
            Request.listOrgs().then(response => {
                this.initialize(response);
            });
        } else {
            Request.listOrgsOrgAdmin().then(response => {
                this.initialize(response);
            });
        }
    }

    initialize = (response) => {
        if (Utils.responseIsSuccess(response)) {
            if (this.props.previousValue) {
                this.setState({loading: false, orgList: response.values, selected: this.props.previousValue});
            } else {
                this.setState({loading: false, orgList: response.values});
            }
        }
    }

    renderOptions = () => {
        const { orgList } = this.state;
        let key = 0
        return orgList.map((orgName) => {
            return <Option key={key++} value={orgName} data-test={orgName + "-option"}>{orgName}</Option>
        });
    };

    handleChange = (value) => {
        this.setState({selected: value});

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { t, disabled } = this.props;
        return (
            <Select showSearch allowClear
                    value={this.state.selected}
                    loading={this.state.loading}
                    onChange={this.handleChange}
                    placeholder={t('OrganizationSelector.select')}
                    style={{ minWidth: 200 }}
                    disabled={disabled}
                    data-test="org-selector"
            >
                {this.renderOptions()}
            </Select>
        );
    }
}

export default withTranslation()(OrganizationSelector);