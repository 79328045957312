import { useMediaQuery } from 'react-responsive';
import i18n from 'i18next';

const MAX_MOBILE_WIDTH = 1000;

class Utils {

  static secondsToDuration(duration) {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor( (duration % 3600) / 60);
    let seconds = Math.floor((duration % 3600) % 60);
    let secondMillis3 = ((duration % 3600) % 60).toFixed(3);


    return {
      hours: hours,
      hoursLong: hours < 10 ? "0"+hours : hours,
      minutes: minutes,
      minutesLong: minutes < 10 ? "0"+minutes : minutes,
      seconds: seconds,
      secondsLong: seconds < 10 ? "0"+seconds : seconds,
      withMillis3: secondMillis3,
      withMillis3Long: secondMillis3 < 10 ? "0" + secondMillis3 : secondMillis3
    };
  }

  static compareDates(a, b) {
    let firstDate = Utils.getAsDate(a);
    let secondDate = Utils.getAsDate(b);

    return (firstDate > secondDate) - (firstDate < secondDate);
  }

  static getAsDate(date) {
    if (date && date.constructor === Date) {
      return date;
    } else {
      return new Date(date);
    }
  }

  static formHasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  static getResource(source) {
    if (Utils.isAndroid()) {
      return "/android_asset/www" + source;
    } else {
      return source;
    }
  }

  /**
   * Determine whether or not a thing exists
   * @param thing anything
   * @returns {boolean} returns true if the thing has a value
  */
  static exists(thing) {
    return thing !== undefined && thing !== null;
  }

  static hasConnection() {
    if (!navigator.connection) {
      // We can't tell if there is a connection so assume yes
      return true;
    }
    return navigator.connection.type !== 'none';
  }

  static isAndroid() {
    // noinspection JSUnresolvedVariable
    return window.cordova && window.cordova.platformId === "android";
  }

  static translateProductAccess(productString) {
    let products = productString.split(',');
    let readableProducts = "";

    for (let product of products) {
      switch(product) {
        case "kl":
          readableProducts += "Kinetica, ";
          break;
        case "lm":
          readableProducts += "Liberty-Mutual, ";
          break;
        case "hmc":
          readableProducts += "Hyundai, ";
          break
        default:
          break
      }
    }

    // Strip last comma/space
    if (readableProducts.length > 2) {
      readableProducts = readableProducts.substring(0, readableProducts.length - 2);
    } else {
      readableProducts = i18n.t("UserTable.none");
    }

    return readableProducts;
  }

  /**
   * Translate Joint from back end representation to front end display
   * @param {string} jointString Joint string from back end
   * @returns {string} The translated joint string to be displayed
   */
  static translateJoint(jointString) {
    switch (jointString) {
      case "All":
        return i18n.t('Comparison.joints.all');
      case "Back Bending":
        return i18n.t('Comparison.joints.backBending');
      case "Neck":
        return i18n.t('Comparison.joints.neck');
      case "Right Arm":
        return i18n.t('Comparison.joints.rightArm');
      case "Left Arm":
        return i18n.t('Comparison.joints.leftArm');
      case "Right Knee":
        return i18n.t('Comparison.joints.rightKnee');
      case "Left Knee":
        return i18n.t('Comparison.joints.leftKnee');
      case "Right Elbow":
        return i18n.t('Comparison.joints.rightElbow');
      case "Left Elbow":
        return i18n.t('Comparison.joints.leftElbow');
      case "Right Hip":
        return i18n.t('Comparison.joints.rightHip');
      case "Left Hip":
        return i18n.t('Comparison.joints.leftHip');
      default:
        return i18n.t('Comparison.joints.unknown');
    }
  };

  /**
   * Translate Threshold from back end representation to front end display
   * @param {string} threshold Threshold string from back end
   * @returns {string} The translated threshold to be displayed
   */
  static translateThreshold = (threshold) => {
    return i18n.t(`Comparison.thresholds.${threshold}`);
  }

  /**
   * Get default threshold object to be used downstream
   * This was replaced with the current color/threshold format but is necessary for legacy analyses
   * @param {object} legacyThresholdData threshold data from back end
   * @returns {object} default threshold object usable by the rest of the app
   */
  static getDefaultThresholdData = (legacyThresholdData) => {
    // In case for some reason the passed in thresholds also don't exist
    const thresholdData = legacyThresholdData ? legacyThresholdData : {
      "cautious": [0, 90],
      "dangerous": [90, 180]
    };

    return [
      {low: 0, high: thresholdData.cautious[1], color: '#00FF00', name: i18n.t('AnalysisView.thresholds.safe')},
      {low: thresholdData.cautious[1], high: thresholdData.dangerous[1], color: '#FFFF00', name: i18n.t('AnalysisView.thresholds.cautious')},
      {low: thresholdData.dangerous[1], high: Number.MAX_VALUE, color: '#FF0000', name: i18n.t('AnalysisView.thresholds.dangerous')}
    ];
  }

  /**
   * Get range object usable by the stacked bar charts
   * This data is available directly with current analyses but old analyses
   * need the data manipulated to work with the charts
   * @param {object} legacyThresholds default threshold object
   * @returns {object} threshold ranges usable by the stacked bar charts
   */
  static getRangesFromLegacyThresholds = (legacyThresholds) => {
    let ranges = {}
    legacyThresholds.forEach((threshold) => {
      if (threshold.name === Utils.translateThreshold("cautious")) {
        ranges.cautious = [threshold.low, threshold.high];
      } else if (threshold.name === Utils.translateThreshold("dangerous")) {
        ranges.dangerous = [threshold.low, threshold.high];
      }
    });
    return ranges;
  }

  /**
   * Get a list of valid groupNames from a list of IDs
   * @param groupMembership serialized list of assigned groups (comma separated)
   * @param groups object representing available groups {groupId: <groupName>} (Request.ListGroups)
   * @returns list of groupNames
   */
  static getGroupNames = (groupMembership, groups) => {
    let groupNames = [];
    if (groupMembership && groups) {
      let assignedGroups = groupMembership.split(',');
      for (let groupId of assignedGroups) {
        if (groups[groupId]) {
          groupNames.push(groups[groupId]);
        }
      }
    }
    return groupNames
  }

  /**
   * Get the count of the actual video/assesements in a mixed sorted list
   * @param tableList list of assessments/videos being sent to the table)
   * @returns number of rows with a positive row ID
   */
  static getRowCount(tableList) {
   return tableList.filter((row) => row.id >= 0).length;
  }


  /**
   * Converts the color object passed down in processed data file into an array of objects
   * shaped like this:
   * @param {Object} colors an object shaped like this:
   * {
   *    "default": [0, 255, 0],
   *    "cautious": [0, 255, 255],
   *    "dangerous": [0, 0, 255],
   * }
   * @returns {Object[]} An array of objects shaped like this:
   * [
   *   {name: "default", color: "#00FF00"},
   *   {name: "cautious", color: "#FFFF00"},
   *   {name: "dangerous", color: "#FF0000"}
   * ]
   */
  static convertColors(colors) {
    let hexColors = [];
    if (!colors) {
      return [
        {name: "default", color: "#00FF00"},
        {name: "cautious", color: "#FFFF00"},
        {name: "dangerous", color: "#FF0000"}
      ];
    }

    /**
     * Convert an array of BGR values into its RGB hexadecimal representation
     * @param {number[]} colorArray looks like this [B, G, R] e.g. red = [0, 0, 255]
     * @returns {string} the six character hex representation #RRGGBB e.g. red = #FF0000
     */
    const BGR2Hex = (colorArray) => {
      /**
       * Turn a decimal integer into its hex representation, with a leading 0 if necessary
       * @param {number} integer an integer in the range 0-255
       * @returns {string} two character hex representation e.g. 10 = 0A
       */
      const intToHexCouplet = (integer) => {
        let hex = integer.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }

      // Due to the magic of python's cv2 library, we receive the colors in BGR order
      const r = colorArray[2];
      const g = colorArray[1];
      const b = colorArray[0];
      return "#" + intToHexCouplet(r) + intToHexCouplet(g) + intToHexCouplet(b);
    }

    for (const [colorName, bgrValues] of Object.entries(colors)) {
      hexColors.push({name: colorName, color: BGR2Hex(bgrValues)})
    }

    return hexColors;
  }

  static interpretPopulationPercentage = (populationPercentage) => {
    if (populationPercentage === "N/A") {
      return "N/A"
    } else if (parseFloat(populationPercentage) > 90) {
      return ">90";
    } else if (parseFloat(populationPercentage) < 10) {
      return "<10"
    } else {
      return Math.trunc(parseFloat(populationPercentage))
    }
  }

  /**
   * Function that determines the x-coordinate to start crop and corresponding width of graph
   * @param comparisonArea {Element} Graph area identified by div outside of chart class
   * @param yLabel {Element} y label of graph, the leftmost edge to include in crop
   * @param xAxis {Element} x-axis of graph, the object that returns the rightmost limit
   * @param firstLegendBox {Element} Leftmost box in legend
   * @param lastLegendLabel {Element} Rightmost label in legend
   * @param isMobile {boolean} Whether session is mobile
   * @return {{canvasWidth: number, cropStart: number}}
   */
  static determineCropDims(comparisonArea, yLabel, xAxis, firstLegendBox,
                           lastLegendLabel, isMobile) {
    // Gets rectangles for the corresponding Elements
    const yLabelRect = yLabel.getBoundingClientRect();
    const firstLegendRect = firstLegendBox.getBoundingClientRect();
    const lastLegendLabelRect = lastLegendLabel.getBoundingClientRect();

    // Checks if legend is wider than graph
    const legendBiggerThanGraph = firstLegendRect.x < yLabelRect.x;
    let cropStart; let canvasWidth;

    if (isMobile) {
      // Start crop at the current border and extend it a little past the current width
      cropStart = 0;
      canvasWidth = comparisonArea.clientWidth + 20;
    } else if (legendBiggerThanGraph) {
      // Start crop before first legend box and extend past last legend label
      cropStart = firstLegendRect.x-10;
      canvasWidth = lastLegendLabelRect.right - firstLegendRect.x + 20;
    } else {
      // Start crop before y-axis label and end after x-axis ends
      cropStart = yLabelRect.x - 10;
      canvasWidth = xAxis.getBoundingClientRect().right - yLabelRect.x + 20;
    }

    return {
      "cropStart": cropStart,
      "canvasWidth": canvasWidth
    };
  }

  static getErrorMessageForCode = (code) => {
    if (isNaN(code)) {
      return i18n.t('Error.unknownError');
    } else {
      return i18n.t('Error.' + code.toString());
    }
  }

  static assessmentColors = {
    NO_RISK: "#1ca800",
    LOW_RISK: "#bbff00",
    MEDIUM_RISK: "#fff200",
    HIGH_RISK: "#ff8400",
    VERY_HIGH_RISK: "#c80000",
    UNKNOWN: "#FFFFFF"
  }

  // Truncate a string with ellipses
  static truncateString = (str, length=18) => {
    if (str.length <= length) {
      return str;
    } else  {
      return str.substring(0, length - 3) + "...";
    }
  }

  /**
   * Determine if a value is a valid number and prepare it for display
   *
   * @param value numerical value to be prepared for display
   * @param postfix something to print after the value if the value is valid
   * @param decimalPlaces how many decimal places to round the value to
   * @returns {string|*} A string representation of the value to print
   */
  static interpretDataReportValue = (value, postfix, decimalPlaces=1) => {
    if ((value || value === 0) && value !== -1) {  // Value is valid
      return value.toFixed(decimalPlaces).toString() + postfix;
    } else { // Value is invalid
      return "NO DATA";
    }
  };

  static responseIsSuccess = (response) => {
    return (response && response.context && response.context.success);
  };

  static responseHasErrors = (response) => {
    return (response && response.context && response.context.errors)
  }

  static allSettled = (promises) => {
    return Promise.all(promises.map(promise => promise
        .then(value => ({ state: 'fulfilled', value }))
        .catch(reason => ({ state: 'rejected', reason }))
    ));
  }

}

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: MAX_MOBILE_WIDTH });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
  return isMobile ? children : null;
};

export { Utils, Desktop, Mobile };
