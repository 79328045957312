import React from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";
import {sessionDetails} from '../../utils';

class VideoTableSorter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableSortProps: {
        column: "uploadDate",
        direction: "descend",
      },
    }
  }

  onSortChange = (value) => {
    let sortProps = this.state.tableSortProps;
    switch (value) {
      case "Upload Date Desc":
        sortProps.column = "uploadDate";
        sortProps.direction = "descend";
        break;
      case "Upload Date Asc":
        sortProps.column = "uploadDate";
        sortProps.direction = "ascend";
        break;
      case "Name Desc":
        sortProps.column = "name";
        sortProps.direction = "descend";
        break;
      case "Name Asc":
        sortProps.column = "name";
        sortProps.direction = "ascend";
        break;
      case "Status":
        sortProps.column = "status";
        sortProps.direction = "descend";
        break;
      default:
    }

    if (this.props.sortChange) {
      this.props.sortChange(sortProps)
    }

    if (this.props.persistentSort) {
      sessionDetails.setVideoListSortProps(sortProps);
    }

    this.setState({
      tableSortProps: sortProps,
    });
  };

  render() {
    const { t } = this.props;
    return(
        <Select
            id="video-list-sort-menu"
            defaultValue={t('VideoTableSorter.instruction')}
            onChange={this.onSortChange}
            style={{margin: '5px', marginLeft: '0px', width: '170px'}}
        >
          <Select.Option data-test = "sort-upload-date-desc" value="Upload Date Desc">{t('VideoTableSorter.dateDescending')}</Select.Option>
          <Select.Option data-test = "sort-upload-date-asc" value="Upload Date Asc">{t('VideoTableSorter.dateAscending')}</Select.Option>
          <Select.Option data-test = "sort-name-asc" value="Name Asc">{t('VideoTableSorter.nameAscending')}</Select.Option>
          <Select.Option data-test = "sort-name-desc" value="Name Desc">{t('VideoTableSorter.nameDescending')}</Select.Option>
          <Select.Option data-test = "sort-status-desc" value="Status">{t('VideoTableSorter.status')}</Select.Option>
        </Select>
    );
  }
}

export default withTranslation()(VideoTableSorter);