import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { AdminView, OrgAdminView, AnalysisView, ComparisonView, VideoList, AccountView, SettingsView, AboutView, NewVideoView } from '../';

const ContentContainer = () => {
  return (
    <div className="content" style={{height: '100%'}}>
        <Route exact path="/" render={() => (<Redirect to="/videos"/>)} />
        <Route path="/videos/:id" component={AnalysisView} />
        <Route exact path="/comparison" component={ComparisonView} />
        <Route exact path="/videos" component={VideoList} />
        <Route exact path="/account" component={AccountView} />
        <Route exact path="/settings" component={SettingsView} />
        <Route exact path="/about" component={AboutView} />
        <Route exact path="/newVideo" component={NewVideoView} />
        <Route exact path="/admin" component={AdminView} />
        <Route exact path="/orgAdmin" component={OrgAdminView} />
    </div>
  );
};

export default withRouter(ContentContainer);
