import { Utils } from "../util";
import i18n from 'i18next';
import * as lunr from 'lunr';

export default class VideoListSorter {

  /**
   * Sort / Filter / Group a video list based on user input
   *
   * @param {object[]} videos - A list of video objects
   * @param {string[]} groups -
   * @param {string} sortColumn - Column of videos to sort by
   * @param {string} sortDirection - Direction of sort ("ascend", "descend")
   * @param {string[]} filteredGroups - List of groups to show (filter out everything else)
   * @param {string} search - term/terms to search on (search box content)
   * @returns {object[]} sorted / filtered / grouped list of videos
   */
  static sortVideos = (videos, groups, sortColumn, sortDirection, filteredGroups, search) => {
    const OFFLINE_UPLOADS = "Not Yet Uploaded";
    if (videos === null || videos.length === 0) {
      return videos;
    }

    let searchFilterIDs = [];
    if (search) {
      // Customize search term with wildcards to increase sensitivity
      let searchString = "";
      search.split(' ').forEach(term => {
        if (term.length > 0) {
          searchString += (term + "* " + term + " ");
        }
      });

      // Filter videos by search index
      let searchIndex = lunr(function () {
        this.field('name');
        videos.forEach(video => this.add(video));
      });

      // Condense results down to a list of IDs
      const searchResults = searchIndex.search(searchString);
      searchResults.forEach((match) => searchFilterIDs.push(match.ref));
    }


    let videoGroups = {}

    if (groups) {
      // Split up videos by group
      videos.forEach((video) => {
        // Check for a valid group membership
        let assignedGroup = "None";
        // noinspection JSUnresolvedVariable
        if (video.groupMembership && video.groupMembership !== "" && groups[video.groupMembership.split(',')[0]]) {
          assignedGroup = video.groupMembership.split(',')[0];
        } else if (video["id"].toString().includes("u")) { // Offline upload ids start with "u"
          assignedGroup = OFFLINE_UPLOADS;
        }

        // Filter out videos for search and filter functionality
        let list = videoGroups[assignedGroup];
        const excludedBySearch = (search && !searchFilterIDs.includes(video["id"].toString()));
        if ((filteredGroups.length === 0 || filteredGroups.includes(assignedGroup)) && !excludedBySearch) {
          if (list) {
            list.push(video);
          } else {
            videoGroups[assignedGroup] = [video];
          }
        }
      });
    } else {
      videoGroups["None"] = videos;
    }

    // Sort the videos within each group by the sort column
    let rowId = -1;
    let listOfSortedGroups = [];
    Object.keys(videoGroups).forEach((group) => {
      let sortedGroup = videoGroups[group].sort((videoA, videoB) => {
        if (sortDirection === "ascend") {
          return VideoListSorter.compareVideos(videoA, videoB, sortColumn);
        } else {
          return VideoListSorter.compareVideos(videoB, videoA, sortColumn);
        }
      });

      // Add mixed group entry to the front of each group to act as the group header
      if (groups) {
        let groupName = groups && groups[group] ? groups[group] :
            group === OFFLINE_UPLOADS ? OFFLINE_UPLOADS : i18n.t("VideoList.miscellaneous")
        sortedGroup.unshift({id: rowId, groupName: groupName, groupId: group});
      }
      listOfSortedGroups.push(sortedGroup);
      rowId -= 1;
    });

    // Determine order of groups
    let sortedListOfSortedGroups = listOfSortedGroups.sort((groupA, groupB) => {
      // Compare groups by their highest/lowest video based on the sort column (ignoring header rows)
      if (groupA[0]["groupName"] === OFFLINE_UPLOADS) { // Offline uploads always on top
        return -1;
      } else if (groupB[0]["groupName"] === OFFLINE_UPLOADS) {
        return 1;
      } else if (sortDirection === "ascend") {
        return this.compareVideos(groupA[1], groupB[1], sortColumn);
      } else {
        return this.compareVideos(groupB[1], groupA[1], sortColumn);
      }
    });

    // Combine list of groups into a single analysis list
    let finalVideoList = sortedListOfSortedGroups.flat(1);

    // If Miscellaneous is the first sorted group, remove the group header
    if (finalVideoList[0] && finalVideoList[0].groupName === i18n.t("VideoList.miscellaneous")) {
      finalVideoList = finalVideoList.slice(1)
    }

    return finalVideoList;
  }

  // Sort function to compare videos based on the sort column
  static compareVideos = (videoA, videoB, sortColumn) => {
    if (sortColumn === "name" || sortColumn === "status") {
      return videoA[sortColumn].localeCompare(videoB[sortColumn]);
    } else {
      return Utils.compareDates(videoA.uploadDate, videoB.uploadDate);
    }
  }
}