import React from "react";
import { Button, DatePicker, Form, Input, InputNumber, Select, Modal } from "antd";

import { Request, Utils } from "../../utils";
import { withTranslation } from "react-i18next";
import moment from "moment";
import {OrganizationSelector} from "../OrganizationSelector";


const { Option } = Select;
const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

class UserDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedOrg: props.dataSource ? props.dataSource.organization : null,
      deleteModalVisible: false,
      deactivateModalVisible: false,
      errorModalVisible: false,
      errorMessage: props.t('Error.unknownError'),
      validatedEmailAddress: null,
      submitPressed: false,
    }
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = (e, update) => {
    e.preventDefault();
    const { form, onSubmitSuccess, onSubmitFail } = this.props;
    this.setState({submitPressed: true});
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({loading: true});
        let finishFn = (response) => {
          this.setState({loading: false});
          if (Utils.responseIsSuccess(response)) {
            if (onSubmitSuccess) onSubmitSuccess(values.username);
          } else {
            if (onSubmitFail) onSubmitFail(response);
          }
        };
        if (update) {
          if (this.props.isAdmin){
            Request.editUser(values).then(finishFn);
          } else {
            Request.editUserOrgAdmin(values).then(finishFn);
          }
        } else {
          if (this.props.isAdmin){
            Request.createUser(values).then(finishFn);
          } else {
            Request.createUserOrgAdmin(values).then(finishFn);
          }
        }
      }
    });
  };

  handleDelete = () => {
    const { dataSource, isAdmin } = this.props;
    if (dataSource && dataSource.username) {
      if (isAdmin) {
        Request.deleteUser(dataSource.username).then((res) => {this.handleDeletedUser(res)});
      } else {
        Request.deleteUserOrgAdmin(dataSource.username).then((res) => {this.handleDeletedUser(res)});
      }
    }
  };

  handleDeactivate = () => {
    const { dataSource, isAdmin } = this.props;
    if (dataSource && dataSource.username) {
      if (isAdmin) {
        Request.deactivateUser(dataSource.username).then((res) => {this.handleDeactivatedUser(res)});
      } else {
        Request.deactivateUserOrgAdmin(dataSource.username).then((res) => {this.handleDeactivatedUser(res)});
      }
    }
  };

  handleDeletedUser = (response) => {
    const { t } = this.props;
    this.setState({deleteModalVisible: false});

    if (Utils.responseIsSuccess(response)) {
      this.props.onCancel();
      this.props.onDelete();
    } else if (Utils.responseHasErrors(response)) {
      this.showError(response.context.errors);
    } else {
      this.showError(t('Error.unknownError'));
    }
  };

  handleDeactivatedUser = (response) => {
    const { t, dataSource } = this.props;
    this.setState({deactivateModalVisible: false});

    if (Utils.responseIsSuccess(response)) {
      this.props.onSubmitSuccess(dataSource.username);
    } else if (Utils.responseHasErrors(response)) {
      this.showError(response.context.errors);
    } else {
      this.showError(t('Error.unknownError'));
    }
  };

  validateUserNameAsEmail = (username) => {
    const { isFieldTouched } = this.props.form;
    let usernameAsEmail = null
    if (!isFieldTouched('emailAddress')) {
      if (EMAIL_VALIDATION_REGEX.test(username)) {
        usernameAsEmail = username;
      }
      this.props.form.setFieldsValue({
        emailAddress: usernameAsEmail,
      });
    }
  }

  showError = (error) => {
    this.setState({
      errorMessage: error,
      failModalVisible: true,
    });
  }

  conditionalCancel = () => {
    const { t } = this.props;

    let buttons = [
      <Button key={0} onClick={this.props.onCancel} style={{margin: "4px"}} data-test="user-data-form-cancel-button">{t('UserDataForm.cancel')}</Button>,
      <Button key={1} type="danger" onClick={() => { this.setState({deactivateModalVisible: true}) } } style={{margin: "4px"}} data-test="deactivate-user-button">{t('UserDataForm.deactivate')}</Button>,
      <Button key={2} type="danger" onClick={() => { this.setState({deleteModalVisible: true}) } } style={{margin: "4px"}} data-test="delete-user-button">{t('UserDataForm.delete')}</Button>,
      <Button key={3} onClick={this.props.getVideos} style={{margin: "4px"}}>{t('UserDataForm.showVideos')}</Button>
    ];

    if (this.props.onCancel) {
      return buttons;
    } else {
      return null;
    }
  };

  renderModals = () => {
    const { t } = this.props;

    let modals = [
      <Modal
          key={0}
          title={t('UserDataForm.deactivate')}
          visible={this.state.deactivateModalVisible}
          onCancel={() => { this.setState({deactivateModalVisible: false}) }}
          onOk={this.handleDeactivate}
          okButtonProps={{'data-test': "deactivate-modal-ok-button"}}
          okText="Confirm"
      >
        {t('UserDataForm.deactivateConfirmation')}
      </Modal>,
      <Modal
          key={1}
          title={t('UserDataForm.delete')}
          visible={this.state.deleteModalVisible}
          onCancel={() => { this.setState({deleteModalVisible: false}) }}
          onOk={this.handleDelete}
          okButtonProps={{'data-test': "delete-modal-ok-button"}}
          okText="Confirm"
      >
        {t('UserDataForm.deleteConfirmation')}
      </Modal>,
      <Modal
          key={2}
          title={t('UserDataForm.error')}
          visible={this.state.failModalVisible}
          onOk={() => { this.setState({failModalVisible: false}) }}
          onCancel={() => { this.setState({failModalVisible: false}) }}
          okButtonProps={{'data-test': "error-modal-ok-button"}}
      >
        {
          // TODO: Make this translatable when/if this functionality is exposed to external users
          this.state.errorMessage
        }
      </Modal>
    ];

    return (<span>{modals}</span>);
  }

  renderAdminFields = (initialValues) => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const hasOrg = this.state.selectedOrg !== null && this.state.selectedOrg !== undefined;

    const availableProducts = [
      <Option key={"lm"} data-test="liberty-mutual-product-option">{"Liberty Mutual"}</Option>,
      <Option key={"kl"} data-test="kinetica-product-option">{"Kinetica"}</Option>,
      <Option key={"hmc"} data-test="hyundai-product-option">{"Hyundai"}</Option>
    ];

    if (this.props.isAdmin) {
      return (
        <span>
          <Form.Item label={t('UserDataForm.uses')}>
            {getFieldDecorator('uses', {initialValue: initialValues.uses})(
              <InputNumber min={0} precision={0} step={25} disabled={hasOrg} data-test="uses-input" />
            )}
          </Form.Item>
          <Form.Item label={t('UserDataForm.expiration')}>
            {getFieldDecorator('expiration', initialValues.expiration ? {initialValue: initialValues.expiration} : {})(
              <DatePicker disabled={hasOrg} format="YYYYMMDD" data-test="expiration-data-picker"/>
            )}
          </Form.Item>
          <Form.Item label={t('UserDataForm.productAccess')}>
            {getFieldDecorator('productAccess', {initialValue: initialValues.productAccess})(
              <Select mode="multiple" data-test="product-access-selector">
                {availableProducts}
              </Select>
            )}
          </Form.Item>
        </span>
      );
    } else {
      return null;
    }
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const { t, dataSource, isAdmin } = this.props;
    let userData = {};
    let update = false;
    if (dataSource && dataSource.username) {
      userData = dataSource;
      update = true;
    }

    const initialValues = {
      username: userData.username ? userData.username : "",
      emailAddress: userData.emailAddress ? userData.emailAddress : this.state.validatedEmailAddress,
      displayName: userData.displayName ? userData.displayName: "",
      uses: (userData.uses === null || userData.uses === undefined) ? 25 : Number(userData.uses),
      expiration: userData.expiration ? moment(userData.expiration.substring(0,10)) : "", //Cuts off time from datestring to avoid time zone issues
      userType: userData.userType ? userData.userType : "INDIVIDUAL",
      organization: userData.organization ? userData.organization : null,
      productAccess: userData.productAccess ? userData.productAccess.split(",") : []
    };

    let userTypes = [
      <Option key={1} value="INACTIVE" data-test="inactive-user-option">{t('UserDataForm.userTypes.INACTIVE')}</Option>,
      <Option key={2} value="INDIVIDUAL" data-test="individual-user-option">{t('UserDataForm.userTypes.INDIVIDUAL')}</Option>,
    ]

    // Org related user types only available to users in an org
    if (this.state.selectedOrg) {
      userTypes.push(<Option key={3} value="ORG_READ_ONLY" data-test="org-read-only-user-option">{t('UserDataForm.userTypes.ORG_READ_ONLY')}</Option>)
      userTypes.push(<Option key={4} value="ORG_FULL" data-test="org-full-user-option">{t('UserDataForm.userTypes.ORG_FULL')}</Option>)
      userTypes.push(<Option key={5} value="ORG_ADMIN" data-test="org-admin-user-option">{t('UserDataForm.userTypes.ORG_ADMIN')}</Option>)
    }

    if (isAdmin) {
      userTypes.push(<Option key={6} value="ADMIN" data-test="admin-user-option">{t('UserDataForm.userTypes.ADMIN')}</Option>);
    }

    const usernameError = (isFieldTouched('username') || this.state.submitPressed) && getFieldError('username');
    const passwordError = (isFieldTouched('password') || this.state.submitPressed) && getFieldError('password');
    const emailAddressError = (isFieldTouched('emailAddress') || this.state.submitPressed) && getFieldError('emailAddress');
    return (
      <Form layout="horizontal" labelCol={{span: 4}} wrapperCol={{span: 6}} onSubmit={e => this.handleSubmit(e, update)}>
        <Form.Item validateStatus={!update && usernameError ? 'error' : ''} help={usernameError || ''} label={t('UserDataForm.username')} >
          {getFieldDecorator('username', update ? {initialValue: initialValues.username} : { // This is a workaround to what I believe is a bug in antd Forms
            rules: [{ required: true, message: t('UserDataForm.hints.usernameRequired')}]
          })(
            <Input disabled={update} onChange={(event) => {this.validateUserNameAsEmail(event.target.value)}} data-test={"username-input"}/>
          )}
        </Form.Item>
        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} label={t('UserDataForm.password')}>
          {getFieldDecorator('password', {
            rules: [
              {required: !update, message: t('UserDataForm.hints.passwordRequired')},
              {min: isAdmin ? 0 : 8, message: t('UserDataForm.hints.passwordLengthRequirement')},
            ]
          })(
            <Input.Password autoComplete="new-password" data-test="password-input"/>
          )}
        </Form.Item>
        <Form.Item validateStatus={emailAddressError ? 'error' : ''} help={emailAddressError || ''} label={t('UserDataForm.emailAddress')} >
          {getFieldDecorator('emailAddress', { // This is a workaround to what I believe is a bug in antd Forms
            initialValue: initialValues.emailAddress,
            rules: [
              { required: !isAdmin, message: t('UserDataForm.hints.emailRequired')},
              { pattern: EMAIL_VALIDATION_REGEX, message: t('UserDataForm.hints.validEmailAddress')}
            ]
          })(
            <Input autoComplete={"email"} data-test={"email-input"}/>
          )}
        </Form.Item>
        <Form.Item  label={t('UserDataForm.displayName')}>
          {getFieldDecorator('displayName', {initialValue: initialValues.displayName})(
            <Input data-test="display-name-input"/>
          )}
        </Form.Item>
        <Form.Item label={t('UserDataForm.organization')}>
          {getFieldDecorator('organization', {initialValue: initialValues.organization})(
            <OrganizationSelector previousValue={initialValues.organization} onChange={value => this.setState({selectedOrg: value})} isAdmin={this.props.isAdmin}/>
          )}
        </Form.Item>
        <Form.Item label={t('UserDataForm.userType')}>
          {getFieldDecorator('userType', {initialValue: initialValues.userType})(
            <Select data-test="user-type-selector">
              {userTypes}
            </Select>
          )}
        </Form.Item>
        {this.renderAdminFields(initialValues)}
        <Form.Item labelCol={{}}>
          <Button type="primary" htmlType="submit" loading={this.state.loading} disabled={!update && Utils.formHasErrors(getFieldsError())} style={{margin: "4px"}} data-test="user-data-form-submit-button">
            {update ? t('UserDataForm.update') :t('UserDataForm.submit')}
          </Button>
          {this.conditionalCancel()}
        </Form.Item>
        {this.renderModals()}
      </Form>
    );
  }
}

export default withTranslation()(Form.create()(UserDataForm));