import React from 'react';
import { HashRouter as HshRtr}  from 'react-router-dom';
import App from './App';

const HashRouter = () => (
  <HshRtr>
    <App />
    {/* TODO <Route component={404}/> */}
  </HshRtr>
);

export default HashRouter;