export default class PhysicalTherapy {

  static calculatePTEstimate(analysisData) {
    return {
      "estimates": [],
      "complete": true
    };
  }

  /*
  * Calculates rep data for a single joint
  * @param {Object} analysisData   Data for all joints
  * @param {string} joint          Name of joint to be processed
  * @param {number} minPeriod      Minimum distance between two successive local mins or local maxes
  * @param {number} maxMinRatio    Maximum acceptable ratio of a local min to the global range
  * @param {number} minMaxRatio    Minimum acceptable ratio of a local max to the global range
  * @return {Object}               All relevant statistics regarding reps of the current joint
   */
  static getJointStats(analysisData, joint, minPeriod=15, maxMinRatio=1, minMaxRatio=0) {
    let data = analysisData.joints[joint].data.map((point) => {return point.y});
    let fps = analysisData.joints[joint].fps;

    let minVal = Math.min(...data.filter(val => val !== -1));
    let maxVal = Math.max(...data.filter(val => val !== -1));
    // maximum acceptable local min value
    let maxMin = minVal + maxMinRatio * (maxVal - minVal);
    // minimum acceptable local max value
    let minMax = minVal + minMaxRatio * (maxVal - minVal);

    // list of indices corresponding to local mins
    let minList = [];
    // list of indices corresponding to local maxes
    let maxList = [];
    // true if searching for next max, false if searching for next min
    let maxMode = false;
    for (let i = 0; i < data.length; ++i) {
      // skip bad data
      if (data[i] === -1) {
        continue;
      }
      // if first point in data series
      if (i === 0) {
        // local min if less than next point and sufficiently small
        if (data[i] < data[i+1] && data[i] <= maxMin) {
          minList.push(i);
          maxMode = true;
        }
        // local max if greater than next point and sufficiently large
        else if (data[i] > data[i+1] && data[i] >= minMax) {
          maxList.push(i);
          maxMode = false;
        }
      }
      // if last point in data series
      else if (i === data.length - 1) {
        // local min if less than previous point and sufficiently small
        if (data[i] < data[i-1] && data[i] <= maxMin) {
          minList.push(i);
          maxMode = true;
        }
        // local max if greater than previous point and sufficiently large
        else if (data[i] > data[i-1] && data[i] >= minMax) {
          maxList.push(i);
          maxMode = false;
        }
      }
      else {
        // potential local max
        if (data[i] > data[i-1] && data[i] >= data[i+1] && data[i] >= minMax) {
          // if another local max is found too soon
          if (maxList.length > 0 && i - maxList[maxList.length-1] < minPeriod) {
            // if potential local max is greater than most recent one, replace recent; if not, discard potential
            if (data[i] > data[maxList[maxList.length-1]] && !maxMode) {
              maxList.pop();
              maxList.push(i);
            }
          }
          else {
            // either replace or append new max
            if (!maxMode) {
              maxList.pop();
            }
            maxList.push(i);
            // start searching for next min
            maxMode = false;
          }
        }
        // potential local min
        else if (data[i] < data[i-1] && data[i] <= data[i+1] && data[i] <= maxMin) {
          if (minList.length > 0 && i - minList[minList.length-1] < minPeriod) {
            if (data[i] < data[minList[minList.length-1]] && maxMode) {
              minList.pop();
              minList.push(i);
            }
          }
          else {
            if (maxMode) {
              minList.pop();
            }
            minList.push(i);
            // start searching for next max
            maxMode = true;
          }
        }
      }
    }

    let reps = Math.max(minList.length, maxList.length) - 1;
    let duration = data.length / fps;    // in seconds
    let period = duration / reps;

    // get time between local mins or maxes, whichever set has the most
    let periods = [];
    if (minList.length > maxList.length) {
      for(let i=1; i<minList.length; ++i) {
        periods.push((minList[i] - minList[i-1]) / fps)
      }
    }
    else {
      for(let i=1; i<maxList.length; ++i) {
        periods.push((maxList[i] - maxList[i-1]) / fps)
      }
    }

    let min = Infinity;
    let max = -Infinity;
    let sum = 0;
    let num = 0;
    let avg;
    for(const i of maxList) {
      min = Math.min(min, data[i]);
      max = Math.max(max, data[i]);
      sum += data[i];
      num++;
    }
    if (num === 0) {
      min = -1;
      max = -1;
      avg = -1;
    }
    else {
      avg = sum / num;
    }

    return {
      minAngle: min,
      maxAngle: max,
      avgAngle: avg,
      reps: reps,
      period: period,
      periods: periods,
      duration: duration
    };
  }

  static generateAndDownloadPTReport(data, videoName, date, groupNames) {
    let report = "PT ASSESSMENT\n\n";
    let name = data.name ? data.name : "Unnamed Assessment";
    let group = "";
    let duration = data.initialData.Neck.duration;
    if (groupNames && groupNames.length > 0) {
      group = groupNames[0]
    }
    report += "GROUP:,,," + group + ",\n";
    report += "VIDEO NAME:,,," + videoName + ",\n";
    report += "ASSESSMENT NAME:,,," + name + ",\n";
    report += "ASSESSMENT DATE:,,," + date + ",\n";
    report += "VIDEO DURATION:,,," + duration + " seconds,\n";
    report += "\n\n";
    report += "JOINT,MINIMUM ANGLE,MAXIMUM ANGLE,AVERAGE ANGLE,REPS\n";

    for(const [joint, stats] of Object.entries(data.initialData)) {
      report += joint + "," + stats.minAngle.toFixed(2) + "," + stats.maxAngle.toFixed(2) + "," + stats.avgAngle.toFixed(2) + "," + stats.reps + "\n";
    }

    report += "\n\nREP PERIODS\n";
    for(const [joint, stats] of Object.entries(data.initialData)) {
      report += joint + "," + stats.periods.map(data => {return data.toFixed(2)}).join() + "\n";
    }

    return new Blob(["\ufeff" + report], { type: "text/csv" });
  }
}