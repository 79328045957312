import React from 'react';
import { Card, Steps, } from 'antd';
import { Redirect } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import UploadVideo from "./UploadVideo";
import TrimVideo from "./TrimVideo";
import { sessionDetails } from "../../utils";

const { Step } = Steps;

class NewVideoView extends React.Component {
  constructor(props) {
    super(props);
    sessionDetails.changeView("NewVideoView");
    this.minFrameRate = 30;
    this.state = {
      step: 0,
      title: "",
      file: null,
    };
  };

  onSelectFile = (file, localURL) => {
    this.setState((state) => {
      return {
        step: 1,
        video: {
          ...state.video,
          url: localURL,
          file: file,
          loading: true,
          duration: 0,
          trimStart: 0,
          trimEnd: 0,
      }};
    });
    return false;
  };

  onNext = () => {
    this.setState((state) => {
      return {step: state.step + 1}
    });
  };

  onBack = () => {
    this.setState((state) => {
      return {step: state.step - 1}
    });
  };

  // Go back to the initial video upload view
  onAllTheWayBack = () => {
    this.setState({
      step: 0
    });
  }


  render() {
    const { step } = this.state;
    const { t } = this.props;
    let content;
    switch (step) {
      case 0:
        content = <UploadVideo onSetVideo={this.onSelectFile}/>;
        break;
      case 1:
      case 2:
        content = <TrimVideo
            isStaging={sessionDetails.isStaging()}
            file={this.state.video.file}
            sourceURL={this.state.video.url}
            onFailedValidation={() => this.setState({step:0})}
            onNextFn={this.onNext}
            onBackFn={this.onBack}
            onAllTheWayBack={this.onAllTheWayBack}
            upload={this.state.step===2}
        />;
        break;
      default:
        content = <Redirect to="/videos"/>;
        break;  
    }
    return (
      <div className="NewVideo">
        <Card style={{width: '100%'}} bodyStyle={{padding: '0px', paddingTop: '12px'}}>
          <Steps labelPlacement='vertical' current={step} >
            <Step title={t('NewVideo.select')}/>
            <Step title={t('NewVideo.trim')}/>
            <Step title={t('NewVideo.review')}/>
          </Steps>
          {content}
        </Card>
      </div>
    );
  }
}

export default withTranslation()(NewVideoView);