const TABLEA =
  [
    [
        
      [[1,2], [2,2], [2,3], [3,3]],
      [[2,2], [2,2], [3,3], [3,3]],
      [[2,3], [3,3], [3,3], [4,4]]
    ],
    [
      [[2,3], [3,3], [3,4], [4,4]],
      [[3,3], [3,3], [3,4], [4,4]],
      [[3,4], [4,4], [4,4], [5,5]]
    ],
    [
      [[3,3], [4,4], [4,4], [5,5]],
      [[3,4], [4,4], [4,4], [5,5]],
      [[4,4], [4,4], [4,5], [5,5]]
    ],
    [
      [[4,4], [4,4], [4,5], [5,5]],
      [[4,4], [4,4], [4,5], [5,5]],
      [[4,4], [4,5], [5,5], [6,6]]
    ],
    [
      [[5,5], [5,5], [5,6], [6,7]],
      [[5,6], [6,6], [6,7], [7,7]],
      [[6,6], [6,7], [7,7], [7,8]]
    ],
    [
      [[7,7], [7,7], [7,8], [8,9]],
      [[8,8], [8,8], [8,9], [9,9]],
      [[9,9], [9,9], [9,9], [9,9]]
    ]
  ];
const TABLEB =
  [
    [[1,3], [2,3], [3,4], [5,5], [6,6], [7,7]],
    [[2,3], [2,3], [4,5], [5,5], [6,7], [7,7]],
    [[3,3], [3,4], [4,5], [5,6], [6,7], [7,7]],
    [[5,5], [5,6], [6,7], [7,7], [7,7], [8,8]],
    [[7,7], [7,7], [7,8], [8,8], [8,8], [8,8]],
    [[8,8], [8,8], [8,8], [8,9], [9,9], [9,9]]
  ];
const TABLEC =
  [
    [1,2,3,3,4,5,5],
    [2,2,3,4,4,5,5],
    [3,3,3,4,4,5,6],
    [3,3,3,4,5,6,6],
    [4,4,4,5,6,7,7],
    [4,4,5,6,6,7,7],
    [5,5,6,6,7,7,7],
    [5,5,6,7,7,7,7]
  ];

export default class Rula {
  static calculateRULA(data) {
    let rulaScores = {};
    rulaScores.upperArmCompositeScore = Math.max(1, data.upperArm + data.shoulderRaised + data.upperArmAbducted - data.armSupported);
    rulaScores.lowerArmCompositeScore = data.lowerArm + data.lowerArmOut;
    rulaScores.wristCompositeScore = data.wrist + data.wristSide;
    rulaScores.neckCompositeScore = data.neck + data.neckTwist + data.neckSideBending;
    rulaScores.backCompositeScore = data.back + data.backTwist + data.backSideBending;

    rulaScores.postureScoreA = TABLEA[rulaScores.upperArmCompositeScore - 1][rulaScores.lowerArmCompositeScore - 1][rulaScores.wristCompositeScore - 1][data.wristTwist - 1];
    rulaScores.postureScoreB = TABLEB[rulaScores.neckCompositeScore - 1][rulaScores.backCompositeScore - 1][data.legsSupported - 1];

    rulaScores.wristArmScore = Math.min(rulaScores.postureScoreA + data.muscleScore + data.loadScore, 7);
    rulaScores.neckBackLegScore = Math.min(rulaScores.postureScoreB + data.muscleScore + data.loadScore, 6);

    rulaScores.rulaScore = TABLEC[rulaScores.wristArmScore -1][rulaScores.neckBackLegScore - 1];

    return rulaScores;
  }

  static calculateRULAEstimate(analysisData) {
    let joints = analysisData.joints;
    let scores = [];
    // noinspection DuplicatedCode
    for (let i = 0; i < joints['Neck']['data'].length; ++i) {
      let neckScore = 0;
      let backScore = 0;
      let upperArmScore = 0;
      let lowerArmScore = 0;
      let neck = joints['Neck']['data'][i]['y'];
      let back = joints['Back Bending']['data'][i]['y'];
      let upperArm = Math.max(joints['Left Elbow']['data'][i]['y'], joints['Right Elbow']['data'][i]['y']);
      let lowerArm = Math.max(joints['Left Arm']['data'][i]['y'], joints['Right Arm']['data'][i]['y']);

      // noinspection JSUnresolvedVariable
      scores.push({'x': joints.Neck.data[i]['x'], 'y': 0});
      neck > 10 && neck < 20 ? neckScore += 1 : neckScore += 2;
      back < 20 ? backScore += 1 : back < 60 ? backScore += 2 : backScore += 3;
      lowerArm > 60 && lowerArm < 100 ? lowerArmScore += 1 : lowerArmScore += 2;
      upperArm > 20 && upperArm < 45 ? upperArmScore += 1 : upperArm < 90 ? upperArmScore += 2 : upperArmScore += 3;

      if (analysisData.headDirections && analysisData.headDirections[i] === "backward") {
        neckScore = 4;
      }

      // noinspection UnnecessaryLocalVariableJS
      let adjustedCompositeScore = 0.5 * (upperArmScore -1) + 0.25 * (lowerArmScore -1) + 0.75 * (neckScore -1) + 0.75 * (backScore -1) + 1;
      scores[i]['y'] = adjustedCompositeScore;
      
      if ([neck, back, upperArm, lowerArm].includes(-1)) {
        scores[i]['y'] = -1;
      }
    }
    return {
      "estimates": scores,
      "complete": true
    };
  }

  // noinspection DuplicatedCode
  /**
   * Get the csv RULA report as a blob
   *
   * @param scores {Object}
   * @param data {Object}
   * @param videoName {String}
   * @param date {String}
   * @param groupNames {[String]}
   * @returns {Blob}
   */
  static generateRULAReport(scores, data, videoName, date, groupNames) {
    let name = data.name ? data.name : "Unnamed Assessment";
    let group = "";
    if (groupNames && groupNames.length > 0) {
      group = groupNames[0]
    }

    let wristTwist1 = (data.wristTwist === 1) ? 1 : 0;
    let wristTwist2 = (data.wristTwist === 2) ? 2 : 0;
    let legsSupported1 = (data.legsSupported === 1) ? 1 : 0;
    let legsSupported2 = (data.legsSupported === 2) ? 2 : 0;
    let report = "";

    report += "RULA ASSESSMENT,,,,,,\n";
    report += ",,,,,,\n";
    report += "GROUP:,,,," + group + "\n";
    report += "VIDEO NAME:,,,," + videoName + ",\n";
    report += "ASSESSMENT NAME:,,,," + name + ",\n";
    report += "ASSESSMENT DATE:,,,," + date + ",\n";
    report += "RULA SCORE:,,,," + scores.rulaScore + ",\n\n";
    report += "ARM AND WRIST ANALYSIS,,,,,,\n";
    report += ",UPPER ARM POSITION,,,," + data.initialAngles.upperArm.toFixed(1) + "\n";
    report += ",,," + data.upperArm + ",Position\n";
    report += ",,," + data.shoulderRaised + ",Shoulder raised\n";
    report += ",,," + data.upperArmAbducted + ",Arm abducted\n";
    report += ",,," + data.armSupported + ",Arm supported or person leaning\n";
    report += ",,," + scores.upperArmCompositeScore + ",UPPER ARM SCORE\n";
    report += ",LOWER ARM POSITION,,,," + data.initialAngles.lowerArm.toFixed(1) + "\n";
    report += ",,," + data.lowerArm + ",Position\n";
    report += ",,," + data.lowerArmOut + ",Either arm working across midline or out to side of body\n";
    report += ",,," + scores.lowerArmCompositeScore + ",LOWER ARM SCORE\n";
    report += ",WRIST POSITION,,,,,\n";
    report += ",,," + data.wrist + ",Position\n";
    report += ",,," + data.wristSide + ",Wrist bent from midline\n";
    report += ",,," + scores.wristCompositeScore + ",WRIST SCORE\n";
    report += ",WRIST TWIST,,,,,\n";
    report += ",,," + wristTwist1 + ",Twisted at midrange\n";
    report += ",,," + wristTwist2 + ",Wrist at or near end of range\n";
    report += ",,," + data.wristTwist + ",WRIST TWIST SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.postureScoreA + ",TABLE A LOOKUP: ARM AND WRIST POSTURE SCORE\n";
    report += ",,," + data.muscleScore + ",MUSCLE SCORE\n";
    report += ",,," + data.loadScore + ",LOAD SCORE\n";
    report += ",,," + scores.wristArmScore + ",TABLE C ROW: ARM AND WRIST SCORE\n";
    report += ",,,,,,\n";
    report += "NECK TRUNK AND LEG ANALYSIS,,,,,,\n";
    report += ",NECK POSITION,,,," + data.initialAngles.neck.toFixed(1) + "\n";
    report += ",,," + data.neck + ",Position\n";
    report += ",,," + data.neckTwist + ",Neck twisted\n";
    report += ",,," + data.neckSideBending + ",Neck is side bending\n";
    report += ",,," + scores.neckCompositeScore + ",NECK SCORE\n";
    report += ",TRUNK POSITION,,,," + data.initialAngles.back.toFixed(1) + "\n";
    report += ",,," + data.back + ",Position\n";
    report += ",,," + data.backTwist + ",Trunk twisted\n";
    report += ",,," + data.backSideBending + ",Trunk is side bending\n";
    report += ",,," + scores.backCompositeScore + ",TRUNK SCORE\n";
    report += ",LEGS,,,,,\n";
    report += ",,," + legsSupported1 + ",Legs and feet supported\n";
    report += ",,," + legsSupported2 + ",Legs and feet not supported\n";
    report += ",,," + data.legsSupported + ",LEG SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.postureScoreB + ",TABLE B LOOKUP: NECK TRUNK AND LEG POSTURE SCORE\n";
    report += ",,," + data.muscleScore + ",MUSCLE SCORE\n";
    report += ",,," + data.loadScore + ",LOAD SCORE\n";
    report += ",,," + scores.neckBackLegScore + ",\"TABLE C COLUMN: NECK, TRUNK, LEG SCORE\"\n";
    report += ",,,,,,\n";
    report += ",,," + scores.wristArmScore + ",TABLE C ROW: ARM AND WRIST SCORE\n";
    report += ",,," + scores.neckBackLegScore + ",\"TABLE C COLUMN: NECK, TRUNK, LEG SCORE\"\n";
    report += ",,," + scores.rulaScore + ",FINAL RULA SCORE\n";

    return new Blob(["\ufeff" + report], { type: "text/csv" });
  }
}