/**
 * @file Antd Form Item component that reports changes and can be set by parent
 */

import React from 'react';
import { withTranslation } from "react-i18next";

import {Input, Tooltip, Icon} from 'antd';

class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.data.initVal){
      this.setState({
        value: this.props.data.initVal,
        initVal: this.props.data.initVal
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // This allows the form value to be updated through the initVal prop
    if (nextProps.data.initVal !== prevState.initVal) {
      return {
        value: nextProps.data.initVal,
        initVal: nextProps.data.initVal
      };
    } else {
      return null;
    }
  }

  onChange = (event) => {
    if (this.props.onChange){
      this.props.onChange(event.target.value, this.props.data.key);
    }
    this.setState({value: event.target.value});
  };

  render() {
    const { data, tooltip } = this.props;

    let formText;
    if (data.label) {
      if (tooltip) {
        formText = <span style={{marginRight: '12px'}}><Tooltip title={data.description}><Icon type="info-circle-o" data-test={data.key + "-tooltip"}/></Tooltip> {data.label}:</span>
      } else {
        formText = <span style={{marginRight: '12px'}}>{data.label}:</span>
      }
    } else {
      formText = null;
    }


    return(
        <div>
          {formText}
          <Input
              onChange={this.onChange}
              defaultValue={data.initVal ? data.initVal : null}
              disabled={this.props.disabled ? this.props.disabled : false}
              autoComplete={this.props.autoComplete ? this.props.autoComplete : "on"}
              size={this.props.size ? this.props.size : "large"}
              value={this.state.value}
              style={this.props.style}
              data-test={this.props.dataTest}
          />
        </div>
    );
  }
}

FormInput.defaultProps = {
  tooltip: false
}
export default withTranslation()(FormInput);