import {sessionDetails} from "./sessionDetails";
import {CordovaUtils} from "./cordovaUtils";
import * as constants from "./constants";
import {FileCache, Request} from "./index";
import i18next from "i18next";

class FileUtil {
    static prepareFilename(filename) {
        if (filename && filename.replace) {
            let replaceSpaces = filename.replace(/ /g, "_");
            return replaceSpaces.replace(/[^A-Za-z0-9.\-_]/g, "");
        }

        return filename;
    }

    // Save file in browser (not cordova)
    static saveFile(url, filename) {
        let a = document.createElement('a');
        a.download = filename;
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * Download a file to the device. On Android it will be saved to the external root directory.
     *
     * @param blob {Blob}
     * @param filename {String}
     * @returns {Promise}
     */
    static saveBlobToDevice(blob, filename) {
        if (sessionDetails.isCordova()) {
            if (window.cordova.file.externalRootDirectory) {
                return CordovaUtils.saveFile(blob, window.cordova.file.externalRootDirectory, constants.NATIVE_DOWNLOAD_FOLDER, filename).then(FileUtil.alertDownloadFinished);
            } else {
                console.error("This device does not support external file saving");
                return Promise.reject();
            }
        } else {
            let blobUrl = window.URL.createObjectURL(blob);
            FileUtil.saveFile(blobUrl, filename);
            window.URL.revokeObjectURL(blobUrl);
            return Promise.resolve();
        }
    }

    static alertDownloadFinished() {
        alert(i18next.t('AnalysisView.nativeDownload'));
    }

    /**
     * Download a video to the device.
     *
     * @param id
     * @param auth
     * @param requestParam
     * @param filename
     * @returns {Promise|Promise<*>}
     */
    static exportVideo(id, auth, requestParam, filename) {
        if (sessionDetails.isCordova()) {
            return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "videos", id + ".mp4", CordovaUtils.readFileEntryAsBlob)
                .then((blob) => {
                    if (blob) {
                        return FileUtil.saveBlobToDevice(blob, filename);
                    }
                });
        } else {
            let url = Request.getRemoteVideoURL(id, auth, requestParam);
            return Promise.resolve(FileUtil.saveFile(url, filename));
        }
    }

    static urlToBlob(url){
        if (url) {
            return fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    return blob;
                });
        } else {
            return null;
        }
    }
}

export default FileUtil;