import React from "react";
import { Button, Form, Icon, Input, Tooltip, Modal, Spin, Radio, Progress } from "antd";
import { withTranslation } from "react-i18next";
import { Persistence, Request, Desktop, Mobile, sessionDetails, uploadCache, firebaseClient } from "../../utils";
import { TrimSlider, TrimmedVideoPlayer, GroupSelector } from "../../components";
import { FormCheckbox } from '../../components/Forms/FormItems';

class TrimVideo extends React.Component {
    constructor(props) {
        super(props);
        this.playerRef = React.createRef();
        this.thumbnail = null;
        this.state = {
            loading: true,
            videoLoading: true,
            settings: {
                minFrameRate: 30,
                largerResLimit: 1920,
                smallerResLimit: 1080,
                minBitrate: 5000000
            },
            name: "",
            groups: null,
            playedSeconds: 0,
            trimStart: 0,
            trimEnd: 0,
            blurLevel: 0,
            blackBackground: 0,
            runOptions: [],
            duration: 0,
            uploading: false,
            deviceId: null,
            uploadProgress: 0,
            videoErrorModalVisible: false,
            browserCompatibilityModalVisible: false,
            videoSizeModalVisible: false,
            videoSizeMessage: props.t('TrimVideo.videoSizeRestriction'),
            uploadErrorModalVisible: false,
            notificationPromptModalVisible: false,
            notificationDisabledModalVisible: false,
            errorStatus: null,
            errorMessage: props.t('Error.unknownError'),
        }
    }

    onChangeTrimStart = (value) => {
        this.playerRef.current.pause();
        this.playerRef.current.seekTo(value, "seconds");
        this.setState({trimStart: value});
    };

    onChangeTrimEnd = (value) => {
        this.playerRef.current.pause();
        this.playerRef.current.seekTo(value, "seconds");
        if (value === this.state.duration) {
            this.setState({trimEnd: 0});
        } else {
            this.setState({trimEnd: value});
        }
    };

    onDurationChange = (d) => {
        this.setState({
            duration: d,
            trimEnd: d
        });
    };

    onProgressChange = (p) => {
        this.setState({playedSeconds: p, videoLoading: false});
    };

    onTitleChange = (e) => {
        e.preventDefault();
        this.setState({name: e.target.value});
    };

    onTrimSliderAfterChange = () => {
        this.playerRef.current.play();
    };

    onVideoNotLoaded = () => {
        this.setState({videoErrorModalVisible: true, videoLoading: false});
    };

    onVideoLoaded = () => {
        this.setState({videoLoading: false});
    };

    onOptionChange = (key, value) => {
        let newRunOptions = this.state.runOptions;
        if (value && !this.state.runOptions.includes(key)) {
            newRunOptions.push(key);
        } else {
            newRunOptions = newRunOptions.filter(function(e) { return e !== key })
        }
        this.setState({runOptions: newRunOptions});
    };

    onGroupsChange = (groups) => {
        this.setState({groups: groups});
    }

    updateThumbnail = (video, width, height) => {
        let canvas = document.createElement('canvas');
        canvas.width = 480;
        canvas.height = 270;
        let ctx = canvas.getContext('2d');

        let aspectRatio = 16/9;
        let thumbnailHeight = height;
        let thumbnailWidth = width;
        let xOffset = 0;
        let yOffset = 0;

        // Crop thumbnail
        if (width/height <= aspectRatio) {
            thumbnailHeight = width/aspectRatio;
            yOffset = (height - thumbnailHeight)/2;
        } else {
            thumbnailWidth = height * aspectRatio;
            xOffset = (width - thumbnailWidth) /2
        }

        if(!video.mozCaptureStream) {
            ctx.drawImage(video, xOffset, yOffset, thumbnailWidth, thumbnailHeight, 0, 0, canvas.width, canvas.height);

            // Convert thumbnail to correct data type
            let url = canvas.toDataURL('image/jpeg');
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    this.thumbnail = new File([blob], "thumbnail.jpeg", {type: "image/png"});
                });
        }
    };

    // noinspection JSUnusedLocalSymbols
    validateVideo = (framerate, width, height, duration, loaded) => {
        if (!loaded) {
            this.setState({browserCompatibilityModalVisible: true});
        }
    };

    // Limit videos to 300MB
    validateUploadSize = () => {
        return (this.props.file.size <= 300_000_000);
    }

    // Limit videos to 2 minutes
    validateUploadLength = () => {
        return (this.state.trimEnd - this.state.trimStart <= 120);
    }

    prepareUpload = (token) => {
        const { t } = this.props;
        if (!this.validateUploadSize()) {
            this.setState({
                videoSizeMessage: t('TrimVideo.videoSizeRestriction'),
                videoSizeModalVisible: true
            });
            return;
        }

        if (!this.validateUploadLength()) {
            this.setState({
                videoSizeMessage: t('TrimVideo.videoLengthRestriction'),
                videoSizeModalVisible: true
            });
            return;
        }

        if (token) { // We have a notification token
            this.uploadVideo(token);
            return;
        }

        // Notification preferences need to be figured out
        if (Persistence.isNotificationStatusKnown()) {
            // We know whether the user wants notifications or not
            if (Persistence.areNotificationsAllowed()) {
                // The user DOES want notifications; get the token and upload.
                firebaseClient.getToken().then(token => this.uploadVideo(token)).catch(() => {
                    // If getToken errors out, something has gone wrong and we don't know their status anymore.
                    Persistence.setNotificationStatusUnknown();
                    this.uploadVideo();
                });
            } else {
                this.uploadVideo();
            }
        } else {
            // We don't know if they want notifications or not, so we'll ask them.
            this.setState({notificationPromptModalVisible: true});
        }
    }

    uploadVideo = (notificationDeviceId=null) => {
        if (this.state.runOptions.includes("blockSubject") ||
            this.state.runOptions.includes("blackBackground") ||
            this.state.blurLevel > 0) {
                this.thumbnail = null;
        }

        this.setState({uploading: true});
        Request.uploadVideo(
                this.state.name,
                this.props.file,
                this.thumbnail,
                this.props.form.getFieldValue("devEnv"),
                this.state.runOptions,
                this.state.trimStart,
                (this.state.trimEnd === this.state.duration) ? 0 : this.state.trimEnd,
                this.state.blurLevel,
                notificationDeviceId,
                this.state.groups,
                (progress) => {this.setState({uploadProgress: progress})})
            .then(this.handleUploadSuccess, this.handleUploadFailure);
    };

    handleUploadSuccess = (response) => {
        Request.checkCredentialedResponse(response);
        this.setState({uploading: false});
        this.props.onNextFn();
    };

    handleUploadFailure = (res) => {
        const { t } = this.props;
        this.setState({uploading: false});
        let status = (res && res.status) ? res.status : null;
        if (res && res.data) {
            if (res.data.context && res.data.context.errors) { // Failed with specific error message from master server
                if (res.data.context.errors[0].includes("User")) {
                    this.showProcessingError(res.data.context.errors[0], status);
                } else {
                    this.showProcessingError(t('Error.unknownError'), status);
                }
            } if (res.data.error) { // Failed with a basic endpoint error
                this.showProcessingError(t('Error.unknownError'), status);
            }
        } else {  //Failed without response from Master Server
            if (sessionDetails.isCordova()) {
                this.stageOfflineUpload().then(() => {
                    this.props.onNextFn();
                });
            } else {
                this.showProcessingError(t('Errors.unknownError'), null);
            }
        }
    }

    showProcessingError = (error, status) => {
        this.setState({
            uploadErrorModalVisible: true,
            errorMessage: error,
            errorStatus: status
        });
    }

    stageOfflineUpload = () => {
        let stagedUploadData = {
            name: this.state.name,
            devEnv: this.props.form.getFieldValue("devEnv"),
            runOptions: this.state.runOptions,
            trimStart: this.state.trimStart,
            trimEnd: (this.state.trimEnd === this.state.duration) ? 0 : this.state.trimEnd,
            groups: this.state.groups,
        };
        return uploadCache.addAnalysis(this.props.file, this.thumbnail, stagedUploadData);
    };

    handleNotificationAcceptance = () => {
        const gotTokenFn = (token) => {
            Persistence.setNotificationsAllowed();
            this.prepareUpload(token);
        };

        const notificationDeclinedFn = () => {
            Persistence.setNotificationsDeclined();
            this.setState({
                notificationDisabledModalVisible: true,
                notificationPromptModalVisible: false
            });
        };

        firebaseClient.requestPermission().then((permissionGranted) => {
            if (permissionGranted) {
                // We get the token here to force android devices to ask for permission if necessary.
                firebaseClient.getToken().then(token => {
                    if (token) {
                        gotTokenFn(token);
                    } else {
                        notificationDeclinedFn();
                    }
                }).catch(notificationDeclinedFn);
            } else {
                notificationDeclinedFn();
            }
        });
    }

    renderModals = () => {
        const { t } = this.props;
        const { errorMessage, uploadErrorModalVisible, browserCompatibilityModalVisible, videoErrorModalVisible,
            videoSizeModalVisible,notificationPromptModalVisible, notificationDisabledModalVisible } = this.state;
        let errorStatus = this.state.errorStatus ? " (" + this.state.errorStatus + ")" : "";
        return(
            <span>
                <Modal
                    closable={false}
                    title={t('TrimVideo.uploadFailed') + errorStatus}
                    visible={uploadErrorModalVisible}
                    footer={[
                        <Button type={'primary'} key="ok" onClick={() => this.setState({uploadErrorModalVisible: false})}>
                            { t('TrimVideo.okButton') }
                        </Button>
                    ]}
                >
                    {errorMessage}
                </Modal>
                <Modal
                    closable={false}
                    visible={browserCompatibilityModalVisible}
                    footer={[
                        <Button type={'primary'} key="ok" onClick={() => this.setState({browserCompatibilityModalVisible: false})} data-test="browser-compatibility-modal-ok-button">
                            { t('TrimVideo.okButton') }
                        </Button>
                    ]}
                >
                    {t('TrimVideo.browserCompatibility')}
                </Modal>
                <Modal
                    closable={false}
                    visible={videoSizeModalVisible}
                    footer={[
                        <Button type={'primary'} key="ok" data-test="video-size-modal-ok-button" onClick={() => {
                            if (this.state.videoSizeMessage === t('TrimVideo.videoSizeRestriction')) {
                                // Bring the user back to the start if the video is too big
                                this.setState({videoSizeModalVisible: false}, this.props.onAllTheWayBack)
                            } else {
                                // Bring the user back one step so they can properly trim
                                this.setState({videoSizeModalVisible: false}, this.props.onBackFn)
                            }
                        }}>
                            { t('TrimVideo.okButton') }
                        </Button>
                    ]}
                >
                    {this.state.videoSizeMessage}
                </Modal>
                <Modal
                    closable={false}
                    visible={videoErrorModalVisible}
                    footer={[
                        <Button type={'primary'} key="ok" onClick={() => this.setState({videoErrorModalVisible: false})} data-test="video-error-modal-ok-button">
                            { t('TrimVideo.okButton') }
                        </Button>
                    ]}
                >
                    {t('TrimVideo.badVideo')}
                </Modal>
                <Modal
                    closable={false}
                    visible={notificationPromptModalVisible}
                    okText={t('TrimVideo.yesButton')}
                    cancelText={t('TrimVideo.noButton')}
                    onOk={() => {
                        this.setState({notificationPromptModalVisible: false}, () => this.handleNotificationAcceptance())
                    }}
                    onCancel={() => {
                        Persistence.setNotificationsSoftDeclined();
                        this.setState({notificationPromptModalVisible: false}, () => this.prepareUpload())
                    }}
                >
                    {t('TrimVideo.notificationPrompt')}
                </Modal>
                <Modal
                    closable={false}
                    visible={notificationDisabledModalVisible}
                    footer={[
                        <Button type={'primary'} key="ok" onClick={() => {
                            this.setState({notificationDisabledModalVisible: false}, () => this.prepareUpload())
                        }}>
                            { t('TrimVideo.okButton') }
                        </Button>
                    ]}
                >
                    {t('TrimVideo.notificationsDisabled')}
                </Modal>
            </span>
        );
    }

    renderBackButton = () => {
        const { t, onBackFn } = this.props;
        return (
            <Button icon="left" onClick={onBackFn} data-test="back-button">
                {t('TrimVideo.backButton')}
            </Button>
        );
    };

    renderNextButton = () => {
        const { t, upload } = this.props;
        if (upload) {
            return (
                <Button loading={this.state.uploading} icon="upload" type="primary" onClick={() => this.prepareUpload()} style={{right: '0px', float: "right"}} data-test="upload-button">{t('TrimVideo.uploadButton')}</Button>);
        } else if (this.state.name) {
            return this.renderContinueButton();
        } else {
            return (
                <Tooltip title={t('TrimVideo.submitHint')}>
                    {this.renderContinueButton()}
                </Tooltip>
            );
        }
    };

    renderContinueButton = () => {
        const { t, onNextFn } = this.props;
        return (
            <Button
                type="primary"
                disabled={!this.state.name}
                onClick={onNextFn}
                style={{right: '0px', float: "right"}}
                data-test="continue-button"
            >{t('TrimVideo.postTrimButton')}<Icon type="right" /> </Button>
        );
    };

    renderProgressBar = () => {
        if (this.state.uploading) {
            return(<Progress percent={this.state.uploadProgress} status="active" />);
        } else {
            return null;
        }
    }

    renderDevEnv = () => {
        if (sessionDetails.isStaging() && Persistence.isAdmin()) {
            const { t } = this.props;
            const { getFieldDecorator } = this.props.form;
            return (
                <Form.Item label={t('TrimVideo.devEnv')} >
                    {getFieldDecorator('devEnv', {initialValue: "staging"})(
                        <Input size="large" disabled={this.props.upload}/>
                    )}
                </Form.Item>
            );
        } else {
            return null;
        }
    };

    renderVideoOptions = () => {
        const runOptions = [];
        const validOptions = ["blockSubject"];
        const { t, upload } = this.props;
        for (let i = 0; i < validOptions.length; i++) {
            let data = {
                key: validOptions[i],
                label: t("TrimVideo.runOptions." + validOptions[i]),
                initval: false
            };
            runOptions.push(<FormCheckbox disabled={upload} data={data} key={validOptions[i]} onChange={this.onOptionChange} dataTest={validOptions[i] + "-checkbox"}/>)
        }
        return (
            <div style={{width: '100%', margin: 'auto'}}>
                <Form style={{width: '100%', margin: 'auto'}}>
                    <Form.Item label={t("TrimVideo.runOptions.group")} layout="horizontal">
                        <GroupSelector
                          onChange={this.onGroupsChange}
                          disabled={upload}
                        />
                    </Form.Item>
                    <Form.Item label={t("TrimVideo.runOptions.runOptions")} layout="horizontal">
                        {runOptions}
                    </Form.Item>
                    <Form.Item label={t("TrimVideo.runOptions.blurLevel")} layout="horizontal">
                        <Radio.Group defaultValue={0} disabled={upload} onChange={(e) => {
                            // Treat the black background as a separate flag/option from blurLevel
                            if (e.target.value === 4) { // Black background
                                this.onOptionChange("blackBackground", 1);
                                this.setState({blurLevel: 0});
                            } else { // Blur Level change
                                this.onOptionChange("blackBackground", 0);
                                this.setState({blurLevel: e.target.value});
                            }
                        }}>
                            <Radio value={0} key={0} data-test="no-blur-option">{t("TrimVideo.runOptions.noBlur")}</Radio>
                            <Radio value={1} key={1} data-test="low-blur-option">{t("TrimVideo.runOptions.lowBlur")}</Radio>
                            <Radio value={2} key={2} data-test="medium-blur-option">{t("TrimVideo.runOptions.mediumBlur")}</Radio>
                            <Radio value={3} key={3} data-test="high-blur-option">{t("TrimVideo.runOptions.highBlur")}</Radio>
                            <Radio value={4} key={4} data-test="high-blur-option">{t("TrimVideo.runOptions.blackBackground")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {this.renderDevEnv()}
                </Form>
            </div>
        );
    };

    render() {
        const { t, upload } = this.props;
        return (
            <div style={{height: '100%', margin: '2vw'}}>
                <div style={{width: '100%', margin: 'auto', alignItems: 'center'}}>
                    <Desktop>
                        <Input
                            maxLength={255}
                            size="large"
                            prefix={<Icon type="tag" style={{color: '#00A79D'}}/>}
                            placeholder={ t('TrimVideo.videoTitle') }
                            disabled={upload}
                            value={this.state.name}
                            style={{fontWeight: 'bold'}}
                            onPressEnter={() => document.activeElement.blur()}
                            onChange={e => this.onTitleChange(e) }
                            addonBefore={ this.renderBackButton() }
                            addonAfter={ this.renderNextButton() }
                            data-test="video-upload-name"
                        />
                    </Desktop>
                    <Mobile>
                        {this.renderBackButton()}
                        {this.renderNextButton()}
                        <Input
                            size="large"
                            prefix={<Icon type="tag" style={{color: '#00A79D'}}/>}
                            placeholder={ t('TrimVideo.videoTitle') }
                            disabled={upload}
                            value={this.state.name}
                            style={{fontWeight: 'bold', marginTop: '6px'}}
                            onPressEnter={() => document.activeElement.blur()}
                            onChange={e => this.onTitleChange(e) }
                            data-test="video-upload-name"
                        />
                    </Mobile>
                </div>
                {this.renderProgressBar()}
                <Spin spinning={this.state.videoLoading}
                    size="large" 
                    style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '2vh'}}>
                </Spin>
                <div style={{maxWidth: '700px', height: 'auto', margin: 'auto', paddingTop: '1vh'}}>
                    <TrimmedVideoPlayer
                        muted
                        trimStart={this.state.trimStart}
                        trimEnd={this.state.trimEnd}
                        url={this.props.sourceURL}
                        width="100%"
                        height="100%"
                        controls={false}
                        onDuration={this.onDurationChange}
                        onProgress={this.onProgressChange}
                        onMetadata={this.validateVideo}
                        onNoVideo={this.onVideoNotLoaded}
                        onVideo={this.onVideoLoaded}
                        updateThumbnail={this.updateThumbnail}
                        style={{maxHeight: "40vh"}}
                        ref={this.playerRef}
                    />
                </div>
                {this.renderModals()}
                <div style={{maxWidth: '700px', margin: 'auto', padding: '6px'}}>
                    <TrimSlider
                        disabled={upload}
                        duration={this.state.duration}
                        value={this.state.playedSeconds}
                        trimStart={this.state.trimStart}
                        trimEnd={this.state.trimEnd}
                        onChangeTrimStart={v => this.onChangeTrimStart(v)}
                        onChangeTrimEnd={v => this.onChangeTrimEnd(v)}
                        onAfterChange={() => this.onTrimSliderAfterChange()}
                    />
                </div>
                {this.renderVideoOptions()}
            </div>
        );
    }
}

export default withTranslation()(Form.create()(TrimVideo));