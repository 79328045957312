import React from 'react';
import { withTranslation } from "react-i18next";
import { Form, Button, Affix, Input, Divider, Icon } from 'antd';

import FormRadio from './FormItems/FormRadio';
import FormCheckbox  from './FormItems/FormCheckbox';
import { Utils, userInfo, CordovaUtils, FileUtil} from '../../utils';
import { Rula } from '../../utils/AssessmentCalculators'

class RULAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeFormValuesRULA()
  }

  // noinspection DuplicatedCode
  componentDidMount() {
    const { t } = this.props;
    this.props.form.validateFields();

    let optionList = [];
    if (this.props.addOptions && this.prepareAssessmentReport) {
      optionList = [
        {
          key: "downloadAssessment",
          onClick: () => {
            this.prepareAssessmentReport();
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadAssessment'),
          dataTest: "download-assessment-option"
        },
        {
          key: "shareAssessment",
          onClick: () => {
            this.prepareAssessmentReport(true);
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareAssessment'),
          dataTest: "share-assessment-option"
        }
      ];
    }
    this.props.addOptions(optionList);
  }

  initializeFormValuesRULA() {
    let { t, frame, analysisData } = this.props;
    let joints = analysisData.joints;
    let initialValues;
    if (this.props.existingAssessmentData) {
        initialValues = this.props.existingAssessmentData;
        initialValues.assessmentId = this.props.assessmentId;
    } else {
      let initialAngles = {
        upperArm: Math.max(joints['Left Arm']['data'][frame]['y'], joints['Right Arm'].data[frame]['y']),
        lowerArm: Math.max(joints['Left Elbow']['data'][frame]['y'], joints['Right Elbow'].data[frame]['y']),
        back: joints['Back Bending']['data'][frame]['y'],
        neck: joints['Neck']['data'][frame]['y'],
      };
      initialValues = {
        name: null,
        initialAngles: initialAngles,
        frameTimestamp: this.props.frameTimestamp,
        upperArm: this.getScoreFromAngle(initialAngles.upperArm, [20, 45, 90]) + 1,
        lowerArm: (initialAngles.lowerArm > 60 && initialAngles.lowerArm < 100) || initialAngles.lowerArm < 0
            ? 1 : 2,
        back: this.getScoreFromAngle(initialAngles.back, [0, 20, 60]) + 1,
        neck: analysisData.headDirections && analysisData.headDirections[frame] === "backward" && initialAngles.neck > 0
            ? 4 : this.getScoreFromAngle(initialAngles.neck, [10, 20]) + 1,
        wrist: 1,
        wristTwist: 1,
        legsSupported: 1,
        muscleScore: 0,
        loadScore: 0,
        shoulderRaised: 0,
        upperArmAbducted: 0,
        armSupported: 0,
        lowerArmOut: 0,
        wristSide: 0,
        neckTwist: 0,
        neckSideBending: 0,
        backTwist: 0,
        backSideBending: 0,
      };
    }

    let inputFields = {
      upperArm: {
        key: "upperArm",
        label: t('AssessmentForm.rula.upperArm.label'),
        initVal: initialValues["upperArm"] === -1 ? 1 : initialValues["upperArm"],
        calculatedValue: "(" + initialValues.initialAngles.upperArm.toFixed(1) + "º)",
        invalid: initialValues.initialAngles.upperArm === -1,
        image: <img width="300px" src={Utils.getResource('/images/UpperArmREBARULA.svg')} alt=""/>,
        options: [
          {label: t('AssessmentForm.rula.upperArm.option1'), score: 1, index: 1},
          {label: t('AssessmentForm.rula.upperArm.option2'), score: 2, index: 2},
          {label: t('AssessmentForm.rula.upperArm.option3'), score: 3, index: 3},
          {label: t('AssessmentForm.rula.upperArm.option4'), score: 4, index: 4},
        ],
      },
      shoulderRaised: {
        key: "shoulderRaised",
        label: t('AssessmentForm.rula.shoulderRaised'),
        initVal: initialValues["shoulderRaised"] === -1 ? false : initialValues["shoulderRaised"],
      },
      upperArmAbducted: {
        key: "upperArmAbducted",
        label: t('AssessmentForm.rula.upperArmAbducted'),
        initVal: initialValues["upperArmAbducted"] === -1 ? false : initialValues["upperArmAbducted"],
      },
      armSupported: {
        key: "armSupported",
        label: t('AssessmentForm.rula.armSupported'),
        initVal: initialValues["armSupported"] === 1,
      },
      lowerArm: {
        key: "lowerArm",
        label: t('AssessmentForm.rula.lowerArm.label'),
        initVal: initialValues["lowerArm"] === -1 ? 1 : initialValues["lowerArm"],
        calculatedValue: "(" + initialValues.initialAngles.lowerArm.toFixed(1) + "º)",
        invalid: initialValues.initialAngles.lowerArm === -1,
        image: <img width="160px" src={Utils.getResource('/images/LowerArmREBARULA.svg')} alt=""/>,
        options: [
          {label: t('AssessmentForm.rula.lowerArm.option1'), score: 1, index: 1},
          {label: t('AssessmentForm.rula.lowerArm.option2'), score: 2, index: 2},
        ],
      },
      lowerArmOut: {
        key: "lowerArmOut",
        label: t('AssessmentForm.rula.lowerArmOut'),
        initVal: initialValues["lowerArmOut"] === -1 ? false : initialValues["lowerArmOut"],
      },
      wrist: {
        key: "wrist",
        label: t('AssessmentForm.rula.wrist.label'),
        initVal: initialValues["wrist"],
        image: <img width="300px" src={Utils.getResource('/images/WristRULA.svg')} alt=""/>,
        options: [
          {label: t('AssessmentForm.rula.wrist.option1'), score: 1, index: 1},
          {label: t('AssessmentForm.rula.wrist.option2'), score: 2, index: 2},
          {label: t('AssessmentForm.rula.wrist.option3'), score: 3, index: 3},
        ],
      },
      wristSide: {
        key: "wristSide",
        label: t('AssessmentForm.rula.wristSide'),
        initVal: initialValues["wristSide"] === -1 ? false : initialValues["wristSide"],
      },
      wristTwist: {
        key: "wristTwist",
        label: t('AssessmentForm.rula.wristTwist.label'),
        initVal: initialValues["wristTwist"],
        options: [
          {label: t('AssessmentForm.rula.wristTwist.option1'), score: 1},
          {label: t('AssessmentForm.rula.wristTwist.option2'), score: 2},
        ],
      },
      neck: {
        key: "neck",
        label: t('AssessmentForm.rula.neck.label'),
        initVal: initialValues["neck"] === -1 ? 1 : initialValues["neck"],
        calculatedValue: "(" + initialValues.initialAngles.neck.toFixed(1) + "º)",
        invalid: initialValues.initialAngles.neck === -1,
        image: <img width="300px" src={Utils.getResource('/images/NeckRULA.svg')} alt=""/>,
        options: [
          {label: t('AssessmentForm.rula.neck.option1'), score: 1, index: 1},
          {label: t('AssessmentForm.rula.neck.option2'), score: 2, index: 2},
          {label: t('AssessmentForm.rula.neck.option3'), score: 3, index: 3},
          {label: t('AssessmentForm.rula.neck.option4'), score: 4, index: 4},
        ],
      },
      neckTwist: {
        key: "neckTwist",
        label: t('AssessmentForm.rula.neckTwist'),
        initVal: initialValues["neckTwist"] === -1 ? false : initialValues["neckTwist"],
      },
      neckSideBending: {
        key: "neckSideBending",
        label: t('AssessmentForm.rula.neckSideBending'),
        initVal: initialValues["neckSideBending"] === -1 ? false : initialValues["neckSideBending"],
      },
      back: {
        key: "back",
        label: t('AssessmentForm.rula.back.label'),
        initVal: initialValues["back"] === -1 ? 1 : initialValues["back"],
        calculatedValue: "(" + initialValues.initialAngles.back.toFixed(1) + "º)",
        invalid: initialValues.initialAngles.back === -1,
        image: <img width="300px" height="112px" src={Utils.getResource('/images/BackRULA.svg')} alt=""/>,
        options: [
          {label: t('AssessmentForm.rula.back.option1'), score: 1, index: 1},
          {label: t('AssessmentForm.rula.back.option2'), score: 2, index: 2},
          {label: t('AssessmentForm.rula.back.option3'), score: 3, index: 3},
          {label: t('AssessmentForm.rula.back.option4'), score: 4, index: 4},
        ],
      },
      backTwist: {
        key: "backTwist",
        label: t('AssessmentForm.rula.backTwist'),
        initVal: initialValues["backTwist"] === -1 ? false : initialValues["backTwist"],
      },
      backSideBending: {
        key: "backSideBending",
        label: t('AssessmentForm.rula.backSideBending'),
        initVal: initialValues["backSideBending"] === -1 ? false : initialValues["backSideBending"],
      },
      legsSupported: {
        key: "legsSupported",
        label: t('AssessmentForm.rula.legsSupported.label'),
        initVal: initialValues["legsSupported"],
        options: [
          {label: t('AssessmentForm.rula.legsSupported.option1'), score: 1},
          {label: t('AssessmentForm.rula.legsSupported.option2'), score: 2},
        ],
      },
      muscleScore: {
        key: "muscleScore",
        label: t('AssessmentForm.rula.muscleScore'),
        initVal: initialValues["muscleScore"],
      },
      loadScore: {
        key: "loadScore",
        label: t('AssessmentForm.rula.loadScore.label'),
        initVal: initialValues["loadScore"],
        options: userInfo.getMeasure() === "metric" ? [
          {label: t('AssessmentForm.rula.loadScore.option1Metric'), score: 0},
          {label: t('AssessmentForm.rula.loadScore.option2Metric'), score: 1},
          {label: t('AssessmentForm.rula.loadScore.option3Metric'), score: 2},
          {label: t('AssessmentForm.rula.loadScore.option4Metric'), score: 3},
        ] : [
          {label: t('AssessmentForm.rula.loadScore.option1'), score: 0},
          {label: t('AssessmentForm.rula.loadScore.option2'), score: 1},
          {label: t('AssessmentForm.rula.loadScore.option3'), score: 2},
          {label: t('AssessmentForm.rula.loadScore.option4'), score: 3},
        ],
      },
    };

    // calculatedScoreData contains all intermediate values required in the Assessment Report
    let calculatedScoreData = Rula.calculateRULA(initialValues);
    let score = {"rulaScore": calculatedScoreData.rulaScore};

    return ({
      assessmentState: initialValues,
      inputFields: inputFields,
      scoreData: calculatedScoreData,
      score: score,
      uploading: false,
    });
  }

/**
 * Calculate score from an angle
 * @param {number} angle - A numerical angle
 * @param {Array<int>} angleBuckets A list of increasing numbers representing angle ranges
 * @return {int} The index of the bucket that the angle belongs
 */
  getScoreFromAngle(angle, angleBuckets) {
    let bucketIdx = 0;
    for (;bucketIdx < angleBuckets.length; bucketIdx++) {
      if (angle < angleBuckets[bucketIdx]) {
        return bucketIdx;
      }
    }
    return bucketIdx;
  }

  handleChange = (key, value) => {
    let newState = this.state.assessmentState;
    newState[key] = value;
    let score = Rula.calculateRULA(newState).rulaScore;

    this.setState({
      assessmentState: newState,
      score: {"rulaScore": score}
    });
  };

  prepareAssessmentReport = (share=false) => {
    const { t, videoData, videoName, assessmentDate, groups } = this.props;
    // noinspection JSUnresolvedVariable
    let groupNames = Utils.getGroupNames(videoData.groupMembership, groups)
    let reportData = Rula.generateRULAReport(this.state.scoreData, this.state.assessmentState, videoName, t('Formats.calendarDateTime', {date: new Date(assessmentDate)}), groupNames);
    const assessmentName = (this.state.assessmentState && this.state.assessmentState.name) ? this.state.assessmentState.name : "Unsaved_Assessment";
    let filename = FileUtil.prepareFilename(this.props.videoName + "_" + assessmentName + "_RULA_Report.csv");

    if (share) {
      return CordovaUtils.shareBlob(reportData, filename);
    } else {
      return FileUtil.saveBlobToDevice(reportData, filename);
    }
  };

  renderFormSubmissionBar = () => {
    const { t, assessmentType } = this.props;
    // noinspection DuplicatedCode
    return(
      <Affix offsetBottom={-2}>
        <Input
          size="large"
          placeholder={ t('AssessmentForm.assessmentName') }
          onChange={e => this.handleChange("name", e.target.value)}
          defaultValue={this.state.assessmentState.name}
          style={{fontWeight: 'bold'}}
          data-test="assessment-name-input"
          addonBefore={ <h3 style={{margin: "0px"}}>{t('AssessmentForm.' + assessmentType + '.' + assessmentType)}: <span data-test="rula-score">{this.state.score.rulaScore}</span></h3> }
          addonAfter={ 
            <span>
              <Button
                  type="primary"
                  onClick={() => {
                    this.setState({uploading: true}, this.props.handleSubmit(this.state.assessmentState, this.state.score));
                  }}
                  disabled={!this.state.assessmentState.name || this.state.assessmentState.name.length <= 0}
                  loading={this.state.uploading}
                  htmlType="submit"
                  data-test="assessment-submit-button"
              >
                {t('AssessmentForm.save')}
              </Button>
              <Button type="default" onClick={this.props.onLeave} style={{marginLeft: '6px'}} data-test="assessment-cancel-button">
                {t('AssessmentForm.cancel')}
              </Button>
            </span> 
          }
          />
      </Affix>
    );
  };

  render() {
    const { t, form } = this.props;
    const { inputFields } = this.state;
    return (
      <Form colon={false} layout="horizontal" style={{marginTop: '16px'}}>
        <FormRadio data={inputFields.upperArm} onChange={this.handleChange} form={form} image={true} dataTest="upper-arm-radio"/>
        <Form.Item>
          <FormCheckbox data={inputFields.shoulderRaised} onChange={this.handleChange} dataTest="shoulder-raised-checkbox"/>
          <FormCheckbox data={inputFields.upperArmAbducted} onChange={this.handleChange} dataTest="upper-arm-abducted-checkbox"/>
          <FormCheckbox data={inputFields.armSupported} onChange={this.handleChange} dataTest="arm-supported-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.lowerArm} onChange={this.handleChange} form={form} dataTest="lower-arm-radio"/>
        <Form.Item>
          <div><img width="100px" src={Utils.getResource("/images/ArmMidlineRULA.svg")} alt="" /></div>
          <FormCheckbox data={inputFields.lowerArmOut} onChange={this.handleChange} dataTest="lower-arm-out-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.wrist} onChange={this.handleChange} form={form} dataTest="wrist-radio"/>
        <Form.Item>
          <div><img width="100px" src={Utils.getResource("/images/WristMidlineREBARULA.svg")} alt="" /></div>
          <FormCheckbox data={inputFields.wristSide} onChange={this.handleChange} dataTest="wrist-side-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.wristTwist} onChange={this.handleChange} form={form} dataTest="wrist-twist-radio"/>
        <FormRadio data={inputFields.neck} onChange={this.handleChange} form={form} dataTest="neck-radio"/>
        <Form.Item>
          <FormCheckbox data={inputFields.neckTwist} onChange={this.handleChange} dataTest="neck-twist-checkbox"/>
          <FormCheckbox data={inputFields.neckSideBending} onChange={this.handleChange} dataTest="neck-side-bending-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.back} onChange={this.handleChange} form={form} dataTest="back-radio"/>
        <Form.Item>
          <FormCheckbox data={inputFields.backTwist} onChange={this.handleChange} dataTest="back-twist-checkbox"/>
          <FormCheckbox data={inputFields.backSideBending} onChange={this.handleChange} dataTest="back-side-bending-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.legsSupported} onChange={this.handleChange} form={form} dataTest="legs-supported-radio"/>
        <Form.Item label={ <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}><b>{t('AssessmentForm.rula.muscleScoreLabel')}</b></Divider>}>
          <FormCheckbox data={inputFields.muscleScore} onChange={this.handleChange} dataTest="muscle-score-checkbox"/>
        </Form.Item>
        <FormRadio data={inputFields.loadScore} onChange={this.handleChange} form={form} dataTest="load-score-radio"/>
        <Form.Item style={{margin: '0px'}}>
          {this.renderFormSubmissionBar()}
        </Form.Item>
      </Form>
    );
  };
}

export default withTranslation()(Form.create()(RULAForm));