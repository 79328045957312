import React from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { withTranslation } from "react-i18next";
import { Persistence, Request, Utils, userInfo } from "../../utils";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginError: false,
            loginErrorMessage: props.t('Error.unknownError')
        }
    }

    renderErrorBanner = () => {
        if (this.state.loginError) {
            return (<Alert type="error" message={ this.state.loginErrorMessage } showIcon/>);
        } else {
            return null;
        }
    };

    renderInvalidSessionBanner = () => {
        const { t } = this.props;
        if (Persistence.getInvalidatedSession()) {
            return (<Alert type="error" message={t('LoginForm.invalidSession')} banner showIcon />);
        } else {
            return null;
        }
    };

    componentDidMount() {
        if (!Persistence.isLoggedIn()) {
            this.props.form.validateFields();
        }
    }

    handleSubmit = e => {
        const { t } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                Request.login(values.username, values.password).then(res => {
                    // noinspection JSUnresolvedVariable
                    if (res.validLogin) {
                        // noinspection JSUnresolvedVariable
                        Persistence.setLogin(values.username, res.sessionId, res.stylizedUsername, res.userType, res.hasAcceptedTOS);
                        this.props.onLogin();

                        return userInfo.downloadUserInfo();
                    } else {
                        let errorMessage = t('Error.unknownError');
                        if (res.context) {
                            errorMessage = Utils.getErrorMessageForCode(res.context.errorCode)
                        }
                        this.props.form.setFieldsValue( { password: "" });
                        this.setState( {
                            loginError: true ,
                            loginErrorMessage: errorMessage
                        });
                    }
                });
            }
        })
    };



    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form; // utility functions for the Form
        const { t } = this.props; //translation service

        let usernameError = isFieldTouched('username') && getFieldError('username');
        let passwordError = isFieldTouched('password') && getFieldError('password');

        return (
            <Form onSubmit={this.handleSubmit}>
                { this.renderInvalidSessionBanner() }
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: t('LoginForm.usernameHint') }],
                        initialValue: localStorage.getItem("username") || null
                    })(
                        <Input
                            data-test="login-username"
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25' }} />}
                            placeholder={ t('LoginForm.username') }
                            autoComplete="username"
                        />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} style={{marginBottom: '4px'}}>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: t('LoginForm.passwordHint') }],

                    })(
                        <Input
                            data-test="login-password"
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder={t('LoginForm.password')}
                            autoComplete="current-password"
                        />,
                    )}
                </Form.Item>

                <Button
                    type={"link"}
                    onClick={this.props.onClickPasswordReset}
                    style={{margin: "0px", padding: "0px"}}
                    data-test="forgot-password-link"
                >
                    {t('LoginForm.forgotPassword')}
                </Button>
                { this.renderErrorBanner() }
                <Form.Item>
                    <Button style={{width: "100%"}} type="primary" data-test="login-submit" htmlType="submit" className="login-form-button" disabled={Utils.formHasErrors(getFieldsError())}>
                        { t('LoginForm.login') }
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(Form.create( { name: 'vertical_login' })(LoginForm));
