const TABLEA =
  [
    [[1,2,3,4],[1,2,3,4],[3,3,5,6]],
    [[2,3,4,5],[3,4,5,6],[4,5,6,7]],
    [[2,4,5,6],[3,4,5,6],[5,6,7,8]],
    [[3,5,6,7],[5,6,7,8],[6,7,8,9]],
    [[4,6,7,8],[6,7,8,9],[7,8,9,9]]
  ];
const TABLEB =
  [
    [[1,2,2],[1,2,3]],
    [[1,2,3],[2,3,4]],
    [[3,4,5],[4,5,5]],
    [[4,5,5],[5,6,7]],
    [[6,7,8],[7,8,8]],
    [[7,8,8],[8,9,9]]
  ];
const TABLEC =
  [
    [1,1,1,2,3,3,4,5,6,7,7,7],
    [1,2,2,3,4,4,5,6,6,7,7,8],
    [2,3,3,3,4,5,6,7,7,8,8,8],
    [3,4,4,4,5,6,7,8,8,9,9,9],
    [4,4,4,5,6,7,8,8,9,9,9,9],
    [6,6,6,7,8,8,9,9,10,10,10,10],
    [7,7,7,8,9,9,9,10,10,11,11,11],
    [8,8,8,9,10,10,10,10,10,11,11,11],
    [9,9,9,10,10,10,11,11,11,12,12,12],
    [10,10,10,11,11,11,11,12,12,12,12,12],
    [11,11,11,11,12,12,12,12,12,12,12,12],
    [12,12,12,12,12,12,12,12,12,12,12,12]
  ];


export default class Reba {
  static calculateREBA(data) {
    let rebaScores = {};
    rebaScores.upperArmCompositeScore = Math.max(1, data.upperArm + data.shoulderRaised + data.upperArmAbducted - data.armSupported);
    rebaScores.lowerArmCompositeScore = data.lowerArm;
    rebaScores.wristCompositeScore = data.wrist + data.wristSide;
    rebaScores.neckCompositeScore = data.neck + data.neckTwist;
    rebaScores.backCompositeScore = data.back + data.backTwist + data.backSideBending;
    rebaScores.legCompositeScore = data.legs + data.legsSupported
    rebaScores.activityCompositeScore = data.activityScore1 + data.activityScore2 + data.activityScore3;

    rebaScores.postureScoreA = TABLEA[rebaScores.backCompositeScore - 1][rebaScores.neckCompositeScore - 1][rebaScores.legCompositeScore - 1];
    rebaScores.postureScoreB = TABLEB[rebaScores.upperArmCompositeScore - 1][rebaScores.lowerArmCompositeScore - 1][rebaScores.wristCompositeScore - 1];

    rebaScores.scoreA = rebaScores.postureScoreA + data.loadScore + data.shock;
    rebaScores.scoreB = rebaScores.postureScoreB + data.couplingScore;

    rebaScores.scoreC = TABLEC[rebaScores.scoreA -1][rebaScores.scoreB - 1];

    rebaScores.rebaScore = rebaScores.scoreC + rebaScores.activityCompositeScore;

    return rebaScores;
  }

  static calculateREBAEstimate(analysisData) {
    let joints = analysisData.joints;
    let scores = [];
    // noinspection DuplicatedCode
    for (let i = 0; i < joints['Neck']['data'].length; ++i) {
      let neckScore = 0;
      let backScore = 0;
      let legScore = 0;
      let upperArmScore = 0;
      let lowerArmScore = 0;
      let neck = joints['Neck']['data'][i]['y'];
      let back = joints['Back Bending']['data'][i]['y'];
      let upperArm = Math.max(joints['Left Elbow']['data'][i]['y'], joints['Right Elbow']['data'][i]['y']);
      let lowerArm = Math.max(joints['Left Arm']['data'][i]['y'], joints['Right Arm']['data'][i]['y']);
      let knee = Math.max(joints['Left Knee']['data'][i]['y'], joints['Right Knee']['data'][i]['y']);

      // noinspection JSUnresolvedVariable
      scores.push({'x': joints.Neck.data[i]['x'], 'y': 0});
      neck > 10 && neck < 20 ? neckScore += 1 : neckScore += 2;
      back < 20 ? backScore += 1 : back < 60 ? backScore += 2 : backScore += 3;
      lowerArm > 60 && lowerArm < 100 ? lowerArmScore += 1 : lowerArmScore += 2;
      upperArm > 20 && upperArm < 45 ? upperArmScore += 1 : upperArm < 90 ? upperArmScore += 2 : upperArmScore += 3;
      knee < 30 ? legScore += 1 : knee < 60 ? legScore += 2 : legScore += 3;

      if (analysisData.headDirections && analysisData.headDirections[i] === "backward") {
        neckScore = 2;
      }

      // noinspection UnnecessaryLocalVariableJS
      let adjustedCompositeScore = 0.9 * (upperArmScore - 1) + 0.5 * (lowerArmScore - 1) + 0.45 * (neckScore - 1) + 0.9 * (backScore - 1) + 0.7 * (legScore - 1) + 0.5;
      scores[i]['y'] = adjustedCompositeScore;

      if ([neck, back, upperArm, lowerArm].includes(-1)) {
        scores[i]['y'] = -1;
      }
    }
    return {
      "estimates": scores,
      "complete": true
    };
  }

  // noinspection DuplicatedCode
  static generateAndDownloadREBAReport(scores, data, videoName, date, groupNames) {
    let name = data.name ? data.name : "Unnamed Assessment";
    let group = "";
    if (groupNames && groupNames.length > 0) {
      group = groupNames[0]
    }

    let legsSupported1 = (data.legsSupported === 1) ? 1 : 0;
    let legsSupported2 = (data.legsSupported === 2) ? 2 : 0;
    let legs1 = (data.legs === 1) ? 1 : 0;
    let legs2 = (data.legs === 2) ? 2 : 0;
    let loadCompositeScore = data.loadScore + data.shock;
    let report = "";

    report += "REBA ASSESSMENT,,,,,,\n";
    report += ",,,,,,\n";
    report += "GROUP:,,,," + group + "\n";
    report += "VIDEO NAME:,,,," + videoName + ",\n";
    report += "ASSESSMENT NAME:,,,," + name + ",\n";
    report += "ASSESSMENT DATE:,,,," + date + ",\n";
    report += "REBA SCORE:,,,," + scores.rebaScore + ",\n\n";
    report += "NECK TRUNK AND LEG ANALYSIS,,,,,,\n";
    report += ",NECK POSITION,,,," + data.initialAngles.neck.toFixed(1) + "\n";
    report += ",,," + data.neck + ",Position\n";
    report += ",,," + data.neckTwist + ",Neck twisted or side bending\n";
    report += ",,," + scores.neckCompositeScore + ",NECK SCORE\n";
    report += ",TRUNK POSITION,,,," + data.initialAngles.back.toFixed(1) + "\n";
    report += ",,," + data.back + ",Position\n";
    report += ",,," + data.backTwist + ",Trunk twisted\n";
    report += ",,," + data.backSideBending + ",Trunk is side bending\n";
    report += ",,," + scores.backCompositeScore + ",TRUNK SCORE\n";
    report += ",LEGS,,,,,\n";
    report += ",,," + legsSupported1 + ",Legs and feet supported\n";
    report += ",,," + legsSupported2 + ",Legs and feet not supported\n";
    report += ",,," + legs1 + ",30º - 60º\n";
    report += ",,," + legs2 + ",>60º\n";
    report += ",,," + scores.legCompositeScore + ",LEG SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.postureScoreA + ",TABLE A LOOKUP: NECK TRUNK AND LEG POSTURE SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + data.loadScore + ",LOAD\n";
    report += ",,," + data.shock + ",Shock or rapid buildup of force\n";
    report += ",,," + loadCompositeScore + ",LOAD SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.scoreA + ",TABLE C ROW: NECK TRUNK LEG SCORE\n";
    report += ",,,,,,\n";
    report += "ARM AND WRIST ANALYSIS,,,,,,\n";
    report += ",UPPER ARM POSITION,,,," + data.initialAngles.upperArm.toFixed(1) + "\n";
    report += ",,," + data.upperArm + ",Position\n";
    report += ",,," + data.shoulderRaised + ",Shoulder raised\n";
    report += ",,," + data.upperArmAbducted + ",Arm abducted\n";
    report += ",,," + data.armSupported + ",Arm supported or person leaning\n";
    report += ",,," + scores.upperArmCompositeScore + ",UPPER ARM SCORE\n";
    report += ",LOWER ARM POSITION,,,," + data.initialAngles.lowerArm.toFixed(1) + "\n";
    report += ",,," + data.lowerArm + ",Position\n";
    report += ",,," + scores.lowerArmCompositeScore + ",LOWER ARM SCORE\n";
    report += ",WRIST POSITION,,,,,\n";
    report += ",,," + data.wrist + ",Position\n";
    report += ",,," + data.wristSide + ",Wrist bent from midline\n";
    report += ",,," + scores.wristCompositeScore + ",WRIST SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.postureScoreB + ",TABLE B LOOKUP: ARM AND WRIST POSTURE SCORE\n";
    report += ",,," + data.couplingScore + ",COUPLING\n";
    report += ",,," + scores.scoreB + ",TABLE C COLUMN: ARM AND WRIST SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.scoreC + ",TABLE C SCORE\n";
    report += ",,,,,,\n";
    report += "ACTIVITY,,,,,,\n";
    report += ",,," + data.activityScore1 + ",1 or more body parts held > 1 min (static)\n";
    report += ",,," + data.activityScore1 + ",Repeated small range actions (>4x/min)\n";
    report += ",,," + data.activityScore1 + ",Action causes rapid large range changes in postures or unstable base\n";
    report += ",,," + scores.activityCompositeScore + ",ACTIVITY SCORE\n";
    report += ",,,,,,\n";
    report += ",,," + scores.rebaScore + ",FINAL REBA SCORE\n";

    return new Blob(["\ufeff" + report], { type: "text/csv" });
  }
}