import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from "react-i18next";

import {CordovaUtils, FileUtil, sessionDetails, Utils} from '../../utils';
import { ComparisonGraph } from '../../components';
import html2canvas from 'html2canvas';

class MultiJointChart extends React.Component {
  constructor(props) {
    super(props);
    this.getChartData();
  }

  static captureAndExportStackedBarGraph = (videoData, share) => {
    const comparisonArea = document.getElementById("joint-chart");
    const yLabel = document.getElementsByClassName("vx-axis-label")[2];
    const xAxis = document.getElementsByClassName("vx-line vx-axis-line")[5];
    const firstLegendBox = document.getElementsByClassName("vx-legend-shape")[3];
    const legendLabelArray = document.getElementsByClassName("vx-legend-label");
    const lastLegendLabel = legendLabelArray[legendLabelArray.length - 1];

    if (yLabel === null || xAxis === null || firstLegendBox === null || legendLabelArray === null) {
      return;
    }

    const cropDims = Utils.determineCropDims(comparisonArea, yLabel, xAxis, firstLegendBox, lastLegendLabel, sessionDetails.isMobile());

    window.scrollTo(0,0);
    html2canvas(comparisonArea, {scrollY: -window.scrollY, x: cropDims['cropStart'], width: cropDims['canvasWidth']}).then(canvas => {
      const dataURL = canvas.toDataURL();
      fetch(dataURL)
      .then(res => res.blob())
      .then(blob => {
        let filename = FileUtil.prepareFilename(videoData.name + "_Stacked_Bar_Graph.png");
        if (share) {
          return CordovaUtils.shareBlob(blob, filename);
        } else {
          return FileUtil.saveBlobToDevice(blob, filename);
        }
      });
    });
  }

  getChartData = () => {
    const { t } = this.props;
    let joints = Object.keys(this.props.data.joints)
    let combinedJoint = {"name": t("MultiJointChart.fullBody"), "Safe": 0};
    let data = []
    for (let joint of joints) {
      let finalJoint = {name: Utils.translateJoint(joint)};
      const jointData = this.props.data.joints[joint]
      let nonSafeCount = 0;
      // Translate data points and thresholds into percentages
      let thresholdRanges = (jointData.thresholdConfig) ? jointData.thresholdConfig.ranges :
          Utils.getRangesFromLegacyThresholds(jointData.thresholds);
      thresholdRanges["none"] = [-1,0];

      Object.keys(thresholdRanges).forEach((range) => {
        const [lowerBound, upperBound] = thresholdRanges[range];
        const rangePercentage = jointData.angles.filter((angle) => angle < upperBound && angle >= lowerBound).length / jointData.angles.length * 100;
        nonSafeCount += rangePercentage;

        if (finalJoint[Utils.translateThreshold(range)]) {
          finalJoint[Utils.translateThreshold(range)] += rangePercentage
        } else {
          finalJoint[Utils.translateThreshold(range)] = rangePercentage;
        }

        if (combinedJoint[Utils.translateThreshold(range)]) {
          combinedJoint[Utils.translateThreshold(range)] += rangePercentage
        } else {
          combinedJoint[Utils.translateThreshold(range)] = rangePercentage
        }
      });
      finalJoint[Utils.translateThreshold('safe')] = 100 - nonSafeCount;
      combinedJoint[Utils.translateThreshold('safe')] += 100 - nonSafeCount
      data.push(finalJoint);
    }

    // Average joints out for full body column
    for (let threshold in combinedJoint) {
      if (combinedJoint.hasOwnProperty(threshold) && threshold !== "name") {
        combinedJoint[threshold] /= data.length;
      }
    }
    data.unshift(combinedJoint)

    let keys = [];
    let colors = [];

    if (this.props.data.colors) {
      Object.entries(this.props.data.colors).forEach((entry) => {
        const [key, color] = entry;
        if (!keys.includes(Utils.translateThreshold(key === 'default' ? 'safe' : key))) { // dont push duplicates
          keys.push(Utils.translateThreshold(key === 'default' ? 'safe' : key));
          // Convert rgb components from array to css rgb string
          colors.push(`rgb(${color[2]}, ${color[1]}, ${color[0]})`);
        }
      });
      keys.push("No Data");
      colors.push("rgb(255, 255, 255)");
    } else { // Use a default for Legacy color format
      keys = ["Hazardous", "Cautious", "Safe"]
      colors = ["rgb(255, 0, 0)", "rgb(255, 255, 0)", "rgb(0, 255, 0)"];
    }

    this.chartData = data;
    this.keys = keys;
    this.colors = colors;
  }

  render() {
    const { t } = this.props;
    return (
      <ComparisonGraph
          data={this.chartData}
          keys={this.keys}
          keyColors={this.colors}
          axisLabel={t('MultiJointChart.axisLabel')}
      />
    );
  }
}

// hoistStatics allows us to access static functions of the class
export default hoistStatics(withTranslation()(MultiJointChart), MultiJointChart);
