import React from "react";
import { Form, Divider } from "antd";

import { Request } from "../../utils";
import { withTranslation } from "react-i18next";
import { UserTable, OrganizationSelector } from "..";
import { OrganizationDataForm } from "../Forms";

class OrgLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrg: null,
      userList: null
    }
  }

  componentDidMount() {
    if (this.props.initialOrg) {
      this.onSelectOrg(this.props.initialOrg);
    }
  }

  handleCancel = () => {
    this.setState({
      selectedOrg: null,
      userList: null
    });
  };

  conditionalUserList = () => {
    if (this.state.userList) {
      return (<UserTable dataSource={this.state.userList} onClickUser={this.props.onClickUser} hideOrgData={true} key={this.state.userList}/>);
    } else {
      return null;
    }
  };

  getUserList = () => {
    if (this.state.selectedOrg && this.state.selectedOrg.orgName) {
      if (this.props.isAdmin) {
        Request.listOrgUsersAdmin(this.state.selectedOrg.orgName).then(response => {
          this.setState({userList: response.users});
        });
      } else {
        Request.listOrgUsersOrgAdmin(this.state.selectedOrg.orgName).then(response => {
          this.setState({userList: response.users});
        });
      }
    }
  };

  conditionalOrganizationForm = () => {
    const { selectedOrg } = this.state;
    if (selectedOrg) {
      return (
        <div>
          <Divider style={{maxHeight: '20px', width: '100%', marginTop: '12px', marginBottom: '12px'}}/>
          <OrganizationDataForm
              dataSource={this.state.selectedOrg}
              getUsers={this.getUserList}
              onCancel={() => this.handleCancel()}
              onSubmitSuccess={() => alert("Organization Successfully Updated")}
              onSubmitFail={() => alert("Something Went Wrong!")}
              isAdmin={this.props.isAdmin}
          />
          {this.conditionalUserList()}
        </div>
      );
    } else {
        return null;
    }
  };

  updateOrg = (response) => {
    if (response) {
      this.setState({
        selectedOrg: {
          orgName: response.displayName,
          uses: response.uses,
          expiration: response.expiration,
          superOrg: response.superOrg,
        },
        userList: null,
      });
      if (!this.props.isAdmin) {
        this.getUserList();
      }
    }
  }

  onSelectOrg = (org) => {
    if (org) {
      this.setState({selectedOrg: null});
      if (this.props.isAdmin) {
        Request.findOrg(org).then((response) => {
          this.updateOrg(response);
        });
      } else {
        Request.findOrgOrgAdmin(org).then((response) => {
          this.updateOrg(response);
        });
      }
    }
  };

  render() {
    return (
      <span>
        <OrganizationSelector previousValue={null} onChange={value => this.onSelectOrg(value)} isAdmin={this.props.isAdmin}/>
        {this.conditionalOrganizationForm()}
      </span>
    );
  }
}

export default withTranslation()(Form.create()(OrgLookup));