import React from 'react';
import {Form, Input, Button, message} from 'antd';
import { withTranslation } from "react-i18next";
import {Persistence, Request, Utils} from '../../utils';

class NewPasswordForm extends React.Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    const { t, token } = this.props
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err);
      } else {
        Request.newPassword(values['newPassword'], token).then((response) => {
          if (Utils.responseIsSuccess(response)) {
            // Process response as a login request
            // noinspection JSUnresolvedVariable
            Persistence.setLogin(response.username, response.sessionId, response.stylizedUsername, response.userType, response.hasAcceptedTOS);
            message.success(t('NewPasswordForm.passwordReset'));
          } else if (Utils.responseHasErrors(response)) {
            message.error(response.context.errors, 10);
          } else {
            message.error(t('NewPasswordForm.passwordResetFailed'), 10);
          }
          this.props.onNewPassword();
        }, () => {
          message.error(t('Error.unknownError'), 10);
          this.props.onNewPassword();
        });
      }
    })
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { t, form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('ChangePasswordForm.matchPasswords'));
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form; // utility functions for the Form
    const { t } = this.props;

    let newPasswordError = isFieldTouched('newPassword') && getFieldError('newPassword');
    let confirmPasswordError = isFieldTouched('confirmPassword') && getFieldError('confirmPassword');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={newPasswordError ? 'error' : ''} help={newPasswordError || ''} style={{marginBottom: '6px'}}>
          {getFieldDecorator('newPassword', {
            rules: [
              { required: true, message: t('NewPasswordForm.newPasswordHint') },
              { min: 8, message: t('NewPasswordForm.passwordLengthRequirement') },
              { validator: this.validateToNextPassword }
            ],
          })(
            <Input.Password
                data-test="new-password-input"
                placeholder={ t('NewPasswordForm.newPassword') }
                autoComplete="new-password"
            />,
          )}
        </Form.Item>
        <Form.Item validateStatus={confirmPasswordError ? 'error' : ''} help={confirmPasswordError || ''} style={{marginBottom: '6px'}}>
          {getFieldDecorator('confirmPassword', {
            rules: [
              { required: true, message: t('NewPasswordForm.newPasswordConfirmationHint') },
              { min: 8, message: t('NewPasswordForm.passwordLengthRequirement') },
              { validator: this.compareToFirstPassword }
            ],
          })(
              <Input.Password
                  data-test="confirm-password-input"
                  placeholder={ t('NewPasswordForm.confirmPassword') }
                  autoComplete="new-password"
              />,
          )}
        </Form.Item>
        <Form.Item>
          <Button style={{width: "100%"}} type="primary" data-test="new-password-submit-button" htmlType="submit" disabled={Utils.formHasErrors(getFieldsError())}>
            { t('NewPasswordForm.changePassword') }
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(Form.create()(NewPasswordForm));
