import React from "react";
import { Tag } from "antd";
import { withTranslation } from "react-i18next";
import { Utils } from "../../utils";

class ColorScore extends React.Component {

  // Get an array of colors for scores
  getScoreColors = () => {
    const { assessmentType, score } = this.props;
    const colors = Utils.assessmentColors;
    switch (assessmentType) {
      case "rula":
        if (score.rulaScore <= 2){
          return [colors.NO_RISK];
        } else if (score.rulaScore <= 4) {
          return [colors.LOW_RISK];
        } else if (score.rulaScore <= 6) {
          return [colors.MEDIUM_RISK];
        } else {
          return [colors.VERY_HIGH_RISK];
        }
      case "reba":
        if (score.rebaScore <= 1){
          return [colors.NO_RISK];
        } else if (score.rebaScore <= 3) {
          return [colors.LOW_RISK];
        } else if (score.rebaScore <= 7) {
          return [colors.MEDIUM_RISK];
        } else if (score.rebaScore <= 10) {
          return [colors.HIGH_RISK];
        } else {
          return [colors.VERY_HIGH_RISK];
        }
      case "niosh":
        if (score.liftIndex < 1){
          return [colors.NO_RISK];
        } else if (score.liftIndex < 1.5) {
          return [colors.LOW_RISK];
        } else if (score.liftIndex < 2) {
          return [colors.MEDIUM_RISK];
        } else if (score.liftIndex < 3) {
          return [colors.HIGH_RISK];
        } else {
          return [colors.VERY_HIGH_RISK];
        }
      case "lm":
        if (score.hasOwnProperty("populationPercentageLMTables"))  { // lift/lower/carry
          return [
            this.getPopulationPercentageColor(score.populationPercentageLMTables),
            this.getPopulationPercentageColor(score.populationPercentageLMEquations)
          ];
        } else { // push/pull
          return [
            this.getPopulationPercentageColor(score.populationPercentageInitialForceLMTables),
            this.getPopulationPercentageColor(score.populationPercentageSustainedForceLMTables),
            this.getPopulationPercentageColor(score.populationPercentageInitialForceLMEquations),
            this.getPopulationPercentageColor(score.populationPercentageSustainedForceLMEquations)
          ];
        }
      default:
        return [colors.UNKNOWN];
    }
  }

  // Get color for a population percentage
  getPopulationPercentageColor = (populationPercentage) => {
    const colors = Utils.assessmentColors;
    if (populationPercentage >= 90) {
      return colors.NO_RISK;
    } else if (populationPercentage >= 75) {
      return colors.MEDIUM_RISK;
    } else {
      return colors.VERY_HIGH_RISK;
    }
  }

  // Return proper text colors to contrast score colors
  getTextColors(scoreColors) {
    const colors = Utils.assessmentColors;
    const colorsThatNeedWhiteText = [colors.HIGH_RISK, colors.VERY_HIGH_RISK];

    let textColors = [];
    for (let scoreColor of scoreColors){
      if (colorsThatNeedWhiteText.includes(scoreColor)) {
        textColors.push("#ffffff");
      } else {
        textColors.push("#000000");
      }
    }
    return textColors;
  }

  // Get an array of score texts
  getScoreTexts = () => {
    const { t, assessmentType, score } = this.props;
    switch (assessmentType) {
      case "rula":
        return [<span>{t('ColorScore.score')}: <b>{score.rulaScore}</b></span>];
      case "reba":
        return [<span>{t('ColorScore.score')}: <b>{score.rebaScore}</b></span>];
      case "niosh":
        return [
          <span>
            {t('AssessmentForm.niosh.liftIndexAbbrev')}: <b>{score.liftIndex} </b>
            {t('AssessmentForm.niosh.RecommendedWeightLimitAbbrev')}: <b>{score.recommendedWeightLimit}</b>
          </span>
        ];
      case "lm":
        if (score.hasOwnProperty("populationPercentageLMTables")) { // lift/lower/carry
          return [
            <span>{t('AssessmentForm.lm.populationPercentageLMTablesAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageLMTables)}% </b></span>,
            <span>{t('AssessmentForm.lm.populationPercentageLMEquationsAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageLMEquations)}%</b></span>
          ];
        } else { // push/pull
          return [
            <span>{t('AssessmentForm.lm.populationPercentageInitialForceLMTablesAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageInitialForceLMTables)}% </b></span>,
            <span>{t('AssessmentForm.lm.populationPercentageSustainedForceLMTablesAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageSustainedForceLMTables)}% </b></span>,
            <span>{t('AssessmentForm.lm.populationPercentageInitialForceLMEquationsAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageInitialForceLMEquations)}% </b></span>,
            <span>{t('AssessmentForm.lm.populationPercentageSustainedForceLMEquationsAbbrev')}: <b>{Utils.interpretPopulationPercentage(score.populationPercentageSustainedForceLMEquations)}%</b></span>
          ];
        }
      default:
        return null;
    }
  }


  render = () => {
    const scoreTexts = this.getScoreTexts();
    const scoreColors = this.getScoreColors();
    const textColors = this.getTextColors(scoreColors);

    let tags = [];
    if (scoreTexts) {
      for (let i = 0; i < scoreTexts.length; i++) {
        tags.push(
            <Tag color={scoreColors[i]} style={{color: textColors[i], fontSize: "14px", fontWeight: "500", marginBottom: "4px"}}>
              {scoreTexts[i]}
            </Tag>
        );
      }
    }

    return tags;
  }
}

export default withTranslation()(ColorScore);