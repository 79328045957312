import React from 'react';
import {Row, Col, Divider, Layout, Icon} from 'antd';

import { Logo } from '../../components';
import { LoginForm } from '../../components/Forms';

import { withTranslation } from "react-i18next";

const { Footer, Content } = Layout;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const loginFormSpans = {
      xs: 16,
      sm: 11,
      md: 8,
      lg: 6,
      xl: 5,
      xxl: 4,
    };
    const { t } = this.props;
    return (
      <div className="Login" style={{height: "100vh"}}>
        <Layout style={{height: "100%", backgroundColor: "#ffffff"}}>
          <Content>
            <Row type="flex" justify="center" align="middle" style={{height: '95vh'}}>
              <Col span={24} style={{maxWidth: '300px'}}>
                <Row type="flex" justify="center" align="middle" style={{height: "60px"}}>
                  <Col {...loginFormSpans} style={{display: "flex", width: '100%'}}>
                    <span style={{width: '100%', fontSize: '28pt', textAlign: 'center', fontWeight: 600, marginBottom: "10px", color: "#2B3990"}}>
                      { t('Login.userLogin') }
                    </span>
                  </Col>
                </Row>
                <Row type="flex" justify="center" align="middle" style={{height: "2px"}}>
                  <Col {...loginFormSpans} style={{width: '100%'}}>
                    <Divider style={{margin: 0, height: "2px"}}/>
                  </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                  <Col {...loginFormSpans} style={{display: "flex", alignItems: "center", flexDirection: "column", width: '100%'}}>
                    <Logo />
                  </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                  <Col {...loginFormSpans} style={{width: '100%'}}>
                    <LoginForm onLogin={ this.props.onLogin } onClickPasswordReset={this.props.onClickPasswordReset}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
          <Footer style={{backgroundColor: "#ffffff", textAlign: "center"}}>
            <span>
              <div>{t('AppLayout.copyright')}</div>
              <a href={"https://www.ehs.com/privacy"} target="_blank" style={{display: "inline-block", margin: "0px 12px 0px 0px"}} rel="noreferrer">
                <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.privacyPolicy')}
              </a>
              <a href={"https://legal.ehs.com/wp-content/uploads/VelocityEHS_Master_Subscription_Services_Agreement.pdf"} target="_blank" style={{display: "inline-block"}} rel="noreferrer">
                <Icon type="select" style={{marginRight: '4px'}}/>{t('AppLayout.termsAndConditions')}
              </a>
            </span>
          </Footer>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(Login);
