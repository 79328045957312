import React from 'react';
import { withTranslation } from "react-i18next";

import {Form, Radio, Divider, Tooltip, Icon} from 'antd';
import {sessionDetails} from "../../../utils";

class FormRadio extends React.Component {

  onChange = (e) => {
    const { data } = this.props;
    if (this.props.onChange){
      let score = data.options[e.target.value]['score'];
      this.props.onChange(this.props.data.key, score);
    }
  };

  getIndexForScore = (score) => {
    const { data } = this.props;
    for (let i = 0; i < data.options.length; i++) {
      if (score === data.options[i].score) {
        return i;
      }
    }
    return 0;
  }

  renderLabel = (label) => {
    const { data, t } = this.props;

    let invalidTooltip = null;
    if (data.invalid) {
      invalidTooltip =
        <Tooltip title={t('AssessmentForm.invalidAngle')} trigger={sessionDetails.isCordova() ? "click" : "hover"}>
          <Icon style={{color: "red", marginLeft: "6px"}} type="exclamation-circle" data-test={data.key + "-invalid-tooltip"}/>
        </Tooltip>
    }

    if (data.label) {
      return <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}>{label}{invalidTooltip}</Divider>
    } else {
      return null;
    }
  }

  render() {
    const { data, buttonStyle } = this.props;
    const { getFieldDecorator } = this.props.form;
    let options = [];
    for (let i = 0; i < data.options.length; i++) {
      let option = data.options[i];
      let optionLabel = option.label ? option.label : option.value;
      if (option.index) {
        optionLabel = <span><b style={{paddingRight: '10px'}}>{option.index}</b>({optionLabel})</span>;
      }
      if (buttonStyle) {
        options.push(<Radio.Button buttonStyle="solid" size="large" value={i} key={i} style={{maxWidth: '90vw', marginTop: '16px'}} data-test={this.props.dataTest + "-" + (i+1).toString()}>{optionLabel}</Radio.Button>);
      } else {
        options.push(<Radio buttonStyle="solid" value={i} key={i} style={{maxWidth: '90vw', marginTop: '16px'}} data-test={this.props.dataTest + "-" + (i+1).toString()}>{optionLabel}</Radio>);
      }
    }

    let label = data.calculatedValue ? <span><b>{data.label}</b>  {data.invalid ? "(N/A)" : data.calculatedValue}</span> : <b>{data.label}</b>;
    let initVal = 0;
    if (data.initIndex) {
      initVal = data.initIndex;
    } else if (data.initVal) {
      initVal = this.getIndexForScore(data.initVal)
    }

    return(
      <Form.Item
        style={{marginBottom: '10px'}}
      >
        {this.renderLabel(label)}
        <div style={this.props.data.image ? {marginTop: '8px'} : {}}>{this.props.data.image}</div>
        {getFieldDecorator(data.key, {size: "large", initialValue: initVal, buttonStyle: "solid"})(
          <Radio.Group buttonStyle="solid" onChange={this.onChange}>
            {options}
          </Radio.Group>

        )}
      </Form.Item>
    );
  }
}

export default withTranslation()(FormRadio);