import React from "react";
import {Button, Form, Input, Modal} from "antd";

import {Request, Utils} from "../../utils";
import { withTranslation } from "react-i18next";


class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      message: props.t('Error.unknownError')
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { t, form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('ChangePasswordForm.matchPasswords'));
    } else {
      callback();
    }
  };

  handleSubmit = (e) => {
    const { t, form } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({loading: true}, () => {
          Request.changePassword(values["password"], values["newPassword"]).then((response) => {
            this.setState({loading: false});
            if (Utils.responseIsSuccess(response)) {
              this.showMessage(t('ChangePasswordForm.passwordChanged'));
              form.resetFields();
            } else if (Utils.responseHasErrors(response)) {
              this.showMessage(response.context.errors);
            } else {
              this.showMessage(t('Error.unknownError'));
            }
          }, () => {
            this.setState({loading: false});
            this.showMessage(t('Error.unknownError'));
          });
        });
      }
    });
  };

  showMessage = (message) => {
    this.setState({
      modalVisible: true,
      message: message
    });
  }

  renderModals = () => {
    const { t } = this.props;
    const { message, modalVisible } = this.state;


    return (
        <span>
          <Modal
              closable={false}
              visible={modalVisible}
              footer={
                <Button type={'primary'} onClick={() => this.setState({modalVisible: false})} data-test="settings-modal-ok-button">
                  { t('TrimVideo.okButton') }
                </Button>
              }
          >
            <span data-test="settings-modal-text">{message}</span>
          </Modal>
        </span>);
  }

  render() {
    const {getFieldDecorator, form} = this.props.form;
    const {t} = this.props;

    return (
        <Form form={form} layout='horizontal'  labelCol={{span: 6}} wrapperCol={{span: 6}} style={{marginTop: '6px'}}
              onSubmit={e => this.handleSubmit(e)}>
          <Form.Item
              hasFeedback
              name="password"
              style={{marginBottom: '6px'}}
          >
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: t('ChangePasswordForm.enterCurrentPassword') },
              ],
            })(<Input.Password autoComplete="current-password" placeholder={t('ChangePasswordForm.currentPassword')} data-test="current-password-field" />)}
          </Form.Item>
          <Form.Item
              hasFeedback
              style={{marginBottom: '6px'}}
          >
            {getFieldDecorator('newPassword', {
              rules: [
                { required: true,  message: t('ChangePasswordForm.enterNewPassword')},
                { min: 8, message: t('ChangePasswordForm.passwordLengthRequirement') },
                { validator: this.validateToNextPassword},
              ],
            })(<Input.Password autoComplete="new-password" placeholder={t('ChangePasswordForm.newPassword')} data-test="new-password-field" />)}
          </Form.Item>
          <Form.Item
              hasFeedback
              name="confirmPassword"
              style={{marginBottom: '6px'}}
          >
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: t('ChangePasswordForm.enterConfirmPassword') },
                { min: 8, message: t('ChangePasswordForm.passwordLengthRequirement') },
                { validator: this.compareToFirstPassword },
              ],
            })(<Input.Password autoComplete="new-password" placeholder={t('ChangePasswordForm.confirmPassword')} data-test="confirm-password-field" />)}
          </Form.Item>
          <Form.Item>
            <Button type='primary' loading={this.state.loading} htmlType='submit' data-test="change-password-submit-button">{t('ChangePasswordForm.changePassword')}</Button>
          </Form.Item>
          {this.renderModals()}
        </Form>
    );
  }
}

export default withTranslation()(Form.create()(SettingsForm));