import Request from './request'
import { Utils } from './util';
import {FileCache, uploadCache} from './index';

class ThumbnailCache {
    constructor() {
        this.cache = {};
    }

    getAnalysisThumbnail(analysisId) {
        return this._get(analysisId, false);
    }

    getAssessmentThumbnail(analysisId, assessmentId) {
        return this._get(analysisId, false, assessmentId)
    }

    updateAndGetAnalysisThumbnail(analysisId) {
        return this._get(analysisId, true);
    }

    updateAndGetAssessmentThumbnail(analysisId, assessmentId) {
        return this._get(analysisId, true, assessmentId);
    }

    _get(analysisId, update, assessmentId=null) {
        let index = analysisId;
        if (assessmentId) {
            index = analysisId + '-' + assessmentId;
        }

        let entry = this.cache[index];
        if (entry) {
            if (!update) {
                return Promise.resolve(entry.url);
            }
        } else if (uploadCache.isCached(analysisId) || uploadCache.isCached(assessmentId)) {
            return FileCache.getThumbnailUrl(analysisId, "cachedUpload", assessmentId).then((response) => {
                if (response && response.responseData) {
                    entry = {url: response.responseData.thumbnail, filename: "cachedUpload"};
                    this.cache[index] = entry;
                    return Promise.resolve(response.responseData.thumbnail)
                }
            });
        } else {
            entry = {url: Utils.getResource("/images/thumbnail_not_available.png"), filename: ""};
        }

        return Request.getThumbnailUrl(analysisId, entry.filename, assessmentId).then(response => {
            if (response && response.responseData) {
                if (response.responseData.update === "yes") {
                    if (response.responseData.thumbnail) {
                        entry = {url: response.responseData.thumbnail, filename: response.responseData.filename};
                    }
                }
            } else {
                return Promise.reject();
            }
            this.cache[index] = entry;
            return Promise.resolve(entry.url);
        }, () => {
            return Promise.resolve(entry.url);
        });
    }
}

export let thumbnailCache = new ThumbnailCache();