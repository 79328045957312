import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import { Utils } from "./utils/";

const initI18next = () => {
    i18n.use(initReactI18next).use(Backend);

    let loadPath = "/locales/{{lng}}/{{ns}}.json";
    if (Utils.isAndroid()) {
        loadPath = "." + loadPath;
    }

    // Update moment when i18next language changes
    i18n.on('languageChanged', lng => moment.locale(lng));

    return i18n.init({
        fallbackLng: 'en',
        lng: 'en',
        debug: true,
        backend: {
            loadPath: loadPath
        },
        interpolation: {
            format: (value, format, lng) => {
                // Add ability to use moment to translate dates
                switch(format) {
                    case "longDate":
                        return moment(value).format("LLL");
                    case "shortDate":
                        return moment(value).format("ll");
                    case "shortDateTime":
                        return moment(value).format("lll");
                    case "calendarDate" :
                        return moment(value).format("MMM-DD YYYY");
                    case "sortableTimestamp":
                        return moment(value).format("YYYY-MM-DD HH:mm:ss");
                    default:
                        return value;
                }
            }
        }
    });
};

export default initI18next;