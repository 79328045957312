import React from 'react';
import { withTranslation } from "react-i18next";
import {Form, Button, Affix, Input, Icon, Divider} from 'antd';

import {CordovaUtils, Utils, FileUtil, sessionDetails} from '../../utils';
import { PT } from '../../utils/AssessmentCalculators';
import {ThresholdGraph} from "../ThresholdGraph";

class PTForm extends React.Component {
  constructor(props) {
    super(props);
    this.ptJoints = ["Neck", "Back Bending", "Left Arm", "Right Arm", "Left Elbow", "Right Elbow", "Left Knee", "Right Knee", "Left Hip", "Right Hip"];
    this.ptJoints = this.ptJoints.filter(joint => joint in this.props.analysisData.joints);
    this.graphThresholds = [{low: 0, high:180, color: "#000000"}]
    this.state = {uploading: false};
  }

  // noinspection DuplicatedCode
  componentDidMount() {
    let { t } = this.props;
    this.props.form.validateFields();

    let optionList = [];
    if (this.props.addOptions && this.prepareAssessmentReport) {
      optionList = [
        {
          key: "downloadAssessment",
          onClick: () => {
            this.prepareAssessmentReport();
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadAssessment')
        },
        {
          key: "shareAssessment",
          onClick: () => {
            this.prepareAssessmentReport(true);
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareAssessment')
        }
      ];
    }
    this.props.addOptions(optionList);
    this.setState(this.initializeFormValuesPT());
  }

  initializeFormValuesPT() {
    let initialValues;
    if (this.props.existingAssessmentData) {
      initialValues = this.props.existingAssessmentData;
      initialValues.assessmentId = this.props.assessmentId;
    }
    else {
      let initialData = {};
      this.ptJoints.forEach((joint) => {
        initialData[joint] = PT.getJointStats(this.props.analysisData, joint, 15, .35, .75);
      });

      initialValues = {
        name: null,
        initialData: initialData,
      };
    }
    return ({
      assessmentState: initialValues
    });
  }

  getTruncatedList = (array, maxLength) => {
    let arrayString = array.map((data) => {return data.toFixed(2)}).join(",\xa0\xa0");
    if (arrayString.length > maxLength)
      arrayString = arrayString.substr(0, maxLength-3).concat("...");
    return arrayString;
  }

  handleChange = (key, value) => {
    let newAssessmentState = this.state.assessmentState;
    newAssessmentState[key] = value;
    this.setState({
      assessmentState: newAssessmentState,
    });
  };

  prepareAssessmentReport = (share=false) => {
    const { t, videoName, videoData, assessmentDate, groups } = this.props;
    // noinspection JSUnresolvedVariable
    let groupNames = Utils.getGroupNames(videoData.groupMembership, groups)
    let reportData = PT.generateAndDownloadPTReport(this.state.assessmentState, videoName, t('Formats.calendarDateTime', {date: new Date(assessmentDate)}), groupNames);
    const assessmentName = (this.state.assessmentState && this.state.assessmentState.name) ? this.state.assessmentState.name : "Unsaved_Assessment";
    let filename = FileUtil.prepareFilename(this.props.videoName + "_" + assessmentName + "_PT_Report.csv");

    if (share) {
      return CordovaUtils.shareBlob(reportData, filename);
    } else {
      return FileUtil.saveBlobToDevice(reportData, filename);
    }
  };

  onSubmit = () => {
    let currentAssessmentData = this.state.assessmentState;
    let finalAssessmentData = {};

    finalAssessmentData.name = currentAssessmentData.name;
    finalAssessmentData.initialData = currentAssessmentData.initialData;

    if (currentAssessmentData.assessmentId) {
      finalAssessmentData.assessmentId = currentAssessmentData.assessmentId;
    }

    this.props.handleSubmit(finalAssessmentData, {});
  }

  // noinspection DuplicatedCode
  renderFormSubmissionBar = () => {
    const { t } = this.props;
    const { assessmentState } = this.state;

    return (
      <Affix offsetBottom={-2}>
        <span>
          <Input
              size="large"
              placeholder={ t('AssessmentForm.assessmentName') }
              onChange={e => this.handleChange("name", e.target.value)}
              defaultValue={assessmentState.name}
              style={{fontWeight: 'bold'}}
              data-test="assessment-name-input"
              addonBefore={null}
              addonAfter={
            <span>
              <Button
                  type="primary"
                  onClick={() => {
                    this.setState({uploading: true}, this.onSubmit());
                  }}
                  disabled={!assessmentState.name || assessmentState.name.length <= 0}
                  loading={this.state.uploading}
                  htmlType="submit"
                  data-test="assessment-submit-button"
              >
                {t('AssessmentForm.save')}
              </Button>
              <Button type="default" onClick={this.props.onLeave} style={{marginLeft: '6px'}} data-test="assessment-cancel-button">
                {t('AssessmentForm.cancel')}
              </Button>
            </span>
              }
          />
        </span>
      </Affix>
    );
  };

  renderJointGraph = (currentJointData) => {
    const { t } = this.props;
    const graphHeight = sessionDetails.isMobile() ? '200px' : '300px'
    const playedSeconds = 0;
    return (
      <div style={{height: graphHeight, marginBottom: "12px"}}>
        <ThresholdGraph
            data={currentJointData.data}
            thresholds={this.graphThresholds}
            xAccessor={d => d.x}
            yAccessor={d => d.y}
            playedTime={playedSeconds}
            onClick={null}
            onHover={null}
            onHoverEnd={null}
            renderTooltips={true}
            renderLegend={false}
            yTooltipUnits={"º"}
            yAxisLabel={t('AnalysisView.angleLabel')}
            yMinRange={0}
            hideSelectionLine={true}
            horizontalMode={false}
        />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { assessmentState } = this.state;
    if (assessmentState) {
      return (
        <Form colon={false} layout="horizontal" onSubmit={this.handleSubmit} style={{marginTop: '16px'}}>
          <div align={'center'}>
            {this.ptJoints.map((joint) => {
              let jointData = assessmentState.initialData[joint];
              return (
                  <div style={{textAlign: 'center', width: '600px', margin: '50px', color: '#000000'}}>
                    {this.renderJointGraph(this.props.analysisData.joints[joint])}
                    <Divider style={{maxHeight: '20px', margin: '0px'}}>{Utils.translateJoint(joint)}</Divider>
                    <div>{t('AssessmentForm.pt.reps')}: {jointData.reps.toFixed(0)} in {jointData.duration.toFixed(2)} seconds</div>
                    <span style={{margin: '10px'}}>{t('AssessmentForm.pt.min')}: {jointData.minAngle.toFixed(2)}°</span>
                    <span style={{margin: '10px'}}>{t('AssessmentForm.pt.max')}: {jointData.maxAngle.toFixed(2)}°</span>
                    <span style={{margin: '10px'}}>{t('AssessmentForm.pt.avg')}: {jointData.avgAngle.toFixed(2)}°</span>
                    <div>{t('AssessmentForm.pt.repIntervals')} (seconds): {this.getTruncatedList(jointData.periods, 60)}</div>
                  </div>
              );
            })}
          </div>
          <Form.Item style={{margin: '0px'}}>
            {this.renderFormSubmissionBar()}
          </Form.Item>
        </Form>
      );
    }
    else {
      return null;
    }
  };
}

export default withTranslation()(Form.create()(PTForm));