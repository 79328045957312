import FileCache from "./fileCache";
import { uploadCache } from "./uploadCache";

class CordovaUtils {

  static uploadVideo(onUploadFunc) {
    let options = {
      quality: 100,
      destinationType: navigator.camera.DestinationType.FILE_URI,
      sourceType: navigator.camera.PictureSourceType.SAVEDPHOTOALBUM,
      mediaType: navigator.camera.MediaType.VIDEO,
      allowEdit: true,
      correctOrientation: true  //Corrects Android orientation quirks
    };

    navigator.camera.getPicture((fileUrl) => {
      let url = fileUrl;
      if (!url.includes("://")) {
        // For some reason the android plugin doesn't include the file protocol in the URL it returns.
        url = "file://" + url;
      }
      CordovaUtils.getFileEntry(url).then((fileEntry) => {
        fileEntry.file((file) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            let blob = new Blob([reader.result], {type:"video/mp4"});
            onUploadFunc(blob, CordovaUtils.getFileUrlForDisplay(fileEntry));
          };
          reader.readAsArrayBuffer(file);
        }, (e) => {
          console.log(e);
        });
      }, (e) => console.log(e));
    }, (error) => {
      console.log("Unable to obtain picture: " + error, "app");
    }, options);
  }

  // noinspection JSValidateJSDoc
  /**
   * Save file to local filesystem
   *
   * @param {Blob} blob Data to be saved
   * @param location Path on local filesystem eg. window.cordova.file.dataDirectory
   * @param directory Subdirectory within location eg. "videos"
   * @param filename Name of the file eg. "video123.mp4"
   *
   * @returns {Promise<FileEntry>} Promise with the FileEntry of the file that was saved
   */
  static saveFile(blob, location, directory, filename) {
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(location, (dirEntry) => {
        dirEntry.getDirectory(directory, { create: true, exclusive: false }, (fsDirectory) => {
          fsDirectory.getFile(filename, { create: true, exclusive: false }, (fileEntry) => {
            fileEntry.createWriter((writer) => {
              writer.onwriteend = () => {
                resolve(fileEntry);
              };

              writer.onerror = () => {
                resolve(null);
              };

              writer.write(blob);
            }, (e) => { console.log(e); });
          },(e) => {console.log(e); });
        }, (e) => { console.log(e); });
      }, (e) => { console.log(e); });
    });
  }

  /**
   * Use this function to retrieve the local URL of a file that has been saved to the device. You can optionally specify
   * a new filename which will copy the file to a temporary location and return its URL.
   *
   * @param subDirectory The subdirectory the file is stored in
   * @param filename The name of the file to retrieve e.g. video123.mp4
   * @param newFilename Optional, a temporary new name for the file
   *
   * @returns {Promise<{FileEntry}>} A promise returning the FileEntry of the file to download, or null if the file does
   * not exist
   */
  static getLocalFile(subDirectory, filename, newFilename) {
    if (newFilename) {
      // Copy the file to a temp location with the new name
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), subDirectory, filename, CordovaUtils.readFileEntryAsBlob).then(blob => {
        if (blob) {
          return CordovaUtils.saveFile(blob, FileCache.getTempDirectory(), "tmp", newFilename);
        } else {
          return null;
        }
      });
    } else {
      let path = CordovaUtils.normalizeDirectory(FileCache.getCacheDirectory()) +
          CordovaUtils.normalizeDirectory(subDirectory) +
          filename;
      return CordovaUtils.getFileEntry(path);
    }
  }

  static getFileEntry(path) {
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(path, fileEntry => {
        return resolve(fileEntry);
      }, resolve);
    });
  }

  static shareVideo(id, auth, newFilename) {
    let filename = id + ".mp4";
    let newName;
    if (newFilename) {
      newName = newFilename;
    }
    let subdirectory = "videos";
    if (uploadCache.isCached(id)) {
      subdirectory = uploadCache.getSubdirectory();
    }

    return CordovaUtils.shareFile(subdirectory, filename, newName);
  }

  /**
   * Share a blob at the specified location
   *
   * @param blob The blob to share
   * @param filename The filename to share the blob as
   * @returns {Promise<Promise>}
   */
  static shareBlob(blob, filename) {
    return CordovaUtils.saveFile(blob, FileCache.getTempDirectory(), "tmp", filename).then(fileEntry => {
      return CordovaUtils.share(fileEntry.nativeURL);
    });
  }

  /**
   * Share a file stored at the specified location
   *
   * @param subdirectory Subdirectory the file is in e.g. "videos"
   * @param filename Name of the file as it is saved
   * @param newFilename Name the file should be downloaded as
   * @returns {Promise<{FileEntry}>}
   */
  static shareFile(subdirectory, filename, newFilename) {
    return CordovaUtils.getLocalFile(subdirectory, filename, newFilename)
        .then(fileEntry => {
          if (fileEntry) {
            return CordovaUtils.share(fileEntry.nativeURL);
          } else {
            console.error("File could not be retrieved", filename);
          }
        });
  }

  /**
   * Use device's native share capability to share the object at the url
   * @param url remote or local URL to share
   * @returns {Promise}
   */
  static share(url) {
    return new Promise((resolve, reject) => {
      window.plugins.socialsharing.shareWithOptions({files: [url]}, resolve, reject);
    });
  }

  /**
   * Turn a javascript object into a blob.
   * @param data A data structure that can be converted to a JSON string using JSON.stringify()
   * @returns {Blob}
   */
  static blobify(data) {
    return new Blob([JSON.stringify(data)], {type: "application/octet-stream"});
  }

  /**
   * Retrieve a file from the local filesystem
   * @param location Path on android filesystem eg. window.cordova.file.externalRootDirectory
   * @param directory Subdirectory within locaton eg. "videos"
   * @param filename Name of the file eg. "video123.mp4"
   * @param readFunc Function for reading the specific data we want to retrieve
   * @returns {Promise<any>} contains the contents of the retrieved file or null if it doesnt exist
  */
  static retrieveFile(location, directory, filename, readFunc) {
    let path = CordovaUtils.normalizeDirectory(location) +
        CordovaUtils.normalizeDirectory(directory) +
        filename;
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(path, (fileEntry) => { //On success
        if (readFunc) {
          readFunc(fileEntry).then((fileContents) => {
            resolve(fileContents);
          });
        } else {
          resolve(fileEntry);
        }
      }, () => { resolve(null); });
    });
  }

  /**
   * This function returns the native URL that can be loaded by the web view
   * @param fileEntry retrieved using resolveLocalFileSystemURL()
   * @returns {String} a local url pointing at the fileEntry
   */
  static getFileUrlForDisplay(fileEntry) {
    let url = fileEntry.nativeURL;
    if (window.WkWebView && window.WkWebView.convertFilePath) {
      return window.WkWebView.convertFilePath(url);
    }
    return url;
  }

  /**
   * Returns a directory name with a single slash at the end
   *
   * @param directory the directory or subdirectory to normalize
   * @returns {string|*}
   */
  static normalizeDirectory(directory) {
    if (directory && directory.endsWith) {
      return directory.endsWith("/") ? directory : directory + "/";
    }

    return "";
  }

  /**
   * Delete a file from the local filesystem
   * @param location Path on android filesystem eg. window.cordova.file.externalRootDirectory
   * @param directory Subdirectory within locaton eg. "videos"
   * @param filename Name of the file eg. "video123"
   * @param extension File extension eg. ".mp4"
  */
  static deleteFile(location, directory, filename, extension) {
    let path = CordovaUtils.normalizeDirectory(location) +
        CordovaUtils.normalizeDirectory(directory) +
        filename + extension;
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(path, (fileEntry) => {
        fileEntry.remove(() => { // The file has been removed successfully
          resolve({success: "true"});
        },() => {
          resolve({success: "false"});
        },() => {
          resolve(null);
        });
      });
    });
  }

  /**
   * Delete a folder from the local filesystem
   * @param location Path on android filesystem eg. window.cordova.file.externalRootDirectory
   * @param directory Subdirectory within locaton eg. "videos"
  */
 static deleteFolder(location, directory) {
  if (directory.length > 0) {
    let path = location + "/" + directory;
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(path, (dirEntry) => {
        dirEntry.removeRecursively(() => { // The directory has been removed successfully
          resolve({success: "true"});
        },() => {
          resolve({success: "false"});
        });
      });
    });
  } else {
    return Promise.resolve({success: "false"});
  }
}

  // Parse a dictionary from the contents of a fileEntry
  static readJSONFileEntry(fileEntry) {
    return new Promise((resolve) => {
      fileEntry.file((file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(JSON.parse(reader.result));
        };
        reader.readAsText(file);
      }, (e) => { console.log(e); });
    });
  }

  // Read the contents of a fileEntry into a file
  static readFileEntryAsBlob(fileEntry) {
    return new Promise((resolve) => {
      fileEntry.file((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type});
          resolve(blob);
        };
        reader.readAsArrayBuffer(file);
      }, (e) => {
        console.log(e);
      });
    });
  }
}

export { CordovaUtils };
