import React from 'react';
import { Utils, thumbnailCache } from '../../utils'

class Thumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: Utils.getResource("/images/thumbnail_not_available.png")
        };
        if (this.props.assessmentId) {
            thumbnailCache.getAssessmentThumbnail(this.props.analysisId, this.props.assessmentId).then(url => this.setState({url: url}));
        } else {
            thumbnailCache.getAnalysisThumbnail(this.props.analysisId).then(url => this.setState({url: url}));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.refreshDate !== this.props.refreshDate) {
            if (this.props.assessmentId) {
                thumbnailCache.updateAndGetAssessmentThumbnail(this.props.analysisId, this.props.assessmentId).then(url => this.setState({url: url}));
            } else {
                thumbnailCache.updateAndGetAnalysisThumbnail(this.props.analysisId).then(url => this.setState({url: url}));
            }
        }
    }

    render() {
        return (<img src={this.state.url} alt={""} style={this.props.style}/>);
    }
}

export default Thumbnail;