import React from 'react';
import {Card, Spin, Select, Result, Tabs, Button, Icon, Dropdown, Menu} from 'antd';
import queryString from 'query-string';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import {CordovaUtils, FileUtil, Request, sessionDetails, Utils} from "../../utils";
import { ComparisonGraph } from '../../components';
import html2canvas from "html2canvas";

class ComparisonView extends React.Component {

  constructor(props) {
    super(props);
    sessionDetails.changeView("ComparisonView");
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const ids = params.v ? typeof (params.v) === 'string' ? [params.v] : params.v : [];
    this.state = {
      ids, // list of video ids
      videos: {}, // id -> video object
      analyses: {}, // id -> analysis object
      selectedJoint: "All",
    };
  }

  componentDidMount() {
    const { ids } = this.state;
    ids.forEach((id) => {
      Request.getAnalysisJson(id)
        .then(json => {
          this.setState(({ analyses }) => {
            let newAnalyses = Object.assign({}, analyses);
            newAnalyses[id] = json;
            return { analyses: newAnalyses };
          })
        })
        .catch((e) => console.log(e));
      Request.getVideoData(id)
        .then(json => {
          this.setState(({ videos }) => {
            let newVideos = Object.assign({}, videos);
            newVideos[id] = json;
            return { videos: newVideos };
          })
        })
        .catch((e) => console.log(e));
    });
  }

  onJointSelect = (jointName) => {
    this.setState({
      selectedJoint: jointName,
    });
  }

  isLoaded = () => {
    const { ids, videos, analyses } = this.state;
    if (ids.length === 0) return false;
    const videoKeys = Object.keys(videos);
    const analysesKeys = Object.keys(analyses);
    // Only completely loaded if there is a video and analysis for every id
    return ids.every((id) => videoKeys.includes(id) && analysesKeys.includes(id))
  }

  getSelectedJoints = () => {
    const { selectedJoint } = this.state;
    if (!selectedJoint || selectedJoint === "All") {
      return this.getJointArray();
    }
    return [selectedJoint];
  }

  getValidIds = () => {
    const { ids, analyses, videos } = this.state;
    return ids.filter((id) => analyses.hasOwnProperty(id) && videos.hasOwnProperty(id))
  }

  getJointArray = () => {
    const { analyses } = this.state;
    return Array.from(this.getValidIds().flatMap((id) => analyses[id].processedData.joints.map((j) => j.name)) // Extract joint names
      .reduce((set, name) => set.add(name), new Set()));  // Consolidate into set
  }

  getGraphData = () => {
    const { analyses, videos } = this.state;
    const validIds = this.getValidIds();
    const joints = this.getJointArray();
    if (joints.length < 1) return null;
    const selectedJoints = this.getSelectedJoints();
    // Creates array of objects of type: 
    // {name: String, dangerous: Number, cautious: Number, safe: Number}
    try {
      const data = validIds.map((id) => {
        let videoData = []
        for (let joint of selectedJoints) {
          let d = {};
          const jointData = analyses[id].processedData.joints.find((jnt) => jnt.name === joint);
          let nonSafeCount = 0;
          let thresholdRanges = (jointData.thresholdConfig) ? jointData.thresholdConfig.ranges :
              Utils.getRangesFromLegacyThresholds(Utils.getDefaultThresholdData(jointData.thresholds));
          thresholdRanges["none"] = [-1,0];

          Object.keys(thresholdRanges).forEach((range) => {
            const [lowerBound, upperBound] = thresholdRanges[range];
            const rangePercentage = jointData.angles.filter((angle) => angle < upperBound && angle >= lowerBound).length / jointData.angles.length * 100;
            if (d[Utils.translateThreshold(range)]){
              d[Utils.translateThreshold(range)] += rangePercentage;
            }
            else {
              d[Utils.translateThreshold(range)] = rangePercentage;
            }
            nonSafeCount += rangePercentage;
          });
          d[Utils.translateThreshold('safe')] = 100 - nonSafeCount;
          videoData.push(d);
        }

        // Combine joints
        let final_data = { name: videos[id].name };
        for (let jointData of videoData) {
          for (let status of Object.keys(jointData)) {
            if (!final_data[status]) {
              final_data[status] = jointData[status];
            } else {
              final_data[status] += jointData[status];
            }
          }
        }
        for (let jointStatus in final_data) {
          if (jointStatus !== "name") {
            final_data[jointStatus] /= selectedJoints.length;
          }
        }
        return final_data
      });

      let keys = [];
      let colors = [];
      if (analyses[validIds[0]].processedData.colors) {
        Object.entries(analyses[validIds[0]].processedData.colors).forEach((entry) => {
          const [key, color] = entry;
          if (!keys.includes(Utils.translateThreshold(key === 'default' ? 'safe' : key))) { // dont push duplicates
            keys.push(Utils.translateThreshold(key === 'default' ? 'safe' : key));
            // Convert rgb components from array to css rgb string
            colors.push(`rgb(${color[2]}, ${color[1]}, ${color[0]})`);
          }
        });
        keys.push("No Data");
        colors.push("rgb(255, 255, 255)");
      } else { // Use a default for Legacy color format
        keys = ["Hazardous", "Cautious", "Safe"]
        colors = ["rgb(255, 0, 0)", "rgb(255, 255, 0)", "rgb(0, 255, 0)"];
      }

      return { keys, colors, data };
    } catch(e) {
      console.log(e)
      return null;
    }
  }

  captureAndExportComparison = (share) => {
    const { ids, videos, selectedJoint } = this.state;
    const { t } = this.props;

    // Gets Elements for entire div, y-label box, x-axis line,
    // first square in legend, and last label in legend
    const comparisonArea = document.getElementById("comparison-graph");
    const yLabel = document.getElementsByClassName("vx-axis-label")[0];
    const xAxis = document.getElementsByClassName("vx-line vx-axis-line")[1];
    const firstLegendBox = document.getElementsByClassName("vx-legend-shape")[0];
    const legendLabelArray = document.getElementsByClassName("vx-legend-label");
    const lastLegendLabel = legendLabelArray[legendLabelArray.length - 1];

    if (yLabel === null || xAxis === null || firstLegendBox === null || legendLabelArray === null) {
      return;
    }

    const cropDims = Utils.determineCropDims(comparisonArea, yLabel, xAxis, firstLegendBox, lastLegendLabel, sessionDetails.isMobile());

    window.scrollTo(0,0);

    html2canvas(comparisonArea, {scrollY: -window.scrollY, x: cropDims['cropStart'], width: cropDims['canvasWidth']}).then(canvas => {
      const dataURL = canvas.toDataURL();
      fetch(dataURL)
          .then(res => res.blob())
          .then(blob => {
            let filename = FileUtil.prepareFilename(videos[ids[0]]["name"] + "_ComparisonGraph_" + selectedJoint + "_" + t('Formats.sortableTimestamp', {date: new Date()}) + ".png");
            if (share) {
              return CordovaUtils.shareBlob(blob, filename);
            } else {
              return FileUtil.saveBlobToDevice(blob, filename);
            }
          });
    });
  }

  renderPageTitle = () => {
    const { t } = this.props;
    return (
      <span style={{ width: '100%', fontSize: '20pt', textAlign: 'center' }}>{t('Comparison.title')}</span>
    );
  }

  renderOptionsMenu = () => {
    const { t } = this.props;
    let options = [
      <Menu.Item key="downloadComparison" onClick={() => this.captureAndExportComparison(false)} style={{paddingTop: '8px', paddingBottom: '8px'}} data-test="download-comparison-option">
        <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>
        <span>{t("Comparison.downloadComparison")}</span>
      </Menu.Item>
    ];

    if (sessionDetails.isCordova()) {
      options.push(
          <Menu.Item key="shareComparison" onClick={() => this.captureAndExportComparison(true)} style={{paddingTop: '8px', paddingBottom: '8px'}} data-test="share-comparison-option">
            <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>
            <span>{t("Comparison.shareComparison")}</span>
          </Menu.Item>
      )
    }
    return (
        <Dropdown overlay={
          <Menu>
            {options}
          </Menu>} trigger={['click']}>
          <Button data-test="comparison-action-menu" type="secondary" style={{marginLeft: '4px'}}>
            <Icon type="menu-unfold" />{t('Comparison.actions')}
          </Button>
        </Dropdown>
    );
  };

  renderJointSelector = () => {
    const { selectedJoint } = this.state;
    const jointNames = Array.prototype.concat(["All"], this.getJointArray());
    if (sessionDetails.isMobile()) {
      const joints = jointNames.map((joint) => {
        return (
          <Select.Option key={joint} id={joint} data-test={joint + "-option"}>{Utils.translateJoint(joint)}</Select.Option>
        );
      });
      return (
        <Select
          defaultValue={joints[0].key}
          onChange={this.onJointSelect}
          size="large"
          style={{ margin: '5px', marginLeft: '0px', width: '150px' }}
          id="select"
        >
          {joints}
        </Select>
      );
    } else {
      const buttons = jointNames.map((joint) => {
        return <Tabs.TabPane key={joint} tab={Utils.translateJoint(joint)} id={joint} data-test={joint + "-option"} />
      });
      return (
        <div style={{ textAlign: 'center' }}>
          <Tabs
            onChange={this.onJointSelect}
            activeKey={selectedJoint}
            animated={false}
            size="large"
          >
            {buttons}
          </Tabs>
        </div>
      );
    }
  };

  renderGraphTitle = () => {
    const { t } = this.props;
    const { selectedJoint } = this.state;
    return (
      <React.Fragment>
        <span style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', fontSize: '16pt', marginTop: '20px' }}>
          {t('Comparison.graphTitle')}
        </span>
        <br />
        <span style={{ textAlign: 'center', width: '100%', fontSize: '12pt' }}>
          {Utils.translateJoint(selectedJoint)}
        </span>
      </React.Fragment>
    );
  }

  renderContent = () => {
    const graphData = this.getGraphData();
    if (!graphData) return null;
    const { data, keys, colors } = graphData;
    const { t } = this.props;
    return (
      <span style={{ width: "100%", textAlign: "center", display: 'inline-block' }}>
        <Card hoverable style={{ padding: 0 }} bodyStyle={{ padding: 0, width: '100%' }} bordered={false} >
          {this.renderJointSelector()}<br /><br />
          <div id="comparison-graph">
            {this.renderGraphTitle()}
            <div style={{ height: '350px'}}>
              <ComparisonGraph data={data} keys={keys} keyColors={colors} axisLabel={t('Comparison.axisLabel')} />
            </div>
          </div>
        </Card>
      </span>
    );
  }

  render() {
    const { t } = this.props;
    let content;
    if (!this.isLoaded()) {
      content = (<Spin style={{ margin: '20px' }} size="large" />);
    } else {
      content = this.renderContent();
      if (!content) {
        content = (
          <Result
            status="warning"
            title={t('Comparison.insufficientDataWarning')}
          />
        );
      }
    }
    return (
      <div className="comparison">
        <Card
          size='small'
          title={this.renderPageTitle()}
          style={{ padding: '0px', backgroundColor: 'white' }}
          bordered={false}
          bodyStyle={{ padding: '0px', margin: 'calc(6px + 1vw)' }}
        >
          <Link to={'/videos'}>
            <Button type="secondary" style={{ margin: '4px', marginLeft: '0px' }}>
              <Icon type="left" />{t('Comparison.videosButtonTitle')}
            </Button>
          </Link>
          {this.renderOptionsMenu()}
          {content}
        </Card>
      </div>
    );
  }
}

export default withTranslation()(ComparisonView);
