import React from 'react';
import {Button, Form, Icon, Input, Modal} from "antd";
import { withTranslation } from "react-i18next";
import { Request, sessionDetails } from "../../utils";
import DevEnvironmentTable from "./DevEnvironmentTable";

class DevEnvironmentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devEnvironments: null,
      loading: false,
      newDevEnvironmentModalVisible: false,
      newDevEnvironmentLoading: false,
    }
  }

  componentDidMount() {
    this.initializeDevEnvList();
  }

  initializeDevEnvList = () => {
    this.setState({loading: true});
    Request.listDevEnvironments().then(response => this.setState({
      devEnvironments: response.environments,
      loading: false
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    this.setState({newDevEnvironmentLoading: true});

    Request.createDevEnvironment(form.getFieldsValue()).then(() => {
      this.setState({newDevEnvironmentModalVisible: false, newDevEnvironmentLoading: false});
      this.initializeDevEnvList();
    });
  };

  renderNewDevEnvironmentModal = () => {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal onOk={this.handleSubmit} onCancel={() => this.setState({newDevEnvironmentModalVisible: false})} visible={this.state.newDevEnvironmentModalVisible} confirmLoading={this.state.newDevEnvironmentLoading}>
        <Form layout="horizontal" labelCol={{span: 4}} wrapperCol={{span: 6}}>
          <Form.Item label={t('InstanceTable.devEnv')}>
            {getFieldDecorator('targetEnv', {})(
              <Input/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Button disabled={!sessionDetails.isStaging()} onClick={() => this.setState({newDevEnvironmentModalVisible: true})} type="primary">{t('DevEnvironment.newDevEnvironment')}</Button>
        <Button onClick={this.initializeDevEnvList} style={{marginLeft: "4px"}}><Icon type="sync" spin={this.state.loading}/>{t('InstanceTable.refresh')}</Button>
        {this.renderNewDevEnvironmentModal()}
        <DevEnvironmentTable loading={this.state.loading} devEnvironments={this.state.devEnvironments} onDevEnvironmentChange={this.initializeDevEnvList}/>
      </div>
    );
  }
}

export default withTranslation()(Form.create()(DevEnvironmentView));