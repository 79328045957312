import { CordovaUtils, sessionDetails } from './';
import { FileCache, Request, Utils } from "./";

class RequestCache {
  constructor() {
      this.requests = {
        nameChanges: {}
      };
  }

  getSubdirectory() {
    return "staged_requests";
  }

  initializeRequestCache = () =>  {
    if (sessionDetails.isCordova()) {
      CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-requests.json", CordovaUtils.readJSONFileEntry).then((savedRequests) => {
        if (savedRequests) {
          this.requests = savedRequests;
        }
      });
      this.upload();
    }
  }

  upload = () => {
    let requests = [];
    for (let [analysisId, name] of Object.entries(this.requests.nameChanges)) {
      requests.push(
        Request.editAnalysis(analysisId, {name: name}).then((res) => {
          if (res && res.context) {
            delete this.requests.nameChanges[analysisId];
          }
        })
      );
    }

    return Utils.allSettled(requests).then((res) => {
      return CordovaUtils.saveFile(CordovaUtils.blobify(this.requests), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-requests.json");
    });
  }

  addNameChangeRequest(analysisId, newName) {
    this.requests.nameChanges[analysisId] = newName;

    CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "data", "videoList.json", CordovaUtils.readJSONFileEntry).then((cachedVideoList) => {
      if (cachedVideoList && cachedVideoList.videos) {
        let analysisIndex = cachedVideoList.videos.findIndex((analysis) => analysis.id === analysisId);
        if (analysisIndex) {
          cachedVideoList.videos[analysisIndex].name = newName;
          return CordovaUtils.saveFile(CordovaUtils.blobify(cachedVideoList), FileCache.getCacheDirectory(), "data", "videoList.json");
        }
      }
    });

    return CordovaUtils.saveFile(CordovaUtils.blobify(this.requests), FileCache.getCacheDirectory(), this.getSubdirectory(), "staged-requests.json");
  }

  getChangedName = (analysisId) => {
    return this.requests.nameChanges[analysisId];
  }

  clearCache = () =>  {
    this.requests = {
      nameChanges: {}
    };

    CordovaUtils.deleteFolder(window.cordova.file.externalDataDirectory, this.getSubdirectory());
  }
}

export let requestCache = new RequestCache();