import React from 'react';
import {Card, Button, Divider, Modal, message} from 'antd';
import { withTranslation } from "react-i18next";
import {FileCache, sessionDetails, Persistence, firebaseClient } from "../../utils";
import {ChangePasswordForm, SettingsForm} from "../../components/Forms";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cacheLoading: false,
            enableNotificationsModalVisible: false,
            disableNotificationsModalVisible: false,
            notificationsAllowed: Persistence.areNotificationsAllowed(),
            update: false
        }
    sessionDetails.changeView("Settings");
    }

    renderNotificationOptions = () => {
        const { t } = this.props;
        const enableNotificationsButton = <span>
                    <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}/>
                    <Button type='primary' onClick={this.handleEnableNotifications} style={{margin: "12px"}}>
                        {t('Settings.enableNotifications')}
                    </Button>
                </span>
        const disableNotificationsButton = <span>
                    <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}/>
                    <Button type='primary' onClick={() => this.setState({disableNotificationsModalVisible: true})} style={{margin: "12px"}}>
                        {t('Settings.disableNotifications')}
                    </Button>
                </span>

        if (this.state.notificationsAllowed) {
            return (disableNotificationsButton);
        } else {
            return (enableNotificationsButton);
        }
    }


    renderModals = () => {
        const { t } = this.props;
        const links = [
            <a
                href="https://support.google.com/chrome/answer/3220216"
                onClick={() => window.open("https://support.google.com/chrome/answer/3220216", "_blank")}
                style={{padding: '6px'}}
                key={1}
            >
                {t('Settings.chrome')}
            </a>,
            <a
                href="https://support.mozilla.org/en-US/kb/push-notifications-firefox"
                onClick={() => window.open("https://support.mozilla.org/en-US/kb/push-notifications-firefox", "_blank")}
                style={{padding: '6px'}}
                key={2}
            >
                {t('Settings.firefox')}
            </a>,
            <a href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/13.0/mac/10.15"
               onClick={() => window.open("https://support.apple.com/guide/safari/websites-ibrwe2159f50/13.0/mac/10.15", "_blank")}
               style={{padding: '6px'}}
               key={3}
            >
                {t('Settings.safari')}
            </a>
        ];
        return (
          <span>
              <Modal
                visible={this.state.enableNotificationsModalVisible}
                onCancel={() => this.setState({enableNotificationsModalVisible: false})}
                footer={[
                    <Button type={'primary'} key="ok" onClick={() => this.setState({enableNotificationsModalVisible: false})}>
                        { t('Settings.okButton') }
                    </Button>
                ]}
              >
                <p>{t('Settings.enableNotificationsInstructions')}</p>
                <p>{t('Settings.enableInstructions')}{links}</p>
            </Modal>
              <Modal
                visible={this.state.disableNotificationsModalVisible}
                onCancel={() => this.setState({disableNotificationsModalVisible: false})}
                footer={[
                    <Button type={'primary'} key="ok" onClick={() => this.setState({disableNotificationsModalVisible: false, notificationsAllowed: false})}>
                        { t('Settings.okButton') }
                    </Button>
                ]}
              >
                <p>{t('Settings.disableNotificationsInstructions')}</p>
            </Modal>
          </span>

        );
    }


    renderCacheButton = () => {
        const { t } = this.props;
        if (sessionDetails.isCordova()) {
            return (
                <span>
                    <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}/>
                    <Button
                            type='primary'
                            onClick={() => {
                                message.info(t('Settings.cacheCleared'));
                                FileCache.clearCache()
                            }}
                            style={{margin: "12px"}}
                            data-test="clear-cache-button">
                        {t('Settings.clearCache')}
                    </Button>
                </span>
            );
        }
    }


    handleEnableNotifications = () => {
        firebaseClient.requestPermission().then((permissionGranted) => {
            if (permissionGranted) {
                Persistence.setNotificationsAllowed();
                this.setState({notificationsAllowed: true});
            } else {
                Persistence.setNotificationsDeclined();
                this.setState({enableNotificationsModalVisible: true});
            }
        });
    }


    render() {
        const {t} = this.props;

        return (
            <Card size='small' title={<span style={{width: '100%', fontSize: '20pt', padding: '6px'}}>{t('Settings.title')}</span>}
                  bodyStyle={{padding: '10px', paddingTop: '1px', paddingBottom: '1px'}}>
                <SettingsForm/>
                <Divider style={{maxHeight: '20px', width: '80vw', margin: '0px'}}>{t('ChangePasswordForm.changePassword')}</Divider>
                <ChangePasswordForm/>
                {this.renderCacheButton()}
                {this.renderNotificationOptions()}
                {this.renderModals()}
            </Card>
        );
    }
}

export default withTranslation()(Settings);