import React from "react";
import { Badge, Button, Form, Modal, Table } from "antd";

import { Utils, Request } from "../../utils";

import { withTranslation } from "react-i18next";


class DevEnvironmentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startModalVisible: false,
      deleteEnvironmentModalVisible: false,
      modalLoading: false,
      targetEnvironment: null
    }
  }

  stopDevEnvironment = (targetEnvironment) => {
    Request.stopDevEnvironment(targetEnvironment).then(this.props.onDevEnvironmentChange);
  };

  startDevEnvironment = (targetEnvironment) => {
    Request.startDevEnvironment(targetEnvironment).then(this.props.onDevEnvironmentChange);
  };

  deployWebapp = (targetEnvironment) => {
    Request.deployDevEnvironment("webapp", targetEnvironment).then(this.props.onDevEnvironmentChange);
  }

  deployMasterServer = (targetEnvironment) => {
    Request.deployDevEnvironment("masterServer",  targetEnvironment).then(this.props.onDevEnvironmentChange);
  }

  deleteDevEnvironment = (targetEnvironment) => {
    this.setState({deleteEnvironmentModalVisible: true, targetEnvironment: targetEnvironment});
  };

  onTerminate = (e) => {
    e.preventDefault();
    this.setState({modalLoading: true});

    Request.deleteDevEnvironment(this.state.targetEnvironment).then(() => {
      this.setState({modalLoading: false, deleteEnvironmentModalVisible: false});
      this.props.onDevEnvironmentChange();
    });
  };

  renderDevEnvButtons(status, environmentName) {
    const { t } = this.props;
    let startStopButton = null;
    if (status === "success" || status === 'warning') {
      startStopButton = <Button onClick={() => this.stopDevEnvironment(environmentName)} type={'danger'} size="small" style={{margin: "2px"}}>{t('DevEnvironment.stop')}</Button>;
    } else if (status === "default") {
      startStopButton = <Button onClick={() => this.startDevEnvironment(environmentName)} type={'primary'} size="small" style={{margin: "2px"}}>{t('DevEnvironment.start')}</Button>;
    }


    return (
      <span>
        <Button onClick={() => this.deployWebapp(environmentName)} type={'primary'} size="small" style={{margin: "2px"}}>{t('DevEnvironment.deployWebapp')}</Button>
        <Button onClick={() => this.deployMasterServer(environmentName)} type={'primary'} size="small" style={{margin: "2px"}}>{t('DevEnvironment.deployMasterServer')}</Button>
        {startStopButton}
        <Button onClick={() => this.deleteDevEnvironment(environmentName)} disabled={status === 'processing'} type="danger" size="small" style={{margin: "2px"}}>{ t('DevEnvironment.delete')}</Button>
      </span>
    );
  }

  renderStatus(id, envStatus, instanceStatus) {
    let badgeStatus = 'default';
    if (instanceStatus === 'running' || instanceStatus === 'pending') {
      badgeStatus =
          envStatus === 'initializing' ? 'warning' :
          envStatus === 'error' ? 'error' :
              instanceStatus === 'pending' ? 'warning' : 'success';
    }

    return (
      <span>
        <Badge status={badgeStatus}/>
        {this.renderDevEnvButtons(badgeStatus, id)}
      </span>
    )
  }

  renderModals = () => {
    const { t } = this.props;
    return (
      <span>
        <Modal onOk={this.onTerminate} onCancel={() => this.setState({deleteEnvironmentModalVisible:false})} visible={this.state.deleteEnvironmentModalVisible} confirmLoading={this.state.modalLoading}>
          {t('DevEnvironment.confirmDelete', {targetEnvironment: this.state.targetEnvironment})}
        </Modal>
      </span>
    )

  }

  render() {
    const { t, devEnvironments } = this.props;
    // noinspection JSUnresolvedVariable
    const columns = [
      {
        title: t('DevEnvironment.columns.id'),
        dataIndex: 'id',
        sorter: (a, b) => {return (a.id ?? a.id.localeCompare(b.id) : 0)},
      },
      {
        title: t('DevEnvironment.columns.username'),
        dataIndex: 'username',
        sorter: (a, b) => {
          if (a.username === null || b.username === null) {
            return 0;
          }
          a.username.localeCompare(b.username)
        }
      },
      {
        title: t('DevEnvironment.columns.instanceId'),
        dataIndex: 'instanceId'
      },
      {
        title: t('DevEnvironment.columns.url'),
        dataIndex: 'webappUrl',
        render: (webappUrl) => {return (<a href={webappUrl} target="_blank" rel="noopener noreferrer">{webappUrl}</a>)}
      },
      {
        title: t('DevEnvironment.columns.creationDate'),
        dataIndex: 'creationDate',
        render: (creationDate) => <span>{t('Formats.longDateTime', {date: new Date(creationDate)})}</span>,
        sorter: (a, b) => Utils.compareDates(a.creationDate, b.creationDate),
        defaultSortOrder: "descend"
      },
      {
        title: t('DevEnvironment.columns.state'),
        dataIndex: 'instanceStatus',
        render: (instanceStatus, record) => this.renderStatus(record.id, record.envStatus, instanceStatus),
        sorter: (a, b) => a.instanceStatus ? a.instanceStatus.localeCompare(b.instanceStatus) : 0
      }
    ];
    return (
      <div>
        {this.renderModals()}
        <Table loading={this.props.loading} columns={columns} rowKey={'id'} dataSource={devEnvironments}/>
      </div>
    );
  }
}

export default withTranslation()(Form.create()(DevEnvironmentTable));