import React from 'react';
import {Form, Input, Button, message} from 'antd';
import { withTranslation } from "react-i18next";
import { Request, Utils } from "../../utils";

class RecoverPasswordForm extends React.Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    const { t } = this.props
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err);
      } else {
        Request.requestPasswordReset(values['usernameOrPassword']).then((res) => {
          // Navigate the user back to the login page and display a message
          // This should happen even if an invalid username/email is sent
          this.props.onPasswordResetRequest();
          message.info(t('RecoverPasswordForm.passwordResetEmailSent'));
        }, () => {
          // If the request fails, navigate back to the login page anyway
          this.props.onPasswordResetRequest();
        });
      }
    })
  };



  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form; // utility functions for the Form
    const { t } = this.props;

    let usernameOrPasswordError = isFieldTouched('usernameOrPassword') && getFieldError('usernameOrPassword');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={usernameOrPasswordError ? 'error' : ''} help={usernameOrPasswordError || ''} style={{marginBottom: '6px'}}>
          {getFieldDecorator('usernameOrPassword', {
            rules: [{ required: true, message: t('RecoverPasswordForm.usernameEmailHint') }],
          })(
            <Input
                data-test="username-email-input"
                placeholder={ t('RecoverPasswordForm.usernameOrEmail') }
                autoComplete="username"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button style={{width: "100%"}} type="primary" data-test="request-password-reset-submit-button" htmlType="submit" disabled={Utils.formHasErrors(getFieldsError())}>
            { t('RecoverPasswordForm.sendRecoveryEmail') }
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(Form.create( { name: 'vertical_password_recovery' })(RecoverPasswordForm));
