// Index order: [end_height, hand_distance, object_weight, lift_distance, frequency]
const liftingFemale = [
  [
    [
      [
        [0,0,0,0,13],
        [0,0,0,0,29],
        [0,0,0,0,34]
      ],
      [
        [0,0,0,0,17],
        [0,0,0,0,35],
        [0,0,0,0,40]
      ],
      [
        [0,0,0,0,22],
        [0,0,0,0,41],
        [0,0,0,0,46]
      ],
      [
        [0,0,0,0,29],
        [0,0,0,0,48],
        [0,0,0,0,53]
      ],
      [
        [0,0,0,0,35],
        [0,0,0,0,54],
        [0,0,0,12,59]
      ],
      [
        [0,0,0,0,43],
        [0,0,0,13,61],
        [0,0,0,17,65]
      ],
      [
        [0,0,0,0,51],
        [0,0,0,19,67],
        [0,0,14,24,71]
      ],
      [
        [0,0,0,11,58],
        [0,0,16,27,73],
        [14,16,20,32,76]
      ],
      [
        [0,0,0,18,66],
        [0,14,24,36,78],
        [21,23,29,41,81]
      ],
      [
        [0,12,16,26,73],
        [16,22,33,46,83],
        [30,33,38,50,85]
      ],
      [
        [17,19,25,37,79],
        [25,32,44,56,87],
        [40,43,49,60,88]
      ],
      [
        [27,30,36,48,84],
        [36,43,55,65,100],
        [51,54,59,69,100]
      ],
      [
        [39,43,49,60,88],
        [49,55,65,74,100],
        [63,65,69,77,100]
      ],
      [
        [53,56,61,70,100],
        [61,67,75,81,100],
        [73,75,78,83,100]
      ],
      [
        [66,69,73,80,100],
        [73,77,83,87,100],
        [81,83,85,89,100]
      ],
      [
        [78,79,82,87,100],
        [82,85,89,100,100],
        [88,89,100,100,100]
      ],
      [
        [87,88,89,100,100],
        [89,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,0,20]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,19],
        [0,0,0,0,26]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,25],
        [0,0,0,0,32]
      ],
      [
        [0,0,0,0,14],
        [0,0,0,0,31],
        [0,0,0,0,38]
      ],
      [
        [0,0,0,0,20],
        [0,0,0,0,38],
        [0,0,0,0,45]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,0,45],
        [0,0,0,0,53]
      ],
      [
        [0,0,0,0,34],
        [0,0,0,0,53],
        [0,0,0,11,60]
      ],
      [
        [0,0,0,0,42],
        [0,0,0,13,60],
        [0,0,0,17,67]
      ],
      [
        [0,0,0,0,51],
        [0,0,11,20,68],
        [0,0,14,24,73]
      ],
      [
        [0,0,0,13,60],
        [0,0,18,29,74],
        [14,16,22,34,78]
      ],
      [
        [0,0,11,21,68],
        [11,16,27,39,80],
        [22,25,32,44,83]
      ],
      [
        [12,14,20,31,76],
        [19,25,38,50,85],
        [33,37,43,55,87]
      ],
      [
        [21,25,32,44,82],
        [30,38,51,62,89],
        [46,49,55,66,100]
      ],
      [
        [35,38,46,57,87],
        [44,51,63,72,100],
        [59,62,67,75,100]
      ],
      [
        [50,54,60,70,100],
        [59,65,74,81,100],
        [71,73,77,83,100]
      ],
      [
        [66,69,73,80,100],
        [72,77,83,88,100],
        [81,83,85,89,100]
      ],
      [
        [79,81,84,88,100],
        [83,86,100,100,100],
        [88,89,100,100,100]
      ],
      [
        [88,89,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,13]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,18]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,0,25]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,20],
        [0,0,0,0,32]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,28],
        [0,0,0,0,41]
      ],
      [
        [0,0,0,0,18],
        [0,0,0,0,36],
        [0,0,0,0,50]
      ],
      [
        [0,0,0,0,27],
        [0,0,0,0,46],
        [0,0,0,0,58]
      ],
      [
        [0,0,0,0,37],
        [0,0,0,0,56],
        [0,0,0,13,67]
      ],
      [
        [0,0,0,0,49],
        [0,0,0,18,66],
        [0,0,12,22,75]
      ],
      [
        [0,0,0,13,60],
        [0,0,18,29,74],
        [11,14,22,34,81]
      ],
      [
        [0,0,14,24,71],
        [0,16,31,43,82],
        [21,26,36,48,87]
      ],
      [
        [14,18,27,39,80],
        [22,30,46,58,87],
        [36,42,51,62,100]
      ],
      [
        [29,35,45,57,87],
        [39,48,63,72,100],
        [54,59,66,75,100]
      ],
      [
        [50,55,64,73,100],
        [59,66,77,83,100],
        [71,74,79,85,100]
      ],
      [
        [71,74,80,85,100],
        [77,81,87,100,100],
        [84,86,89,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,15]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,11],
        [0,0,0,0,21]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,17],
        [0,0,0,0,29]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,24],
        [0,0,0,0,38]
      ],
      [
        [0,0,0,0,19],
        [0,0,0,0,34],
        [0,0,0,15,48]
      ],
      [
        [0,0,0,0,29],
        [0,0,0,12,44],
        [0,0,14,23,58]
      ],
      [
        [0,0,0,0,40],
        [0,0,12,21,56],
        [0,0,23,34,68]
      ],
      [
        [0,0,0,18,52],
        [0,14,22,32,66],
        [11,18,35,47,76]
      ],
      [
        [0,0,20,30,64],
        [20,25,34,46,76],
        [20,30,49,60,83]
      ],
      [
        [12,18,33,45,75],
        [34,39,49,60,83],
        [34,45,62,71,89]
      ],
      [
        [25,33,50,60,84],
        [50,55,64,73,89],
        [50,60,75,81,100]
      ],
      [
        [43,52,66,74,100],
        [66,70,77,83,100],
        [67,74,84,88,100]
      ],
      [
        [62,70,80,85,100],
        [80,83,87,100,100],
        [80,85,100,100,100]
      ],
      [
        [79,84,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,14]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,11],
        [0,0,0,0,21]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,17],
        [0,0,0,0,30]
      ],
      [
        [0,0,0,0,14],
        [0,0,0,0,26],
        [0,0,0,0,41]
      ],
      [
        [0,0,0,0,22],
        [0,0,0,0,38],
        [0,0,0,18,52]
      ],
      [
        [0,0,0,0,34],
        [0,0,0,16,50],
        [0,0,18,29,63]
      ],
      [
        [0,0,0,15,48],
        [0,11,18,28,62],
        [0,14,31,42,73]
      ],
      [
        [0,0,17,27,61],
        [18,22,31,43,74],
        [18,27,45,56,82]
      ],
      [
        [11,17,32,43,74],
        [32,38,47,58,83],
        [33,43,61,70,88]
      ],
      [
        [25,34,50,61,84],
        [51,56,64,73,89],
        [51,61,75,81,100]
      ],
      [
        [46,54,68,76,100],
        [69,73,79,84,100],
        [69,76,85,89,100]
      ],
      [
        [68,74,83,88,100],
        [83,86,89,100,100],
        [84,88,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,17]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,16],
        [0,0,0,0,28]
      ],
      [
        [0,0,0,0,14],
        [0,0,0,0,28],
        [0,0,0,11,42]
      ],
      [
        [0,0,0,0,27],
        [0,0,0,11,42],
        [0,0,13,22,56]
      ],
      [
        [0,0,0,11,43],
        [0,0,14,23,58],
        [0,12,26,37,70]
      ],
      [
        [0,0,16,26,60],
        [17,21,30,41,73],
        [18,26,44,55,81]
      ],
      [
        [14,20,35,46,76],
        [37,41,51,61,84],
        [37,47,64,72,89]
      ],
      [
        [35,44,59,68,87],
        [60,64,71,79,100],
        [61,69,80,85,100]
      ],
      [
        [63,70,80,85,100],
        [80,83,87,100,100],
        [81,88,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,13],
        [0,0,0,0,26],
        [0,0,0,0,40]
      ],
      [
        [0,0,0,0,19],
        [0,0,0,0,33],
        [0,0,0,15,48]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,11,41],
        [0,0,12,21,55]
      ],
      [
        [0,0,0,0,34],
        [0,0,0,18,50],
        [0,0,18,29,63]
      ],
      [
        [0,0,0,12,43],
        [0,0,15,24,59],
        [0,11,26,38,70]
      ],
      [
        [0,0,11,19,53],
        [11,14,22,33,67],
        [11,18,36,47,77]
      ],
      [
        [0,0,18,28,62],
        [18,22,32,44,74],
        [18,27,46,57,82]
      ],
      [
        [0,14,27,39,71],
        [28,33,43,54,81],
        [28,38,57,65,87]
      ],
      [
        [16,23,39,51,79],
        [40,45,55,65,86],
        [40,50,67,75,100]
      ],
      [
        [27,36,52,63,85],
        [53,58,66,74,100],
        [53,62,76,82,100]
      ],
      [
        [41,50,65,73,100],
        [65,69,76,82,100],
        [66,73,84,88,100]
      ],
      [
        [57,64,76,82,100],
        [76,79,84,88,100],
        [77,82,89,100,100]
      ],
      [
        [71,77,85,89,100],
        [85,87,100,100,100],
        [85,89,100,100,100]
      ],
      [
        [83,86,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,12],
        [0,0,0,0,22]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,17],
        [0,0,0,0,30]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,24],
        [0,0,0,0,38]
      ],
      [
        [0,0,0,0,18],
        [0,0,0,0,32],
        [0,0,0,14,46]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,0,41],
        [0,0,12,21,55]
      ],
      [
        [0,0,0,0,35],
        [0,0,0,17,51],
        [0,0,19,29,64]
      ],
      [
        [0,0,0,13,45],
        [0,0,16,26,60],
        [0,13,28,40,72]
      ],
      [
        [0,0,13,21,56],
        [13,17,25,37,69],
        [13,21,39,51,79]
      ],
      [
        [0,0,22,33,66],
        [23,27,37,49,77],
        [23,33,51,62,84]
      ],
      [
        [13,19,34,46,76],
        [35,40,50,61,84],
        [35,46,63,72,89]
      ],
      [
        [24,32,49,59,83],
        [49,54,63,72,89],
        [50,59,74,81,100]
      ],
      [
        [39,48,63,72,89],
        [64,68,75,81,100],
        [64,72,83,87,100]
      ],
      [
        [57,64,76,82,100],
        [76,79,84,88,100],
        [77,82,89,100,100]
      ],
      [
        [73,78,86,100,100],
        [86,88,100,100,100],
        [86,100,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,13]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,20]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,17],
        [0,0,0,0,29]
      ],
      [
        [0,0,0,0,13],
        [0,0,0,0,25],
        [0,0,0,0,39]
      ],
      [
        [0,0,0,0,21],
        [0,0,0,0,36],
        [0,0,0,17,50]
      ],
      [
        [0,0,0,0,32],
        [0,0,0,15,48],
        [0,0,17,27,61]
      ],
      [
        [0,0,0,13,45],
        [0,0,16,26,60],
        [0,13,28,40,72]
      ],
      [
        [0,0,15,24,59],
        [16,20,28,40,72],
        [16,25,43,54,80]
      ],
      [
        [0,15,28,40,72],
        [30,35,44,55,81],
        [30,40,58,68,87]
      ],
      [
        [22,30,46,57,82],
        [48,52,61,70,88],
        [48,57,72,79,100]
      ],
      [
        [42,50,64,73,100],
        [66,70,76,82,100],
        [66,73,83,88,100]
      ],
      [
        [64,70,80,85,100],
        [81,83,87,100,100],
        [81,85,100,100,100]
      ],
      [
        [82,85,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
  ]
];

// Index order: [end_height, hand_distance, object_weight, lift_distance, frequency]
const liftingMale = [
  [
    [
      [
        [0,0,0,30,49],
        [0,0,13,34,54],
        [0,11,25,48,65]
      ],
      [
        [0,0,14,34,54],
        [0,0,17,39,58],
        [0,15,30,52,69]
      ],
      [
        [0,0,17,39,58],
        [0,0,21,44,62],
        [11,19,34,57,72]
      ],
      [
        [0,0,22,45,63],
        [0,11,26,49,66],
        [15,23,40,61,75]
      ],
      [
        [0,0,27,50,67],
        [0,14,31,54,70],
        [20,28,45,65,78]
      ],
      [
        [0,14,33,55,71],
        [11,19,37,59,73],
        [25,34,51,69,80]
      ],
      [
        [0,19,39,60,74],
        [15,24,43,64,77],
        [31,40,56,73,83]
      ],
      [
        [15,25,45,65,78],
        [20,31,50,69,80],
        [37,46,62,77,85]
      ],
      [
        [20,31,52,70,81],
        [26,37,56,73,83],
        [44,53,67,80,87]
      ],
      [
        [27,39,58,75,84],
        [34,45,62,77,85],
        [71,59,72,83,89]
      ],
      [
        [35,46,64,79,86],
        [41,52,68,81,88],
        [58,65,76,86,100]
      ],
      [
        [43,54,70,82,89],
        [49,59,73,84,100],
        [65,71,80,88,100]
      ],
      [
        [52,62,76,85,100],
        [58,66,78,87,100],
        [71,76,84,100,100]
      ],
      [
        [60,69,80,88,100],
        [65,73,82,89,100],
        [76,81,87,100,100]
      ],
      [
        [68,76,85,100,100],
        [73,79,86,100,100],
        [81,85,100,100,100]
      ],
      [
        [76,81,88,100,100],
        [79,84,89,100,100],
        [86,88,100,100,100]
      ],
      [
        [82,86,100,100,100],
        [84,88,100,100,100],
        [89,100,100,100,100]
      ],
      [
        [87,100,100,100,100],
        [89,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,16,35],
        [0,0,0,20,39],
        [0,0,13,33,53]
      ],
      [
        [0,0,0,20,40],
        [0,0,0,24,44],
        [0,0,16,38,57]
      ],
      [
        [0,0,0,25,45],
        [0,0,0,29,49],
        [0,0,20,43,61]
      ],
      [
        [0,0,0,30,50],
        [0,0,14,34,54],
        [0,11,25,48,65]
      ],
      [
        [0,0,14,35,55],
        [0,0,18,40,59],
        [0,15,30,53,69]
      ],
      [
        [0,0,19,41,60],
        [0,0,23,46,63],
        [12,19,36,58,73]
      ],
      [
        [0,0,24,47,64],
        [0,12,29,51,68],
        [16,25,42,63,76]
      ],
      [
        [0,12,30,53,69],
        [0,16,35,57,72],
        [21,31,48,68,79]
      ],
      [
        [0,17,37,59,73],
        [13,22,42,63,76],
        [28,38,55,72,82]
      ],
      [
        [13,23,44,65,77],
        [18,29,49,65,80],
        [35,45,61,76,85]
      ],
      [
        [19,31,52,70,81],
        [25,37,56,72,83],
        [42,52,67,80,87]
      ],
      [
        [27,39,59,75,84],
        [33,45,63,78,86],
        [51,60,72,84,89]
      ],
      [
        [36,48,66,80,87],
        [42,53,69,82,88],
        [59,66,77,87,100]
      ],
      [
        [45,57,73,84,89],
        [52,62,75,85,100],
        [66,73,82,89,100]
      ],
      [
        [56,66,78,87,100],
        [61,70,81,88,100],
        [73,79,86,100,100]
      ],
      [
        [65,73,83,100,100],
        [70,77,85,100,100],
        [80,84,89,100,100]
      ],
      [
        [74,80,88,100,100],
        [77,83,89,100,100],
        [85,88,100,100,100]
      ],
      [
        [81,86,100,100,100],
        [84,87,100,100,100],
        [89,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,12],
        [0,0,0,0,23]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,16],
        [0,0,0,11,28]
      ],
      [
        [0,0,0,0,16],
        [0,0,0,0,20],
        [0,0,0,15,33]
      ],
      [
        [0,0,0,0,20],
        [0,0,0,0,25],
        [0,0,0,19,38]
      ],
      [
        [0,0,0,0,25],
        [0,0,0,13,30],
        [0,0,0,24,43]
      ],
      [
        [0,0,0,13,31],
        [0,0,0,17,36],
        [0,0,0,29,49]
      ],
      [
        [0,0,0,18,37],
        [0,0,0,22,42],
        [0,0,14,35,55]
      ],
      [
        [0,0,0,24,43],
        [0,0,0,28,48],
        [0,0,19,42,60]
      ],
      [
        [0,0,11,30,50],
        [0,0,14,35,54],
        [0,0,25,48,66]
      ],
      [
        [0,0,16,37,57],
        [0,0,20,42,60],
        [0,14,33,55,71]
      ],
      [
        [0,0,22,45,63],
        [0,0,27,50,66],
        [12,21,40,62,75]
      ],
      [
        [0,11,30,53,69],
        [0,14,35,57,72],
        [18,28,48,68,79]
      ],
      [
        [0,17,39,61,75],
        [11,22,44,64,77],
        [26,37,57,74,83]
      ],
      [
        [14,26,49,68,80],
        [19,31,53,71,82],
        [35,47,65,79,86]
      ],
      [
        [23,37,59,75,84],
        [29,42,62,77,85],
        [46,57,72,83,89]
      ],
      [
        [35,48,68,81,88],
        [40,53,71,83,89],
        [57,66,78,87,100]
      ],
      [
        [48,60,76,86,100],
        [53,64,78,87,100],
        [67,75,84,100,100]
      ],
      [
        [61,71,83,100,100],
        [66,74,84,100,100],
        [77,82,88,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,12],
        [0,0,0,0,25],
        [0,0,16,21,46]
      ],
      [
        [0,0,0,0,16],
        [0,0,0,0,25],
        [0,0,20,26,52]
      ],
      [
        [0,0,0,0,21],
        [0,0,0,13,36],
        [0,11,25,32,57]
      ],
      [
        [0,0,0,0,27],
        [0,0,12,17,42],
        [0,15,31,38,63]
      ],
      [
        [0,0,0,11,33],
        [0,0,17,23,49],
        [12,20,38,44,68]
      ],
      [
        [0,0,11,16,40],
        [0,0,23,29,55],
        [17,26,45,51,72]
      ],
      [
        [0,0,16,21,47],
        [0,14,30,36,61],
        [23,33,52,58,77]
      ],
      [
        [0,11,22,28,54],
        [12,20,37,44,67],
        [30,41,59,64,81]
      ],
      [
        [12,17,30,36,61],
        [18,27,45,52,73],
        [38,49,65,70,84]
      ],
      [
        [18,25,38,45,68],
        [25,35,54,60,78],
        [47,57,72,76,87]
      ],
      [
        [26,33,48,54,74],
        [34,45,62,67,83],
        [64,72,82,85,100]
      ],
      [
        [36,43,57,63,80],
        [44,54,69,74,86],
        [64,72,82,85,100]
      ],
      [
        [46,54,66,71,85],
        [54,63,76,80,100],
        [72,78,86,88,100]
      ],
      [
        [57,64,74,78,88],
        [64,72,82,85,100],
        [79,84,100,100,100]
      ],
      [
        [68,73,81,84,100],
        [73,79,87,89,100],
        [85,88,100,100,100]
      ],
      [
        [77,81,87,89,100],
        [81,85,100,100,100],
        [89,100,100,100,100]
      ],
      [
        [84,87,100,100,100],
        [87,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,12],
        [0,0,0,0,30]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,16],
        [0,0,0,13,36]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,21],
        [0,0,12,17,42]
      ],
      [
        [0,0,0,0,13],
        [0,0,0,0,26],
        [0,0,17,22,48]
      ],
      [
        [0,0,0,0,18],
        [0,0,0,11,32],
        [0,0,22,28,54]
      ],
      [
        [0,0,0,0,24],
        [0,0,11,15,39],
        [0,13,29,35,60]
      ],
      [
        [0,0,0,0,31],
        [0,0,16,21,47],
        [11,19,36,42,66]
      ],
      [
        [0,0,0,15,39],
        [0,0,22,28,54],
        [16,25,43,50,72]
      ],
      [
        [0,0,16,21,47],
        [0,14,29,36,61],
        [23,33,51,57,77]
      ],
      [
        [0,12,23,29,55],
        [13,20,38,44,68],
        [31,42,59,64,81]
      ],
      [
        [13,19,32,38,63],
        [20,29,47,53,74],
        [41,51,67,71,85]
      ],
      [
        [21,28,41,48,70],
        [28,39,56,62,80],
        [50,60,73,77,88]
      ],
      [
        [31,38,52,58,77],
        [39,49,65,70,84],
        [60,68,80,83,100]
      ],
      [
        [42,50,62,67,83],
        [51,60,74,77,88],
        [69,76,85,87,100]
      ],
      [
        [55,61,72,76,87],
        [62,70,81,84,100],
        [77,82,89,100,100]
      ],
      [
        [66,72,80,83,100],
        [72,78,86,88,100],
        [77,82,89,100,100]
      ],
      [
        [77,81,86,88,100],
        [81,85,100,100,100],
        [89,100,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [88,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,13]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,17]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,23]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,11],
        [0,0,0,0,29]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,16],
        [0,0,0,13,36]
      ],
      [
        [0,0,0,0,11],
        [0,0,0,0,23],
        [0,0,14,19,44]
      ],
      [
        [0,0,0,0,16],
        [0,0,0,0,30],
        [0,0,20,26,52]
      ],
      [
        [0,0,0,0,24],
        [0,0,0,15,39],
        [0,13,28,34,60]
      ],
      [
        [0,0,0,0,32],
        [0,0,17,22,48],
        [13,20,37,44,67]
      ],
      [
        [0,0,13,17,42],
        [0,11,25,31,57],
        [20,29,47,53,74]
      ],
      [
        [0,11,21,27,53],
        [12,19,36,42,66],
        [30,40,57,63,80]
      ],
      [
        [14,19,32,38,63],
        [20,29,47,53,74],
        [42,51,67,71,85]
      ],
      [
        [24,31,44,51,72],
        [32,42,59,64,81],
        [54,63,75,79,89]
      ],
      [
        [38,45,58,63,80],
        [46,56,70,74,87],
        [66,73,82,85,100]
      ],
      [
        [53,60,70,74,87],
        [61,69,79,83,100],
        [76,82,88,100,100]
      ],
      [
        [68,73,81,84,100],
        [74,79,87,89,100],
        [85,88,100,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,28],
        [0,0,14,18,44],
        [0,16,33,39,64]
      ],
      [
        [0,0,0,11,33],
        [0,0,17,23,49],
        [12,20,38,45,68]
      ],
      [
        [0,0,0,15,39],
        [0,0,22,28,54],
        [16,25,44,50,72]
      ],
      [
        [0,0,14,19,45],
        [0,12,27,34,59],
        [21,31,49,55,75]
      ],
      [
        [0,0,19,25,50],
        [0,16,33,40,64],
        [27,37,55,61,79]
      ],
      [
        [0,13,24,31,56],
        [13,22,40,46,69],
        [33,43,60,66,82]
      ],
      [
        [12,18,31,37,62],
        [18,28,46,52,73],
        [39,50,66,71,85]
      ],
      [
        [17,24,38,44,68],
        [24,34,53,59,78],
        [46,56,71,75,87]
      ],
      [
        [23,31,45,51,73],
        [31,42,59,65,81],
        [53,62,76,79,89]
      ],
      [
        [31,38,52,58,77],
        [39,50,66,77,85],
        [60,68,80,83,100]
      ],
      [
        [39,47,60,65,82],
        [47,57,72,76,87],
        [67,74,84,86,100]
      ],
      [
        [48,55,67,72,85],
        [56,65,77,81,100],
        [73,79,87,89,100]
      ],
      [
        [57,63,74,77,88],
        [64,72,82,85,100],
        [79,83,100,100,100]
      ],
      [
        [65,71,79,82,100],
        [71,78,86,88,100],
        [83,87,100,100,100]
      ],
      [
        [73,78,84,87,100],
        [78,83,89,100,100],
        [87,100,100,100,100]
      ],
      [
        [80,83,88,100,100],
        [84,87,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [88,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,14],
        [0,0,0,0,28],
        [0,0,18,24,49]
      ],
      [
        [0,0,0,0,18],
        [0,0,0,11,33],
        [0,0,22,29,54]
      ],
      [
        [0,0,0,0,23],
        [0,0,0,14,38],
        [0,12,28,34,59]
      ],
      [
        [0,0,0,0,29],
        [0,0,14,19,44],
        [0,17,33,40,64]
      ],
      [
        [0,0,0,12,35],
        [0,0,18,24,50],
        [13,22,39,46,69]
      ],
      [
        [0,0,12,16,41],
        [0,0,24,30,56],
        [18,27,46,52,73]
      ],
      [
        [0,0,16,22,47],
        [0,14,30,37,62],
        [24,34,52,58,77]
      ],
      [
        [0,11,22,28,54],
        [12,20,37,44,67],
        [30,41,58,64,81]
      ],
      [
        [11,17,29,35,61],
        [17,26,44,51,72],
        [38,48,64,69,84]
      ],
      [
        [17,23,37,43,67],
        [24,34,52,58,77],
        [46,55,70,74,87]
      ],
      [
        [24,31,45,51,73],
        [32,42,59,65,81],
        [54,63,76,79,89]
      ],
      [
        [32,40,53,59,78],
        [41,51,67,71,85],
        [61,69,80,83,100]
      ],
      [
        [42,49,62,67,82],
        [50,59,73,77,88],
        [69,75,84,87,100]
      ],
      [
        [51,58,70,74,86],
        [59,67,79,82,100],
        [75,81,88,100,100]
      ],
      [
        [61,67,77,80,100],
        [68,75,84,86,100],
        [81,85,100,100,100]
      ],
      [
        [70,75,83,85,100],
        [76,81,88,100,100],
        [86,89,100,100,100]
      ],
      [
        [78,82,87,89,100],
        [83,86,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [85,88,100,100,100],
        [88,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,19]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,23]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,11],
        [0,0,0,0,28]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,11,34]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,19],
        [0,0,11,16,40]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,25],
        [0,0,16,21,46]
      ],
      [
        [0,0,0,0,17],
        [0,0,0,0,31],
        [0,0,21,27,53]
      ],
      [
        [0,0,0,0,23],
        [0,0,0,14,38],
        [0,12,27,33,59]
      ],
      [
        [0,0,0,0,30],
        [0,0,15,20,45],
        [11,18,34,41,65]
      ],
      [
        [0,0,0,14,37],
        [0,0,21,27,53],
        [16,25,42,49,71]
      ],
      [
        [0,0,15,20,46],
        [0,13,28,35,60],
        [23,33,50,56,76]
      ],
      [
        [0,12,22,28,54],
        [13,20,37,44,67],
        [31,41,58,64,81]
      ],
      [
        [13,19,31,37,62],
        [20,29,46,53,74],
        [41,51,66,71,85]
      ],
      [
        [21,28,41,48,70],
        [29,39,56,62,79],
        [51,60,73,77,88]
      ],
      [
        [31,39,52,58,77],
        [40,50,65,70,84],
        [61,69,79,83,100]
      ],
      [
        [43,50,62,68,83],
        [52,61,74,78,88],
        [70,76,85,87,100]
      ],
      [
        [56,62,72,76,88],
        [63,71,81,84,100],
        [78,83,89,100,100]
      ],
      [
        [68,73,81,83,100],
        [74,79,87,89,100],
        [85,88,100,100,100]
      ],
    ],
  ]
];

// Index order: [start_height, hand_distance, object_weight, lower_distance, frequency]
const loweringFemale = [
  [
    [
      [
        [0,0,0,0,47],
        [0,0,0,17,69],
        [0,0,0,22,73]
      ],
      [
        [0,0,0,0,56],
        [0,0,0,25,75],
        [0,0,11,31,79]
      ],
      [
        [0,0,0,14,65],
        [0,0,14,35,81],
        [0,12,18,41,84]
      ],
      [
        [0,0,0,20,71],
        [0,0,19,42,84],
        [14,17,24,49,87]
      ],
      [
        [0,0,0,26,76],
        [0,12,25,50,87],
        [20,24,31,56,89]
      ],
      [
        [0,0,13,34,80],
        [12,18,33,58,100],
        [27,31,39,63,100]
      ],
      [
        [0,12,19,42,84],
        [18,25,41,65,100],
        [35,39,48,70,100]
      ],
      [
        [15,19,26,51,88],
        [25,34,50,72,100],
        [44,48,56,76,100]
      ],
      [
        [23,27,36,60,100],
        [34,43,59,78,100],
        [53,57,64,81,100]
      ],
      [
        [32,37,46,68,100],
        [44,53,67,83,100],
        [62,66,72,86,100]
      ],
      [
        [43,47,56,76,100],
        [55,63,75,87,100],
        [71,74,79,89,100]
      ],
      [
        [54,58,66,82,100],
        [65,72,81,100,100],
        [78,80,84,100,100]
      ],
      [
        [65,69,75,87,100],
        [74,79,87,100,100],
        [84,86,89,100,100]
      ],
      [
        [75,78,82,100,100],
        [81,85,100,100,100],
        [89,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [83,85,88,100,100],
        [88,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,26],
        [0,0,0,0,50],
        [0,0,0,0,56]
      ],
      [
        [0,0,0,0,36],
        [0,0,0,0,59],
        [0,0,0,14,64]
      ],
      [
        [0,0,0,0,46],
        [0,0,0,16,68],
        [0,0,0,21,72]
      ],
      [
        [0,0,0,0,53],
        [0,0,0,22,73],
        [0,0,0,28,77]
      ],
      [
        [0,0,0,0,60],
        [0,0,0,29,78],
        [0,0,14,35,81]
      ],
      [
        [0,0,0,15,67],
        [0,0,15,37,82],
        [0,12,20,43,85]
      ],
      [
        [0,0,0,22,73],
        [0,0,21,45,86],
        [15,18,27,52,88]
      ],
      [
        [0,0,0,30,78],
        [0,14,29,54,89],
        [22,26,35,60,100]
      ],
      [
        [0,0,17,40,83],
        [14,22,39,63,100],
        [30,35,45,68,100]
      ],
      [
        [13,16,25,50,87],
        [22,31,49,70,100],
        [40,45,55,75,100]
      ],
      [
        [21,25,35,60,100],
        [32,41,59,77,100],
        [51,55,64,81,100]
      ],
      [
        [31,36,47,69,100],
        [43,53,68,83,100],
        [61,65,73,86,100]
      ],
      [
        [43,49,58,77,100],
        [55,64,77,88,100],
        [71,74,80,100,100]
      ],
      [
        [56,61,69,84,100],
        [67,74,84,100,100],
        [79,82,86,100,100]
      ],
      [
        [69,72,79,89,100],
        [77,82,89,100,100],
        [86,88,100,100,100]
      ],
      [
        [83,86,89,100,100],
        [88,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,12],
        [0,0,0,0,16]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,18],
        [0,0,0,0,24]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,27],
        [0,0,0,0,33]
      ],
      [
        [0,0,0,0,14],
        [0,0,0,0,34],
        [0,0,0,0,41]
      ],
      [
        [0,0,0,0,18],
        [0,0,0,0,42],
        [0,0,0,0,48]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,0,50],
        [0,0,0,0,56]
      ],
      [
        [0,0,0,0,34],
        [0,0,0,0,58],
        [0,0,0,13,63]
      ],
      [
        [0,0,0,0,43],
        [0,0,0,14,66],
        [0,0,0,19,70]
      ],
      [
        [0,0,0,0,52],
        [0,0,0,22,73],
        [0,0,0,27,77]
      ],
      [
        [0,0,0,11,62],
        [0,0,11,31,79],
        [0,0,15,37,82]
      ],
      [
        [0,0,0,19,70],
        [0,0,18,41,84],
        [0,13,23,48,87]
      ],
      [
        [0,0,0,29,78],
        [0,11,28,53,88],
        [16,22,34,59,100]
      ],
      [
        [0,0,18,41,84],
        [11,20,40,64,100],
        [26,33,46,69,100]
      ],
      [
        [12,17,29,54,89],
        [21,32,53,74,100],
        [39,46,59,78,100]
      ],
      [
        [23,30,44,67,100],
        [34,46,66,82,100],
        [53,60,71,85,100]
      ],
      [
        [47,54,66,82,100],
        [59,68,82,100,100],
        [74,78,85,100,100]
      ],
      [
        [72,77,84,100,100],
        [80,85,100,100,100],
        [88,100,100,100,100]
      ],
      [
        [89,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,25],
        [0,0,0,0,43],
        [0,0,0,21,58]
      ],
      [
        [0,0,0,0,35],
        [0,0,0,17,53],
        [0,0,0,30,67]
      ],
      [
        [0,0,0,12,46],
        [0,0,0,26,63],
        [0,0,11,41,76]
      ],
      [
        [0,0,0,17,54],
        [0,0,0,33,69],
        [0,0,15,49,79]
      ],
      [
        [0,0,0,24,61],
        [0,0,0,41,75],
        [0,0,22,57,83]
      ],
      [
        [0,0,0,32,68],
        [0,11,16,49,80],
        [0,14,29,64,87]
      ],
      [
        [0,0,0,40,74],
        [14,16,23,58,84],
        [14,21,38,71,100]
      ],
      [
        [0,0,16,50,80],
        [21,24,31,66,88],
        [21,30,47,77,100]
      ],
      [
        [0,13,24,59,85],
        [29,33,41,73,100],
        [30,39,57,82,100]
      ],
      [
        [14,20,34,68,89],
        [40,44,52,80,100],
        [40,50,66,87,100]
      ],
      [
        [23,30,45,76,100],
        [51,55,62,85,100],
        [51,60,74,100,100]
      ],
      [
        [34,42,57,82,100],
        [62,65,71,89,100],
        [62,70,81,100,100]
      ],
      [
        [47,55,68,88,100],
        [72,75,80,100,100],
        [72,79,87,100,100]
      ],
      [
        [60,67,77,100,100],
        [81,83,86,100,100],
        [81,85,100,100,100]
      ],
      [
        [72,78,85,100,100],
        [87,89,100,100,100],
        [88,100,100,100,100]
      ],
      [
        [86,89,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,23],
        [0,0,0,0,38]
      ],
      [
        [0,0,0,0,17],
        [0,0,0,0,33],
        [0,0,0,14,49]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,0,44],
        [0,0,0,22,59]
      ],
      [
        [0,0,0,0,33],
        [0,0,0,15,51],
        [0,0,0,29,65]
      ],
      [
        [0,0,0,0,41],
        [0,0,0,22,59],
        [0,0,0,37,72]
      ],
      [
        [0,0,0,14,50],
        [0,0,0,29,66],
        [0,0,13,45,77]
      ],
      [
        [0,0,0,21,58],
        [0,0,0,38,72],
        [0,0,19,54,82]
      ],
      [
        [0,0,0,30,66],
        [0,0,14,47,78],
        [0,13,27,62,86]
      ],
      [
        [0,0,0,39,74],
        [13,16,22,57,83],
        [13,21,37,70,89]
      ],
      [
        [0,0,16,50,80],
        [21,24,31,66,88],
        [21,30,47,77,100]
      ],
      [
        [0,14,25,60,85],
        [31,35,43,74,100],
        [32,41,58,83,100]
      ],
      [
        [16,23,37,70,89],
        [43,47,54,81,100],
        [44,53,68,88,100]
      ],
      [
        [27,35,50,79,100],
        [56,59,66,87,100],
        [56,65,77,100,100]
      ],
      [
        [41,49,63,85,100],
        [68,71,76,100,100],
        [68,75,84,100,100]
      ],
      [
        [56,63,74,100,100],
        [78,80,84,100,100],
        [78,83,100,100,100]
      ],
      [
        [76,81,87,100,100],
        [89,100,100,100,100],
        [89,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,12]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,20]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,0,27]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,20],
        [0,0,0,0,35]
      ],
      [
        [0,0,0,0,13],
        [0,0,0,0,27],
        [0,0,0,0,43]
      ],
      [
        [0,0,0,0,20],
        [0,0,0,0,36],
        [0,0,0,16,52]
      ],
      [
        [0,0,0,0,28],
        [0,0,0,11,45],
        [0,0,0,23,61]
      ],
      [
        [0,0,0,0,37],
        [0,0,0,18,55],
        [0,0,0,33,69]
      ],
      [
        [0,0,0,13,48],
        [0,0,0,27,64],
        [0,0,12,43,76]
      ],
      [
        [0,0,0,22,59],
        [0,0,0,38,73],
        [0,0,20,54,82]
      ],
      [
        [0,0,0,33,69],
        [0,12,16,50,80],
        [0,16,30,65,87]
      ],
      [
        [0,0,13,46,77],
        [18,21,27,62,86],
        [19,27,43,74,100]
      ],
      [
        [0,13,24,59,85],
        [31,34,41,73,100],
        [31,40,57,82,100]
      ],
      [
        [19,25,39,71,100],
        [46,50,56,82,100],
        [46,55,70,89,100]
      ],
      [
        [43,50,63,86,100],
        [69,72,76,100,100],
        [70,76,85,100,100]
      ],
      [
        [70,76,83,100,100],
        [86,88,100,100,100],
        [87,100,100,100,100]
      ],
      [
        [89,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,18],
        [0,0,0,0,33]
      ],
      [
        [0,0,0,0,13],
        [0,0,0,0,27],
        [0,0,0,0,43]
      ],
      [
        [0,0,0,0,21],
        [0,0,0,0,38],
        [0,0,0,17,53]
      ],
      [
        [0,0,0,0,28],
        [0,0,0,12,45],
        [0,0,0,23,61]
      ],
      [
        [0,0,0,0,36],
        [0,0,0,17,53],
        [0,0,0,31,67]
      ],
      [
        [0,0,0,11,44],
        [0,0,0,24,61],
        [0,0,0,39,73]
      ],
      [
        [0,0,0,17,53],
        [0,0,0,32,68],
        [0,0,15,48,79]
      ],
      [
        [0,0,0,24,61],
        [0,0,11,41,75],
        [0,0,22,57,84]
      ],
      [
        [0,0,0,34,69],
        [0,12,17,51,81],
        [0,16,31,66,88]
      ],
      [
        [0,0,12,44,77],
        [16,19,26,61,86],
        [17,24,42,73,100]
      ],
      [
        [0,0,20,55,83],
        [25,29,37,70,89],
        [26,35,53,80,0]
      ],
      [
        [12,18,31,66,88],
        [37,41,49,78,100],
        [37,47,64,86,100]
      ],
      [
        [22,29,44,75,100],
        [50,54,61,85,100],
        [50,59,73,100,100]
      ],
      [
        [35,43,58,83,100],
        [63,66,72,100,100],
        [63,71,82,100,100]
      ],
      [
        [50,58,70,89,100],
        [74,77,81,100,100],
        [75,80,88,100,100]
      ],
      [
        [72,78,85,100,100],
        [87,89,100,100,100],
        [87,100,100,100,100]
      ],
      [
        [88,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,14]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,11],
        [0,0,0,0,21]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,19],
        [0,0,0,0,31]
      ],
      [
        [0,0,0,0,12],
        [0,0,0,0,26],
        [0,0,0,0,39]
      ],
      [
        [0,0,0,0,17],
        [0,0,0,0,33],
        [0,0,0,12,47]
      ],
      [
        [0,0,0,0,24],
        [0,0,0,0,42],
        [0,0,0,18,55]
      ],
      [
        [0,0,0,0,33],
        [0,0,0,15,50],
        [0,0,0,26,63]
      ],
      [
        [0,0,0,0,42],
        [0,0,0,22,59],
        [0,0,0,35,70]
      ],
      [
        [0,0,0,16,52],
        [0,0,0,31,67],
        [0,0,13,45,77]
      ],
      [
        [0,0,0,24,62],
        [0,0,11,42,75],
        [0,0,20,55,83]
      ],
      [
        [0,0,0,35,71],
        [11,13,18,53,81],
        [11,16,30,65,87]
      ],
      [
        [0,0,14,47,78],
        [19,22,29,64,87],
        [19,27,42,74,100]
      ],
      [
        [0,13,24,59,85],
        [30,34,42,73,100],
        [31,39,55,81,100]
      ],
      [
        [17,24,38,71,100],
        [44,48,55,82,100],
        [44,53,67,87,100]
      ],
      [
        [31,38,53,80,100],
        [59,62,68,88,],
        [59,66,78,100,100]
      ],
      [
        [56,63,74,100,100],
        [78,80,84,100,100],
        [78,83,89,100,100]
      ],
      [
        [79,83,89,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,0]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,0],
        [0,0,0,0,13]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,0,19]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,21],
        [0,0,0,0,27]
      ],
      [
        [0,0,0,0,15],
        [0,0,0,0,29],
        [0,0,0,0,37]
      ],
      [
        [0,0,0,0,23],
        [0,0,0,0,40],
        [0,0,0,13,47]
      ],
      [
        [0,0,0,0,33],
        [0,0,0,15,51],
        [0,0,0,21,58]
      ],
      [
        [0,0,0,11,45],
        [0,0,0,25,62],
        [0,0,0,32,68]
      ],
      [
        [0,0,0,21,58],
        [0,0,0,37,72],
        [0,0,13,45,77]
      ],
      [
        [0,0,0,34,69],
        [0,12,17,51,81],
        [11,14,24,59,84]
      ],
      [
        [0,0,15,49,79],
        [21,24,31,65,87],
        [21,27,38,71,100]
      ],
      [
        [19,25,39,71,100],
        [46,50,56,82,100],
        [46,52,63,85,100]
      ],
      [
        [48,55,67,87,100],
        [73,75,79,100,100],
        [73,77,83,100,100]
      ],
      [
        [78,82,88,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
  ]
];

// Index order: [start_height, hand_distance, object_weight, lower_distance, frequency]
const loweringMale = [
  [
    [
      [
        [0,0,23,47,73],
        [0,14,18,51,76],
        [21,28,42,64,83]
      ],
      [
        [0,13,27,51,75],
        [0,17,32,55,78],
        [24,32,46,67,84]
      ],
      [
        [0,16,31,55,78],
        [13,20,36,59,80],
        [29,36,50,70,86]
      ],
      [
        [13,20,35,58,80],
        [16,24,40,62,82],
        [33,40,54,72,87]
      ],
      [
        [16,24,40,62,82],
        [20,28,45,66,84],
        [37,45,58,75,88]
      ],
      [
        [20,28,45,66,84],
        [24,33,49,69,85],
        [42,50,62,78,89]
      ],
      [
        [24,33,50,69,85],
        [29,38,54,72,87],
        [47,54,66,80,100]
      ],
      [
        [29,38,54,73,87],
        [34,43,59,76,88],
        [52,59,69,82,100]
      ],
      [
        [35,44,59,76,89],
        [40,49,63,78,100],
        [57,63,73,84,100]
      ],
      [
        [40,49,64,79,100],
        [45,54,67,81,100],
        [62,67,76,86,100]
      ],
      [
        [46,55,68,82,100],
        [51,59,71,83,100],
        [66,72,79,88,100]
      ],
      [
        [52,60,72,84,100],
        [57,64,75,86,100],
        [71,75,82,100,100]
      ],
      [
        [58,66,76,86,100],
        [62,69,79,88,100],
        [75,79,85,100,100]
      ],
      [
        [64,71,80,88,100],
        [68,74,82,89,100],
        [78,82,87,100,100]
      ],
      [
        [70,75,83,100,100],
        [73,78,85,100,100],
        [82,85,89,100,100]
      ],
      [
        [75,79,86,100,100],
        [77,82,87,100,100],
        [85,87,100,100,100]
      ],
      [
        [79,83,88,100,100],
        [81,85,89,100,100],
        [88,100,100,100,100]
      ],
      [
        [83,10,100,100,100],
        [85,100,100,100,100],
        [100,100,100,100,]
      ],
    ],
    [
      [
        [0,0,11,31,62],
        [0,0,14,36,66],
        [0,14,26,50,75]
      ],
      [
        [0,0,14,35,65],
        [0,0,17,40,69],
        [11,17,30,54,77]
      ],
      [
        [0,0,17,40,69],
        [0,0,21,44,72],
        [14,20,35,58,80]
      ],
      [
        [0,0,21,44,71],
        [0,11,25,69,74],
        [17,24,39,61,81]
      ],
      [
        [0,11,25,69,74],
        [0,14,29,53,77],
        [21,29,44,65,83]
      ],
      [
        [0,14,29,53,77],
        [11,18,34,57,79],
        [25,33,48,68,85]
      ],
      [
        [11,18,34,57,79],
        [14,22,39,61,81],
        [30,38,53,72,87]
      ],
      [
        [15,23,39,62,82],
        [18,27,44,66,84],
        [35,44,58,75,88]
      ],
      [
        [19,28,45,66,84],
        [23,32,49,69,85],
        [41,49,62,78,89]
      ],
      [
        [24,33,50,70,86],
        [28,38,55,73,89],
        [46,85,66,80,100]
      ],
      [
        [29,39,56,74,88],
        [34,44,60,76,89],
        [52,59,71,83,100]
      ],
      [
        [36,45,61,88,89],
        [40,50,65,80,100],
        [58,64,74,85,100]
      ],
      [
        [42,52,66,80,100],
        [47,56,70,82,100],
        [63,69,78,87,100]
      ],
      [
        [49,58,71,83,100],
        [54,62,74,85,100],
        [68,74,81,89,100]
      ],
      [
        [56,64,76,86,100],
        [60,68,78,87,100],
        [73,78,84,100,100]
      ],
      [
        [63,70,80,88,100],
        [67,73,82,89,100],
        [78,82,87,100,100]
      ],
      [
        [69,75,83,100,100],
        [72,78,85,100,100],
        [82,85,89,100,100]
      ],
      [
        [75,80,87,100,100],
        [78,82,88,100,100],
        [85,88,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,33],
        [0,0,0,0,38],
        [0,0,0,20,52]
      ],
      [
        [0,0,0,0,37],
        [0,0,0,12,42],
        [0,0,0,24,55]
      ],
      [
        [0,0,0,12,41],
        [0,0,0,15,46],
        [0,0,0,28,59]
      ],
      [
        [0,0,0,15,46],
        [0,0,0,19,50],
        [0,0,11,32,63]
      ],
      [
        [0,0,0,19,50],
        [0,0,0,23,54],
        [0,0,15,36,66]
      ],
      [
        [0,0,0,23,54],
        [0,0,0,27,59],
        [0,0,18,41,70]
      ],
      [
        [0,0,0,27,59],
        [0,0,11,32,63],
        [0,0,23,46,73]
      ],
      [
        [0,0,12,32,63],
        [0,0,15,37,67],
        [0,13,27,51,76]
      ],
      [
        [0,0,15,38,67],
        [0,0,19,43,70],
        [0,17,33,56,79]
      ],
      [
        [0,0,20,43,71],
        [0,0,24,48,74],
        [14,21,38,61,81]
      ],
      [
        [0,0,26,49,75],
        [0,13,30,54,77],
        [18,27,44,66,84]
      ],
      [
        [0,14,32,55,78],
        [0,18,36,59,80],
        [24,33,50,70,86]
      ],
      [
        [11,19,38,61,81],
        [14,23,43,65,83],
        [30,40,56,74,88]
      ],
      [
        [16,26,45,66,84],
        [20,30,50,70,86],
        [37,47,62,78,100]
      ],
      [
        [22,33,52,71,86],
        [26,38,57,74,88],
        [44,54,68,81,100]
      ],
      [
        [30,41,60,76,89],
        [34,46,63,79,100],
        [52,61,73,85,100]
      ],
      [
        [38,49,66,80,100],
        [43,54,70,82,100],
        [60,67,78,87,100]
      ],
      [
        [47,58,73,84,100],
        [52,62,75,86,100],
        [67,74,82,100,100]
      ],
    ],
  ],
  [
    [
      [
        [15,17,20,36,62],
        [21,25,33,51,73],
        [41,46,54,68,83]
      ],
      [
        [19,21,24,41,66],
        [26,30,38,55,78],
        [46,50,58,71,85]
      ],
      [
        [24,25,29,47,70],
        [31,35,44,60,79],
        [51,55,63,75,87]
      ],
      [
        [29,31,35,52,74],
        [36,41,49,64,81],
        [56,60,67,78,89]
      ],
      [
        [34,36,41,57,77],
        [42,47,55,69,84],
        [61,65,71,81,100]
      ],
      [
        [40,43,47,62,80],
        [48,52,60,73,86],
        [68,69,75,83,100]
      ],
      [
        [47,49,53,67,83],
        [54,58,65,76,88],
        [71,73,78,86,100]
      ],
      [
        [53,55,59,72,85],
        [60,64,70,80,100],
        [75,77,81,88,100]
      ],
      [
        [60,61,65,78,88],
        [66,69,74,83,100],
        [79,81,84,100,100]
      ],
      [
        [66,67,70,80,100],
        [71,76,79,86,100],
        [82,84,87,100,100]
      ],
      [
        [71,73,75,83,100],
        [76,78,82,88,100],
        [85,87,89,100,100]
      ],
      [
        [76,78,80,87,100],
        [80,82,86,100,100],
        [88,89,100,100,100]
      ],
      [
        [81,82,84,89,100],
        [84,86,88,100,100],
        [100,100,100,100,100]
      ],
      [
        [85,86,87,100,100],
        [88,89,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [88,89,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,22,49],
        [0,13,19,36,62],
        [27,31,39,56,76]
      ],
      [
        [0,0,12,26,54],
        [14,17,24,41,66],
        [32,36,44,60,79]
      ],
      [
        [12,13,16,32,59],
        [18,21,29,46,70],
        [67,41,49,64,81]
      ],
      [
        [16,17,21,37,63],
        [22,26,34,51,73],
        [42,47,54,69,84]
      ],
      [
        [21,22,26,43,68],
        [58,32,40,57,77],
        [48,52,59,72,86]
      ],
      [
        [26,28,32,49,72],
        [34,38,46,62,80],
        [54,58,64,76,88]
      ],
      [
        [32,34,38,55,76],
        [40,44,52,67,83],
        [59,63,69,79,89]
      ],
      [
        [39,41,45,61,79],
        [47,51,58,71,85],
        [65,58,74,82,100]
      ],
      [
        [46,48,52,66,82],
        [53,57,64,76,88],
        [70,77,81,88,100]
      ],
      [
        [53,55,58,72,85],
        [60,64,70,80,100],
        [75,77,81,88,100]
      ],
      [
        [60,62,65,76,88],
        [67,70,75,83,100],
        [79,81,84,100,100]
      ],
      [
        [67,68,71,81,100],
        [72,75,79,86,100],
        [83,85,87,100,100]
      ],
      [
        [73,74,77,85,100],
        [78,80,83,89,100],
        [86,88,100,100,100]
      ],
      [
        [79,80,82,88,100],
        [82,84,87,100,100],
        [89,100,100,100,100]
      ],
      [
        [84,84,86,100,100],
        [86,88,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [88,88,89,100,100],
        [100,100,100,100,100],
        [100,100,100,100,]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,20],
        [0,0,0,0,34],
        [0,0,12,27,54]
      ],
      [
        [0,0,0,0,25],
        [0,0,0,14,39],
        [0,11,16,31,58]
      ],
      [
        [0,0,0,0,30],
        [0,0,0,18,44],
        [12,14,20,37,63]
      ],
      [
        [0,0,0,11,35],
        [0,0,0,22,50],
        [15,18,25,42,67]
      ],
      [
        [0,0,0,15,41],
        [0,0,13,28,55],
        [20,23,31,48,71]
      ],
      [
        [0,0,0,20,47],
        [0,12,18,34,60],
        [25,29,37,54,75]
      ],
      [
        [0,0,12,26,53],
        [14,17,23,40,56],
        [32,35,43,59,78]
      ],
      [
        [13,14,16,32,59],
        [19,22,29,47,70],
        [38,42,50,65,82]
      ],
      [
        [18,20,22,39,65],
        [25,29,36,53,75],
        [45,49,56,70,84]
      ],
      [
        [25,26,29,47,70],
        [32,36,44,50,79],
        [53,56,63,85,87]
      ],
      [
        [32,34,37,54,75],
        [40,44,52,66,82],
        [60,63,69,79,89]
      ],
      [
        [41,43,46,62,80],
        [49,53,59,72,86],
        [67,69,74,83,100]
      ],
      [
        [50,52,55,69,84],
        [57,61,67,78,88],
        [73,75,79,86,100]
      ],
      [
        [59,61,63,85,87],
        [65,68,71,82,100],
        [78,80,84,89,100]
      ],
      [
        [68,69,71,81,100],
        [73,75,79,86,100],
        [59,61,63,75,87]
      ],
      [
        [75,76,78,85,100],
        [80,81,84,100,100],
        [87,89,100,100,100]
      ],
      [
        [82,83,84,89,100],
        [85,86,89,100,100],
        [87,89,100,100,100]
      ],
      [
        [87,88,89,100,100],
        [89,100,100,100,100],
        [100,100,100,100,10]
      ],
    ],
  ],
  [
    [
      [
        [0,0,0,16,42],
        [0,0,14,28,56],
        [20,23,31,49,72]
      ],
      [
        [0,0,0,20,47],
        [0,11,17,33,60],
        [24,28,37,54,75]
      ],
      [
        [0,0,11,25,52],
        [12,15,22,39,65],
        [29,34,42,58,78]
      ],
      [
        [11,12,15,30,57],
        [16,20,27,45,69],
        [35,39,48,63,81]
      ],
      [
        [15,16,20,36,62],
        [21,25,33,50,73],
        [41,45,53,68,83]
      ],
      [
        [20,22,25,42,67],
        [27,31,39,56,76],
        [47,51,59,72,86]
      ],
      [
        [26,28,32,49,72],
        [33,38,46,62,80],
        [53,57,64,76,88]
      ],
      [
        [32,34,38,55,76],
        [40,45,53,67,83],
        [60,63,69,80,89]
      ],
      [
        [40,42,46,62,80],
        [48,52,59,72,86],
        [66,69,74,83,100]
      ],
      [
        [48,50,53,68,83],
        [55,59,66,77,88],
        [71,74,79,86,100]
      ],
      [
        [55,57,61,73,86],
        [62,66,72,81,100],
        [76,79,82,88,100]
      ],
      [
        [63,65,68,78,89],
        [69,72,77,85,100],
        [81,83,86,100,100]
      ],
      [
        [70,72,74,83,100],
        [75,78,82,88,100],
        [85,86,89,100,100]
      ],
      [
        [77,78,80,87,100],
        [81,83,86,100,100],
        [88,89,100,100,100]
      ],
      [
        [82,83,85,100,100],
        [85,87,89,100,100],
        [100,100,100,100,100]
      ],
      [
        [87,87,89,100,100],
        [89,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,27],
        [0,0,0,15,41],
        [0,12,18,34,60]
      ],
      [
        [0,0,0,0,32],
        [0,0,0,19,46],
        [12,15,22,39,65]
      ],
      [
        [0,0,0,13,37],
        [0,0,11,24,52],
        [16,20,27,44,69]
      ],
      [
        [0,0,0,17,43],
        [0,0,15,30,57],
        [21,25,33,50,72]
      ],
      [
        [0,0,0,22,49],
        [0,13,19,36,62],
        [26,31,39,56,76]
      ],
      [
        [0,0,13,27,55],
        [14,18,25,42,67],
        [33,37,45,61,79]
      ],
      [
        [13,15,18,34,61],
        [20,23,31,48,71],
        [39,43,51,66,82]
      ],
      [
        [19,20,24,41,66],
        [26,60,68,55,76],
        [46,50,58,71,85]
      ],
      [
        [25,27,31,48,71],
        [33,37,45,61,80],
        [53,57,64,76,87]
      ],
      [
        [33,35,39,56,76],
        [41,45,53,67,83],
        [60,64,70,80,100]
      ],
      [
        [41,43,47,63,80],
        [49,53,60,73,86],
        [67,80,75,83,100]
      ],
      [
        [50,52,56,69,54],
        [57,61,67,78,89],
        [73,75,80,87,100]
      ],
      [
        [59,61,64,76,87],
        [65,68,57,83,100],
        [78,80,84,89,100]
      ],
      [
        [67,69,71,81,100],
        [73,75,80,86,100],
        [83,85,87,100,100]
      ],
      [
        [75,76,78,85,100],
        [79,81,86,100,100],
        [87,88,100,100,100]
      ],
      [
        [81,82,84,89,100],
        [85,86,88,100,100],
        [100,100,100,100,100]
      ],
      [
        [87,87,88,100,100],
        [89,100,100,100,100],
        [100,100,100,100,100]
      ],
      [
        [100,100,100,100,100],
        [100,100,100,100,100],
        [100,100,100,100,100]
      ],
    ],
    [
      [
        [0,0,0,0,0],
        [0,0,0,0,14],
        [0,0,0,0,32]
      ],
      [
        [0,0,0,0,0],
        [0,0,0,0,18],
        [0,0,0,12,37]
      ],
      [
        [0,0,0,0,11],
        [0,0,0,0,22],
        [0,0,0,16,42]
      ],
      [
        [0,0,0,0,15],
        [0,0,0,0,28],
        [0,0,0,21,48]
      ],
      [
        [0,0,0,0,20],
        [0,0,0,0,34],
        [0,0,12,26,54]
      ],
      [
        [0,0,0,0,26],
        [0,0,0,14,40],
        [0,11,17,32,59]
      ],
      [
        [0,0,0,0,32],
        [0,0,0,19,46],
        [13,16,22,39,65]
      ],
      [
        [0,0,0,14,39],
        [0,0,12,26,53],
        [18,22,29,46,70]
      ],
      [
        [0,0,0,19,46],
        [0,12,17,33,60],
        [25,59,36,53,75]
      ],
      [
        [0,0,12,26,54],
        [14,17,24,41,66],
        [32,36,44,60,79]
      ],
      [
        [15,16,18,35,61],
        [21,24,32,49,72],
        [41,45,52,67,83]
      ],
      [
        [22,23,26,44,68],
        [29,33,41,57,77],
        [49,53,60,73,86]
      ],
      [
        [31,33,36,53,74],
        [39,43,50,65,82],
        [58,62,68,78,89]
      ],
      [
        [41,43,46,62,80],
        [49,53,60,73,86],
        [67,70,75,83,100]
      ],
      [
        [53,54,57,71,85],
        [60,63,69,79,89],
        [74,77,81,87,100]
      ],
      [
        [64,65,67,78,89],
        [69,72,77,84,100],
        [81,83,86,100,100]
      ],
      [
        [73,74,76,84,100],
        [78,80,83,89,100],
        [86,88,100,100,100]
      ],
      [
        [82,82,84,89,100],
        [85,86,88,100,100],
        [100,100,100,100,100]
      ],
    ],
  ]
];

// Index order: [initial_force, hand_height, frequency]
const pushingInitialFemale = [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,13],
      [0,0,0,0,13],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,11,17],
      [0,0,0,11,17],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,14,22],
      [0,0,0,15,22],
      [0,0,0,0,0]
    ],
    [
      [0,0,14,19,27],
      [0,0,14,20,28],
      [0,0,0,0,0]
    ],
    [
      [0,0,19,25,34],
      [0,0,19,25,34],
      [0,0,0,0,11]
    ],
    [
      [0,0,24,31,40],
      [0,0,25,31,41],
      [0,0,0,0,11]
    ],
    [
      [0,12,31,38,47],
      [0,12,31,38,48],
      [0,0,0,0,15]
    ],
    [
      [0,17,38,45,54],
      [0,17,39,46,55],
      [0,0,0,14,21]
    ],
    [
      [14,23,46,53,61],
      [13,24,46,53,62],
      [0,0,14,19,27]
    ],
    [
      [20,30,54,60,68],
      [19,31,54,61,68],
      [0,0,20,26,35]
    ],
    [
      [27,39,61,67,74],
      [26,39,62,68,74],
      [0,0,27,34,44]
    ],
    [
      [36,48,69,74,80],
      [34,48,69,74,80],
      [0,15,36,43,52]
    ],
    [
      [45,57,75,79,84],
      [44,57,76,80,84],
      [12,23,45,52,61]
    ],
    [
      [55,65,81,84,88],
      [54,66,81,85,88],
      [19,32,55,61,69]
    ],
    [
      [64,73,86,88,100],
      [63,74,86,89,100],
      [28,42,64,70,76]
    ],
    [
      [73,80,100,100,100],
      [72,80,100,100,100],
      [39,54,73,78,83]
    ],
    [
      [80,86,100,100,100],
      [79,86,100,100,100],
      [51,64,80,84,88]
    ],
    [
      [86,100,100,100,100],
      [86,100,100,100,100],
      [63,74,86,89,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [74,82,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [83,88,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [89,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
];

// Index order: [initial_force, hand_height, frequency]
const pushingInitialMale = [
  [
    [0,0,0,0,25],
    [0,0,13,14,36],
    [0,0,0,0,22]
  ],
  [
    [0,0,0,0,28],
    [0,0,15,16,39],
    [0,0,0,0,25]
  ],
  [
    [0,0,0,11,31],
    [0,12,18,19,42],
    [0,0,0,0,29]
  ],
  [
    [0,0,12,13,35],
    [0,14,20,21,46],
    [0,0,0,11,32]
  ],
  [
    [0,0,15,16,38],
    [0,17,23,25,49],
    [0,0,13,13,35]
  ],
  [
    [0,12,17,18,42],
    [11,19,27,28,52],
    [0,0,15,16,39]
  ],
  [
    [0,14,20,21,46],
    [13,23,30,31,56],
    [0,12,18,19,43]
  ],
  [
    [0,17,24,25,49],
    [16,26,34,35,59],
    [0,15,21,22,46]
  ],
  [
    [12,20,27,28,53],
    [19,30,38,39,63],
    [0,18,24,26,50]
  ],
  [
    [14,23,31,32,57],
    [23,34,42,43,66],
    [12,21,28,29,54]
  ],
  [
    [17,27,35,36,60],
    [26,38,46,47,69],
    [14,24,32,33,58]
  ],
  [
    [21,31,39,41,64],
    [30,42,50,51,72],
    [17,28,36,68,61]
  ],
  [
    [25,36,44,45,67],
    [35,46,54,55,75],
    [21,33,41,42,65]
  ],
  [
    [29,40,48,49,71],
    [39,51,58,59,77],
    [25,37,45,46,68]
  ],
  [
    [33,45,53,54,74],
    [44,55,62,63,80],
    [29,42,50,51,72]
  ],
  [
    [38,50,57,58,77],
    [49,59,66,67,82],
    [34,47,54,56,75]
  ],
  [
    [43,54,62,63,79],
    [53,64,70,71,84],
    [39,51,59,60,78]
  ],
  [
    [48,59,66,67,82],
    [58,68,73,74,86],
    [44,56,63,64,80]
  ],
  [
    [53,64,70,71,84],
    [63,72,77,77,88],
    [50,61,68,68,83]
  ],
  [
    [59,68,74,74,86],
    [67,75,80,80,100],
    [55,66,72,72,85]
  ],
  [
    [63,72,77,78,88],
    [71,78,82,83,100],
    [60,70,75,76,87]
  ],
  [
    [68,76,80,81,100],
    [75,82,85,85,100],
    [65,74,79,79,89]
  ],
  [
    [73,79,83,84,100],
    [79,84,87,88,100],
    [70,78,82,82,100]
  ],
  [
    [77,83,86,86,100],
    [82,87,89,100,100],
    [74,81,85,85,100]
  ],
  [
    [80,85,88,89,100],
    [85,89,100,100,100],
    [78,84,87,88,100]
  ],
  [
    [84,88,100,100,100],
    [88,100,100,100,100],
    [82,87,89,100,100]
  ],
  [
    [87,100,100,100,100],
    [100,100,100,100,100],
    [85,88,100,100,100]
  ],
  [
    [89,100,100,100,100],
    [100,100,100,100,100],
    [88,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
];

// Index order: [distance, sustained_force, hand_height, frequency]
const pushingSustainedFemale = [
  [
    [
      [0,0,0,0,23],
      [0,0,0,0,16],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,28],
      [0,0,0,0,21],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,14,34],
      [0,0,0,0,26],
      [0,0,0,0,13]
    ],
    [
      [0,0,13,19,40],
      [0,0,0,12,32],
      [0,0,0,0,18]
    ],
    [
      [0,0,18,25,47],
      [0,0,12,17,39],
      [0,0,0,0,24]
    ],
    [
      [0,13,24,31,54],
      [0,0,17,23,46],
      [0,0,0,11,30]
    ],
    [
      [0,19,31,39,60],
      [0,13,23,30,53],
      [0,0,11,17,38]
    ],
    [
      [16,26,40,47,66],
      [0,19,31,39,60],
      [0,0,17,24,46]
    ],
    [
      [23,35,48,55,72],
      [16,27,40,47,67],
      [0,14,25,32,54]
    ],
    [
      [32,44,57,63,77],
      [24,36,49,56,73],
      [12,21,34,42,62]
    ],
    [
      [43,54,65,70,82],
      [34,46,59,65,78],
      [20,31,45,52,70]
    ],
    [
      [54,64,73,77,86],
      [46,57,67,72,83],
      [30,43,55,62,77]
    ],
    [
      [64,73,80,83,89],
      [57,67,75,79,87],
      [43,55,66,71,82]
    ],
    [
      [74,80,85,87,100],
      [69,76,82,85,100],
      [57,67,75,79,87]
    ],
    [
      [82,86,89,100,100],
      [78,83,87,89,100],
      [70,77,83,87,89]
    ],
    [
      [88,100,100,100,100],
      [86,89,100,100,100],
      [80,85,89,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [88,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,12],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,12],
      [0,0,0,0,16],
      [0,0,0,0,11]
    ],
    [
      [0,0,0,0,17],
      [0,0,0,0,22],
      [0,0,0,0,15]
    ],
    [
      [0,0,0,0,23],
      [0,0,0,0,29],
      [0,0,0,0,21]
    ],
    [
      [0,0,0,11,30],
      [0,0,0,15,36],
      [0,0,0,0,28]
    ],
    [
      [0,0,12,17,39],
      [0,0,16,22,44],
      [0,0,0,15,36]
    ],
    [
      [0,0,18,25,47],
      [0,12,23,30,53],
      [0,0,16,23,45]
    ],
    [
      [13,15,27,34,56],
      [12,20,32,40,61],
      [0,13,25,32,54]
    ],
    [
      [21,24,37,44,65],
      [20,29,43,50,69],
      [18,22,35,42,63]
    ],
    [
      [32,35,48,55,72],
      [31,41,54,60,75],
      [28,33,46,53,71]
    ],
    [
      [45,48,60,66,79],
      [44,53,64,70,82],
      [41,46,58,64,78]
    ],
    [
      [58,61,71,75,85],
      [57,65,74,78,86],
      [55,59,69,74,84]
    ],
    [
      [71,73,80,83,89],
      [70,76,82,85,100],
      [68,71,79,82,89]
    ],
    [
      [81,82,87,89,100],
      [81,84,88,100,100],
      [79,81,86,88,100]
    ],
    [
      [89,89,100,100,100],
      [88,100,100,100,100],
      [88,89,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,12],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,14],
      [0,0,0,0,18],
      [0,0,0,0,12]
    ],
    [
      [0,0,0,0,20],
      [0,0,0,0,25],
      [0,0,0,0,18]
    ],
    [
      [0,0,0,0,28],
      [0,0,0,14,34],
      [0,0,0,0,26]
    ],
    [
      [0,0,11,17,38],
      [0,0,15,21,43],
      [0,0,0,15,35]
    ],
    [
      [0,0,19,26,48],
      [0,13,24,31,53],
      [0,0,17,23,46]
    ],
    [
      [0,17,30,37,58],
      [0,22,35,43,63],
      [0,16,27,35,57]
    ],
    [
      [16,29,42,50,68],
      [14,35,48,55,72],
      [13,27,40,47,67]
    ],
    [
      [29,44,56,62,77],
      [26,49,61,67,80],
      [25,41,54,60,76]
    ],
    [
      [46,59,69,74,84],
      [43,64,73,77,86],
      [42,57,68,72,83]
    ],
    [
      [64,73,80,83,89],
      [61,76,82,85,100],
      [60,72,79,82,89]
    ],
    [
      [79,84,88,100,100],
      [77,86,89,100,100],
      [76,83,88,89,100]
    ],
    [
      [89,100,100,100,100],
      [88,100,100,100,100],
      [88,100,100,100,100]
    ],
  ],
];

// Index order: [distance, sustained_force, hand_height, frequency]
const pushingSustainedMale = [
  [
    [
      [0,0,0,0,23],
      [0,0,0,11,28],
      [0,0,0,0,27]
    ],
    [
      [0,0,0,12,29],
      [0,0,12,15,34],
      [0,0,11,14,32]
    ],
    [
      [0,0,13,16,35],
      [0,0,17,20,40],
      [0,0,16,19,39]
    ],
    [
      [0,0,18,22,42],
      [0,0,22,26,47],
      [0,0,21,25,45]
    ],
    [
      [0,0,24,28,49],
      [0,13,29,33,53],
      [0,12,28,32,52]
    ],
    [
      [0,14,31,36,56],
      [0,18,36,41,60],
      [0,17,35,39,59]
    ],
    [
      [0,21,39,44,63],
      [13,25,44,49,67],
      [12,24,43,47,66]
    ],
    [
      [16,29,48,52,69],
      [20,33,53,57,72],
      [19,32,52,55,72]
    ],
    [
      [23,38,57,60,75],
      [58,43,61,64,78],
      [27,42,60,63,77]
    ],
    [
      [33,48,65,68,80],
      [38,52,69,71,82],
      [37,51,68,71,82]
    ],
    [
      [44,58,72,75,85],
      [49,62,76,78,86],
      [47,61,75,77,86]
    ],
    [
      [55,67,79,81,88],
      [59,71,82,83,100],
      [58,70,81,83,89]
    ],
    [
      [66,76,85,86,100],
      [70,78,86,88,100],
      [69,78,86,87,100]
    ],
    [
      [76,83,89,100,100],
      [78,85,100,100,100],
      [78,84,100,100,100]
    ],
    [
      [84,88,100,100,100],
      [85,100,100,100,100],
      [85,89,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,11],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,16],
      [0,0,0,0,14],
      [0,0,0,0,11]
    ],
    [
      [0,0,0,0,21],
      [0,0,0,0,20],
      [0,0,0,0,16]
    ],
    [
      [0,0,0,11,27],
      [0,0,0,0,26],
      [0,0,0,0,22]
    ],
    [
      [0,0,13,16,35],
      [0,0,12,15,33],
      [0,0,0,12,29]
    ],
    [
      [0,0,19,23,43],
      [0,0,18,21,41],
      [0,0,14,17,37]
    ],
    [
      [0,11,27,31,51],
      [0,0,25,29,50],
      [0,0,21,25,45]
    ],
    [
      [0,18,36,50,60],
      [0,16,34,38,58],
      [0,13,29,34,54]
    ],
    [
      [0,26,46,50,67],
      [0,25,44,48,66],
      [0,21,39,44,63]
    ],
    [
      [11,37,56,60,75],
      [14,36,55,58,74],
      [13,31,50,54,71]
    ],
    [
      [20,49,66,69,81],
      [23,47,65,68,80],
      [23,43,61,64,78]
    ],
    [
      [32,61,75,77,86],
      [36,59,74,76,85],
      [35,55,71,73,84]
    ],
    [
      [47,72,82,86,100],
      [50,71,81,83,100],
      [50,67,79,81,88]
    ],
    [
      [62,81,88,89,65],
      [65,80,87,89,100],
      [65,78,86,87,100]
    ],
    [
      [75,88,100,100,100],
      [77,87,100,100,100],
      [77,86,100,100,100]
    ],
    [
      [86,100,100,100,100],
      [87,100,100,100,100],
      [87,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,13],
      [0,0,0,0,12],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,19],
      [0,0,0,0,18],
      [0,0,0,0,14]
    ],
    [
      [0,0,12,15,26],
      [0,0,12,14,24],
      [0,0,0,11,20]
    ],
    [
      [0,0,19,23,34],
      [0,0,18,22,33],
      [0,0,14,18,28]
    ],
    [
      [0,12,28,33,43],
      [0,11,27,31,42],
      [0,0,23,26,37]
    ],
    [
      [0,20,39,43,53],
      [0,19,38,42,52],
      [0,15,33,37,47]
    ],
    [
      [0,32,51,55,62],
      [0,30,50,53,61],
      [0,25,45,59,57]
    ],
    [
      [15,45,62,66,71],
      [19,43,61,65,70],
      [20,38,57,61,67]
    ],
    [
      [28,58,73,76,79],
      [33,57,72,75,78],
      [34,53,69,82,75]
    ],
    [
      [44,71,82,83,85],
      [49,70,81,83,85],
      [50,67,79,81,83]
    ],
    [
      [62,82,88,89,100],
      [66,81,88,89,100],
      [67,79,87,88,88]
    ],
    [
      [77,89,100,100,100],
      [80,89,100,100,100],
      [80,88,100,100,100]
    ],
    [
      [88,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
];

// Index order: [initial_force, hand_height, frequency]
const pullingInitialFemale = [
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,13]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,11],
    [0,0,0,0,17]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,15],
    [0,0,0,14,22]
  ],
  [
    [0,0,0,0,14],
    [0,0,0,12,20],
    [0,0,13,19,28]
  ],
  [
    [0,0,0,12,19],
    [0,0,12,17,26],
    [0,0,18,24,34]
  ],
  [
    [0,0,12,17,25],
    [0,0,17,23,32],
    [0,0,24,31,41]
  ],
  [
    [0,0,17,23,32],
    [0,0,22,30,40],
    [0,11,31,38,49]
  ],
  [
    [0,0,23,30,40],
    [0,0,29,37,47],
    [0,25,38,46,56]
  ],
  [
    [0,0,30,38,48],
    [0,15,37,45,55],
    [13,22,46,54,63]
  ],
  [
    [0,15,38,46,56],
    [13,21,46,53,63],
    [19,29,54,62,70]
  ],
  [
    [14,22,47,55,64],
    [19,29,54,62,70],
    [27,38,63,69,76]
  ],
  [
    [21,31,56,63,71],
    [27,38,63,69,76],
    [36,47,70,76,82]
  ],
  [
    [29,40,65,71,78],
    [37,48,71,76,82],
    [46,57,77,81,86]
  ],
  [
    [40,51,73,78,83],
    [47,58,78,82,87],
    [56,66,82,86,100]
  ],
  [
    [50,61,80,84,88],
    [58,67,83,87,100],
    [64,74,87,100,100]
  ],
  [
    [61,70,85,88,100],
    [68,76,88,100,100],
    [74,81,100,100,100]
  ],
  [
    [71,79,100,100,100],
    [76,83,100,100,100],
    [82,87,100,100,100]
  ],
  [
    [80,85,100,100,100],
    [84,88,100,100,100],
    [87,100,100,100,100]
  ],
  [
    [87,100,100,100,100],
    [89,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
];

// Index order: [initial_force, hand_height, frequency]
const pullingInitialMale = [
  [
    [0,0,0,0,0],
    [0,0,0,0,13],
    [0,0,0,0,29]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,16],
    [0,0,0,0,33]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,19],
    [0,0,0,11,37]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,22],
    [0,0,13,14,41]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,26],
    [0,0,16,17,45]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,30],
    [0,12,19,20,49]
  ],
  [
    [0,0,0,0,0],
    [0,0,0,0,34],
    [0,15,23,24,53]
  ],
  [
    [0,0,0,0,0],
    [0,0,12,13,39],
    [0,18,27,28,58]
  ],
  [
    [0,0,0,0,0],
    [0,0,15,16,43],
    [11,22,31,33,62]
  ],
  [
    [0,0,0,0,0],
    [0,11,18,19,48],
    [14,27,36,38,66]
  ],
  [
    [0,0,0,0,0],
    [0,15,22,24,53],
    [17,31,41,43,70]
  ],
  [
    [0,0,0,0,0],
    [0,18,27,28,58],
    [21,26,46,48,73]
  ],
  [
    [0,0,0,0,12],
    [11,23,32,33,62],
    [26,42,51,53,77]
  ],
  [
    [0,0,0,0,16],
    [14,28,37,39,67],
    [31,47,56,58,80]
  ],
  [
    [0,0,0,0,20],
    [19,33,43,44,71],
    [37,53,61,63,83]
  ],
  [
    [0,0,0,0,25],
    [23,39,49,50,75],
    [42,58,66,68,85]
  ],
  [
    [0,0,0,0,30],
    [29,45,54,56,79],
    [48,63,71,72,88]
  ],
  [
    [0,0,0,11,37],
    [35,51,60,62,82],
    [54,68,75,76,100]
  ],
  [
    [0,0,15,16,43],
    [42,57,66,67,85],
    [60,73,71,72,88]
  ],
  [
    [0,13,20,21,50],
    [48,63,71,72,88],
    [66,77,83,83,100]
  ],
  [
    [0,17,26,27,56],
    [55,69,76,77,100],
    [71,81,86,86,100]
  ],
  [
    [12,24,33,34,63],
    [62,74,80,81,100],
    [76,85,88,89,100]
  ],
  [
    [17,31,40,42,69],
    [68,79,84,84,100],
    [81,88,100,100,100]
  ],
  [
    [23,39,48,50,75],
    [74,83,87,88,100],
    [84,100,100,100,100]
  ],
  [
    [31,47,56,58,80],
    [79,86,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [40,56,64,66,84],
    [83,89,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [49,64,72,73,88],
    [87,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [59,72,78,79,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [68,78,83,84,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [76,84,88,88,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [82,89,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [88,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
  [
    [100,100,100,100,100],
    [100,100,100,100,100],
    [100,100,100,100,100]
  ],
];

// Index order: [distance, sustained_force, hand_height, frequency]
const pullingSustainedFemale = [
  [
    [
      [0,0,0,0,13],
      [0,0,0,0,11],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,19],
      [0,0,0,0,16],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,25],
      [0,0,0,0,21],
      [0,0,0,0,13]
    ],
    [
      [0,0,0,0,32],
      [0,0,0,0,29],
      [0,0,0,0,19]
    ],
    [
      [0,0,0,16,41],
      [0,0,0,13,37],
      [0,0,0,0,26]
    ],
    [
      [0,0,16,23,49],
      [0,0,13,20,46],
      [0,0,0,12,35]
    ],
    [
      [0,12,24,32,58],
      [0,0,21,29,55],
      [0,0,12,19,44]
    ],
    [
      [14,19,34,42,66],
      [12,16,30,39,63],
      [0,0,20,28,54]
    ],
    [
      [23,29,45,53,74],
      [20,26,41,50,72],
      [12,17,30,39,64]
    ],
    [
      [35,41,56,64,80],
      [31,38,53,61,79],
      [21,27,43,51,73]
    ],
    [
      [48,54,67,73,86],
      [45,51,65,71,85],
      [34,40,55,63,80]
    ],
    [
      [62,67,77,81,100],
      [59,64,75,80,89],
      [49,55,68,74,86]
    ],
    [
      [74,75,85,88,100],
      [72,76,83,86,],
      [64,69,78,82,100]
    ],
    [
      [84,86,100,100,100],
      [82,85,100,100,100],
      [77,80,87,89,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [87,89,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,13],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,19],
      [0,0,0,0,16],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,26],
      [0,0,0,0,22],
      [0,0,0,0,14]
    ],
    [
      [0,0,0,12,34],
      [0,0,0,0,31],
      [0,0,0,0,21]
    ],
    [
      [0,0,12,19,44],
      [0,0,0,16,40],
      [0,0,0,0,30]
    ],
    [
      [0,0,20,28,54],
      [0,0,17,24,50],
      [0,0,0,15,40]
    ],
    [
      [0,16,30,39,63],
      [0,13,26,35,60],
      [0,0,17,25,51]
    ],
    [
      [17,27,42,51,72],
      [14,23,38,47,70],
      [0,15,28,36,62]
    ],
    [
      [28,40,55,63,80],
      [25,36,52,59,78],
      [16,26,41,63,80]
    ],
    [
      [59,69,78,82,100],
      [56,66,78,81,100],
      [46,57,69,75,87]
    ],
    [
      [74,80,86,89,100],
      [71,78,85,88,100],
      [64,72,81,84,100]
    ],
    [
      [85,89,100,100,100],
      [84,88,100,100,100],
      [79,84,89,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [89,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,15],
      [0,0,0,0,12],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,22],
      [0,0,0,0,19],
      [0,0,0,0,11]
    ],
    [
      [0,0,0,0,32],
      [0,0,0,0,28],
      [0,0,0,0,19]
    ],
    [
      [0,0,12,18,43],
      [0,0,0,15,39],
      [0,0,0,0,29]
    ],
    [
      [0,0,21,29,55],
      [0,0,18,25,51],
      [0,0,0,16,41]
    ],
    [
      [0,19,33,42,66],
      [0,16,30,38,63],
      [0,0,20,28,54]
    ],
    [
      [0,33,48,56,74],
      [0,29,45,53,74],
      [0,19,34,43,66]
    ],
    [
      [23,50,64,70,84],
      [20,46,60,67,83],
      [12,35,51,59,77]
    ],
    [
      [43,67,77,81,100],
      [39,64,75,79,89],
      [28,55,68,73,86]
    ],
    [
      [64,81,87,89,100],
      [61,79,85,88,100],
      [51,73,81,85,100]
    ],
    [
      [81,100,100,100,100],
      [80,89,100,100,100],
      [74,86,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [89,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
];

// Index order: [distance, sustained_force, hand_height, frequency]
const pullingSustainedMale = [
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,18],
      [0,0,0,0,25]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,24],
      [0,0,0,0,31]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,31],
      [0,0,0,13,38]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,13,38],
      [0,0,0,19,46]
    ],
    [
      [0,0,0,0,11],
      [0,0,0,19,46],
      [0,0,0,26,54]
    ],
    [
      [0,0,0,0,17],
      [0,0,0,27,54],
      [0,0,15,31,61]
    ],
    [
      [0,0,0,0,25],
      [0,0,16,35,62],
      [0,0,22,43,69]
    ],
    [
      [0,0,0,11,34],
      [0,11,24,45,70],
      [0,16,31,53,75]
    ],
    [
      [0,0,0,18,45],
      [0,18,34,55,77],
      [16,25,41,62,81]
    ],
    [
      [0,0,11,28,56],
      [18,28,45,65,83],
      [25,36,53,71,86]
    ],
    [
      [0,0,19,40,66],
      [29,40,57,74,87],
      [37,48,63,78,100]
    ],
    [
      [0,17,32,53,76],
      [43,53,68,81,100],
      [50,61,73,85,100]
    ],
    [
      [20,30,46,66,83],
      [57,66,78,87,100],
      [64,72,82,100,100]
    ],
    [
      [35,46,62,77,89],
      [70,77,85,100,100],
      [76,8,88,100,100]
    ],
    [
      [54,63,75,86,100],
      [81,86,100,100,100],
      [85,69,100,100,100]
    ],
    [
      [71,78,86,100,100],
      [89,100,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [86,100,100,100,100],
      [87,100,100,100,100],
      [87,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,13]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,13],
      [0,0,0,0,19]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,19],
      [0,0,0,0,26]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,27],
      [0,0,0,11,35]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,12,36],
      [0,0,0,17,44]
    ],
    [
      [0,0,0,0,11],
      [0,0,0,19,46],
      [0,0,0,25,53]
    ],
    [
      [0,0,0,0,18],
      [0,0,0,28,56],
      [0,0,16,36,63]
    ],
    [
      [0,0,0,0,28],
      [0,0,18,39,65],
      [0,0,25,47,71]
    ],
    [
      [0,0,0,15,41],
      [0,0,29,51,74],
      [0,12,37,58,79]
    ],
    [
      [0,0,0,26,54],
      [0,16,42,63,81],
      [0,23,50,69,85]
    ],
    [
      [0,0,20,41,67],
      [0,29,56,74,87],
      [14,37,64,79,100]
    ],
    [
      [0,11,35,57,78],
      [21,46,70,83,100],
      [29,53,76,86,100]
    ],
    [
      [0,26,54,72,86],
      [39,63,81,89,100],
      [47,69,85,100,100]
    ],
    [
      [23,47,71,84,100],
      [60,78,89,100,100],
      [67,82,100,100,100]
    ],
    [
      [48,70,185,100,100],
      [78,89,100,100,100],
      [82,100,100,100,100]
    ],
    [
      [74,86,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,0],
      [0,0,0,0,11]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,11],
      [0,0,0,0,17]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,18],
      [0,0,0,0,24],
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,26],
      [0,0,0,0,34]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,12,36],
      [0,0,0,28,44]
    ],
    [
      [0,0,0,0,12],
      [0,0,0,20,48],
      [0,0,14,27,55]
    ],
    [
      [0,0,0,0,22],
      [0,13,17,32,59],
      [0,19,23,40,66]
    ],
    [
      [0,0,0,11,34],
      [0,23,29,45,70],
      [0,31,36,53,75]
    ],
    [
      [0,0,0,22,49],
      [12,38,43,59,79],
      [18,46,51,66,83]
    ],
    [
      [0,17,22,37,64],
      [26,54,59,72,86],
      [33,61,66,77,89]
    ],
    [
      [0,34,40,56,77],
      [44,70,74,83,100],
      [52,75,78,86,100]
    ],
    [
      [27,56,61,73,87],
      [64,82,85,100,100],
      [70,86,88,100,100]
    ],
    [
      [52,75,79,86,100],
      [81,100,100,100,100],
      [84,100,100,100,100]
    ],
    [
      [88,100,100,100,100],
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
];

// Index order: [distance, object_weight, hand_height, frequency]
const carryingFemale = [
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,21]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,28]
    ],
    [
      [0,0,0,0,15],
      [0,0,0,0,36]
    ],
    [
      [0,0,0,0,22],
      [0,0,0,0,45]
    ],
    [
      [0,0,0,0,30],
      [0,0,0,0,54]
    ],
    [
      [0,0,0,0,40],
      [0,0,0,11,63]
    ],
    [
      [0,0,0,0,50],
      [0,0,14,18,71]
    ],
    [
      [0,0,0,0,60],
      [0,14,22,27,79]
    ],
    [
      [0,0,13,17,70],
      [18,22,33,39,85]
    ],
    [
      [0,13,22,27,78],
      [28,34,45,51,89]
    ],
    [
      [17,23,34,40,85],
      [41,47,58,63,100]
    ],
    [
      [29,36,48,54,100],
      [55,60,70,74,100]
    ],
    [
      [44,51,62,67,100],
      [68,72,80,83,100]
    ],
    [
      [60,66,75,79,100],
      [79,82,87,89,100]
    ],
    [
      [74,79,85,87,100],
      [88,100,100,100,100]
    ],
    [
      [85,88,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,70]
    ],
    [
      [0,0,0,0,13],
      [0,0,0,0,21]
    ],
    [
      [0,0,0,0,19],
      [0,0,0,0,29]
    ],
    [
      [0,0,0,0,27],
      [0,0,0,0,38]
    ],
    [
      [0,0,0,0,36],
      [0,0,0,0,47]
    ],
    [
      [0,0,0,0,46],
      [0,0,0,0,57]
    ],
    [
      [0,0,0,0,57],
      [0,0,11,14,67]
    ],
    [
      [0,0,11,14,67],
      [0,0,18,23,75]
    ],
    [
      [0,0,19,24,76],
      [0,0,29,34,82]
    ],
    [
      [0,0,31,36,83],
      [0,13,42,47,88]
    ],
    [
      [0,15,44,50,89],
      [0,23,55,61,100]
    ],
    [
      [11,27,59,64,100],
      [19,38,69,73,100]
    ],
    [
      [23,43,72,76,100],
      [34,54,80,83,100]
    ],
    [
      [40,60,83,86,100],
      [51,70,88,100,100]
    ],
    [
      [59,76,100,100,100],
      [69,82,100,100,100]
    ],
    [
      [77,87,100,100,100],
      [83,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,0],
      [0,0,0,0,0]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,12]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,18]
    ],
    [
      [0,0,0,0,0],
      [0,0,0,0,25]
    ],
    [
      [0,0,0,0,13],
      [0,0,0,0,34]
    ],
    [
      [0,0,0,0,20],
      [0,0,0,0,43]
    ],
    [
      [0,0,0,0,29],
      [0,0,0,0,54]
    ],
    [
      [0,0,0,0,39],
      [0,0,0,12,63]
    ],
    [
      [0,0,0,0,51],
      [0,15,15,20,73]
    ],
    [
      [0,0,0,11,62],
      [12,25,25,30,80]
    ],
    [
      [0,15,15,20,70],
      [22,37,37,43,86]
    ],
    [
      [18,27,27,32,81],
      [35,51,51,57,100]
    ],
    [
      [31,41,41,47,88],
      [50,65,65,70,100]
    ],
    [
      [47,57,57,63,100],
      [65,77,77,81,100]
    ],
    [
      [64,72,72,76,100],
      [78,86,86,88,100]
    ],
    [
      [78,84,84,86,100],
      [87,100,100,100,100]
    ],
    [
      [88,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
];

// Index order: [distance, object_weight, hand_height, frequency]
const carryingMale = [
  [
    [
      [0,0,0,18,50],
      [0,11,22,36,67]
    ],
    [
      [0,0,12,23,56],
      [0,15,28,42,71]
    ],
    [
      [0,0,17,29,62],
      [14,20,35,49,75]
    ],
    [
      [0,0,22,35,66],
      [18,25,40,54,78]
    ],
    [
      [0,14,27,41,70],
      [23,31,46,59,81]
    ],
    [
      [13,19,33,47,74],
      [29,37,52,64,84]
    ],
    [
      [18,25,40,53,78],
      [35,44,58,69,86]
    ],
    [
      [24,32,46,59,81],
      [42,50,64,74,88]
    ],
    [
      [31,39,53,65,84],
      [49,57,69,78,100]
    ],
    [
      [39,47,60,71,87],
      [57,64,74,82,100]
    ],
    [
      [47,55,67,78,89],
      [64,70,79,85,100]
    ],
    [
      [56,52,73,80,100],
      [70,75,83,88,100]
    ],
    [
      [64,70,78,84,100],
      [76,80,86,100,100]
    ],
    [
      [71,76,83,88,100],
      [81,85,89,100,100]
    ],
    [
      [78,82,87,100,100],
      [86,88,100,100,100]
    ],
    [
      [85,87,100,100,100],
      [100,100,100,100,100]
    ],
    [
      [100,100,100,100,100],
      [100,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,36],
      [0,0,11,21,54]
    ],
    [
      [0,0,0,12,42],
      [0,0,15,27,60]
    ],
    [
      [0,0,0,17,49],
      [0,0,21,34,65]
    ],
    [
      [0,0,11,21,54],
      [0,0,26,39,70]
    ],
    [
      [0,0,15,27,60],
      [0,13,31,45,73]
    ],
    [
      [0,0,20,33,65],
      [0,17,38,51,77]
    ],
    [
      [0,0,26,39,69],
      [11,23,44,57,80]
    ],
    [
      [0,13,32,46,74],
      [16,29,51,63,83]
    ],
    [
      [0,19,40,53,78],
      [22,37,58,69,86]
    ],
    [
      [13,26,47,60,82],
      [29,44,64,74,88]
    ],
    [
      [19,34,55,67,85],
      [37,52,70,78,100]
    ],
    [
      [27,43,63,73,88],
      [46,60,76,83,100]
    ],
    [
      [37,52,70,78,100],
      [55,68,81,86,100]
    ],
    [
      [47,61,76,83,100],
      [64,75,85,89,100]
    ],
    [
      [58,70,82,87,100],
      [72,81,88,100,100]
    ],
    [
      [70,79,88,100,100],
      [81,87,100,100,100]
    ],
    [
      [81,87,100,100,100],
      [88,100,100,100,100]
    ],
  ],
  [
    [
      [0,0,0,0,22],
      [0,0,0,15,47]
    ],
    [
      [0,0,0,0,28],
      [0,0,11,20,53]
    ],
    [
      [0,0,0,0,34],
      [0,15,16,26,59]
    ],
    [
      [0,0,0,0,40],
      [0,19,20,32,64]
    ],
    [
      [0,0,0,14,46],
      [11,24,25,38,68]
    ],
    [
      [0,0,0,19,52],
      [15,30,31,44,73]
    ],
    [
      [0,13,14,25,58],
      [21,37,38,50,76]
    ],
    [
      [0,18,19,31,63],
      [27,44,45,57,80]
    ],
    [
      [0,25,26,38,69],
      [34,51,52,63,83]
    ],
    [
      [16,32,34,46,74],
      [42,58,59,69,86]
    ],
    [
      [23,41,42,54,79],
      [50,65,66,74,88]
    ],
    [
      [31,50,51,62,83],
      [58,71,72,79,100]
    ],
    [
      [41,58,59,69,86],
      [66,77,78,83,100]
    ],
    [
      [51,67,68,76,89],
      [73,82,82,87,100]
    ],
    [
      [61,74,75,82,100],
      [80,86,87,100,100]
    ],
    [
      [73,82,83,87,100],
      [86,100,100,100,100]
    ],
    [
      [82,89,89,100,100],
      [100,100,100,100,100]
    ],
  ],
];

const scales = {
  liftingFemale1FWeightScaleStart: 14,
  liftingFemale1FWeightScaleEnd: 65,
  liftingFemale1FWeightScaleStep: 3,
  liftingFemale2FWeightScaleStart: 8,
  liftingFemale2FWeightScaleEnd: 59,
  liftingFemale2FWeightScaleStep: 3,
  liftingFemale3FWeightScaleStart: 6,
  liftingFemale3FWeightScaleEnd: 40,
  liftingFemale3FWeightScaleStep: 2,
  liftingMale1MWeightScaleStart: 28,
  liftingMale1MWeightScaleEnd: 96,
  liftingMale1MWeightScaleStep: 4,
  liftingMale2MWeightScaleStart: 28,
  liftingMale2MWeightScaleEnd: 96,
  liftingMale2MWeightScaleStep: 4,
  liftingMale3MWeightScaleStart: 26,
  liftingMale3MWeightScaleEnd: 77,
  liftingMale3MWeightScaleStep: 3,
  loweringFemaleWeightScale: [51, 48, 45, 43, 41, 39, 37, 35, 33, 31, 29, 27, 25, 23, 21, 18, 15, 12],
  loweringMale4MWeightScaleStart: 36,
  loweringMale4MWeightScaleEnd: 87,
  loweringMale4MWeightScaleStep: 3,
  loweringMale5MWeightScaleStart: 24,
  loweringMale5MWeightScaleEnd: 75,
  loweringMale5MWeightScaleStep: 3,
  loweringMale6MWeightScaleStart: 22,
  loweringMale6MWeightScaleEnd: 73,
  loweringMale6MWeightScaleStep: 3,
  pushingInitialScaleStart: 25,
  pushingInitialScaleEnd: 130,
  pushingInitialScaleStep: 3,
  pushingSustainedFemaleScaleStart: 12,
  pushingSustainedFemaleScaleEnd: 80,
  pushingSustainedFemaleScaleStep: 4,
  pushingSustainedMaleScaleStart: 20,
  pushingSustainedMaleScaleEnd: 105,
  pushingSustainedMaleScaleStep: 5,
  pullingInitialScaleStart: 25,
  pullingInitialScaleEnd: 130,
  pullingInitialScaleStep: 3,
  pullingSustainedFemaleScaleStart: 8,
  pullingSustainedFemaleScaleEnd: 76,
  pullingSustainedFemaleScaleStep: 4,
  pullingSustainedMaleScaleStart: 20,
  pullingSustainedMaleScaleEnd: 105,
  pullingSustainedMaleScaleStep: 5,
  carryingFemaleScaleStart: 25,
  carryingFemaleScaleEnd: 73,
  carryingFemaleScaleStep: 3,
  carrryingMaleScale: [99, 94, 89, 85, 81, 77, 73, 69, 65, 61, 57, 53, 49, 45, 41, 36, 31]
}

export { liftingFemale, liftingMale, loweringFemale, loweringMale, pushingInitialFemale, pushingInitialMale,
  pushingSustainedFemale, pushingSustainedMale, pullingInitialFemale, pullingInitialMale, pullingSustainedFemale,
  pullingSustainedMale, carryingFemale, carryingMale, scales}