import { CM_TO_IN, KG_TO_LBS, M_TO_FT } from '../constants';
import i18n from "i18next";
import {
  liftingFemale,
  liftingMale,
  loweringFemale,
  loweringMale,
  pushingInitialFemale,
  pushingInitialMale,
  pushingSustainedFemale,
  pushingSustainedMale,
  pullingInitialFemale,
  pullingInitialMale,
  pullingSustainedFemale,
  pullingSustainedMale,
  carryingFemale,

  carryingMale,
  scales
} from "./lmTables";
import {LM} from './index';
import {Utils} from '..';

const mathjs = require('mathjs')
const GENDER_FEMALE = 0;
const GENDER_MALE = 1

export default class LibertyMutual {

  // noinspection DuplicatedCode
  static convertToImperial(data) {
    let newData = JSON.parse(JSON.stringify(data));

    newData.height = data.height * CM_TO_IN;
    newData.startHandHeight = data.startHandHeight * CM_TO_IN;
    newData.endHandHeight = data.endHandHeight * CM_TO_IN;
    newData.handDistance = data.handDistance * CM_TO_IN;
    newData.objectWeight = data.objectWeight * KG_TO_LBS;
    newData.handHeight = data.handHeight * CM_TO_IN;
    newData.initialForce = data.initialForce * KG_TO_LBS;
    newData.sustainedForce = data.sustainedForce * KG_TO_LBS;
    newData.distance = data.distance * M_TO_FT;
    return newData;
  }

  static convertToMetricForEquations(data) {
    let newData = JSON.parse(JSON.stringify(data));

    // Distance units must be in meters
    newData.height = (data.height / CM_TO_IN) / 100;
    newData.startHandHeight = (data.startHandHeight / CM_TO_IN) / 100;
    newData.endHandHeight = (data.endHandHeight / CM_TO_IN) / 100;
    newData.handDistance = (data.handDistance / CM_TO_IN) / 100;
    newData.objectWeight = data.objectWeight / KG_TO_LBS;
    newData.handHeight = (data.handHeight / CM_TO_IN) / 100;
    newData.initialForce = data.initialForce / KG_TO_LBS;
    newData.sustainedForce = data.sustainedForce / KG_TO_LBS;
    newData.distance = data.distance / M_TO_FT;
    return newData;
  }

  static convertCentimetersToMetersForEquations(data) {
    let newData = JSON.parse(JSON.stringify(data));

    // Distance units must be in meters
    newData.height = data.height  / 100;
    newData.startHandHeight = data.startHandHeight / 100;
    newData.endHandHeight = data.endHandHeight / 100;
    newData.handDistance = data.handDistance / 100;
    newData.handHeight = data.handHeight / 100;
    return newData;
  }

  static calculateLM(assessmentData) {
    switch (assessmentData ? assessmentData.actionType : "") {
      case "lift":
        return LM.calculateLift(assessmentData);
      case "lower":
        return LM.calculateLower(assessmentData);
      case "push":
        return LM.calculatePush(assessmentData);
      case "pull":
        return LM.calculatePull(assessmentData);
      case "carry":
        return LM.calculateCarry(assessmentData);
      default:
        break;
    }
  }

  // noinspection DuplicatedCode
  static calculateLift = (assessmentData) => {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    // Get bounding indices
    let endHandHeight = this.getStartEndHandHeightIndex(data.endHandHeight, data.gender);
    let handDistance = this.getHandDistanceIndex(data.handDistance);
    let rawLiftDistance = data.endHandHeight - data.startHandHeight
    let liftDistance = this.getLiftDistanceIndex(rawLiftDistance);
    let frequencyIndex = data.frequency;
    let naFields = [];

    // Get correct object weight scale form table
    let objectWeight;
    if (data.gender === GENDER_FEMALE) {
      // noinspection DuplicatedCode
      if (endHandHeight.index === 0) {
        // Are we on table 1F. (Start Hand height < 28")
        objectWeight = this.getWeightOrForceIndex(scales.liftingFemale1FWeightScaleStart, scales.liftingFemale1FWeightScaleEnd, scales.liftingFemale1FWeightScaleStep, data.objectWeight);
      } else if (endHandHeight.index === 1) {
        // We're using table 2F. (Start Hand height <= 28" AND >= 53")
        objectWeight = this.getWeightOrForceIndex(scales.liftingFemale2FWeightScaleStart, scales.liftingFemale2FWeightScaleEnd, scales.liftingFemale2FWeightScaleStep, data.objectWeight);
      } else {
        // We're using table 3F. (Start Hand height > 53")
        objectWeight = this.getWeightOrForceIndex(scales.liftingFemale3FWeightScaleStart, scales.liftingFemale3FWeightScaleEnd, scales.liftingFemale3FWeightScaleStep, data.objectWeight);
      }
    } else {
      // noinspection DuplicatedCode
      if (endHandHeight.index === 0) {
        // We're using table 1M. (Start Hand height < 31")
        objectWeight = this.getWeightOrForceIndex(scales.liftingMale1MWeightScaleStart, scales.liftingMale1MWeightScaleEnd, scales.liftingMale1MWeightScaleStep, data.objectWeight);
      } else if (endHandHeight.index === 1) {
        // We're using table 2M. (Start Hand height <= 31" AND >= 57")
        objectWeight = this.getWeightOrForceIndex(scales.liftingMale2MWeightScaleStart, scales.liftingMale2MWeightScaleEnd, scales.liftingMale2MWeightScaleStep, data.objectWeight);
      } else {
        // We're using table 3M. (Start Hand height > 57")
        objectWeight = this.getWeightOrForceIndex(scales.liftingMale3MWeightScaleStart, scales.liftingMale3MWeightScaleEnd, scales.liftingMale3MWeightScaleStep, data.objectWeight);
      }
    }

    let chosenTable = data.gender === GENDER_FEMALE ? liftingFemale[endHandHeight.index] : liftingMale[endHandHeight.index];
    let bounds = [handDistance, objectWeight, liftDistance]

    let liftingMx = this.getBoundingMatrix(chosenTable, bounds, frequencyIndex);
    let populationPercentage = Math.trunc(parseFloat(this.interpolate(liftingMx, [handDistance.weight, objectWeight.weight, liftDistance.weight])));

    if (rawLiftDistance > 30) {
      populationPercentage = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.liftLowerDistanceCondition'))
    }
    if (rawLiftDistance < 0) {
      populationPercentage = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.liftPositiveDistanceCondition'))
    }
    if (data.handDistance > 15) {
      populationPercentage = "N/A"
      naFields.push(i18n.t('AssessmentForm.lm.liftLowerHandDistanceCondition'))
    }

    const { populationPercentageLMEquations } = this.calculateLiftLowerFromEquations(assessmentData)

    return {
      "score": {
        "populationPercentageLMTables": populationPercentage,
        "populationPercentageLMEquations": populationPercentageLMEquations
      },
      "naFields": naFields
    };
  }

  // noinspection DuplicatedCode
  static calculateLower = (assessmentData) => {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    // Get bounding indices
    let startHandHeight = this.getStartEndHandHeightIndex(data.startHandHeight, data.gender);
    let handDistance = this.getHandDistanceIndex(data.handDistance);
    let rawLowerDistance = data.startHandHeight - data.endHandHeight
    let lowerDistance = this.getLiftDistanceIndex(rawLowerDistance);
    let frequencyIndex = data.frequency;
    let naFields = [];

    // Get correct object weight scale form table
    let objectWeight;
    if (data.gender === GENDER_FEMALE) {
      objectWeight = this.getIndexFromScale(data.objectWeight, scales.loweringFemaleWeightScale);
    } else {
      // noinspection DuplicatedCode
      if (startHandHeight.index === 0) {
        // We're using table 4M. (Start Hand height < 31")
        objectWeight = this.getWeightOrForceIndex(scales.loweringMale4MWeightScaleStart, scales.loweringMale4MWeightScaleEnd, scales.loweringMale4MWeightScaleStep, data.objectWeight);
      } else if (startHandHeight.index === 1) {
        // We're using table 5M. (Start Hand height <= 31" AND >= 57")
        objectWeight = this.getWeightOrForceIndex(scales.loweringMale5MWeightScaleStart, scales.loweringMale5MWeightScaleEnd, scales.loweringMale5MWeightScaleStep, data.objectWeight);
      } else {
        // We're using table 6M. (Start Hand height > 57")
        objectWeight = this.getWeightOrForceIndex(scales.loweringMale6MWeightScaleStart, scales.loweringMale6MWeightScaleEnd, scales.loweringMale6MWeightScaleStep, data.objectWeight);
      }
    }

    let chosenTable = data.gender === GENDER_FEMALE ? loweringFemale[startHandHeight.index] : loweringMale[startHandHeight.index];
    let bounds = [handDistance, objectWeight, lowerDistance]

    let liftingMx = this.getBoundingMatrix(chosenTable, bounds, frequencyIndex)
    let populationPercentage = Math.trunc(parseFloat(this.interpolate(liftingMx, [handDistance.weight, objectWeight.weight, lowerDistance.weight])));

    if (data.objectWeight < 12) {
      populationPercentage = 100;
    }

    if (rawLowerDistance > 30) {
      populationPercentage = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.liftLowerDistanceCondition'))
    }
    if (rawLowerDistance < 0) {
      populationPercentage = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.lowerPositiveDistanceCondition'))
    }
    if (data.handDistance > 15) {
      populationPercentage = "N/A"
      naFields.push(i18n.t('AssessmentForm.lm.liftLowerHandDistanceCondition'))
    }

    const { populationPercentageLMEquations } = this.calculateLiftLowerFromEquations(assessmentData)

    return {
      "score": {
        "populationPercentageLMTables": populationPercentage,
        "populationPercentageLMEquations": populationPercentageLMEquations
      },
      "naFields": naFields
    };
  }

  static calculateLiftLowerFromEquations = (assessmentData) => {
    let data;
    if (assessmentData.units === "imperial") {
      data = this.convertToMetricForEquations(assessmentData);
    }
    else {
      data = this.convertCentimetersToMetersForEquations(assessmentData);
    }

    let frequency = LibertyMutual.getFrequencyMultiplier(data);
    let verticalDistance = data.startHandHeight - data.endHandHeight;

    // Returns N/A if action type selected is wrong
    if ((data.actionType === "lower" && verticalDistance < 0) ||
        (data.actionType === "lift" && verticalDistance > 0)) {
      return {
        "populationPercentageLMEquations": "N/A",
      };
    }

    verticalDistance = Math.abs(verticalDistance);
    let horizontalDistance = data.handDistance;
    let verticalRangeMiddle = verticalDistance / 2;

    let referenceLoadMultiplier = LibertyMutual.getReferenceLoadMultiplier(data);
    let verticalRangeMiddleMultiplier = 0.9877 + (verticalRangeMiddle / 13.69) + (Math.pow(verticalRangeMiddle, 2) / (-9.221));
    let distanceMultiplier = 0.8199 + (Math.log(verticalDistance) / (-7.696));
    let horizontalMultiplier = 1.2602 + (horizontalDistance / (-0.7686));
    let frequencyMultiplier = 0.6767 + (Math.log(frequency) / (-12.59)) + (Math.pow(Math.log(frequency), 2) / -228.2);

    if (data.gender === GENDER_MALE) {
      verticalRangeMiddleMultiplier = 0.7746 + (verticalRangeMiddle / 1.912) + (Math.pow(verticalRangeMiddle, 2) / (-3.296));
      distanceMultiplier = 0.8695 + (Math.log(verticalDistance) / (-10.62));
      horizontalMultiplier = 1.3532 + (horizontalDistance / (-0.7079));
      if (data.actionType === "lift") {
        frequencyMultiplier = 0.6259 + (Math.log(frequency) / (-9.092)) + (Math.pow(Math.log(frequency), 2) / (-125));
      } else { // lower
        frequencyMultiplier = 0.5773 + (Math.log(frequency) / (-10.80)) + (Math.pow(Math.log(frequency), 2) / (-255.9));
      }
    }

    verticalRangeMiddleMultiplier = LibertyMutual.validateMultiplier(verticalRangeMiddleMultiplier);
    distanceMultiplier = LibertyMutual.validateMultiplier(distanceMultiplier);
    horizontalMultiplier = LibertyMutual.validateMultiplier(horizontalMultiplier);
    frequencyMultiplier = LibertyMutual.validateMultiplier(frequencyMultiplier);

    let maxAcceptedLoad = referenceLoadMultiplier * verticalRangeMiddleMultiplier * distanceMultiplier * horizontalMultiplier * frequencyMultiplier;
    if (data.handCoupling !== 0) {
      maxAcceptedLoad *= 0.84;
    }

    let standardDeviation = LibertyMutual.getStandardDeviation(data, maxAcceptedLoad)
    let populationPercentage = 100 * (1 - LibertyMutual.cdfNormal(data.objectWeight, maxAcceptedLoad, standardDeviation));

    return {
      "populationPercentageLMEquations": populationPercentage,
    };
  }

  // noinspection DuplicatedCode
  static calculatePush = (assessmentData) => {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    // Get bounding indices
    let handHeight = this.getPushPullHandHeightIndex(data.handHeight, data.gender);
    let initialForce = this.getWeightOrForceIndex(scales.pushingInitialScaleStart, scales.pushingInitialScaleEnd, scales.pushingInitialScaleStep, data.initialForce)
    let sustainedForce =  data.gender === GENDER_FEMALE ? this.getWeightOrForceIndex(scales.pushingSustainedFemaleScaleStart, scales.pushingSustainedFemaleScaleEnd, scales.pushingSustainedFemaleScaleStep, data.sustainedForce):
      this.getWeightOrForceIndex(scales.pushingSustainedMaleScaleStart, scales.pushingSustainedMaleScaleEnd, scales.pushingSustainedMaleScaleStep, data.sustainedForce);
    let distance = this.getPushPullDistanceIndex(data.distance);
    let frequencyIndex = data.frequency;
    let initialForceBounds = [initialForce, handHeight];
    let sustainedForceBounds = [distance, sustainedForce, handHeight];
    let chosenInitialTable = data.gender === GENDER_FEMALE ? pushingInitialFemale : pushingInitialMale;
    let chosenSustainedTable = data.gender === GENDER_FEMALE ? pushingSustainedFemale : pushingSustainedMale;
    let naFields = [];

    let pushingInitialForceMx = this.getBoundingMatrix(chosenInitialTable, initialForceBounds, frequencyIndex)
    let pushingSustainedForceMx = this.getBoundingMatrix(chosenSustainedTable, sustainedForceBounds, frequencyIndex);

    let populationPercentageInitialForce = Math.trunc(parseFloat(this.interpolate(pushingInitialForceMx, [initialForce.weight, handHeight.weight])));
    let populationPercentageSustainedForce = Math.trunc(parseFloat(this.interpolate(pushingSustainedForceMx, [distance.weight, sustainedForce.weight, handHeight.weight])));

    if (data.distance > 50) {
      populationPercentageSustainedForce = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.pushDistanceCondition'))
    }
    if (data.gender === GENDER_FEMALE) {
      if (data.handHeight > 53 || data.handHeight < 22) {
        populationPercentageInitialForce = "N/A";
        populationPercentageSustainedForce = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.pushHandCondition.female'))
      }
    } else {
      if (data.handHeight > 57 || data.handHeight < 25) {
        populationPercentageInitialForce = "N/A";
        populationPercentageSustainedForce = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.pushHandCondition.male'))
      }
    }

    const { populationPercentageInitialForceLMEquations, populationPercentageSustainedForceLMEquations} = this.calculatePushPullFromEquations(assessmentData);

    return {
      "score": {
        "populationPercentageInitialForceLMTables": populationPercentageInitialForce,
        "populationPercentageSustainedForceLMTables": populationPercentageSustainedForce,
        "populationPercentageInitialForceLMEquations": populationPercentageInitialForceLMEquations,
        "populationPercentageSustainedForceLMEquations": populationPercentageSustainedForceLMEquations,
      },
      "naFields": naFields
    };
  }


  // noinspection DuplicatedCode
  static calculatePull = (assessmentData) => {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    // Get bounding indices
    let handHeight = this.getPushPullHandHeightIndex(data.handHeight, data.gender);
    let initialForce = this.getWeightOrForceIndex(scales.pullingInitialScaleStart, scales.pullingInitialScaleEnd, scales.pullingInitialScaleStep, data.initialForce);
    let sustainedForce =  data.gender === GENDER_FEMALE ? this.getWeightOrForceIndex(scales.pullingSustainedFemaleScaleStart, scales.pullingSustainedFemaleScaleEnd, scales.pullingSustainedFemaleScaleStep, data.sustainedForce):
      this.getWeightOrForceIndex(scales.pullingSustainedMaleScaleStart, scales.pullingSustainedMaleScaleEnd, scales.pullingSustainedMaleScaleStep, data.sustainedForce);
    let distance = this.getPushPullDistanceIndex(data.distance);
    let frequencyIndex = data.frequency;
    let initialForceBounds = [initialForce, handHeight];
    let sustainedForceBounds = [distance, sustainedForce, handHeight];
    let chosenInitialTable = data.gender === GENDER_FEMALE ? pullingInitialFemale : pullingInitialMale;
    let chosenSustainedTable = data.gender === GENDER_FEMALE ? pullingSustainedFemale : pullingSustainedMale;
    let naFields = [];

    let pullingInitialForceMx = this.getBoundingMatrix(chosenInitialTable, initialForceBounds, frequencyIndex)
    let pullingSustainedForceMx = this.getBoundingMatrix(chosenSustainedTable, sustainedForceBounds, frequencyIndex);

    let populationPercentageInitialForce = Math.trunc(parseFloat(this.interpolate(pullingInitialForceMx, [initialForce.weight, handHeight.weight])));
    let populationPercentageSustainedForce = Math.trunc(parseFloat(this.interpolate(pullingSustainedForceMx, [distance.weight, sustainedForce.weight, handHeight.weight])));

    if (data.distance > 50) {
      populationPercentageSustainedForce = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.pullDistanceCondition'))
    }
    if (data.gender === GENDER_FEMALE) {
      if (data.sustainedForce < 12) {
        populationPercentageSustainedForce = 100;
      }
      if (data.handHeight > 53 || data.handHeight < 22) {
        populationPercentageInitialForce = "N/A";
        populationPercentageSustainedForce = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.pullHandCondition.female'))
      }
    } else {
      if (data.sustainedForce < 20) {
        populationPercentageSustainedForce = 100;
      }
      if (data.handHeight > 57 || data.handHeight < 25) {
        populationPercentageInitialForce = "N/A";
        populationPercentageSustainedForce = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.pullHandCondition.male'))
      }
    }
    if (data.distance > 50) {
      populationPercentageSustainedForce = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.distance'))
    }

    const { populationPercentageInitialForceLMEquations, populationPercentageSustainedForceLMEquations} = this.calculatePushPullFromEquations(assessmentData);

    return {
      "score": {
        "populationPercentageInitialForceLMTables": populationPercentageInitialForce,
        "populationPercentageSustainedForceLMTables": populationPercentageSustainedForce,
        "populationPercentageInitialForceLMEquations": populationPercentageInitialForceLMEquations,
        "populationPercentageSustainedForceLMEquations": populationPercentageSustainedForceLMEquations,
      },
      "naFields": naFields
    };
  }

  static calculatePushPullFromEquations = (assessmentData) => {
    let data;
    if (assessmentData.units === "imperial") {
      data = this.convertToMetricForEquations(assessmentData);
    }
    else {
      data = this.convertCentimetersToMetersForEquations(assessmentData);
    }

    let frequency = LibertyMutual.getFrequencyMultiplier(data);
    let verticalDistance = data.handHeight;
    let horizontalDistance = data.distance;
    let referenceLoadMultiplierInitial = LibertyMutual.getReferenceLoadMultiplier(data, "initial");
    let referenceLoadMultiplierSustain = LibertyMutual.getReferenceLoadMultiplier(data, "sustained");

    let verticalMultiplierInitial = -0.5304 + (verticalDistance / 0.3361) + (Math.pow(verticalDistance,2) / (-0.6915));
    let verticalMultiplierSustained = -0.6539 + (verticalDistance / 0.2941) + (Math.pow(verticalDistance,2) / (-0.5722));
    let distanceMultiplierInitial = 1.0286 + (horizontalDistance / (-72.22)) + (Math.pow(horizontalDistance,2) / 9782);
    let distanceMultiplierSustained = 1.0391 + (horizontalDistance / (-52.91)) + (Math.pow(horizontalDistance,2) / 7975);
    let frequencyMultiplierInitial = 0.7251 + (Math.log(frequency) / (-13.19)) + (Math.pow(Math.log(frequency), 2) / (-197.3));
    let frequencyMultiplierSustained = 0.6086 + (Math.log(frequency) / (-11.95)) + (Math.pow(Math.log(frequency), 2) / (304.4));

    if (data.gender === GENDER_MALE) {
      if (data.actionType === "push") {
        verticalMultiplierInitial = 1.2737 + (verticalDistance / (-1.335)) + (Math.pow(verticalDistance,2) / 2.576);
        verticalMultiplierSustained = 2.294 + (verticalDistance / -0.3345) + (Math.pow(verticalDistance,2) / 0.6887);
      } else { // pull
        verticalMultiplierInitial = 1.7186 + (verticalDistance / (-0.6888)) + (Math.pow(verticalDistance,2) / 2.025);
        verticalMultiplierSustained = 2.1977 + (verticalDistance / -0.385) + (Math.pow(verticalDistance,2) / 0.9047);
      }
      distanceMultiplierInitial = 1.0790 + (Math.log(horizontalDistance) / (-9.392));
      distanceMultiplierSustained = 1.1035 + (Math.log(horizontalDistance) / (-7.17));
      frequencyMultiplierInitial = 0.6281 + (Math.log(frequency) / (-13.07)) + (Math.pow(Math.log(frequency), 2) / (-379.5));
      frequencyMultiplierSustained = 0.4891 + (Math.log(frequency) / (-10.20)) + (Math.pow(Math.log(frequency), 2) / (-403.9));
    }

    verticalMultiplierInitial = LibertyMutual.validateMultiplier(verticalMultiplierInitial);
    verticalMultiplierSustained = LibertyMutual.validateMultiplier(verticalMultiplierSustained);
    distanceMultiplierInitial = LibertyMutual.validateMultiplier(distanceMultiplierInitial);
    distanceMultiplierSustained = LibertyMutual.validateMultiplier(distanceMultiplierSustained);
    frequencyMultiplierInitial = LibertyMutual.validateMultiplier(frequencyMultiplierInitial);
    frequencyMultiplierSustained = LibertyMutual.validateMultiplier(frequencyMultiplierSustained);

    let maxAcceptedLoadInitial = referenceLoadMultiplierInitial * verticalMultiplierInitial * distanceMultiplierInitial * frequencyMultiplierInitial;
    let maxAcceptedLoadSustained = referenceLoadMultiplierSustain * verticalMultiplierSustained * distanceMultiplierSustained * frequencyMultiplierSustained;

    let standardDeviationInitial = LibertyMutual.getStandardDeviation(data, maxAcceptedLoadInitial, "initial")
    let standardDeviationSustained = LibertyMutual.getStandardDeviation(data, maxAcceptedLoadSustained, "sustained")

    let populationPercentageInitialForce = 100 * (1 - LibertyMutual.cdfNormal(data.initialForce, maxAcceptedLoadInitial, standardDeviationInitial));
    let populationPercentageSustainedForce = 100 * (1 - LibertyMutual.cdfNormal(data.sustainedForce, maxAcceptedLoadSustained, standardDeviationSustained));

    return {
      "populationPercentageInitialForceLMEquations": populationPercentageInitialForce,
      "populationPercentageSustainedForceLMEquations": populationPercentageSustainedForce
    };
  }

  // noinspection DuplicatedCode
  static calculateCarry = (assessmentData) => {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    // Get bounding indices
    let handHeight = this.getCarryHandHeightIndex(data.handHeight, data.gender);
    let objectWeight = data.gender === GENDER_FEMALE ? this.getWeightOrForceIndex(scales.carryingFemaleScaleStart, scales.carryingFemaleScaleEnd, scales.carryingFemaleScaleStep, data.objectWeight) :
      this.getIndexFromScale(data.objectWeight, scales.carrryingMaleScale)
    let distance = this.getCarryDistanceIndex(data.distance);
    let frequencyIndex = data.frequency;
    let bounds = [distance, objectWeight, handHeight]
    let chosenTable = data.gender === GENDER_FEMALE ? carryingFemale: carryingMale;
    let naFields = [];

    let carryingMx = this.getBoundingMatrix(chosenTable, bounds, frequencyIndex);

    let populationPercentage = Math.trunc(parseFloat(this.interpolate(carryingMx, [distance.weight, objectWeight.weight, handHeight.weight])));

    // TODO: Customize for tooltip
    if (data.gender === GENDER_FEMALE) {
      if (data.objectWeight < 25) {
        populationPercentage = 100;
      } else if (data.objectWeight > 73) {
        populationPercentage = 0;
      }
      if (data.handHeight < 31 || data.handHeight > 40) {
        populationPercentage = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.carryHandCondition.female'))
      }
    } else {
      if (data.objectWeight < 20) {
        populationPercentage = 100;
      } else if (data.objectWeight > 105) {
        populationPercentage = 0;
      }
      if (data.handHeight < 33 || data.handHeight > 43) {
        populationPercentage = "N/A";
        naFields.push(i18n.t('AssessmentForm.lm.carryHandCondition.male'))
      }
    }
    if (data.distance > 28) {
      populationPercentage = "N/A";
      naFields.push(i18n.t('AssessmentForm.lm.carryDistanceCondition'));
    }

    const { populationPercentageLMEquations } = this.calculateCarryFromEquations(assessmentData)

    return {
      "score": {
        "populationPercentageLMTables": populationPercentage,
        "populationPercentageLMEquations": populationPercentageLMEquations
      },
      "naFields": naFields
    };
  }

  static calculateCarryFromEquations = (assessmentData) => {
    let data;
    if (assessmentData.units === "imperial") {
      data = this.convertToMetricForEquations(assessmentData);
    }
    else {
      data = this.convertCentimetersToMetersForEquations(assessmentData);
    }

    let frequency = LibertyMutual.getFrequencyMultiplier(data);
    let verticalDistance = data.handHeight;
    let horizontalDistance = data.distance;

    let referenceLoadMultiplier = LibertyMutual.getReferenceLoadMultiplier(data);
    let verticalMultiplier = 1.1645 + (verticalDistance / (-4.437));
    let distanceMultiplier = 1.0101 + (horizontalDistance / (-207.8));
    let frequencyMultiplier = 0.6219 + (Math.log(frequency) / (-16.33));

    if (data.gender === GENDER_MALE) {
      verticalMultiplier = 1.5505 + (verticalDistance / (-1.417));
      distanceMultiplier = 1.1172 + (Math.log(horizontalDistance) / (-6.332));
      frequencyMultiplier = 0.5149 + (Math.log(frequency) / (-7.958)) + (Math.pow(Math.log(frequency),2) / (-131.1));
    }

    verticalMultiplier = LibertyMutual.validateMultiplier(verticalMultiplier);
    distanceMultiplier = LibertyMutual.validateMultiplier(distanceMultiplier);
    frequencyMultiplier = LibertyMutual.validateMultiplier(frequencyMultiplier);

    let maxAcceptedLoad = referenceLoadMultiplier * verticalMultiplier * distanceMultiplier * frequencyMultiplier;
    let standardDeviation = LibertyMutual.getStandardDeviation(data, maxAcceptedLoad);
    let populationPercentage = 100 * (1 - LibertyMutual.cdfNormal(data.objectWeight, maxAcceptedLoad, standardDeviation));

    return {
      "populationPercentageLMEquations": populationPercentage,
    };
  }

  // Valid multipliers must be positive and less then or equal to 1
  static validateMultiplier = (multiplier) => {
    if (multiplier < 0) {
      return 0;
    } else if (multiplier > 1) {
      return 1;
    } else {
      return multiplier;
    }
  }

  static cdfNormal = (x, mean, standardDeviation) => {
    return (1 - mathjs.erf((mean - x ) / (Math.sqrt(2) * standardDeviation))) / 2
  }

  /**
   * Gets frequency in actions per minute
   *
   * @param assessmentData - the assessment data
   *
   * @return number - the frequency in actions per minute
   */
  static getFrequencyMultiplier = (assessmentData) => {
    const { actionType, frequency } = assessmentData;
    if (actionType === "push" || actionType === "pull") {
      switch (frequency) {
        case 0:
          return 2;
        case 1:
          return 1;
        case 2:
          return 1/5;
        case 3:
          return 1/30;
        case 4:
          return 1/(8*60);
        default:
      }
    } else { // lift/lower/carry
      switch (frequency) {
        case 0:
          return 4;
        case 1:
          return 2;
        case 2:
          return 1;
        case 3:
          return 1/5;
        case 4:
          return 1/(8*60);
        default:
      }
    }
  }


  /**
   * Gets the Reference Load Multiplier, an intermediate constant derived from the action type and gender.
   *
   * @param assessmentData - the assessment Data
   * @param {string} pushPullType - either 'initial' or 'sustained'
   *
   * @return number - the intermediate constant
   */
  static getReferenceLoadMultiplier = (assessmentData, pushPullType="initial") => {
    const actionType = assessmentData.actionType;
    const gender = assessmentData.gender;
    switch (actionType) {
      case "lift":
        if (gender === GENDER_FEMALE) {
          return 34.9;
        } else { // male
          return 82.6;
        }
      case "lower":
        if (gender === GENDER_FEMALE) {
          return 37.0;
        } else { // male
          return 95.9;
        }
      case "push":
        if (pushPullType === "initial") {
          if (gender === GENDER_FEMALE) {
            return 36.9;
          } else { // male
            return 70.3;
          }
        } else {  // sustained
          if (gender === GENDER_FEMALE) {
            return 25.5;
          } else { // male
            return 65.3;
          }
        }
      case "pull":
        if (pushPullType === "initial") {
          if (gender === GENDER_FEMALE) {
            return 36.9;
          } else { // male
            return 69.8;
          }
        } else { // sustained
          if (gender === GENDER_FEMALE) {
            return 25.5;
          } else { // male
            return 61;
          }
        }
      case "carry":
        if (gender === GENDER_FEMALE) {
          return 28.6;
        } else { // male
          return 74.9;
        }
      default:
        return null;
    }
  }

  /**
   * Gets the Standard Deviation for a given task
   *
   * @param assessmentData - the assessment Data
   * @param {number} maxAcceptedLoad - the max accepted load of the task
   * @param {string} pushPullType - either 'initial' or 'sustained'
   *
   * @return number - the standard deviation
   */
  static getStandardDeviation = (assessmentData, maxAcceptedLoad, pushPullType="initial") => {
    const actionType = assessmentData.actionType;
    const gender = assessmentData.gender;
    let cv;
    if (gender === GENDER_FEMALE) {
      switch (actionType) {
        case "lift":
          cv = 0.26;
          break;
        case "lower":
          cv = 0.307;
          break;
        case "push":
          if (pushPullType === "initial") {
            cv = 0.214;
          } else {  // sustained
            cv = 0.286;
          }
          break;
        case "pull":
          if (pushPullType === "initial") {
            cv = 0.234;
          } else { // sustained
            cv = 0.298;
          }
          break;
        case "carry":
          cv = 0.231;
          break;
        default:
          cv = null;
      }
    } else { // male
      switch (actionType) {
        case "lift":
          cv = 0.276;
          break;
        case "lower":
          cv = 0.304;
          break;
        case "push":
          if (pushPullType === "initial") {
            cv = 0.231;
          } else {  // sustained
            cv = 0.267;
          }
          break;
        case "pull":
          if (pushPullType === "initial") {
            cv = 0.238;
          } else { // sustained
            cv = 0.257;
          }
          break;
        case "carry":
          cv = 0.278;
          break;
        default:
          cv = null;
      }
    }

    return maxAcceptedLoad * cv;
  }

  /**
   * Create a 2x2x2 or 2x2 matrix representing the bounding cells for our given assessmentData over 2 or 3 variables
   *  index: closest index in the 'risky' direction for a specific variable
   *  index_safe: closest index in the 'safe' direction for a specific variable
   *  the final interpolated value will be somewhere in the middle of this 'square'/'cube' of boundaries
   *
   * @param {number[][][][]} table - the selected LM table
   * @param {[*, *, *]} bounds - a 2 or 3 item ordered list of the bounding variables
   * @param {number} frequencyIndex - the index of the frequency column on the table
   * @return {number[][][]} - a 2x2x2 or 2x2 matrix representing the bounding cells on the table
   */
  static getBoundingMatrix = (table, bounds, frequencyIndex) => {
    if (bounds.length === 3) {
      // noinspection DuplicatedCode
      return [
        [
          [
            table[bounds[0].index][bounds[1].index][bounds[2].index][frequencyIndex],
            table[bounds[0].index][bounds[1].index][bounds[2].index_safe][frequencyIndex]
          ],
          [
            table[bounds[0].index][bounds[1].index_safe][bounds[2].index][frequencyIndex],
            table[bounds[0].index][bounds[1].index_safe][bounds[2].index_safe][frequencyIndex]
          ]
        ],
        [
          [
            table[bounds[0].index_safe][bounds[1].index][bounds[2].index][frequencyIndex],
            table[bounds[0].index_safe][bounds[1].index][bounds[2].index_safe][frequencyIndex]
          ],
          [
            table[bounds[0].index_safe][bounds[1].index_safe][bounds[2].index][frequencyIndex],
            table[bounds[0].index_safe][bounds[1].index_safe][bounds[2].index_safe][frequencyIndex]
          ]
        ]
      ]
    }
    else if (bounds.length === 2) {
      return [
        [
          table[bounds[0].index][bounds[1].index][frequencyIndex],
          table[bounds[0].index][bounds[1].index_safe][frequencyIndex]
        ],
        [
          table[bounds[0].index_safe][bounds[1].index][frequencyIndex],
          table[bounds[0].index_safe][bounds[1].index_safe][frequencyIndex]
        ]
      ]
    }
  }

  /**
   * Collapses an 2x2x2.... interpolation matrix into a single value based on an ordered list of weights
   *
   * @param {number[][][]} mx - 2x2x2x2... matrix to be collapsed
   * @param {number[]} weights - An ordered list of weights corresponding to the dimensions of mx
   * @return {number} - A single number representing the interpolation of mx
   *
   */
  static interpolate = (mx, weights) => {
    // Recursion Base Case: mx is a 2x1 matrix
    if (typeof mx[0] == 'number') {

      // Adjust "100" and "0" table values to "95" and "5" respectively for interpolation
      let tableVal1 = Math.min(Math.max(mx[0], 5), 95)
      let tableVal2 = Math.min(Math.max(mx[1], 5), 95)

      // Interpolate between the two values based on the distance to the actual point
      return tableVal1 * (1 - weights[0]) + tableVal2 * weights[0];

    } else {
      // Split the matrix in half and calculate each half with a recursive call
      return this.interpolate(mx[0], weights.slice(1)) * (1 - weights[0]) +
        this.interpolate(mx[1], weights.slice(1)) * weights[0];
    }
  }

  /**
   * One-size-fits-most function for indexing the leftmost variable on the LM Tables (weight/force)
   *
   * @param {number} scale_start - The value of the lowest weight/force option on the table
   * @param {number} scale_end - The value of the highest weight/force option on the table
   * @param {number} step - The incremental value for each weight/force option on the table
   * @param {number} value - The value we need an index for
   * @return {object} - A dictionary representing the index
   * {
   *    index: the 'risky' bounding index
   *    index_safe: the 'safe' bounding index
   *    weight: fraction representing where the value lies between index and index_safe
   * }
   *
   */
  static getWeightOrForceIndex(scale_start, scale_end, step, value) {
    // One-size-fits-most function for indexing the leftmost variable on the LM Tables (weight/force)
    const scale_length = ((scale_end - scale_start) / step) + 1;
    let adjusted_index = {"index": 0, "index_safe": 0, "weight": 0};
    for (let i = 0; i < scale_length; i++) {
      let current_bucket_value = scale_start + i * step;
      let previous_bucket_value = scale_start + (i-1) * step;
      if (value <= (scale_start + i * step)) {
        if (i === 0) {
          adjusted_index = {"index": scale_length - 1, "index_safe": scale_length - 2, "weight": 0};
        } else {
          adjusted_index = {
            "index": (scale_length - 1) - i,
            "index_safe": (scale_length - 1) - (i - 1),
            "weight": 1 - (value - previous_bucket_value) / (current_bucket_value - previous_bucket_value)
          }
        }
        return adjusted_index;
      }
    }
    return adjusted_index;
  }

  static getIndexFromScale(weight, scale) {
    let adjusted_index = {"index": scale.length - 1, "index_safe": scale.length - 1, "weight": 0};
    for (let i = 0; i < scale.length; i++) {
      if (weight > scale[i]) {
        if (i === 0) {
          adjusted_index = {"index": 0, "index_safe": 0, "weight": 0};
        } else {
          adjusted_index = {"index": i - 1, "index_safe": i, "weight": (scale[i - 1] - weight) / (scale[i - 1] - scale[i])};
        }
        break;
      }
    }
    return adjusted_index
  }

  static getStartEndHandHeightIndex(height, gender) {
    if (gender === GENDER_FEMALE) {
      if (height <= 28) {
        return {"index": 0};
      } else if (height <= 53) {
        return {"index": 1};
      } else {
        return {"index": 2};
      }
    } else {
      if (height <= 31) {
        return {"index": 0};
      } else if (height <= 57) {
        return {"index": 1};
      } else {
        return {"index": 2};
      }
    }
  }

  static getPushPullHandHeightIndex(height, gender) {
    if (gender === GENDER_FEMALE) {
      if (height <= 22) {
        return {"index": 2, "index_safe": 2, "weight": 0};
      } else if (height <=  35) {
        return {"index": 1, "index_safe": 2, "weight": (35 - height) / (35 - 22)};
      } else if (height <= 53) {
        return {"index": 0, "index_safe": 1, "weight": (53 - height) / (53 - 35)};
      } else {
        return {"index": 0, "index_safe": 0, "weight": 0};
      }
    } else {
      if (height <= 25) {
        return {"index": 2, "index_safe": 2, "weight": 0};
      } else if (height <=  37) {
        return {"index": 1, "index_safe": 2, "weight": (37 - height) / (37 - 25)};
      } else if (height <= 57) {
        return {"index": 0, "index_safe": 1, "weight": (57 - height) / (57 - 37)};
      } else {
        return {"index": 0, "index_safe": 0, "weight": 0};
      }
    }
  }

  static getCarryHandHeightIndex(height, gender) {
    if (gender === GENDER_FEMALE) {
      if (height <= 31) {
        return {"index": 1, "index_safe": 1, "weight": 0};
      } else if (height <=  40) {
        return {"index": 0, "index_safe": 1, "weight": (40 - height) / (40 - 31)};
      } else {
        return {"index": 0, "index_safe": 0, "weight": 0};
      }
    } else {
      if (height <= 33) {
        return {"index": 1, "index_safe": 1, "weight": 0};
      } else if (height <=  43) {
        return {"index": 0, "index_safe": 1, "weight": (43 - height) / (43 - 33)};
      } else {
        return {"index": 0, "index_safe": 0, "weight": 0};
      }
    }
  }

  static getLiftDistanceIndex(distance) {
    if (distance <= 10) {
      return {"index": 2, "index_safe": 2, "weight": 0};
    } else if (distance <= 20) {
      return {"index": 1, "index_safe": 2, "weight": (20 - distance) / 10};
    } else if (distance <= 30) {
      return {"index": 0, "index_safe": 1, "weight": (30 - distance) / 10};
    } else {
      return {"index": 0, "index_safe": 0, "weight": 0};
    }
  }

  static getHandDistanceIndex(distance) {
    if (distance <= 7) {
      return {"index": 0, "index_safe": 0, "weight": 0};
    } else if (distance <= 10) {
      return {"index": 1, "index_safe": 0, "weight": (10 - distance) / 3};
    } else if (distance <= 15) {
      return {"index": 2, "index_safe": 1, "weight": (15 - distance) / 5};
    } else {
      return {"index": 2, "index_safe": 2, "weight": 0};
    }
  }

  static getPushPullDistanceIndex(distance) {
    if (distance <= 7) {
      return {"index": 0, "index_safe": 0, "weight": 0};
    } else if (distance <= 25) {
      return {"index": 1, "index_safe": 0, "weight": (25 - distance) / (25 - 7)};
    } else if (distance <= 50) {
      return {"index": 2, "index_safe": 1, "weight": (50 - distance) / (50 - 25)};
    } else {
      return {"index": 2, "index_safe": 2, "weight": 0};
    }
  }

  static getCarryDistanceIndex(distance) {
    if (distance <= 7) {
      return {"index": 0, "index_safe": 0, "weight": 0};
    } else if (distance <= 14) {
      return {"index": 1, "index_safe": 0, "weight": (14 - distance) / (14 - 7)};
    } else if (distance <= 28) {
      return {"index": 2, "index_safe": 1, "weight": (28 - distance) / (28 - 14)};
    } else {
      return {"index": 2, "index_safe": 2, "weight": 0};
    }
  }

  static calculateLMEstimate(analysisData) {
    let joints = analysisData.joints;
    let hands = analysisData.hands;
    let scores = [];
    let complete = true;

    for (let i = 0; i < joints["Neck"].data.length; ++i) {
      if (!(hands && hands.vertical && hands['vertical'][i] >= 0)) { // Check for missing Data
        complete = false;
      }
      scores.push({'x': joints["Neck"].data[i]['x'], 'y': hands && hands.vertical ? hands['vertical'][i] : 0});
    }

    return {
      "estimates": scores,
      "complete": complete
    };
  }

  static generateAndDownloadLMReport(data, score, videoName, date, groupNames) {
    let report = "";
    let name = data.name ? data.name : "Unnamed Assessment";
    let action = data.actionType.charAt(0).toUpperCase() + data.actionType.slice(1);
    let group = "";
    if (groupNames && groupNames.length > 0) {
      group = groupNames[0]
    }

    let frequency;
    let timeUnit;
    let smallDistanceUnit = (data.units === "metric") ? "cm" : "in";
    let bigDistanceUnit = (data.units === "metric") ? "m" : "ft";
    let massUnit = (data.units === "metric") ? "kg" : "lbs";
    let gender = data.gender === GENDER_FEMALE ? "FEMALE" : "MALE"
    let handCoupling = data.handCoupling === 0 ? "GOOD" :
      data.handCoupling === 1 ? "FAIR" : "POOR";

    let liftLowerCarryFrequencies = [15, 30, 1, 5, 8];
    let pushPullFrequencies = [30, 1, 5, 30, 8];
    let liftLowerCarryUnits = ["sec", "sec", "min", "min", "hrs"]
    let pushPullUnits = ["sec", "min", "min", "min", "hrs"]

    // Determine frequency and time units depending on action type
    if (action === "Lift" || action === "Lower" || action === "Carry") {
      frequency = liftLowerCarryFrequencies[data.frequency];
      timeUnit = liftLowerCarryUnits[data.frequency];
    } else {
      frequency = pushPullFrequencies[data.frequency];
      timeUnit = pushPullUnits[data.frequency];
    }

    report += gender + " LM TABLES ASSESSMENT,\n";
    report += ",\n";
    report += "GROUP:,,," + group + "\n";
    report += "VIDEO NAME:,,," + videoName + "\n";
    report += "ASSESSMENT NAME:,,," + name + "\n";
    report += "ASSESSMENT DATE:,,," + date + "\n";
    report += "ACTION TYPE:,,," + action + "\n";

    // noinspection DuplicatedCode
    if (action === "Lift" || action === "Lower") {
      report += "LM TABLE POPULATION PERCENTAGE:,,," + Utils.interpretPopulationPercentage(score.populationPercentageLMTables) + "\n";
      report += "LM SCORE POPULATION PERCENTAGE:,,," + Utils.interpretPopulationPercentage(score.populationPercentageLMEquations) + "\n";
      report += ",\n";
      report += "WORKER'S HEIGHT:,,," + Utils.interpretDataReportValue(data.height, " " + smallDistanceUnit) + ",\n";
      report += "START HAND HEIGHT:,,," + Utils.interpretDataReportValue(data.startHandHeight, " " +  smallDistanceUnit) + ",\n";
      report += "END HAND HEIGHT:,,," + Utils.interpretDataReportValue(data.endHandHeight, " " +  smallDistanceUnit) + ",\n";
      report += "HAND DISTANCE:,,," + Utils.interpretDataReportValue(data.handDistance, " " +  smallDistanceUnit) + ",\n";
      report += "OBJECT WEIGHT:,,," + Utils.interpretDataReportValue(data.objectWeight, " " +  massUnit) + ",\n";
      report += "HAND COUPLING:,,," + handCoupling + "\n";
    } else if (action === "Push" || action === "Pull") {
      report += "LM TABLE POPULATION PERCENTAGE (INITIAL):,,," + Utils.interpretPopulationPercentage(score.populationPercentageInitialForceLMTables) + "\n";
      report += "LM TABLE POPULATION PERCENTAGE (SUSTAINED):,,," + Utils.interpretPopulationPercentage(score.populationPercentageSustainedForceLMTables) + "\n";
      report += "LM SCORE POPULATION PERCENTAGE (INITIAL):,,," + Utils.interpretPopulationPercentage(score.populationPercentageInitialForceLMEquations) + "\n";
      report += "LM SCORE POPULATION PERCENTAGE (SUSTAINED):,,," + Utils.interpretPopulationPercentage(score.populationPercentageSustainedForceLMEquations) + "\n";
      report += ",\n";
      report += "WORKER'S HEIGHT:,,," + Utils.interpretDataReportValue(data.height, " " + smallDistanceUnit) + ",\n";
      report += "HAND HEIGHT:,,," + Utils.interpretDataReportValue(data.handHeight, " " +  smallDistanceUnit) + ",\n";
      report += "INITIAL FORCE:,,," + Utils.interpretDataReportValue(data.initialForce, " " +  massUnit) + ",\n";
      report += "SUSTAINED FORCE:,,," + Utils.interpretDataReportValue(data.sustainedForce, " " +  massUnit) + ",\n";
      report += "DISTANCE MOVED:,,," + Utils.interpretDataReportValue(data.distance, " " + bigDistanceUnit) + ",\n";
    } else {
      report += "LM TABLE POPULATION PERCENTAGE:,,," + Utils.interpretPopulationPercentage(score.populationPercentageLMTables) + "\n";
      report += "LM SCORE POPULATION PERCENTAGE:,,," + Utils.interpretPopulationPercentage(score.populationPercentageLMEquations) + "\n";
      report += ",\n";
      report += "WORKER'S HEIGHT:,,," + Utils.interpretDataReportValue(data.height, " " + smallDistanceUnit) + ",\n";
      report += "HAND HEIGHT:,,," + Utils.interpretDataReportValue(data.handHeight, " " +  smallDistanceUnit) + ",\n";
      report += "OBJECT WEIGHT:,,," + Utils.interpretDataReportValue(data.objectWeight, " " +  massUnit) + ",\n";
      report += "DISTANCE MOVED:,,," + Utils.interpretDataReportValue(data.distance, " " + bigDistanceUnit) + ",\n";
    }

    report += "FREQUENCY - ONE " + action.toUpperCase() + " EVERY:,,," + frequency + " " + timeUnit + "\n";
    return new Blob(["\ufeff" + report], { type: "text/csv" });
  }
}