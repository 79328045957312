import React from 'react';
import ReactPlayer from 'react-player';

class TrimmedVideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.state = {
      duration: 0,
      playing: true,
      playedSeconds: 0,
    };
  }

  componentDidMount() {
    setTimeout(this.checkVideo, 5000);
  }

  // noinspection JSUnusedLocalSymbols
  onProgress = ({played, playedSeconds, loaded, loadedSeconds}) => {
    const { trimStart, trimEnd, duration } = this.props;
    let videoLength = this.state.duration;
    if (duration && duration !== 0) {
      videoLength = trimStart + duration;
    }
    this.setState({playedSeconds: playedSeconds});
    if (trimStart > playedSeconds || trimEnd < playedSeconds || playedSeconds >= videoLength) {
      this.playerRef.current.seekTo(trimStart, "seconds");
    }
    if (this.props.onProgress) {
      this.props.onProgress(playedSeconds);
    }
  };

  onDuration = (duration) => {
    this.setState({duration: duration});
    if (this.props.onDuration) {
      this.props.onDuration(duration);
    }
  };

  onReady = () => {
    let internalPlayer = this.playerRef.current.getInternalPlayer();

    let settings;
    if (internalPlayer.captureStream && internalPlayer.captureStream(0).getVideoTracks().length > 0) {
      settings = internalPlayer
        .captureStream(0)
        .getVideoTracks()[0]
        .getSettings();
      settings['loaded'] = true;
    } else if(internalPlayer.videoWidth) {
      settings = {frameRate: 30, width:internalPlayer.videoWidth, height:internalPlayer.videoHeight, loaded: true}
    } else {
      settings = {frameRate: 30, width: 1920, height: 1080, loaded: false};
    }
    const { frameRate, width, height, loaded } = settings;
    if (this.props.onMetadata) {
      this.props.onMetadata(frameRate, width, height, this.state.duration, loaded)
    }
    if (this.props.updateThumbnail) {
      this.props.updateThumbnail(internalPlayer, width, height);
    }
  };

  seekTo = (time) => {
    this.playerRef.current.seekTo(time, "seconds");
  };

  play = () => {
    this.setState({playing: true});
  };

  pause = () => {
    this.setState({playing: false});
  };

  checkVideo = () => {
    if (this.state.duration === 0 && this.props.onNoVideo) {
      this.props.onNoVideo()
    }
  };

  render() {
    return (
      <div className="TrimmedVideoPlayer" style={{width: this.props.width, height: this.props.height}}>
        <ReactPlayer
          muted={this.props.muted}
          progressInterval={100}
          playing={this.state.playing}
          url={ this.props.isExistingAnalysis ? this.props.url : [{src: this.props.url, type: 'video/mp4'}] }
          width={"100%"}
          controls={this.props.controls}
          playsinline={true}
          onDuration={(d) => this.onDuration(d)}
          onProgress={(e) => this.onProgress(e)}
          onStart={() => {
            if (!this.props.isExistingAnalysis) {
              this.onReady();
              this.props.onVideo();
            }
          }}
          style={this.props.style}
          ref={this.playerRef}
            />
      </div>
    );
  }
}

export default TrimmedVideoPlayer;