import React from 'react';
import { Card } from 'antd';
import { Redirect } from 'react-router-dom';

import { UserDataForm, UserLookup, OrganizationDataForm, OrgLookup } from "../../components/";
import { InstanceView, DevEnvironmentView } from "../../views";
import { withTranslation } from "react-i18next";
import {Request, sessionDetails, Utils} from "../../utils";

class AdminView extends React.Component {
    constructor(props) {
        super(props);
        sessionDetails.changeView("AdminView");
        this.state = {
            key: 'newUserTab',
            checkedAdmin: false,
            verifiedAdmin: false,
            userData: {}
        }
    }

    componentDidMount() {
        Request.checkAdmin().then(isAdmin => {
            this.setState({checkedAdmin: true, verifiedAdmin: isAdmin});
        })
    };

    handleNewUser = (e) => {
        this.goToUser(e.username);
    };

    handleNewOrg = (e) => {
        this.setState({newOrg: e.orgName, key: 'viewOrgTab'});
    };

    goToUser = (username) => {
        Request.findUser(username).then(userData => this.setState({userData: userData, key: 'editUserTab'}));
    };

    handleSubmissionFailure = (e) => {
        const {t} = this.props
        if (e && e.context) {
            if (e.context.errorCode) {
                alert(Utils.getErrorMessageForCode(e.context.errorCode))
            } else if (e.context.errors) {
                alert(e.context.errors);
            } else {
                alert(t('Error.unknownError'));
            }
        } else {
            alert(t('Error.unknownError'));
        }
    };

    renderContent = () => {
        switch (this.state.key) {
            case 'editOrgTab':
                // noinspection JSUnresolvedVariable
                return(<OrgLookup dataSource={this.state.orgData} onClickUser={this.goToUser} isAdmin={true}/>);
            case 'editUserTab':
                return (<UserLookup dataSource={this.state.userData} onSubmitSuccess={e => console.log(e)} onSubmitFail={this.handleSubmissionFailure} isAdmin={true}/>);
            case 'instanceTab':
                return (<InstanceView/>);
            case 'newOrgTab':
                return (<OrganizationDataForm onSubmitSuccess={this.handleNewOrg} onSubmitFail={this.handleSubmissionFailure} isAdmin={true}/>);
            case 'devEnvironments':
                return (<DevEnvironmentView/>);
            case 'newUserTab':
            default:
                return (<UserDataForm onSubmitSuccess={this.handleNewUser} onSubmitFail={this.handleSubmissionFailure} isAdmin={true}/>);
        }
    };

    render() {
        if (!this.state.checkedAdmin) {
            return null;
        } else if (!this.state.verifiedAdmin) {
            return (
                <Redirect to="/about"/>
            );
        }
        const { t } = this.props;

        let tabList = [
            {
                key: 'newUserTab',
                tab: t('Admin.newUser')
            },
            {
                key: 'editUserTab',
                tab: t('Admin.editUser')
            },
            {
                key: 'newOrgTab',
                tab: t('Admin.newOrg')
            },
            {
                key: 'editOrgTab',
                tab: t('Admin.editOrg')
            },
            {
                key: 'instanceTab',
                tab: t('Admin.instances')
            }
        ];

        if (sessionDetails.isStaging() && !sessionDetails.isDev()) {
            tabList.push({
                key: 'devEnvironments',
                tab: t('Admin.devEnvironments')
            });
        }

        return (
            <div>
                <Card size='small' title={<span style={{width: '100%', fontSize: '20pt', padding: '6px'}}>{t('Admin.title')}</span>}
                      tabList={tabList}
                      activeTabKey={this.state.key}
                      onTabChange={key => this.setState({key: key})}>
                    {this.renderContent()}
                </Card>
            </div>
        );
    }
}

export default withTranslation()(AdminView);
