import { CordovaUtils, FileUtil, uploadCache, Utils } from './';

export default class FileCache {

  static getCacheDirectory() {
    // noinspection JSUnresolvedVariable
    return window.cordova.file.dataDirectory;
  }

  static getTempDirectory() {
    if (Utils.isAndroid()) {
      // noinspection JSUnresolvedVariable
      return window.cordova.file.cacheDirectory;
    } else {
      // noinspection JSUnresolvedVariable
      return window.cordova.file.tempDirectory;
    }
  }

  // Update the cached list, or retrieve the cached list if offline
  static listVideos(currentList) {
    if (currentList && currentList.videos) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentList), FileCache.getCacheDirectory(), "data", "videoList.json");
      return Promise.resolve(currentList);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "data", "videoList.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Update the cached data, or retrieve the cached data if offline
  static getVideoData(id, currentData) {
    if (currentData && currentData.context) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentData), FileCache.getCacheDirectory(), "analysis_data", id + "-data.json");
      return Promise.resolve(currentData);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "analysis_data", id + "-data.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Update the cached data, or retrieve the cached data if offline
  static getAnalysisJson(id, currentData) {
    // noinspection JSUnresolvedVariable
    if (currentData && currentData.processedData) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentData), FileCache.getCacheDirectory(), "analysis_data", id + "-jointData.json");
      return Promise.resolve(currentData);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "analysis_data", id + "-jointData.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Update the cached list, or retrieve the cached list if offline
  static listAssessments(id, assessmentType, currentData) {
    // noinspection JSUnresolvedVariable
    if (currentData && currentData.assessments) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentData), FileCache.getCacheDirectory(), "assessment_lists", id + "-" + assessmentType + "-assessmentList.json");
      return Promise.resolve(currentData);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "assessment_lists", id + "-" + assessmentType + "-assessmentList.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Update the cached assessment, or retrieve the cached assessment if offline
  static getAssessment(id, currentData) {
    if (currentData && currentData.name) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentData), FileCache.getCacheDirectory(), "assessments", id + "-assessment.json");
      return Promise.resolve(currentData);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "assessments", id + "-assessment.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Update the cached list, or retrieve the cached list if offline
  static listGroups(currentData) {
    if (currentData && currentData.groups) {
      // noinspection JSIgnoredPromiseFromCall
      CordovaUtils.saveFile(CordovaUtils.blobify(currentData), FileCache.getCacheDirectory(), "data", "groupList.json");
      return Promise.resolve(currentData);
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "data", "groupList.json", CordovaUtils.readJSONFileEntry);
    }
  }

  // Get the cached thumbnail if it exists
  static getThumbnailUrl(analysisId, filename, assessmentId=null) {
    if (uploadCache.isCached(analysisId)) {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), uploadCache.getSubdirectory(), analysisId + ".png").then((fileEntry) => {
          let update = (filename === "cachedUpload") ? "no" : "yes";
          return Promise.resolve({
              responseData: {
                  thumbnail: CordovaUtils.getFileUrlForDisplay(fileEntry),
                  filename: "cachedUpload",
                  update: update,
              }
          });
      });
    } else if (uploadCache.isCached(assessmentId)) {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), uploadCache.getSubdirectory(), assessmentId + ".png").then((fileEntry) => {
        let update = (filename === "cachedUpload") ? "no" : "yes";
        return Promise.resolve({
          responseData: {
            thumbnail: CordovaUtils.getFileUrlForDisplay(fileEntry),
            filename: "cachedUpload",
            update: update,
          }
        });
      });
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "thumbnails", analysisId + ".png", null).then((fileEntry) => {
        if (fileEntry) {
          let update = (filename === "cachedUpload") ? "no" : "yes";
          return Promise.resolve({
              responseData: {
                  thumbnail: CordovaUtils.getFileUrlForDisplay(fileEntry),
                  filename: "cachedUpload",
                  update: update,
              }
          });
        } else {
          return Promise.resolve(null);
        }
      });
    }
  }

  /**
   * Get the cached video if it exists, otherwise fetch from remote
   * @param id the id of the video
   * @param remoteURL the url from which the video could be downloaded
   * @param display true if the video will need to be displayed on the page, false will return native URL (relevant to
   *                ios only)
   * @returns {Promise<*>}
   */
  static getVideoUrl(id, remoteURL, display = true) {
    if (uploadCache.isCached(id)) {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), uploadCache.getSubdirectory(), id + ".mp4", null).then((fileEntry) => {
        if (fileEntry) {
          // noinspection JSUnresolvedVariable
          let url = display ? CordovaUtils.getFileUrlForDisplay(fileEntry) : fileEntry.nativeURL;
          return Promise.resolve(url);
        } else {
          return Promise.resolve(null);
        }
      });
    } else {
      return CordovaUtils.retrieveFile(FileCache.getCacheDirectory(), "videos", id + ".mp4", null).then((fileEntry) => {
        if (fileEntry) {
          // noinspection JSUnresolvedVariable
          let url = display ? CordovaUtils.getFileUrlForDisplay(fileEntry) : fileEntry.nativeURL;
          return Promise.resolve(url);
        } else {
          return this.getVideoFromUrl(id, remoteURL);
        }
      });
    }
  }

  // Fetch the remote video url and update the cached entry if found
  static getVideoFromUrl(id, url) {
    return FileCache.urlExists(url).then((exists) => {
      if (exists) {
        FileUtil.urlToBlob(url).then((blob) => {
          // noinspection JSIgnoredPromiseFromCall
          CordovaUtils.saveFile(blob, FileCache.getCacheDirectory(), "videos", id + ".mp4");
        });
        return Promise.resolve(url);
      } else {
        //TODO maybe return a placeholder img/vid here if we cant get anything?
        return Promise.resolve(null);
      }
    });
  }

  static clearCache() {
    let dataDirectory = FileCache.getCacheDirectory();
    CordovaUtils.deleteFolder(dataDirectory, "videos");
    CordovaUtils.deleteFolder(dataDirectory, "thumbnails");
    CordovaUtils.deleteFolder(dataDirectory, "analysis_data");
    CordovaUtils.deleteFolder(dataDirectory, "assessments");
    CordovaUtils.deleteFolder(dataDirectory, "assessment_lists");
    // noinspection JSIgnoredPromiseFromCall
    CordovaUtils.deleteFile(dataDirectory, "", "videoList", ".json");
    uploadCache.clearCache()
  }

  static urlExists(url) {
    return fetch(url, { method: 'head' }).then(function(status) {
      return(status.ok);
    });
  }
}
