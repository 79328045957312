// These are keys used for entries in localStorage
const SESSION_ID = "sessionId";
const SESSION_EXPIRED = "sessionExpired";
const USERNAME = "username";
const STYLIZED_USERNAME = "stylizedUsername";
const USER_TYPE = "userType";
const HAS_ACCEPTED_TOS = "hasAcceptedTOS";
const SETTINGS = "settings";
const NOTIFICATION_STATUS = "notificationStatus";

// These are values for entries in localStorage
const NOTIFICATIONS_ALLOWED = "allowed";
const NOTIFICATIONS_DECLINED = "declined";
const NOTIFICATIONS_SOFT_DECLINED = "softDeclined";
const NOTIFICATIONS_UNKNOWN = "unknown";

/**
 * This class handles persisting data to the browser's local data store.
 * localStorage should never be called outside of this class.
 */
export default class Persistence {
    /**
     * @returns {boolean} true if the user is currently logged in, false otherwise
     */
    static isLoggedIn() {
        // noinspection RedundantIfStatementJS
        if (localStorage.getItem(SESSION_ID)) {
            return true;
        } else {
            return false;
        }
    }

    static isAdmin() {
        if (this.isLoggedIn()) {
            // Magic string indicating an admin user. This gets verified on backend as well but there's no need to make this overly guessable.
            return localStorage.getItem(USER_TYPE) === "BVrOAlodlL64";
        } else {
            return false;
        }
    }

    static isOrgAdmin() {
        if (this.isLoggedIn()) {
            return localStorage.getItem(USER_TYPE) === "orgAdmin";
        } else {
            return false;
        }
    }

    static hasAcceptedTOS() {
        if (localStorage.getItem(SESSION_ID)) {
            return localStorage.getItem(HAS_ACCEPTED_TOS) === "true";
        }
    }

    static acceptTOS() {
        localStorage.setItem(HAS_ACCEPTED_TOS, true);
    }

    /**
     * Store the credentials of the logged in user
     * @param {String} username the username to display
     * @param {String} sessionId the session ID to use to authenticate backend requests
     * @param {?String} stylizedUsername optionally, the username that should be displayed to the user
     * @param {String} userType the type of user this is, for UI hints
     * @param {Boolean} hasAcceptedTOS whether or not the user has accepted the Terms of Service
     */
    static setLogin(username, sessionId, stylizedUsername, userType, hasAcceptedTOS) {
        localStorage.setItem(USERNAME, username);
        localStorage.setItem(SESSION_ID, sessionId);
        if (stylizedUsername) {
            localStorage.setItem(STYLIZED_USERNAME, stylizedUsername);
        } else {
            localStorage.setItem(STYLIZED_USERNAME, username);
        }
        localStorage.setItem(USER_TYPE, userType);
        localStorage.setItem(HAS_ACCEPTED_TOS, hasAcceptedTOS);
        localStorage.removeItem(SESSION_EXPIRED);
    }

    /**
     * @returns {string} The username of the currently or most recently logged in user
     */
    static getUsername() {
        return localStorage.getItem(USERNAME);
    }

    static getDisplayName() {
        return localStorage.getItem(STYLIZED_USERNAME);
    }

    static setNotificationStatusUnknown() {
        localStorage.setItem(NOTIFICATION_STATUS, NOTIFICATIONS_UNKNOWN);
    }

    static setNotificationsAllowed() {
        localStorage.setItem(NOTIFICATION_STATUS, NOTIFICATIONS_ALLOWED);
    }

    static setNotificationsDeclined() {
        localStorage.setItem(NOTIFICATION_STATUS, NOTIFICATIONS_DECLINED);
    }

    static setNotificationsSoftDeclined() {
        localStorage.setItem(NOTIFICATION_STATUS, NOTIFICATIONS_SOFT_DECLINED);
    }

    static areNotificationsAllowed() {
        return localStorage.getItem(NOTIFICATION_STATUS) === NOTIFICATIONS_ALLOWED;
    }

    static areNotificationsDeclined() {
        return localStorage.getItem(NOTIFICATION_STATUS) === NOTIFICATIONS_DECLINED;
    }

    static areNotificationsSoftDeclined() {
        return localStorage.getItem(NOTIFICATION_STATUS) === NOTIFICATIONS_SOFT_DECLINED;
    }

    /**
     * Have we asked the user whether they want notifications or not?
     *
     * @returns {boolean} true if we know the user's notification preference, false otherwise.
     */
    static isNotificationStatusKnown() {
        const status = localStorage.getItem(NOTIFICATION_STATUS);
        if (status) {
            // If the status is "unknown", the status is not known. Otherwise we do know the status.
            return status !== NOTIFICATIONS_UNKNOWN;
        } else {
            // if the status is not set, the status is not known
            return false;
        }
    }

    /**
     * @returns {string} The session ID of the logged in user which can be
     * used to authenticate for backend requests.
     */
    static getSessionId() {
        return localStorage.getItem(SESSION_ID);
    }

    /**
     * Log the user out of the current browser.
     */
    static logout() {
        localStorage.removeItem(USERNAME);
        localStorage.removeItem(SESSION_ID);
        localStorage.removeItem(STYLIZED_USERNAME);
        localStorage.removeItem(USER_TYPE);
        localStorage.removeItem(SETTINGS);
        localStorage.removeItem(HAS_ACCEPTED_TOS);
        if (!this.areNotificationsDeclined()) {
            // If notifications on this browser are hard-declined, we should
            // keep that information around regardless of who logs in. Otherwise
            // we should allow the next user to log in to decide again.
            localStorage.removeItem(NOTIFICATION_STATUS);
        }
    }

    static invalidateSession() {
        this.logout();
        localStorage.setItem(SESSION_EXPIRED, "true");
    }

    static getInvalidatedSession() {
        return localStorage.getItem(SESSION_EXPIRED) === "true";
    }

    static setSettings(values) {
        localStorage.setItem(SETTINGS, values);
    }

    static getSettings() {
        return localStorage.getItem(SETTINGS);
    }
}
