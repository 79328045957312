import { CM_TO_IN, KG_TO_LBS } from '../constants';
import i18n from "i18next";

import {Utils} from '..';

const LOAD_CONSTANT = 51; //51 lbs
const FREQUENCIES = [0.2, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, Number.MAX_VALUE];
const FREQUENCY_TABLE = [
  [
    [1.0, 0.97, 0.94, 0.91, 0.88, 0.84, 0.80, 0.75, 0.70, 0.60, 0.52, 0.45, 0.41, 0.37, 0, 0, 0, 0],
    [1.0, 0.97, 0.94, 0.91, 0.88, 0.84, 0.80, 0.75, 0.70, 0.60, 0.52, 0.45, 0.41, 0.37, 0.34, 0.31, 0.28, 0]
  ],
  [
    [0.95, 0.92, 0.88, 0.84, 0.79, 0.72, 0.60, 0.50, 0.42, 0.35, 0.30, 0.26, 0, 0, 0, 0, 0, 0],
    [0.95, 0.92, 0.88, 0.84, 0.79, 0.72, 0.60, 0.50, 0.42, 0.35, 0.30, 0.26, 0.23, 0.21, 0, 0, 0, 0]
  ],
  [
    [0.85, 0.81, 0.75, 0.65, 0.55, 0.45, 0.35, 0.27, 0.22, 0.18, 0, 0, 0, 0, 0, 0, 0, 0],
    [0.85, 0.81, 0.75, 0.65, 0.55, 0.45, 0.35, 0.27, 0.22, 0.18, 0.15, 0.13, 0, 0, 0, 0, 0, 0]
  ]
];

export default class Niosh {

  static calculateNIOSH(assessmentData) {
    let data = (assessmentData.units === "metric") ? this.convertToImperial(assessmentData) : assessmentData;

    let originHorizontalMultiplier = this.calculateHorizontalMultiplier(data.startHandDistance);
    let originVerticalMultiplier = this.calculateVerticalMultiplier(data.startHandHeight);
    let originAsymmetryMultiplier = this.calculateAsymmetryMultiplier(data.startAsymmetryAngle);
    let destinationHorizontalMultiplier = this.calculateHorizontalMultiplier(data.endHandDistance);
    let destinationVerticalMultiplier = this.calculateVerticalMultiplier(data.endHandHeight);
    let destinationAsymmetryMultiplier = this.calculateAsymmetryMultiplier(data.endAsymmetryAngle);
    let distanceMultiplier = this.calculateDistanceMultiplier(data.endHandHeight - data.startHandHeight);
    let frequencyMultiplier = this.calculateFrequencyMultiplier(data.liftsPerHour, data.workDuration, data.startHandHeight, data.timeUnit);
    let couplingMultiplier = this.calculateCouplingMultiplier(data.handCoupling, data.startHandHeight);
    let originRecommendedWeightLimit = LOAD_CONSTANT * originHorizontalMultiplier * originVerticalMultiplier *
      originAsymmetryMultiplier * distanceMultiplier * frequencyMultiplier * couplingMultiplier;

    let destinationRecommendedWeightLimit = LOAD_CONSTANT * destinationHorizontalMultiplier * destinationVerticalMultiplier *
      destinationAsymmetryMultiplier * distanceMultiplier * frequencyMultiplier * couplingMultiplier;

    let worstRWL = Math.min(originRecommendedWeightLimit, destinationRecommendedWeightLimit);
    let liftIndex = data.objectWeight / worstRWL;
    liftIndex = liftIndex.toFixed(1);
    if (assessmentData.units === "metric") {
      worstRWL *= 1 / KG_TO_LBS;
    }

    let zeroFields = this.prepareZeroFields(originVerticalMultiplier, destinationVerticalMultiplier, originHorizontalMultiplier,
      destinationHorizontalMultiplier, frequencyMultiplier, data.liftsPerHour,
      originAsymmetryMultiplier, destinationAsymmetryMultiplier, data.timeUnit);

    return {
      "score": {
        "recommendedWeightLimit": parseFloat(worstRWL.toFixed(1)),
        "liftIndex": liftIndex === "Infinity" ? "N/A" : parseFloat(liftIndex),
      },
      "zeroFields": zeroFields
    };
  }

  static calculateHorizontalMultiplier(handHeight) {
    if (handHeight <= 10) {
      return 1;
    } else if (handHeight > 25) {
      return 0;
    } else {
      return 10 / handHeight;
    }
  }

  static calculateVerticalMultiplier(handDistance) {
    if (handDistance > 70) {
      return 0;
    } else {
      return 1 - (0.0075 * Math.abs(handDistance - 30));
      // TODO 30" is the "knuckle height" of an average person (66")
      // This should become a variable that scales based on the actual height of the subject
    }
  }

  static calculateDistanceMultiplier(distance) {
    if (distance <= 10) {
      return 1;
    } else if (distance > 70) {
      return 0;
    } else {
      return 0.82 + (1.8 / distance);
    }
  }

  static calculateAsymmetryMultiplier(angle) {
    if (angle > 135) {
      return 0;
    } else {
      return 1 - (0.0032 * angle);
    }
  }

  static calculateFrequencyMultiplier(liftsPerHour, workDuration, originHandHeight, timeUnit) {
    //TODO this only uses the vertical distance at the origin.  Should probably use destination too.
    let liftsPerMinute = (timeUnit === "hour") ? liftsPerHour / 60 : liftsPerHour;
    let handHeightAboveKnuckleHeight = originHandHeight < 30 ? 0 : 1;
    if (liftsPerMinute < 0.2) {
      liftsPerMinute = 0.2;
    }

    let frequencyIdx = this.getFrequencyIndex(liftsPerMinute);

    if (frequencyIdx === (FREQUENCIES.length - 1) || workDuration === 3) {
      return 0;
    }
    return FREQUENCY_TABLE[workDuration][handHeightAboveKnuckleHeight][frequencyIdx];
  }

  static getFrequencyIndex(liftsPerMinute) {
    for (let i = 0; i < FREQUENCIES.length; ++i) {
      if (liftsPerMinute <= FREQUENCIES[i]) {
        return i;
      }
    }
  }

  static calculateCouplingMultiplier(handCoupling, originHandHeight) {
    //TODO this only uses the vertical distance at the origin.  Should probably use distination too.
    switch (handCoupling) {
      case 0:
        return 1;
      case 1:
        if (originHandHeight < 30) {
          return 0.95;
        } else {
          return 0.90;
        }
      case 2:
        return 0.90;
      default:
    }
  }

  // noinspection DuplicatedCode
  static convertToImperial(data) {
    let newData = JSON.parse(JSON.stringify(data));
    newData.height = data.height * CM_TO_IN;
    newData.startHandHeight = data.startHandHeight * CM_TO_IN;
    newData.endHandHeight = data.endHandHeight * CM_TO_IN;
    newData.startHandDistance = data.startHandDistance * CM_TO_IN;
    newData.endHandDistance = data.endHandDistance * CM_TO_IN;
    newData.objectWeight = data.objectWeight * KG_TO_LBS;
    return newData;
  }

  static calculateNIOSHEstimate(analysisData) {
    let joints = analysisData.joints;
    let hands = analysisData.hands;
    let scores = [];
    let complete = true;

    // noinspection JSUnresolvedVariable
    for (let i = 0; i < joints.Neck.data.length; ++i) {
      if (!(hands && hands.vertical && hands['vertical'][i] >= 0)) { // Check for missing Data
        complete = false;
      }
      // noinspection JSUnresolvedVariable
      scores.push({'x': joints.Neck.data[i]['x'], 'y': hands && hands.vertical ? hands['vertical'][i] : 0});
    }

    return {
      "estimates": scores,
      "complete": complete
    };
  }

  static generateAndDownloadNIOSHReport(data, score, videoName, date, groupNames) {
    let report = "";
    let name = data.name ? data.name : "Unnamed Assessment";
    let group = "";
    if (groupNames && groupNames.length > 0) {
      group = groupNames[0]
    }

    let distanceUnit = (data.units === "metric") ? "cm" : "in";
    let massUnit = (data.units === "metric") ? "kg" : "lbs";
    let timeUnit = data.timeUnit === "hour" ? "HOUR" : "MINUTE";

    const frequency = (timeUnit === "hour") ? data.liftsPerHour / 60 : data.liftsPerHour;

    let workDuration = data.workDuration === 0 ? "<= 1 hour" :
      data.workDuration === 1 ? ">1 but <= 2 hrs" :
        data.workDuration === 2 ? ">2 but <=8 hrs" : "> 8 hrs";
    let handCoupling = data.handCoupling === 0 ? "GOOD" :
      data.handCoupling === 1 ? "FAIR" : "POOR";

    report += "NIOSH ASSESSMENT,\n";
    report += "\n";
    report += "GROUP:,," + group + "\n";
    report += "VIDEO NAME:,," + videoName + ",\n";
    report += "ASSESSMENT NAME:,," + name + ",\n";
    report += "ASSESSMENT DATE:,," + date + ",\n";
    report += "LI:,," + score.liftIndex + ",\n";
    report += "RWL:,," + score.recommendedWeightLimit + " " + massUnit + ",\n";
    report += ",\n";
    report += "WORKER'S HEIGHT:,," + Utils.interpretDataReportValue(data.height, " " + distanceUnit) + ",\n";
    report += "START HAND HEIGHT:,," + Utils.interpretDataReportValue(data.startHandHeight, " " + distanceUnit) + ",\n";
    report += "END HAND HEIGHT:,," + Utils.interpretDataReportValue(data.endHandHeight, " " + distanceUnit) + ",\n";
    report += "START HAND DISTANCE:,," + Utils.interpretDataReportValue(data.startHandDistance, " " + distanceUnit) + ",\n";
    report += "END HAND DISTANCE:,," + Utils.interpretDataReportValue(data.endHandDistance, " " + distanceUnit) + ",\n";
    report += "OBJECT WEIGHT:,," + Utils.interpretDataReportValue(data.objectWeight, " " + massUnit) + ",\n";
    report += "LIFTS/LOWERS PER " + timeUnit + ":,," + Utils.interpretDataReportValue(frequency, "") + ",\n";
    report += "START ASYMMETRY ANGLE:,," + Utils.interpretDataReportValue(data.startAsymmetryAngle, "°") + ",\n";
    report += "END ASYMMETRY ANGLE:,," + Utils.interpretDataReportValue(data.endAsymmetryAngle, "°") + ",\n";
    report += "WORK DURATION:,," + workDuration + ",\n";
    report += "HAND COUPLING:,," + handCoupling + ",\n";

    return new Blob(["\ufeff" + report], { type: "text/csv" });
  }

  /**
   * Creates array with messages to send if any NIOSH multipliers are zero
   * @return {Array<string>}
   */
  static prepareZeroFields(originVerticalMultiplier, destinationVerticalMultiplier, originHorizontalMultiplier,
                           destinationHorizontalMultiplier, frequencyMultiplier, frequency, originAsymmetryMultiplier,
                           destinationAsymmetryMultiplier, distanceMultiplier, timeUnit) {
    let zeroFields = [];
    const liftsPerMinute = (timeUnit === "hour") ? frequency / 60 : frequency;

    if (originVerticalMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.startHandHeight.label") + i18n.t("AssessmentForm.niosh.startHandHeight.condition"))
    }
    if (destinationVerticalMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.endHandHeight.label") + i18n.t("AssessmentForm.niosh.endHandHeight.condition"));
    }
    if (originHorizontalMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.startHandDistance.label") + i18n.t("AssessmentForm.niosh.startHandDistance.condition"));
    }
    if (destinationHorizontalMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.endHandDistance.label") + i18n.t("AssessmentForm.niosh.endHandDistance.condition"));
    }
    if (frequencyMultiplier === 0) {
      if (liftsPerMinute <= 15) {
        zeroFields.push(i18n.t("AssessmentForm.niosh.frequencyCondition1"));
      } else {
        zeroFields.push(i18n.t("AssessmentForm.niosh.frequencyCondition2"));
      }
    }
    if (originAsymmetryMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.startAsymmetryAngle.label") + i18n.t("AssessmentForm.niosh.startAsymmetryAngle.condition"));
    }
    if (destinationAsymmetryMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.endAsymmetryAngle.label") + i18n.t("AssessmentForm.niosh.endAsymmetryAngle.condition"));
    }

    if (distanceMultiplier === 0) {
      zeroFields.push(i18n.t("AssessmentForm.niosh.distanceCondition"));
    }

    return zeroFields;
  }
}
