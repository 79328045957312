import React from 'react';
import { Button } from "antd";
import { Request } from "../../utils";
import { Link } from "react-router-dom";

/**
 * Class that displays the total number
 * of assessments for an analysis
 */
class AssessmentNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num_assessments: null
    };
  }

  /**
   * Sends request to /api/assessments/listAssessments
   * once mounted
   */
  componentDidMount() {
    const { assessmentId } = this.props;
    Request.listAssessments(assessmentId)
      .then((data) => {
        let list_length = data['assessments'].length ? data['assessments'].length : 0;
        this.setState({
          num_assessments: list_length
        });
      });
  }

  getVideoLink = (id) => {
    return '/videos/' + id;
  }

  render() {
    const { num_assessments } = this.state;
    const { assessmentId, assessmentName } = this.props;
    let searchString = this.props.targetUser ? "?targetUser=" + this.props.targetUser : "";

    // Only load component if number of assessments has been fetched
    if (num_assessments || num_assessments === 0) {
      let linkObj = {
        pathname: this.props.getVideoLink(assessmentId),
        search: searchString,
        state: {
          activeView: "assessments"
        }
      };
      return (
        <Link to={linkObj} >
          <Button data-test={assessmentName + "_number"} size="small" shape="circle" style={{ backgroundColor: '#fff', color: '#990', boxShadow: '0 0 0px 1px #d9d9d9 inset' }}>
            {num_assessments}
          </Button>
        </Link>
      );
    } else {
      return (<div/>);
    }
  }
}

export default AssessmentNumber;