import React from 'react';
import { Row, Col, Divider, Layout } from 'antd';

import { Logo } from '../../components';

import { withTranslation } from "react-i18next";
import { RecoverPasswordForm } from '../../components/Forms';

const { Footer } = Layout;

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{height: "100vh"}}>
        <Row type="flex" justify="center" align="middle" style={{height: '95vh'}}>
          <Col span={24} style={{maxWidth: '300px'}}>
            <Row type="flex" justify="center" align="middle" style={{height: "60px"}}>
              <Col  style={{display: "flex", width: '100%'}}>
                <span style={{width: '100%', fontSize: '28pt', textAlign: 'center', fontWeight: 600, marginBottom: "10px", color: "#2B3990"}}>
                  { t('RecoverPassword.resetPassword') }
                </span>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{height: "2px"}}>
              <Col  style={{width: '100%'}}>
                <Divider style={{margin: 0, height: "2px"}}/>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col  style={{display: "flex", alignItems: "center", flexDirection: "column", width: '100%'}}>
                <Logo />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col  style={{width: '100%', marginBottom: '6px'}}>
                <div>{t('RecoverPassword.recoverInstructions')}</div>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col  style={{width: '100%'}}>
                <RecoverPasswordForm onPasswordResetRequest={ this.props.onPasswordResetRequest }/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer style={{backgroundColor: "#ffffff", textAlign: "center"}}>
          <span>{t('AppLayout.copyright')}</span>
        </Footer>
      </div>
    );
  }
}

export default withTranslation()(RecoverPassword);
