import React from 'react';
import { withTranslation } from "react-i18next";
import {Form, Button, Affix, Input, Icon, Menu, Dropdown, Popover} from 'antd';

import {CordovaUtils, userInfo, Utils, FileUtil } from '../../utils';
import { Niosh } from '../../utils/AssessmentCalculators'
import { FormInputNumber, FormRadio } from './FormItems';

class NIOSHForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  // noinspection DuplicatedCode
  componentDidMount() {
    const { t } = this.props;
    this.props.form.validateFields();

    let optionList = [];
    if (this.props.addOptions && this.prepareAssessmentReport) {
      optionList = [
        {
          key: "downloadAssessment",
          onClick: () => {
            this.prepareAssessmentReport();
          },
          icon: <Icon type="download" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.downloadAssessment'),
          dataTest: "download-assessment-option"
        },
        {
          key: "shareAssessment",
          onClick: () => {
            this.prepareAssessmentReport(true);
          },
          icon: <Icon type="share-alt" style={{padding: '6px', fontSize: '12pt'}}/>,
          text: t('AnalysisView.options.shareAssessment'),
          dataTest: "share-assessment-option"
        }
      ];
    }
    this.props.addOptions(optionList);
    this.setState(this.initializeFormValuesNIOSH());
  }

  // noinspection DuplicatedCode
  initializeFormValuesNIOSH() {
    let { t, frameRange, analysisData } = this.props;
    let hands = analysisData.hands;
    let initialValues;
    let units = userInfo.getMeasure();
    let massUnit = (units === "metric") ? "(" + t('Units.kilogram.abbrev') + ")" : "(" + t('Units.pound.abbrev') + ")";
    let distanceUnit = (units === "metric") ? "(" + t('Units.centimeter.abbrev') + ")" : "(" + t('Units.inch.abbrev') + ")";

    // noinspection DuplicatedCode
    if (this.props.existingAssessmentData) {
      initialValues = this.props.existingAssessmentData;
      initialValues.assessmentId = this.props.assessmentId;
      massUnit = (this.props.existingAssessmentData.units === "metric") ? "(" + t('Units.kilogram.abbrev') + ")" : "(" + t('Units.pound.abbrev') + ")";
      distanceUnit = (this.props.existingAssessmentData.units === "metric") ? "(" + t('Units.centimeter.abbrev') + ")" : "(" + t('Units.inch.abbrev') + ")";
    } else {
      let initialData = {
        startHandHeight: hands && hands.vertical ? hands["vertical"][frameRange.start] : 0,
        endHandHeight: hands && hands.vertical ? hands["vertical"][frameRange.end] : 0,
        startHandDistance: hands && hands.horizontal ? hands["horizontal"][frameRange.start] : 0,
        endHandDistance: hands && hands.horizontal ? hands["horizontal"][frameRange.end] : 0
      };
      initialValues = {
        name: null,
        units: units ? units : "imperial",
        timeUnit: "hour",
        initialData: initialData,
        rangeTimestamp: this.props.rangeTimestamp,
        workDuration: 0,
        handCoupling: 0,
        recommendedWeightLimit: 0,
        score: "",
        zeroFields: []
      };
    }

    let inputFields = {
      height: {
        key: "height",
        label: t('AssessmentForm.niosh.height') + " " + distanceUnit,
        initVal: initialValues["height"],
      },
      startHandHeight: {
        key: "startHandHeight",
        label: t('AssessmentForm.niosh.startHandHeight.label') + " " + distanceUnit,
        initVal: initialValues["startHandHeight"],
        invalid: initialValues.initialData["startHandHeight"] === -1,
        description: t('AssessmentForm.niosh.startHandHeight.description')
      },
      endHandHeight: {
        key: "endHandHeight",
        label: t('AssessmentForm.niosh.endHandHeight.label') + " " + distanceUnit,
        initVal: initialValues["endHandHeight"],
        invalid: initialValues.initialData["endHandHeight"] === -1,
        description: t('AssessmentForm.niosh.endHandHeight.description')
      },
      startHandDistance: {
        key: "startHandDistance",
        label: t('AssessmentForm.niosh.startHandDistance.label') + " " + distanceUnit,
        initVal: initialValues["startHandDistance"],
        invalid: initialValues.initialData["startHandDistance"] === -1,
        description: t('AssessmentForm.niosh.startHandDistance.description')
      },
      endHandDistance: {
        key: "endHandDistance",
        label: t('AssessmentForm.niosh.endHandDistance.label') + " " + distanceUnit,
        initVal: initialValues["endHandDistance"],
        invalid: initialValues.initialData["endHandDistance"] === -1,
        description: t('AssessmentForm.niosh.endHandDistance.description')
      },
      objectWeight: {
        key: "objectWeight",
        label: t('AssessmentForm.niosh.objectWeight') + " " + massUnit,
        initVal: initialValues["objectWeight"],
      },
      liftsPerHour: {
        key: "liftsPerHour",
        label: this.renderTimeUnitSelector(initialValues["timeUnit"]),
        initVal: initialValues["liftsPerHour"],
      },
      startAsymmetryAngle: {
        key: "startAsymmetryAngle",
        label: t('AssessmentForm.niosh.startAsymmetryAngle.label'),
        initVal: initialValues["startAsymmetryAngle"],
        description: t('AssessmentForm.niosh.startAsymmetryAngle.description'),
      },
      endAsymmetryAngle: {
        key: "endAsymmetryAngle",
        label: t('AssessmentForm.niosh.endAsymmetryAngle.label'),
        initVal: initialValues["endAsymmetryAngle"],
        description: t('AssessmentForm.niosh.endAsymmetryAngle.description')
      },
      workDuration: {
        key: "workDuration",
        label: t('AssessmentForm.niosh.workDuration.label'),
        initVal: initialValues["workDuration"] === -1 ? 0 : initialValues["workDuration"],
        options: [
          {label: t('AssessmentForm.niosh.workDuration.option1'), score: 0},
          {label: t('AssessmentForm.niosh.workDuration.option2'), score: 1},
          {label: t('AssessmentForm.niosh.workDuration.option3'), score: 2},
          {label: t('AssessmentForm.niosh.workDuration.option4'), score: 3},
        ],
      },
      handCoupling: {
        key: "handCoupling",
        label: t('AssessmentForm.niosh.handCoupling.label'),
        initVal: initialValues["handCoupling"] === -1 ? 0 : initialValues["handCoupling"],
        options: [
          {label: t('AssessmentForm.niosh.handCoupling.option1'), score: 0},
          {label: t('AssessmentForm.niosh.handCoupling.option2'), score: 1},
          {label: t('AssessmentForm.niosh.handCoupling.option3'), score: 2},
        ],
      }
    };

    let newScore = {
      "recommendedWeightLimit": "N/A",
      "liftIndex": "N/A",
    };

    if (this.isComplete(initialValues)) {
      let { score } = Niosh.calculateNIOSH(initialValues);
      newScore = score;
    }

    return ({
      assessmentState: initialValues,
      inputFields: inputFields,
      score: newScore
    });
  }

  /**
   * Renders fields that are zero as list elements for Popover element
   * @param {Array<string>} zeroFields
   * @return {JSX.Element}
   */
  renderZeroFields = (zeroFields) => {
    const fieldList = zeroFields.map((field, index) =>
      <li key={index}>{field}</li>
    );
    return (<div>{fieldList}</div>)
  }

  handleChange = (key, value) => {
    let newAssessmentState = this.state.assessmentState;
    let newScore;

    newAssessmentState[key] = value;

    if (this.isComplete(this.state.assessmentState)) {
      let { score, zeroFields } = Niosh.calculateNIOSH(newAssessmentState);
      newScore = score;
      newAssessmentState['zeroFields'] = zeroFields;
    } else {
      newScore = {
        "recommendedWeightLimit": "N/A",
        "liftIndex": "N/A",
      };
    }

    if (key === "height") {
      this.setHandLocationForHeight(value);
    }
    this.setState({
      assessmentState: newAssessmentState,
      score: newScore
    });
  };

  // noinspection DuplicatedCode
  setHandLocationForHeight = (height) => {
    const { assessmentState, inputFields } = this.state;
    let newAssessmentState = assessmentState;
    let newInputFields = inputFields;

    const initialData = assessmentState.initialData;

    if (initialData.startHandHeight >= 0) {
      newInputFields.startHandHeight.initVal = assessmentState.initialData.startHandHeight * height;
      newAssessmentState.startHandHeight = assessmentState.initialData.startHandHeight * height;
    }
    if (initialData.endHandHeight >= 0) {
      newInputFields.endHandHeight.initVal = assessmentState.initialData.endHandHeight * height;
      newAssessmentState.endHandHeight = assessmentState.initialData.endHandHeight * height;
    }
    if (initialData.startHandDistance >= 0) {
      newInputFields.startHandDistance.initVal = assessmentState.initialData.startHandDistance * height;
      newAssessmentState.startHandDistance = assessmentState.initialData.startHandDistance * height;
    }
    if (initialData.endHandDistance >= 0) {
      newInputFields.endHandDistance.initVal = assessmentState.initialData.endHandDistance * height;
      newAssessmentState.endHandDistance = assessmentState.initialData.endHandDistance * height;
    }

    this.setState({
      assessmentState: newAssessmentState,
      inputFields: newInputFields
    });
  }

  prepareAssessmentReport = (share=false) => {
    const { t, videoData, videoName, assessmentDate, groups } = this.props;
    // noinspection JSUnresolvedVariable
    let groupNames = Utils.getGroupNames(videoData.groupMembership, groups)

    let reportData = Niosh.generateAndDownloadNIOSHReport(this.state.assessmentState, this.state.score, videoName, t('Formats.calendarDateTime', {date: new Date(assessmentDate)}), groupNames);

    const assessmentName = (this.state.assessmentState && this.state.assessmentState.name) ? this.state.assessmentState.name : "Unsaved_Assessment";
    let filename = FileUtil.prepareFilename(this.props.videoName + "_" + assessmentName + "_NIOSH_Report.csv");

    if (share) {
      return CordovaUtils.shareBlob(reportData, filename);
    } else {
      return FileUtil.saveBlobToDevice(reportData, filename);
    }
  };

  isComplete = (assessmentState) => {
    return ("height" in assessmentState && "startHandHeight" in assessmentState && "endHandHeight" in assessmentState && "endHandDistance" in assessmentState && "objectWeight" in assessmentState &&
      "liftsPerHour" in assessmentState && "startAsymmetryAngle" in assessmentState && "endAsymmetryAngle" in assessmentState && "workDuration" in assessmentState && "handCoupling" in assessmentState);
  }

  changeTimeUnit = (item) => {
    let unit = item.key;
    let newInputFields = this.state.inputFields;
    let newAssessmentState = this.state.assessmentState;

    if (unit !== this.state.assessmentState.timeUnit) {
      newInputFields.liftsPerHour.label = this.renderTimeUnitSelector(unit);
      newAssessmentState.timeUnit = unit;

      let { score, zeroFields } = Niosh.calculateNIOSH(newAssessmentState);

      newAssessmentState['zeroFields'] = zeroFields;

      this.setState({
        inputFields: newInputFields,
        assessmentState: newAssessmentState,
        score: score
      });
    }
  }

  renderTimeUnitSelector = (unit) => {
    const { t } = this.props;
    const menu = (
      <Menu onClick={this.changeTimeUnit} id="menu">
        <Menu.Item data-test="lifts-per-hour-option" key="hour">{t('Units.hour.singular')}</Menu.Item>
        <Menu.Item data-test="lifts-per-minute-option" key="minute">{t('Units.minute.singular')}</Menu.Item>
      </Menu>
    );

    // noinspection HtmlUnknownTarget
    return (
      <span>
        {t('AssessmentForm.niosh.liftsPerHour') + " "}
        <Dropdown overlay={menu}>
          <a href="_blank" className="ant-dropdown-link" onClick={e => e.preventDefault()} data-test="lifts-per-hour-dropdown">
            {t("Units." + unit + ".singular")} <Icon type="down" />
          </a>
        </Dropdown>
      </span>
    );
  }

  // noinspection DuplicatedCode
  renderFormSubmissionBar = () => {
    const { t } = this.props;
    const { assessmentState } = this.state
    let massUnit = (assessmentState.units === "metric") ? t('Units.kilogram.abbrev') : t('Units.pound.abbrev')
    let popover;

    // Handles error message if LI is infinity
    if (assessmentState.zeroFields && assessmentState.zeroFields.length > 0) {
      popover = <Popover
        title={t('AssessmentForm.niosh.NAErrorMessage')}
        content={this.renderZeroFields(assessmentState.zeroFields)}>
        <Icon type="exclamation-circle" style={{color: 'red'}} data-test={"NA-popover-icon"}/>
      </Popover>;
    } else {
      popover = null;
    }

    return (
      <Affix offsetBottom={-2}>
        <Input
          size="large"
          placeholder={ t('AssessmentForm.assessmentName') }
          onChange={e => this.handleChange("name", e.target.value)}
          defaultValue={assessmentState.name}
          style={{fontWeight: 'bold'}}
          data-test="assessment-name-input"
          addonBefore={
            <h3 style={{margin: "0px"}}>
              <div>{t('AssessmentForm.niosh.liftIndexAbbrev')}: <span data-test="lift-index">{this.state.score.liftIndex}</span> {popover}</div>
              <div>{t('AssessmentForm.niosh.RecommendedWeightLimitAbbrev')}: <span data-test="recommended-weight-limit">
                {this.state.score.recommendedWeightLimit}
              </span> {this.state.score.recommendedWeightLimit === "N/A" ? null : massUnit}</div>
            </h3>
          }
          addonAfter={
            <span>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({uploading: true}, this.props.handleSubmit(this.state.assessmentState, this.state.score));
                }}
                disabled={!assessmentState.name || assessmentState.name.length <= 0 || !this.isComplete(assessmentState)}
                loading={this.state.uploading}
                htmlType="submit"
                data-test="assessment-submit-button"
              >
                {t('AssessmentForm.save')}
              </Button>
              <Button type="default" onClick={this.props.onLeave} style={{marginLeft: '6px'}} data-test="assessment-cancel-button">
                {t('AssessmentForm.cancel')}
              </Button>
            </span>
          }
        />
      </Affix>
    );
  };

  render() {
    const { form } = this.props;
    const { inputFields, assessmentState } = this.state;
    if (inputFields) {
      return (
        <Form colon={false} layout="horizontal" style={{marginTop: '16px'}} labelCol={{span:13, offset:0}} wrapperCol={{span:1, offset:0}}>
          <FormInputNumber data={inputFields.height} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="height-input"/>
          <FormInputNumber data={inputFields.startHandHeight} onChange={this.handleChange} disabled={!assessmentState.height} tooltip={true} style={{margin: "4px"}} form={form} dataTest="start-hand-height-input"/>
          <FormInputNumber data={inputFields.endHandHeight} onChange={this.handleChange} disabled={!assessmentState.height} tooltip={true} style={{margin: "4px"}} form={form} dataTest="end-hand-height-input"/>
          <FormInputNumber data={inputFields.startHandDistance} onChange={this.handleChange} disabled={!assessmentState.height} tooltip={true} style={{margin: "4px"}} form={form} dataTest="start-hand-distance-input"/>
          <FormInputNumber data={inputFields.endHandDistance} onChange={this.handleChange} disabled={!assessmentState.height} tooltip={true} style={{margin: "4px"}} form={form} dataTest="end-hand-distance-input"/>
          <FormInputNumber data={inputFields.objectWeight} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="object-weight-input"/>
          <FormInputNumber data={inputFields.liftsPerHour} onChange={this.handleChange} style={{margin: "4px"}} form={form} dataTest="lifts-per-hour-input"/>
          <FormInputNumber data={inputFields.startAsymmetryAngle} onChange={this.handleChange} tooltip={true} style={{margin: "4px"}} form={form} dataTest="start-asymmetry-angle-input"/>
          <FormInputNumber data={inputFields.endAsymmetryAngle} onChange={this.handleChange} tooltip={true} style={{margin: "4px"}} form={form} dataTest="end-asymmetry-angle-input"/>
          <Form.Item style={{margin: '0px'}} wrapperCol={{}}>
            <FormRadio data={inputFields.workDuration} onChange={this.handleChange} form={form} dataTest="work-duration-radio"/>
            <FormRadio data={inputFields.handCoupling} onChange={this.handleChange} form={form} dataTest="hand-coupling-radio"/>
            {this.renderFormSubmissionBar()}
          </Form.Item>
        </Form>
      );
    }
    else {
      return null;
    }
  };
}

export default withTranslation()(Form.create()(NIOSHForm));
