import React from "react";
import { Icon, Upload, Button } from "antd";
import { withTranslation } from "react-i18next";
import { sessionDetails, CordovaUtils } from '../../utils'

const { Dragger } = Upload;

class UploadVideo extends React.Component {
  render() {
    const { t, onSetVideo } = this.props;
    if (sessionDetails.isCordova()) {
      return (
        <div>
        <Button
            size="large"
            type="primary"
            icon="upload"
            onClick={() => CordovaUtils.uploadVideo(onSetVideo)}
            style={{width: "50vw", display: "block", margin: "auto", marginBottom: '16px', marginTop: '16px'}}>
          {t("UploadVideo.uploadVideo")}
        </Button>
        </div>
      );
    } else {
      return (
        <div style={{height: '75vh', width: '100%', marginTop: '5vh'}}>
          <Dragger id="video-uploader" accept=".mp4,.m4v,.mov,.ogg,.webm,.gif" multiple={false} beforeUpload={ file => onSetVideo(file, URL.createObjectURL(file)) }>
            <Icon type="inbox" style={{color: '#00A79D', fontSize: '72pt'}}/>
            <p style={{fontWeight: 600, fontSize: '14pt'}}>{ t('UploadVideo.dragOrClick') }</p>
            <p style={{fontWeight: 300, fontSize: '12pt', color: '#B0B0B0'}}>{ t('UploadVideo.videoFormats')}</p>
          </Dragger>
        </div>
      );
    }
  }
}

export default withTranslation()(UploadVideo);