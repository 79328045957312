import React from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Persistence } from "../../utils";


class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isOrgAdmin: false,
    }
  }

  componentDidMount() {
    this.setState({
      isAdmin: Persistence.isAdmin(),
      isOrgAdmin: Persistence.isOrgAdmin()
    });
  }

  render() {
    const { t } = this.props;
    const { isAdmin, isOrgAdmin } = this.state;
    const menuItems = [
      // {
      //   title: 'Dashboard',
      //   icon: 'dashboard',
      //   link: '/',
      // },
      // {
      //   title: 'Task Analysis',
      //   icon: 'line-chart',
      //   link: '/analysis',
      // },
      // {
      //   title: 'Task Comparison',
      //   icon: 'swap',
      //   link: '/comparison',
      // },
      {
        title: t('SideMenu.newVideoTitle'),
        dataTest: "new-video-link",
        icon: 'plus',
        link: <Link to='/newVideo'/>,
      },
      {
        title: t('SideMenu.videosTitle'),
        dataTest: "videos-link",
        icon: 'video-camera',
        link: <Link to='/videos'/>,
      },
      // {
      //   title: 'Account',
      //   icon: 'user',
      //   link: '/account',
      // },
      {
        title: t('SideMenu.settingsTitle'),
        dataTest: "settings-link",
        icon: 'setting',
        link: <Link to='/settings'/>,
      },
      {
        title: t('SideMenu.aboutTitle'),
        dataTest: "about-link",
        icon: 'info-circle',
        link: <a onClick={() => window.open("https://www.ehs.com/kineticalabs", "_blank")}/>,
      },
      {
        title: t('SideMenu.privacyPolicy'),
        dataTest: "privacy-policy-link",
        icon: 'lock',
        // eslint-disable-next-line
        link: <a onClick={() => window.open("https://www.ehs.com/privacy/", "_blank")}/>,
      },
      // Can re-enable when we have an up to date help page to redirect to
      //{
      //  title: t('SideMenu.help'),
      //  dataTest: "help-link",
      //  icon: 'question-circle',
      //  // eslint-disable-next-line
      //  link: <a onClick={() => window.open("https://kineticalabs.com/web-app-help", "_blank")}/>,
      //},
    ];

    if (isAdmin) {
      menuItems.push({
        title: t('SideMenu.adminTitle'),
        dataTest: "admin-link",
        icon: 'crown',
        link: <Link to='/admin'/>,
      });
    }

    if (isOrgAdmin || isAdmin) {
      menuItems.push({
        title: t('SideMenu.orgAdminTitle'),
        dataTest: "organization-link",
        icon: 'team',
        link: <Link to='/orgAdmin'/>,
      });
    }

    const { location } = this.props;
    const idx = menuItems.findIndex(item => location.pathname.startsWith(item.link));
    const items = menuItems.map((item, i) => {
      return (
        <Menu.Item data-test={item.dataTest} name={item.dataTest} key={`${i}`}>
          <Icon type={item.icon} />
          <span>{item.title}</span>
          {item.link}
        </Menu.Item>
      );
    });
    return (
      <Menu 
        theme="light" 
        mode="inline" 
        defaultSelectedKeys={['1']}
        selectedKeys={[`${idx}`]}
        className="noselect"
        style={{width: "100%"}}
        onClick={this.props.onClick}
      >        
        {items}
      </Menu>
  );
  }
}

export default withTranslation()(SideMenu);