import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import 'antd/dist/antd.css';
import './styles/css/App.css';
import { AppLayout } from './components';
import { NewPassword, NewPasswordEV } from './views';

const Loader = () => {
    return (
        <div className="App">
            <Spin size="large" />
        </div>
    );
};

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <div className="App">
          <Switch>
            <Route path="/resetPasswordEV/:token" component={NewPasswordEV} />
            <Route path="/resetPassword/:token" component={NewPassword} />
            <Route component={AppLayout} />
          </Switch>
        </div>
      </Suspense>
    );
  }
}

export default App;
