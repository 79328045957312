import React from 'react';
import { Slider } from 'antd';
import moment from 'moment';

class TrimSlider extends React.Component {

  formatValue = (value) => {
    const duration = moment.duration(value, 'seconds');
    return moment.utc(duration.asMilliseconds()).format("mm:ss")
  };

  onTrimSliderChange = (value) => {
    const { onChangeTrimStart, onChangeTrimEnd } = this.props;
    if (value[0] !== this.props.trimStart) {
      onChangeTrimStart(value[0]);
    } else {
      onChangeTrimEnd(value[1]);
    }
  };

  onTrimSliderAfterChange = (value) => {
    const { onAfterChange } = this.props;
    onAfterChange(value);
  };

  render() {
    const { duration, trimStart, trimEnd, value, disabled } = this.props;
    const marks = {};
    marks[0] = this.formatValue(0);
    marks[value] = this.formatValue(value);
    marks[duration] = this.formatValue(duration);
    return (
      <div className="TrimSlider" data-test="trim-slider">
        <Slider 
          range 
          disabled={disabled}
          step={0.2}
          marks={marks} 
          value={[trimStart, trimEnd]}
          min={0} 
          max={duration} 
          tipFormatter={this.formatValue} 
          onChange={(v) => this.onTrimSliderChange(v)}
          onAfterChange={(v) => this.onTrimSliderAfterChange(v)}
        />
      </div>
    );
  }
}

export default TrimSlider;
