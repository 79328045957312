import React from "react";
import { Button, Table } from 'antd';

import {sessionDetails, Utils} from "../../utils";
import { withTranslation } from "react-i18next";

class UserTable extends React.Component {

    handleSortChange = (pagination, filters, sorter) => {
        this.props.onChangeSort(pagination.current, sorter.field, sorter.order);
    }

    render() {
        const { t, hideOrgData, defaultPage, defaultSortColumn, defaultSortDirection } = this.props;
        let columns = [
            {
                title: <span data-test="username-column">{t('UserTable.username')}</span>,
                dataIndex: 'username',
                key: 'username',
                defaultSortOrder: defaultSortColumn === 'username' ? defaultSortDirection : null,
                render: (text) => {
                    return <Button onClick={() => this.props.onClickUser(text)} type="link" data-test="user-table-username-entry">{text}</Button>
                },
                sorter: (a, b) => a.username.localeCompare(b.username)
            },
            {
                title: <span data-test="display-name-column">{t('UserTable.displayName')}</span>,
                dataIndex: 'displayName',
                key: 'displayName',
                defaultSortOrder: defaultSortColumn === 'displayName' ? defaultSortDirection : null,
                sorter: (a, b) => a.displayName.localeCompare(b.displayName)
            },
            {
                title: <span data-test="user-type-column">{t('UserTable.userType')}</span>,
                dataIndex: 'userType',
                key: 'userType',
                defaultSortOrder: defaultSortColumn === 'userType' ? defaultSortDirection : null,
                sorter: (a, b) => a.userType.localeCompare(b.userType)
            },
        ];

        let extendedColumns = [
            {
                title: <span data-test="organization-column">{t('UserTable.organization')}</span>,
                dataIndex: 'organization',
                key: 'organization',
                defaultSortOrder: defaultSortColumn === 'organization' ? defaultSortDirection : null,
                sorter: (a, b) => {
                    let orgA = a.organization || 'ZZ';
                    let orgB = b.organization || 'ZZ';
                    return orgA.localeCompare(orgB);
                }
            },
            {
                title: <span data-test="video-count-column">{t('UserTable.videoCount')}</span>,
                dataIndex: 'videoCount',
                key: 'videoCount',
                defaultSortOrder: defaultSortColumn === 'videoCount' ? defaultSortDirection : null,
                sorter: (a, b) => {
                    let countA = a.videoCount ? a.videoCount : 0
                    let countB = b.videoCount ? b.videoCount : 0
                    return countA - countB;
                }
            },
            {
                title: <span data-test="assessment-count-column">{t('UserTable.assessmentCount')}</span>,
                dataIndex: 'assessmentCount',
                key: 'assessmentCount',
                defaultSortOrder: defaultSortColumn === 'assessmentCount' ? defaultSortDirection : null,
                sorter: (a, b) => {
                    let countA = a.assessmentCount ? a.assessmentCount : 0
                    let countB = b.assessmentCount ? b.assessmentCount : 0
                    return countA - countB;
                }
            },
            {
                title: <span data-test="uses-remaining-column">{t('UserTable.usesRemaining')}</span>,
                dataIndex: 'uses',
                key: 'uses',
                defaultSortOrder: defaultSortColumn === 'uses' ? defaultSortDirection : null,
                sorter: (a, b) => {
                    let countA = a.uses ? a.uses : 0
                    let countB = b.uses ? b.uses : 0
                    return countA - countB;
                }
            },
            {
                title: <span data-test="expiration-column">{t('UserTable.expiration')}</span>,
                dataIndex: 'expiration',
                key: 'expiration',
                defaultSortOrder: defaultSortColumn === 'expiration' ? defaultSortDirection : null,
                render: (text) => {
                    let date = t('UserTable.none')
                    if (text) {
                        date = new Date(text)
                    }
                    return t('Formats.calendarDateTime', {date: date});
                },
                sorter: (a, b) => {
                    return Utils.compareDates(a.expiration, b.expiration)
                }
            },
            {
                title: <span data-test="product-access-column">{t('UserTable.productAccess')}</span>,
                dataIndex: 'productAccess',
                key: 'productAccess',
                defaultSortOrder: defaultSortColumn === 'productAccess' ? defaultSortDirection : null,
                render: (text) => {
                    return Utils.translateProductAccess(text)
                },
                sorter: (a, b) => {
                    let productsA = Utils.translateProductAccess(a.productAccess) || t('UserTable.none');
                    let productsB = Utils.translateProductAccess(b.productAccess) || t('UserTable.none');
                    return productsA.localeCompare(productsB);
                }
            }
        ]

        let emailColumn = {
            title: <span data-test="email-column">{t('UserTable.emailAddress')}</span>,
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            defaultSortOrder: defaultSortColumn === 'emailAddress' ? defaultSortDirection : null,
            sorter: (a, b) => {
                let emailA = a.emailAddress || 'ZZ';
                let emailB = b.emailAddress || 'ZZ';
                return emailA.localeCompare(emailB);
            }
        }

        // Determine which columns to show
        if (!sessionDetails.isMobile()) {
            // Insert email column right after username
            columns.splice(1, 0, emailColumn);

            if (hideOrgData) {
                columns = columns.concat([extendedColumns[1], extendedColumns[4]]);
            } else {
                columns = columns.concat(extendedColumns);
            }
        }

        return (
            <Table
                size="small"
                dataSource={this.props.dataSource}
                columns={columns}
                loading={this.props.loading}
                rowKey={'username'}
                onChange={this.handleSortChange}
                pagination={{defaultCurrent: defaultPage, defaultPageSize: 25}}
                style={{padding: '0px'}}
            />
        );
    }
}

export default withTranslation()(UserTable);