import React from "react";
import {Button, Card, Form, Input, Spin} from 'antd';

import {
    CordovaUtils,
    FileUtil,
    Request,
    sessionDetails,
    Utils,
    VideoListSorter,
} from '../../utils';
import { withTranslation } from "react-i18next";
import {UserTable, UserDataForm, VideoTableSorter} from '../';
import { VideoTable } from "../VideoTable";

class UserLookup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userList: null,
            videoList: [],
            userListPage: null,
            userListSortColumn: null,
            userListSortDirection: null,
            tableSortProps: {
                column: "uploadDate",
                direction: "descend",
            },
        }
    }

    componentDidMount() {
        if (this.props.dataSource && this.props.dataSource.username) {
            let data = this.props.dataSource;
            this.setState({
                userData: {
                    username: data.username,
                    displayName: data.displayName,
                    uses: data.uses,
                    expiration: data.expiration,
                    userType: data.userType,
                    organization: data.organization
                }
            });
        } else {
            this.getList();
        }
    }

    getList = () => {
        this.setState({loading: true});
        if (this.props.isAdmin) {
            Request.listUsers().then((response) => {
                this.setState({
                    loading: false,
                    userList: response.users,
                    userData: null
                });
            });
        } else {
            Request.listUsersOrgAdmin().then((response) => {
                this.setState({
                    loading: false,
                    userList: response.users,
                    userData: false,
                });
            });
        }
    };

    conditionalVideoList = () => {
        if (this.state.videoList) {
            const sortedVideos = VideoListSorter.sortVideos(this.state.videoList, null, this.state.tableSortProps.column,
                this.state.tableSortProps.direction, null, null);
            return (
                <span>
                    <VideoTableSorter sortChange={(sortProps) => {this.setState({tableSortProps: sortProps})}} />
                    <VideoTable noSelection targetUser={this.state.userData.username} videos={sortedVideos} tableSortProps={{column: "Upload Date", direction: "Descending"}}/>
                </span>
            );
        } else {
            return null;
        }
    };

    getUserVideos = () => {
        if (this.state.userData && this.state.userData.username) {
            Request.listVideos(this.state.userData.username).then(response => this.setState({videoList: response.videos}));
        }
    };

    conditionalUserForm = () => {
        const { t, isAdmin } = this.props;
        if (this.state.loading) {
            return <Spin/>
        }
        if (this.state.userData && this.state.userData.username) {
            return (
                <div style={{paddingTop: "8px"}}>
                    <UserDataForm
                        dataSource={this.state.userData}
                        getVideos={this.getUserVideos}
                        onDelete={this.getList}
                        onSubmitSuccess={this.handleUpdate}
                        onSubmitFail={this.props.onSubmitFail}
                        isAdmin={isAdmin}
                        onCancel={() => {
                            this.setState({userData: null, videoList: null});
                            if (!this.state.userList) {
                                this.getList();
                            }
                        }}
                    />
                    {this.conditionalVideoList()}
                </div>
            );
        } else {
            return (
                <div style={{paddingTop: "8px"}}>
                    <Button type="primary" loading={this.state.loading} onClick={this.getList} style={{marginBottom: "6px"}} data-test="user-table-refresh-button">{t('UserTable.fetch')}</Button>
                    <Button type="primary" loading={this.state.loading} onClick={this.exportCSV} style={{marginBottom: "6px", marginLeft: "4px"}} data-test="user-table-export-button">{t('UserTable.export')}</Button>
                    <UserTable
                        dataSource={this.state.userList}
                        onClickUser={this.handleUserClick}
                        onChangeSort={this.handleTableSortChange}
                        defaultPage={this.state.userListPage}
                        defaultSortColumn={this.state.userListSortColumn}
                        defaultSortDirection={this.state.userListSortDirection}
                    />
                </div>
            );
        }
    };

    exportCSV = () => {
        this.getList();
        const { t } = this.props;
        let report = "";
        let data = this.state.userList;

        report += "Username,";
        report += "Email Address,";
        report += "Display Name,";
        report += "Privileges,";
        report += "Organization,";
        report += "Videos Processed,";
        report += "Assessments Created,";
        report += "Uses Remaining,";
        report += "Expiration,";
        report += "Product Access,\n";


        for (let i = 0; i < data.length; ++i) {
            let expiration = t('Formats.calendarDateTime', {date: new Date(data[i].expiration)})
            let displayName = data[i].displayName;

            // Checks if display name has comma
            displayName = displayName.includes(",") ? "\"" + displayName + "\"" : displayName;
            const emailAddress = data[i].emailAddress ? data[i].emailAddress : "";
            const organization = data[i].organization ? data[i].organization : "";


            report += data[i].username + ",";
            report += emailAddress + ",";
            report += displayName + ",";
            report += data[i].userType + ",";
            report += organization + ",";
            report += data[i].videoCount + ",";
            report += data[i].assessmentCount + ",";
            report += data[i].uses + ",";
            report += expiration + ",";
            report += "\"" + Utils.translateProductAccess(data[i].productAccess) + "\",\n";
        }

        let reportData = new Blob(["\ufeff" + report], { type: "text/csv" });

        let currDate = t('Formats.calendarDateTime', {date: new Date().toLocaleDateString()})
        currDate = currDate.replace("-", "_")
        let filename = FileUtil.prepareFilename("user_accounts_" + currDate + ".csv")

        if (sessionDetails.isCordova()) {
            return CordovaUtils.shareBlob(reportData, filename);
        } else {
            return FileUtil.saveBlobToDevice(reportData, filename);
        }
    };

    fetchUser = (username) => {
        if (this.props.isAdmin) {
            Request.findUser(username).then(userData => this.setState({userData: userData, loading: false}));
        } else {
            Request.findUserOrgAdmin(username).then(userData => this.setState({userData: userData, loading: false}));
        }
    };

    handleTableSortChange = (page, column, direction) => {
        this.setState({
            userListPage: page,
            userListSortColumn: column,
            userListSortDirection: direction,
        })
    }

    handleUpdate = (username) => {
        this.fetchUser(username);
    };

    handleUserClick = (username) => {
        this.setState({videoList: null});
        this.fetchUser(username);
    };

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields(((errors, values) => {
            if (errors) {
                console.log(errors);
            } else {
                this.setState({loading: true, videoList: null});
                if (values.targetUser) {
                    this.fetchUser(values.targetUser);
                } else {
                    this.setState({userData: null});
                    this.getList();
                }
            }
        }));
    };

    render() {
        const { t } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Card bodyStyle={{padding: '0px'}} bordered={false} title={
                <Form layout="inline" onSubmit={this.handleSearch}>
                    <Form.Item>
                        {getFieldDecorator('targetUser', {})(
                            <Input placeholder={t('UserLookup.username')} autoCorrect="off" data-test="user-lookup-input"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} data-test="user-lookup-search-button">{t('UserLookup.search')}</Button>
                    </Form.Item>
                </Form>
            }>
                {this.conditionalUserForm()}
            </Card>
        );
    }
}

export default withTranslation()(Form.create()(UserLookup));