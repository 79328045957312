import firebase from 'firebase/app';
import 'firebase/messaging';

import Persistence from './persistence';

const webFirebaseConfig = {
  apiKey: 'AIzaSyDogQ3S7CFSnovZnEATBZK4W9zztdPnlZ4',
  authDomain: 'kinetica-labs.firebaseapp.com',
  databaseURL: 'https://kinetica-labs.firebaseio.com',
  projectId: 'kinetica-labs',
  storageBucket: 'kinetica-labs.appspot.com',
  messagingSenderId: '567796528095',
  appId: '1:567796528095:web:e86732d856660746bf0a6a',
  measurementId: 'G-DCWP5EPDN9',
};

class FirebaseClient {
  initialize = () => {
    if (window.FirebasePlugin) {
      this.mobileFirebaseClient = window.FirebasePlugin;
    } else {
      try {
        let firebaseApp = firebase.initializeApp(webFirebaseConfig);
        this.browserFirebaseClient = firebaseApp.messaging();
      } catch (e) {
        console.warn('Push notifications are not supported by this device.');
        Persistence.setNotificationsDeclined();
      }

      if (this.browserFirebaseClient) {
        this.browserFirebaseClient.onMessage((payload) => {
          if (!document.hidden) {
            payload.notification.data = {link: payload.notification.click_action};
            navigator.serviceWorker.getRegistration(
                '/firebase-cloud-messaging-push-scope').then(registration => {
              return registration.showNotification(payload.notification.title,
                  payload.notification);
            });
          }
        });
      }
    }
  }

  /**
   * Determine whether the user wants to receive push notifications or not.
   *
   * @returns {Promise<boolean>} A promise that resolves with true if the user wants to receive notifications (or is on
   * an Android device, which always will return true), or false if the user does not want to receive notifications. The
   * promise will be rejected with an error string if there are any issues using the underlying API.
   */
  requestPermission = () => {
    if (this.mobileFirebaseClient) {
      return this._requestPermissionMobile();
    } else if (this.browserFirebaseClient) {
      return this._requestPermissionBrowser();
    } else {
      // Client does not support notifications
      return Promise.resolve(false);
    }
  }

  /**
   * Get the FCM token that can be used to send push notifications to this device.
   *
   * @returns {Promise<string>}
   */
  getToken = () => {
    if (this.mobileFirebaseClient) {
      return this._getTokenMobile();
    } else if (this.browserFirebaseClient) {
      return this._getTokenBrowser();
    } else {
      // Client does not support notifications
      return Promise.resolve(null);
    }
  }

  /**
   * It is important that we call this so that we don't send notifications with client information to a potentially
   * public or shared device.
   *
   * @returns {Promise<void>|Promise<boolean>}
   */
  logout = () => {
    if (this.mobileFirebaseClient) {
      this.mobileFirebaseClient.unregister();
      return Promise.resolve();
    } else if (this.browserFirebaseClient) {
      return this.browserFirebaseClient.deleteToken();
    } else {
      return Promise.resolve();
    }
  };

  _requestPermissionBrowser = () => {
    const permission = Notification.permission;
    if (permission === "default") {
      // Value of default means the user hasn't been asked for permission, so do that now:
      return Notification.requestPermission().then((newPermission) => {
        return newPermission === "granted";
      });
    } else {
      // If permission is not "default" it is either "granted" or "denied" so we can just respond straight away
      return Promise.resolve(permission === "granted");
    }
  }

  _requestPermissionMobile = () => {
    return new Promise((resolve, reject) => {
      // We MUST first check if the user has already provided permission or iOS will fail on the grantPermission call.
      this.mobileFirebaseClient.hasPermission((hasPermission) => {
        if (hasPermission) {
          resolve(hasPermission);
        } else {
          // User hasn't previously provided permission, we need to ask for it
          // Note: Android will always return true here; the user will be asked for permission when we attempt to request the token.
          this.mobileFirebaseClient.grantPermission(permissionGranted => resolve(permissionGranted), err => reject(err));
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  _getTokenBrowser = () => {
    return this.browserFirebaseClient.getToken(
        {vapidKey: 'BBA9pgUHURh1cpK0VO6T3KSk76-rJCd10ixqYzLopXRByoV9UwGIX1ayA8ATTiIZ1IfoZEBvsGGWQWm_ZEDXUbs'});
  };

  _getTokenMobile = () => {
    return new Promise((resolve, reject) => {
      this.mobileFirebaseClient.getToken((token) => {
        resolve(token);
      }, err => reject(err));
    });
  };
}

export let firebaseClient = new FirebaseClient();